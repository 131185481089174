import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core';

import {MainHeader} from '~/components/MainHeader';
import {drawerWidth} from '~/contants/DrawerWidth';
import MHomeRouter from '~/routers/MHomeRouter';
import {Breakpoint} from '~/contants/Breakpoints';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  content: {
    flexGrow: 1,
    marginLeft: drawerWidth,
  },
  closeMenuContent: {
    marginLeft: theme.spacing(9) + 1,
  },
  paper: {
    [theme.breakpoints.up(Breakpoint.mobile)]: {
      marginTop: theme.spacing(10),
    },
    marginTop: theme.spacing(8) - 4,
  },
}));

const MHomeScreen = () => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className={classes.root}>
      <MainHeader
        title={''}
        open={menuOpen}
        onClickOpen={() => setMenuOpen(true)}
      />
      <div className={classes.paper}>
        <MHomeRouter />
      </div>
    </div>
  );
};

export default MHomeScreen;
