import React, {useState, useEffect, useCallback} from 'react';
import {Button, makeStyles, Typography} from '@material-ui/core';

import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducer';
import {PROFILE, profileRequest} from '~/features/Profile/slice';
import {EMPLOYEES, loadEmployeeSchedules} from '~/features/Employee/slice';
import {ScheduleBtn} from '~/components/ScheduleBtn';
import StyledCalendar from '~/components/StyledCalendar';
import Patient from '~/models/patient';
import {DEPARTMENT, loadDepartment} from '~/features/Department/slice';
import _ from 'lodash';
import moment from 'moment-timezone';
//mport {requsetCenter} from '~/features/Center/slice';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 320,
    padding: '0px 15px',
  },
  todaySchedule: {
    display: 'flex',
    alignItems: 'center',
  },
  topContent: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'center',
  },
  Itembtn: {
    marginLeft: 17.93,
    fontSize: '0.93725em',
    width: 100,
    height: 42,
  },
  calendarDiv: {
    display: 'flex',
    marginTop: theme.spacing(5),
    justifyContent: 'center',
    minHeight: 285,
  },
  schedules: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: theme.spacing(2) - 2,
  },
  calendar: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#F2F2F2',
    borderRadius: theme.spacing(2) - 1,
    padding: theme.spacing(0.5, 1.25, 0.625),
    '& > .react-calendar__navigation': {
      width: 257,
      height: 54,
      marginBottom: 0,
    },
    '& > .react-calendar__viewContainer': {
      width: '100%',
    },
    '& > .react-calendar__viewContainer > div > .react-calendar__year-view__months': {
      display: 'grid !important',
      gridTemplateColumns: 'repeat(auto-fill, minmax(33%, auto))',
      gridTemplateRows: 'repeat(4, minmax(50px, auto))',
      gridAutoFlow: 'dense',
      '& > button': {
        margin: 'auto',
        padding: 0,
      },
    },
    '& > .react-calendar__navigation > .react-calendar__navigation__arrow, .react-calendar__navigation__label': {
      fontSize: '1em',
      fontWeight: 500,
    },
    '& > .react-calendar__viewContainer > .react-calendar__month-view  > div > div >.react-calendar__month-view__days > .react-calendar__tile': {
      fontSize: '0.9em',
    },
    '& > .react-calendar__viewContainer > .react-calendar__month-view > div > div > .react-calendar__month-view__weekdays': {
      fontSize: '0.9em',
      opacity: 0.5,
      fontWeight: 400,
    },
    '& > .react-calendar__viewContainer > .react-calendar__month-view > div > div > .react-calendar__month-view__weekdays > div:first-child': {
      color: '#E31B0C',
    },
    '& > .react-calendar__viewContainer > .react-calendar__month-view > div > div > .react-calendar__month-view__weekdays > div:last-child': {
      color: '#536DFE',
    },
    '& > .react-calendar__viewContainer > .react-calendar__month-view > div > div > .react-calendar__month-view__days > .react-calendar__tile:nth-child(7n-6)': {
      color: '#E31B0C',
    },
    '& > .react-calendar__viewContainer > .react-calendar__month-view > div > div > .react-calendar__month-view__days > .react-calendar__tile:nth-child(7n)': {
      color: '#000000',
    },
    '& > .react-calendar__viewContainer > .react-calendar__month-view > div > div > .react-calendar__month-view__days > .react-calendar__tile--now': {
      background: '#536DFE',
      borderRadius: '50%',
    },
    '& > .react-calendar__viewContainer > .react-calendar__month-view > div > div > .react-calendar__month-view__days > .react-calendar__tile--active:enabled:hover': {
      background: '#536DFE',
      borderRadius: '50%',
    },
    '& > .react-calendar__viewContainer > .react-calendar__month-view > div > div > .react-calendar__month-view__days > .react-calendar__tile--active': {
      borderRadius: '50%',
    },
  },
}));

type ScheduleTime = {
  id: number;
  centerName: string;
  patientName: string;
  startTime: string;
  endTime: string;
  patient: Patient;
};

const MScheduleScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {schedules} = useSelector((state: RootState) => state[EMPLOYEES]);
  // const [menuOpen, setMenuOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [currentSchedules, setCurrentSchedules] = useState<ScheduleTime[]>([]);
  const [centerName, setCenterName] = useState('');
  const handleDate = useCallback((date: Date) => {
    setSelectedDay(date);
  }, []);

  // 센터 id 가져오기
  const userId = useSelector((state: RootState) => state[PROFILE].id);
  const centerInfo = useSelector(
    (state: RootState) => state[DEPARTMENT].centerInfo,
  );

  useEffect(() => {
    if (userId) {
      dispatch(loadDepartment(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(profileRequest());
  }, [dispatch]);

  useEffect(() => {
    if (centerInfo?.employeeId) {
      setCenterName(centerInfo.centerName.split('센터')[0]);
      dispatch(loadEmployeeSchedules());
    }
  }, [dispatch, centerInfo]);

  const handleSchedule = useCallback(
    (data: any) => {
      let tmp: any = [];
      const today = `${selectedDay.getFullYear()}-${(selectedDay.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${selectedDay
        .getDate()
        .toString()
        .padStart(2, '0')}`;
      if (data) {
        _.sortBy(data, 'startDate').forEach((list: any) => {
          if (list.patient) {
            if (today === list.startDate.substr(0, 10)) {
              let cname = '';
              let pname = '';
              if (centerInfo) {
                if (centerInfo.centerName.length > 8) {
                  cname = centerInfo.centerName.substr(0, 7) + '...';
                }
              }
              if (list.patient.name.length > 3) {
                pname = list.patient.name.substr(0, 3);
              } else {
                pname = list.patient.name;
              }

              const start = new Date(list.startDate);
              const end = new Date(list.endDate);
              tmp.push({
                id: list.id,
                centerName: cname,
                patientName: pname,
                startTime: `${start
                  .getHours()
                  .toString()
                  .padStart(2, '0')}:${start
                  .getMinutes()
                  .toString()
                  .padStart(2, '0')}`,
                endTime: `${end
                  .getHours()
                  .toString()
                  .padStart(2, '0')}:${end
                  .getMinutes()
                  .toString()
                  .padStart(2, '0')}`,
                patient: list.patient,
              });
            }
          } else {
            tmp = [];
          }
        });
      }
      setCurrentSchedules(tmp);
    },
    [selectedDay, centerInfo],
  );

  useEffect(() => {
    if (schedules) {
      handleSchedule(schedules);
    }
  }, [selectedDay, schedules, handleSchedule]);

  return (
    <div className={classes.root}>
      <div className={classes.topContent}>
        <div className={classes.todaySchedule}>
          <Typography variant={'h6'} style={{display: 'inline'}}>
            {'오늘의 스케줄'}
          </Typography>
          <Typography
            variant={'h5'}
            style={{
              display: 'inline',
              marginLeft: 5.49,
            }}>
            {`${currentSchedules.length}건`}
          </Typography>
          <Button
            onClick={() => {
              // center 정보 업데이트
              //dispatch(requsetCenter());
              history.push({
                pathname: '/inventory',
                state: {
                  year: selectedDay.getFullYear(),
                  month: selectedDay.getMonth() + 1,
                  date: selectedDay.getDate(),
                },
              });
            }}
            className={classes.Itembtn}
            fullWidth
            variant="contained"
            color="primary">
            물품신청
          </Button>
        </div>
      </div>
      <div className={classes.calendarDiv}>
        <StyledCalendar
          className={classes.calendar}
          onClickDay={handleDate}
          calendarType={'US'}
          value={selectedDay}
          next2Label={null}
          prev2Label={null}
        />
      </div>
      <div className={classes.schedules}>
        {currentSchedules.map((list, idx) => {
          return (
            <ScheduleBtn
              // 센터이름 디비 만들어서 이름 추가해야 함
              date={moment(selectedDay).format('YYYY-MM-DD')}
              key={idx}
              scheduleId={list.id}
              centerName={centerName}
              patient={list.patient}
              name={list.patientName}
              startTime={list.startTime}
              endTime={list.endTime}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MScheduleScreen;
