import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles, Theme, createStyles, Button} from '@material-ui/core';

import {Typography} from '../components/Typography';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import {useHistory, useLocation} from 'react-router';
import {RootState} from '~/app/rootReducer';
import {CENTER, requestFilteredCenter} from '~/features/Center/slice';
import _ from 'lodash';
import {PROFILE} from '~/features/Profile/slice';
import {DEPARTMENT, loadDepartment} from '~/features/Department/slice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    body: {
      display: 'flex',
      minWidth: 320,
      padding: '0px 15px',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flex: 1,
    },
    title: {
      display: 'flex',
      marginTop: theme.spacing(4) - 2,
      justifyContent: 'center',
      fontWeight: 700,
    },
    helperDiv: {
      display: 'flex',
      width: '100%',
      fontSize: '1em',
      lineHeight: '24px',
      color: '#E31B0C',
      letterSpacing: '0.15px',
      marginTop: theme.spacing(1) + 2,
    },
    buttons: {
      width: '100%',
      height: 42,
      display: 'flex',
    },
    cancel: {
      display: 'inline',
      width: '100%',
      fontSize: '0.9375em',
    },
    submit: {
      display: 'inline',
      width: '100%',
      marginLeft: theme.spacing(1) + 6,
      fontSize: '0.9375em',
    },

    formControl: {
      width: '100%',
      marginTop: theme.spacing(3) + 5,
    },
    selectMenu: {
      '& > .MuiSelect-root': {
        textAlign: 'center',
      },
    },
  }),
);

type props = {
  year: number;
  month: number;
  date: number;
};

const MInventoryScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [center, setCenter] = useState(0); // 선택한 센터 이름 저장
  const [select, setSelect] = useState(true); // 센터 선택 유무
  const [date, setDate] = useState(new Date());
  const week = ['일', '월', '화', '수', '목', '금', '토'];
  const centers = useSelector((state: RootState) => state[CENTER].center);
  const [centerNames, setCenterNames] = useState<string[]>([]);
  //const [openProfileMenu, setOpenProfileMenu] = useState(false);
  //const profileAnchorRef = useRef<HTMLDivElement>(null);
  const location = useLocation<props>().state;
  const [selectedDate, setSelectedDate] = useState('');
  // 센터 id 가져오기
  const userId = useSelector((state: RootState) => state[PROFILE].id);
  const centerId = useSelector(
    (state: RootState) => state[DEPARTMENT].centerInfo?.centerId,
  );

  useEffect(() => {
    if (location) {
      const selectedDay = `${location.year.toString()}-${location.month
        .toString()
        .padStart(2, '0')}-${location.date.toString()}`;
      setDate(new Date(selectedDay));
      setSelectedDate(selectedDay);
    }
  }, [location, setDate, setSelectedDate]);

  useEffect(() => {
    if (userId) {
      dispatch(loadDepartment(userId));
    }
  }, [dispatch, userId]);

  const handleChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setCenter(event.target.value as number);
  };

  useEffect(() => {
    if (centerId) {
      dispatch(requestFilteredCenter({centerId}));
    }
  }, [dispatch, centerId]);

  useEffect(() => {
    if (centers) {
      // option 페이지 css 수정 방법을 찾지 못해서 일단 글자수를 14글자까지만 보이도록 함
      setCenterNames(_.map(centers, (c: any) => c.name));
    }
  }, [centers]);

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <Typography className={classes.title} variant={'H4'}>
          {/* getMonth()함수를 그냥 쓰면 -1되서 +1을 해줘야 맞음 */}
          {`${(date.getMonth() + 1)
            .toString()
            .padStart(2, '0')}월 ${date
            .getDate()
            .toString()
            .padStart(2, '0')}일 (${week[date.getDay()]})`}
        </Typography>

        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            native
            className={classes.selectMenu}
            value={center}
            onChange={handleChange}
            inputProps={{
              name: 'age',
              id: 'outlined-age-native-simple',
            }}>
            <option aria-label="None" value={0}>
              센터선택
            </option>
            {centerNames.map((list, index: number) => (
              <option key={index} value={index + 1}>{`${list}`}</option>
            ))}
          </Select>
        </FormControl>

        <div className={classes.helperDiv}>
          {select ? null : '센터를 선택해주세요.'}
        </div>

        <div
          className={classes.buttons}
          style={{
            marginTop: select ? 346 : 322,
          }}>
          <Button
            onClick={() => {
              history.push('/');
            }}
            variant="contained"
            color="secondary"
            className={classes.cancel}>
            취소
          </Button>
          <Button
            onClick={() => {
              if (center !== 0) {
                setSelect(true);
                history.push({
                  pathname: '/item',
                  state: {
                    date: selectedDate,
                  },
                });
              } else {
                setSelect(false);
              }
            }}
            variant="contained"
            color="primary"
            className={classes.submit}>
            완료
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MInventoryScreen;
