import {
  IconButton,
  TableCell,
  TableRow,
  Theme,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import {useEffect, useState} from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import _ from 'lodash';

interface HistoryCount {
  id: string;
  name: string;
  total: number;
  count: number;
  avg: number;
}

export const RenderTable = (props: {
  data: HistoryCount[];
  historyId: string; //ReturnType<typeof string[]>;
  first: number;
  gradeName: string;
  jobName: string;
}) => {
  const theme = useTheme() as Theme;
  const {historyId, first, data, gradeName, jobName} = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    first === 0 ? setOpen(true) : setOpen(false);
  }, [first]);

  return (
    <React.Fragment
      key={historyId + (Math.random() * (100 - 0) + 1).toString()}>
      <TableRow>
        <TableCell colSpan={6} style={{background: '#7F91F7'}}>
          <IconButton
            style={{marginRight: theme.spacing(2)}}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {historyId}
        </TableCell>
      </TableRow>
      {_.map(data, (child, idx) => {
        return open ? (
          <TableRow key={idx}>
            <TableCell
              style={{
                paddingLeft: theme.spacing(7.75),
                borderBottom: 'none',
              }}>
              {child.name}
            </TableCell>
            <TableCell align="center">{jobName}</TableCell>
            <TableCell align="center">{gradeName}</TableCell>
            <TableCell align="center">
              {child.total === 0 ? '-' : child.total.toLocaleString()}
            </TableCell>
            <TableCell align="center">
              {child.count === 0 ? '-' : child.count.toLocaleString()}
            </TableCell>
            <TableCell align="center">
              {child.avg === 0 ? '-' : child.avg.toLocaleString()}
            </TableCell>
          </TableRow>
        ) : (
          <React.Fragment
            key={
              historyId + (Math.random() * (100 - 0) + 1).toString()
            }></React.Fragment>
        );
      })}
    </React.Fragment>
  );
};
