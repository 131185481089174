import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  useTheme,
  Theme,
} from '@material-ui/core';
import {STAT_SCALE} from '~/types/types';
import moment from 'moment';
import {loadAccStat, BEP} from '~/features/BEP/slice';
import {loadAccountType, ACCOUNT_TYPE} from '~/features/AccountType/slice';
import _ from 'lodash';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import {RootState} from '~/app/rootReducer';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar,
  Tooltip,
} from 'recharts';
import withStatTemplate, {StatProps} from '~/hoc/withStatTemplate';
import {renderDateTableHeader_2} from '~/utils';
import {Typography} from '~/components/Typography';
import {withStyles} from '@material-ui/styles';
import {PROFILE} from '~/features/Profile/slice';
import {DEPARTMENT, loadDepartment} from '~/features/Department/slice';
import {INVENTORY, loadMaterialCost} from '~/features/Inventory/slice';

const TableCell = withStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.info.dark}`,
    '&.MuiTableCell-head': {
      fontSize: '0.75em',
    },
    minWidth: 99.64,
    height: 28,
    //padding: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
}))(MuiTableCell);

const TableRow = withStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.info.dark}`,
  },
}))(MuiTableRow);

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
  },
  chartContainer: {
    width: '100%',
    flexGrow: 1,
    height: 436,
    margin: theme.spacing(4.375, 0, 2),
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    marginBlock: theme.spacing(2),
    flex: 1,
  },
  chartLegend: {
    '& > div > svg > path': {
      fill: 'none',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper': {
      top: '0px !important',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend > .recharts-legend-item': {
      display: 'flex !important',
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '0.75em',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend > li > svg': {
      width: 50,
      height: 11,
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend > li > svg > path': {
      d: 'path("M-50,0h146v32h-146z")',
    },
  },
  tooltip: {
    padding: theme.spacing(2.5, 1.875, 1.125),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    //alignItems: 'center',
    backgroundColor: '#FFFFFF',
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: theme.spacing(0.5),
  },
  tooltip__label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 23,
    marginBottom: theme.spacing(1.125),
    fontSize: '1em',
  },
  unit: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    fontSize: '0.625em',
    marginBottom: theme.spacing(1) - 1,
  },
  backColorBlue: {
    fontSize: '0.75em',
    backgroundColor: `${theme.palette.primary.light}14`,
  },
  backColorGray: {
    fontSize: '0.625em',
    backgroundColor: `${theme.palette.info.light}`,
  },
  table: {
    boxShadow: 'none',
    '& > table > thead > tr > th': {
      paddingTop: 0,
      paddingBottom: 0,
      fontWeight: 400,
    },
  },
  tableText: {
    fontSize: '0.625em',
  },
}));

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const OperationScreen = ({statScale, viewType, date}: StatProps) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const dispatch = useDispatch();

  const account = useSelector((state: RootState) => state[BEP].account);
  const accountTypes = useSelector(
    (state: RootState) => state[ACCOUNT_TYPE].accountTypes,
  );

  const [data, setData] = useState<
    ReadonlyArray<{[key: string]: string | number}>
  >();
  // 센터 id 가져오기
  const userId = useSelector((state: RootState) => state[PROFILE].id);
  const centerId = useSelector(
    (state: RootState) => state[DEPARTMENT].centerInfo?.centerId,
  );
  const material = useSelector(
    (state: RootState) => state[INVENTORY].materialCost,
  );

  const DataFormater = (number: number) => {
    return number.toLocaleString();
  };

  useEffect(() => {
    if (userId) {
      dispatch(loadDepartment(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (centerId) {
      const datetime = moment(date);
      const request = {
        scale: statScale,
        datetime: datetime,
        centerId: centerId,
      };
      dispatch(loadAccStat(request));
      dispatch(loadAccountType());
      dispatch(loadMaterialCost(request));
    }
  }, [dispatch, date, statScale, centerId]);

  useEffect(() => {
    if (statScale === STAT_SCALE.WEEK) {
      setData(
        _.keys(account).map((acc) => {
          return {
            name: `${parseInt(acc.slice(8, 10))} 일`,
            사무운영비: _.has(account[acc], '사무운영비')
              ? _.get(account[acc], '사무운영비')
              : 0,
            업무추진비: _.has(account[acc], '업무추진비')
              ? _.get(account[acc], '업무추진비')
              : 0,
            사업운영비: _.has(account[acc], '사업운영비')
              ? _.get(account[acc], '사업운영비')
              : 0,
            시설비: _.has(account[acc], '시설비')
              ? _.get(account[acc], '시설비')
              : 0,
            사업비: _.has(account[acc], '사업비')
              ? _.get(account[acc], '사업비')
              : 0,
            재료비: _.has(material[acc], '재료비')
              ? _.get(material[acc], '재료비')
              : 0,
          };
        }),
      );
    } else {
      setData(
        _.keys(account).map((acc, idx) => {
          return {
            name: `${months[idx]}`,
            사무운영비: _.has(account[acc], '사무운영비')
              ? _.get(account[acc], '사무운영비')
              : 0,
            업무추진비: _.has(account[acc], '업무추진비')
              ? _.get(account[acc], '업무추진비')
              : 0,
            사업운영비: _.has(account[acc], '사업운영비')
              ? _.get(account[acc], '사업운영비')
              : 0,
            시설비: _.has(account[acc], '시설비')
              ? _.get(account[acc], '시설비')
              : 0,
            사업비: _.has(account[acc], '사업비')
              ? _.get(account[acc], '사업비')
              : 0,
            재료비: _.has(material[acc], '재료비')
              ? _.get(material[acc], '재료비')
              : 0,
          };
        }),
      );
    }
  }, [account, material, accountTypes, statScale]);

  const renderTableHeader = useCallback(() => {
    return renderDateTableHeader_2(viewType, account, _.values(account).length);
    //return renderDateTableHeader('decade' as Detail, account);
  }, [viewType, account]);

  const CustomTooltip = ({payload, label}: any) => {
    if (payload && payload.length) {
      return (
        <div className={classes.tooltip}>
          {payload.map((item: any, idx: number) => {
            return (
              <div className={classes.tooltip__label} key={idx}>
                <svg
                  width="24"
                  height="17"
                  viewBox="0, 0, 32 32"
                  style={{
                    display: 'inline-block',
                    marginRight: theme.spacing(5) - 2,
                    verticalAlign: 'middle',
                    marginLeft: theme.spacing(0.5),
                  }}>
                  <path
                    stroke="none"
                    fill={item.fill}
                    d="M0, 4h 64v 24h-64z"></path>
                </svg>
                <p
                  style={{
                    width: 81,
                    display: 'flex',
                    justifyContent: 'left',
                    marginRight: theme.spacing(1) + 2,
                  }}>{`${item.name}`}</p>
                <p
                  style={{
                    width: 115,
                    display: 'flex',
                    justifyContent: 'end',
                  }}>{`${item.value.toLocaleString()}원`}</p>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className={classes.chartContainer}>
        <ResponsiveContainer className={classes.chartLegend}>
          <BarChart
            data={data}
            margin={{top: 20, right: 20, left: 40, bottom: 5}}>
            <CartesianGrid strokeDasharray="5 5" />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={DataFormater} />
            <Tooltip content={<CustomTooltip />} />
            <Legend verticalAlign="top" />
            <Bar dataKey="사무운영비" stackId="a" fill="#FF6384" />
            <Bar dataKey="업무추진비" stackId="a" fill="#36A2EB" />
            <Bar dataKey="사업운영비" stackId="a" fill="#4BC0C0" />
            <Bar dataKey="사업비" stackId="a" fill="#E5E5E5" />
            <Bar dataKey="시설비" stackId="a" fill="#FF9F40" />
            <Bar dataKey="재료비" stackId="a" fill="#a648ff" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className={classes.unit}>단위:원</div>
      <TableContainer className={classes.table}>
        <Table aria-label="simple table">
          <TableHead className={classes.backColorBlue}>
            <TableRow>
              <TableCell align="center">
                <Typography style={{fontSize: '0.75em'}} variant="Small">
                  구분
                </Typography>
              </TableCell>
              {renderTableHeader()}
              <TableCell align="center">
                <Typography style={{fontSize: '0.75em'}} variant="Small">
                  총합
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(accountTypes, (acc, idx) => {
              if (acc.name !== '인건비' && acc.name !== '기타') {
                return (
                  <TableRow key={idx}>
                    <TableCell align="center" className={classes.backColorGray}>
                      {acc.name}
                    </TableCell>
                    {acc.name !== '재료비'
                      ? _.values(account).map((d, idx) => (
                          <TableCell
                            key={idx}
                            style={{fontSize: '0.625em'}}
                            align="center">
                            {(_.has(d, acc.name)
                              ? _.get(d, acc.name)
                              : 0
                            ).toLocaleString()}
                          </TableCell>
                        ))
                      : _.values(material).map((d, idx) => (
                          <TableCell
                            key={idx}
                            className={classes.tableText}
                            align="center">
                            {(_.has(d, acc.name)
                              ? _.get(d, acc.name)
                              : 0
                            ).toLocaleString()}
                          </TableCell>
                        ))}
                    <TableCell className={classes.tableText} align="center">
                      {acc.name !== '재료비'
                        ? _.sum(
                            _.values(account).map((d) => {
                              return _.has(d, acc.name)
                                ? _.get(d, acc.name)
                                : 0;
                            }),
                          ).toLocaleString()
                        : _.sum(
                            _.values(material).map((d) => {
                              return _.has(d, acc.name)
                                ? _.get(d, acc.name)
                                : 0;
                            }),
                          ).toLocaleString()}
                    </TableCell>
                  </TableRow>
                );
              }
            })}
            <TableRow>
              <TableCell className={classes.backColorBlue} align="center">
                소계
              </TableCell>
              {_.zip(_.values(account), _.values(material)).map((d, idx) => (
                <TableCell
                  key={idx}
                  className={classes.backColorBlue}
                  style={{fontSize: '0.625em'}}
                  align="center">
                  {(
                    _.sum(_.values(d[0]).slice(1, 6)) + _.sum(_.values(d[1]))
                  ).toLocaleString()}
                </TableCell>
              ))}
              <TableCell className={classes.backColorBlue} align="center">
                {_.sum(
                  _.zip(_.values(account), _.values(material)).map(
                    (d) =>
                      _.sum(_.values(d[0]).slice(1, 6)) + _.sum(_.values(d[1])),
                  ),
                ).toLocaleString()}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withStatTemplate(OperationScreen, {title: '운영관리비'});
