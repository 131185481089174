import React, {useState, useEffect, useCallback} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import withStatTemplate, {StatProps} from '~/hoc/withStatTemplate';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducer';
import {
  HISTORY,
  loadHistoryFileDetail,
  requestHistoryFileStat,
} from '~/features/History/slice';
import moment from 'moment';
import {DEPARTMENT, loadDepartment} from '~/features/Department/slice';
import {PROFILE} from '~/features/Profile/slice';

const useStyles = makeStyles((theme) => ({
  backColorBlue: {
    fontSize: '0.86em',
    backgroundColor: `${theme.palette.primary.light}14`,
  },
  backColorGray: {
    fontSize: '0.86em',
    backgroundColor: `#F6F6F6`,
  },
  table: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4.75),
    boxShadow: 'none',
    maxHeight: 800,
    '& > table > thead > tr > th': {
      padding: theme.spacing(1, 3.75),
      fontWeight: 400,
      border: 'none',
    },
  },
}));

const HistoryFileScreen = ({
  statScale,
  serviceHistoryType,
  grade,
  date,
}: StatProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  //const theme = useTheme() as Theme;

  const [gradeName, setGradeName] = useState('1등급');

  const {historyFiles, historyFileStat} = useSelector(
    (state: RootState) => state[HISTORY],
  );
  const [nurseStat, setNurStat] = useState({
    minutes1: 0,
    minutes2: 0,
    count1: 0,
    count2: 0,
  });
  const [careStat, setCareStat] = useState({
    minutes1: 0,
    minutes2: 0,
    minutes3: 0,
    minutes4: 0,
    minutes5: 0,
    minutes6: 0,
    count1: 0,
    count2: 0,
    count3: 0,
    count4: 0,
    count5: 0,
    count6: 0,
  });
  // 센터 id 가져오기
  const userId = useSelector((state: RootState) => state[PROFILE].id);
  const centerId = useSelector(
    (state: RootState) => state[DEPARTMENT].centerInfo?.centerId,
  );

  useEffect(() => {
    if (userId) {
      dispatch(loadDepartment(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (grade === 1) {
      setGradeName('1등급');
    } else if (grade === 2) {
      setGradeName('2등급');
    } else if (grade === 3) {
      setGradeName('3등급');
    } else if (grade === 4) {
      setGradeName('4등급');
    } else if (grade === 5) {
      setGradeName('5등급');
    } else if (grade === 6) {
      setGradeName('인지지원등급');
    } else {
      setGradeName('1등급');
    }
  }, [dispatch, grade]);

  useEffect(() => {
    if (centerId) {
      const datetime = moment(date);
      const request = {
        scale: statScale,
        datetime: datetime,
        serviceHistoryType: serviceHistoryType,
        grade: grade,
        centerId: centerId,
      };
      const request2 = {
        id: serviceHistoryType,
      };
      dispatch(requestHistoryFileStat(request));
      dispatch(loadHistoryFileDetail(request2));
    }
  }, [dispatch, statScale, date, grade, serviceHistoryType, centerId]);

  useEffect(() => {
    if (serviceHistoryType === 0) {
      let minutes1 = 0;
      let minutes2 = 0;
      let count1 = 0;
      let count2 = 0;
      _.map(historyFileStat, (his) => {
        _.map(his, (h: any) => {
          minutes1 += h.minutes1;
          minutes2 += h.minutes2;
          count1 += h.minutes1 !== 0 ? 1 : 0;
          count2 += h.minutes2 !== 0 ? 1 : 0;
        });
      });
      setNurStat({
        minutes1,
        minutes2,
        count1,
        count2,
      });
    } else if (serviceHistoryType === 1) {
      let minutes1 = 0;
      let minutes2 = 0;
      let minutes3 = 0;
      let minutes4 = 0;
      let minutes5 = 0;
      let minutes6 = 0;
      let count1 = 0;
      let count2 = 0;
      let count3 = 0;
      let count4 = 0;
      let count5 = 0;
      let count6 = 0;
      _.map(historyFileStat, (his) => {
        _.map(his, (h: any) => {
          minutes1 += h.minutes1;
          minutes2 += h.minutes2;
          minutes3 += h.minutes3;
          minutes4 += h.minutes4;
          minutes5 += h.minutes5;
          minutes6 += h.minutes6;
          count1 += h.minutes1 !== 0 ? 1 : 0;
          count2 += h.minutes2 !== 0 ? 1 : 0;
          count3 += h.minutes3 !== 0 ? 1 : 0;
          count4 += h.minutes4 !== 0 ? 1 : 0;
          count5 += h.minutes5 !== 0 ? 1 : 0;
          count6 += h.minutes6 !== 0 ? 1 : 0;
        });
      });
      setCareStat({
        minutes1,
        minutes2,
        minutes3,
        minutes4,
        minutes5,
        minutes6,
        count1,
        count2,
        count3,
        count4,
        count5,
        count6,
      });
    } else {
    }
  }, [historyFileStat, serviceHistoryType]);

  const renderNurseTable = useCallback(() => {
    return _.map(historyFiles, (his, i) => (
      <React.Fragment key={i}>
        <TableRow key={i}>
          <TableCell align="center" rowSpan={12}>
            {his.name}
          </TableCell>
          {_.map(his.children.slice(0, 1), (h, idx) => (
            <TableCell key={idx} style={{fontSize: '0.75em'}} align="center">
              {h.name}
            </TableCell>
          ))}
          <TableCell style={{fontSize: '0.75em'}} align="center" rowSpan={12}>
            {gradeName}
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center" rowSpan={4}>
            {`${nurseStat.minutes1.toLocaleString()}분`}
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center" rowSpan={4}>
            {`${nurseStat.count1.toLocaleString()}회`}
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center" rowSpan={4}>
            {nurseStat.count1 !== 0
              ? `${(nurseStat.minutes1 / nurseStat.count1).toFixed(2)}분`
              : '-'}
          </TableCell>
        </TableRow>
        {_.map(his.children.slice(1, 4), (h, idx) => (
          <TableRow key={idx}>
            <TableCell style={{fontSize: '0.75em'}} align="center">
              {h.name}
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          {_.map(his.children.slice(4, 5), (h, idx) => (
            <TableCell key={idx} style={{fontSize: '0.75em'}} align="center">
              {h.name}
            </TableCell>
          ))}
          <TableCell style={{fontSize: '0.75em'}} align="center" rowSpan={8}>
            {`${nurseStat.minutes2.toLocaleString()}분`}
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center" rowSpan={8}>
            {`${nurseStat.count2.toLocaleString()}회`}
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center" rowSpan={8}>
            {nurseStat.count2 !== 0
              ? `${(nurseStat.minutes2 / nurseStat.count2).toFixed(2)}분`
              : '-'}
          </TableCell>
        </TableRow>
        {_.map(his.children.slice(5, his.children.length), (h, idx) => (
          <TableRow key={idx}>
            <TableCell style={{fontSize: '0.75em'}} align="center">
              {h.name}
            </TableCell>
          </TableRow>
        ))}
      </React.Fragment>
    ));
  }, [historyFiles, gradeName, nurseStat]);

  const renderCareTable = useCallback(() => {
    return (
      <>
        <TableRow>
          <TableCell align="center" rowSpan={12}>
            방문요양
          </TableCell>
          <TableCell rowSpan={6} style={{fontSize: '0.75em'}} align="center">
            신체활동 지원
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center" rowSpan={2}>
            개인위생
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center">
            개인위생(옷갈아입히기, 세면, 구강청결, 몸단장 등)
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center" rowSpan={12}>
            {gradeName}
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center" rowSpan={6}>
            {`${careStat.minutes1.toLocaleString()}분`}
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center" rowSpan={6}>
            {`${careStat.count1.toLocaleString()}회`}
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center" rowSpan={6}>
            {careStat.count1 !== 0
              ? `${(careStat.minutes1 / careStat.count1).toFixed(2)}분`
              : '-'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{fontSize: '0.75em'}} align="center">
            몸 씻기
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{fontSize: '0.75em'}} align="center">
            영양 관리
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center">
            식사도움(영양관리 등)
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{fontSize: '0.75em'}} align="center" rowSpan={2}>
            이동 도움
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center">
            체위 변경
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{fontSize: '0.75em'}} align="center">
            이동도움(보행도움, 보장구사용도움 등)
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{fontSize: '0.75em'}} align="center" colSpan={2}>
            화장실 이용하기
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{fontSize: '0.75em'}} align="center" rowSpan={2}>
            인지활동 지원
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center" colSpan={2}>
            인지자극 활동
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center">
            {`${careStat.minutes2.toLocaleString()}분`}
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center">
            {`${careStat.count2.toLocaleString()}회`}
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center">
            {careStat.count2 !== 0
              ? `${(careStat.minutes2 / careStat.count2).toFixed(2)}분`
              : '-'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{fontSize: '0.75em'}} align="center" colSpan={2}>
            일상생활 함께하기
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center">
            {`${careStat.minutes3.toLocaleString()}분`}
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center">
            {`${careStat.count3.toLocaleString()}회`}
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center">
            {careStat.count3 !== 0
              ? `${(careStat.minutes3 / careStat.count3).toFixed(2)}분`
              : '-'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{fontSize: '0.75em'}} align="center">
            인지관리 지원
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center" colSpan={2}>
            인지행동변화 관리 등
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center">
            {`${careStat.minutes4.toLocaleString()}분`}
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center">
            {`${careStat.count4.toLocaleString()}회`}
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center">
            {careStat.count4 !== 0
              ? `${(careStat.minutes4 / careStat.count4).toFixed(2)}분`
              : '-'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{fontSize: '0.75em'}} align="center">
            정서 지원
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center" colSpan={2}>
            의사소통 도움 등
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center">
            {`${careStat.minutes5.toLocaleString()}분`}
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center">
            {`${careStat.count5.toLocaleString()}회`}
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center">
            {careStat.count5 !== 0
              ? `${(careStat.minutes5 / careStat.count5).toFixed(2)}분`
              : '-'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{fontSize: '0.75em'}} align="center" rowSpan={2}>
            가사 및 일상생활 지원
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center" colSpan={2}>
            식사준비, 청소 및 주변정리 정돈, 세탁 등
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center" rowSpan={2}>
            {`${careStat.minutes6.toLocaleString()}분`}
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center" rowSpan={2}>
            {`${careStat.count6.toLocaleString()}회`}
          </TableCell>
          <TableCell style={{fontSize: '0.75em'}} align="center" rowSpan={2}>
            {careStat.count6 !== 0
              ? `${(careStat.minutes6 / careStat.count6).toFixed(2)}분`
              : '-'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{fontSize: '0.75em'}} align="center" colSpan={2}>
            개인활동지원 (외출시 동행 등)
          </TableCell>
        </TableRow>
      </>
    );
  }, [gradeName, careStat]);

  return (
    <>
      <TableContainer className={classes.table}>
        <Table>
          <TableHead className={classes.backColorGray}>
            <TableRow>
              <TableCell
                align="center"
                colSpan={serviceHistoryType === 0 ? 2 : 4}>
                구분
              </TableCell>
              <TableCell align="center">등급</TableCell>
              <TableCell align="center">총시간(분)</TableCell>
              <TableCell align="center">횟수(회)</TableCell>
              <TableCell align="center">평균시간(분)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {serviceHistoryType === 0 ? renderNurseTable() : renderCareTable()}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withStatTemplate(HistoryFileScreen, {
  title: '처치히스토리 (수급자용)',
  grade: true,
  serviceHistory: true,
});
