import {takeLatest, call, put, select} from 'redux-saga/effects';
import {
  INVENTORY,
  //requestDeleteItem,
  requestGetItems,
  requestInsertItem,
  requestMinimumCount,
  requestUpdate,
  //successDeleteItem,
  SuccessMinimumCount,
  successNewItem,
  successSaveItems,
  updateSuccess,
  requestInsertStoreItems,
  successSaveStoreItems,
  requestInsertReleaseItems,
  successSaveReleaseItems,
  loadStoredItems,
  successLoadStoredItems,
  loadStatStore,
  successLoadStateStore,
  loadCurrentStoredItems,
  successLoadCurrentStoredItems,
  requestCurrentItems,
  successLoadCurrentItems,
  loadStatRelease,
  successLoadStateRelease,
  loadMaterialCost,
  successLoadMaterialCost,
} from './slice';
import {
  updateInventory,
  getItems,
  updateMinimumCount,
  newInsertItem,
  //deleteItem,
  newStoreItem,
  newReleaseItem,
  getStoredItems,
  getStatStore,
  getCurrentStore,
  getCurrentItems,
  getStatRelease,
  getMaterialCost,
} from '~/api';
import {RootState} from '~/app/rootReducer';
import {DEPARTMENT} from '../Department/slice';

function* handleUpdateInventory() {
  try {
    const state = yield select((state) => state[INVENTORY]);
    yield call(updateInventory, state.request);
    yield put(updateSuccess());
  } catch (err) {
    console.log('error', err);
  }
}

function* handleLoadItems() {
  try {
    const state = yield select((state) => state[INVENTORY]);
    const resp = yield call(getItems, state.request);
    yield put(successSaveItems(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleUpdateMinimumCount() {
  try {
    const state = yield select((state: RootState) => state[INVENTORY]);
    const resp = yield call(updateMinimumCount, state.request);
    yield put(SuccessMinimumCount(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleNewItemInsert() {
  try {
    const state = yield select((state) => state[INVENTORY]);
    const resp = yield call(newInsertItem, state.request);
    yield put(successNewItem(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleInsertStore() {
  try {
    const state = yield select((state) => state[INVENTORY]);
    const resp = yield call(newStoreItem, state.request);
    yield put(successSaveStoreItems(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleInsertRelease() {
  try {
    const state = yield select((state) => state[INVENTORY]);
    const resp = yield call(newReleaseItem, state.request);
    yield put(successSaveReleaseItems(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

// function* handleDeleteItem() {
//   try {
//     const id = yield select((state: RootState) => state[INVENTORY]);
//     yield call(deleteItem, id);
//     yield put(successDeleteItem());
//   } catch (err) {
//     console.log('error', err);
//   }
// }

function* handleLoadStoredItems() {
  try {
    const centerInfo = yield select(
      (state: RootState) => state[DEPARTMENT].centerInfo,
    );
    const resp = yield call(getStoredItems, centerInfo.centerId);
    yield put(successLoadStoredItems(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleLoadStatStore() {
  try {
    const state = yield select((state) => state[INVENTORY]);
    const resp = yield call(getStatStore, state.request);
    yield put(successLoadStateStore(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleLoadStatRelease() {
  try {
    const state = yield select((state) => state[INVENTORY]);
    const resp = yield call(getStatRelease, state.request);
    yield put(successLoadStateRelease(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleLoadCurrentStatStore() {
  try {
    const state = yield select((state) => state[INVENTORY]);
    const resp = yield call(getCurrentStore, state.request);
    yield put(successLoadCurrentStoredItems(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleLoadCurrentItems() {
  try {
    const state = yield select((state) => state[INVENTORY]);
    const resp = yield call(getCurrentItems, state.request);
    yield put(successLoadCurrentItems(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleLoadMaterialCost() {
  try {
    const state = yield select((state) => state[INVENTORY]);
    const resp = yield call(getMaterialCost, state.request);
    yield put(successLoadMaterialCost(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

export default function* watchInventory() {
  yield takeLatest(requestUpdate, handleUpdateInventory);
  yield takeLatest(requestGetItems, handleLoadItems);
  yield takeLatest(requestMinimumCount, handleUpdateMinimumCount);
  yield takeLatest(requestInsertItem, handleNewItemInsert);
  //yield takeLatest(requestDeleteItem, handleDeleteItem);
  yield takeLatest(requestInsertStoreItems, handleInsertStore);
  yield takeLatest(requestInsertReleaseItems, handleInsertRelease);
  yield takeLatest(loadStoredItems, handleLoadStoredItems);
  yield takeLatest(loadStatStore, handleLoadStatStore);
  yield takeLatest(loadCurrentStoredItems, handleLoadCurrentStatStore);
  yield takeLatest(requestCurrentItems, handleLoadCurrentItems);
  yield takeLatest(loadStatRelease, handleLoadStatRelease);
  yield takeLatest(loadMaterialCost, handleLoadMaterialCost);
}
