import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  useTheme,
  Theme,
} from '@material-ui/core';

import {CurrentInventoryStat, STAT_SCALE} from '~/types/types';
import moment from 'moment';
import _ from 'lodash';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import {RootState} from '~/app/rootReducer';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar,
  Tooltip,
} from 'recharts';
import withStatTemplate, {StatProps} from '~/hoc/withStatTemplate';

import {Typography} from '~/components/Typography';
import {withStyles} from '@material-ui/styles';
import {
  INVENTORY,
  loadCurrentStoredItems,
  loadStatRelease,
} from '~/features/Inventory/slice';
import {PROFILE} from '~/features/Profile/slice';
import {DEPARTMENT, loadDepartment} from '~/features/Department/slice';
import {useCallback} from 'react';

const TableCell = withStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.info.dark}`,
    '&.MuiTableCell-head': {
      fontSize: '0.75em',
    },
    maxWidth: 55.64,
    height: 28,
    //padding: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
}))(MuiTableCell);

const TableRow = withStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.info.dark}`,
  },
}))(MuiTableRow);

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
  },
  chartContainer: {
    width: '100%',
    flexGrow: 1,
    height: 436,
    margin: theme.spacing(4.375, 0, 2),
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    marginBlock: theme.spacing(2),
    flex: 1,
  },
  chartLegend: {
    '& > div > svg > path': {
      fill: 'none',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper': {
      top: '0px !important',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend > .recharts-legend-item': {
      display: 'flex !important',
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '0.75em',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend > li > svg': {
      width: 50,
      height: 11,
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend > li > svg > path': {
      d: 'path("M-50,0h146v32h-146z")',
    },
  },
  tooltip: {
    padding: theme.spacing(2.5, 1.875, 1.125),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    //alignItems: 'center',
    backgroundColor: '#FFFFFF',
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: theme.spacing(0.5),
  },
  tooltip__label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 23,
    marginBottom: theme.spacing(1.125),
    fontSize: '1em',
  },
  unit: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    fontSize: '0.625em',
    marginBottom: theme.spacing(1) - 1,
  },
  backColorBlue: {
    fontSize: '0.625em',
    backgroundColor: `#F6F6F6`,
  },
  backColorGray: {
    fontSize: '0.75em',
    backgroundColor: `#E8EAF6`,
  },
  table: {
    boxShadow: 'none',
    '& > table > thead > tr > th': {
      paddingTop: 0,
      paddingBottom: 0,
      fontWeight: 400,
    },
  },
  tableText: {
    fontSize: '0.625em',
  },
}));

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const MaterialCostScreen = ({statScale, date}: StatProps) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const dispatch = useDispatch();
  const [data, setData] = useState<
    ReadonlyArray<{
      [key: string]: string | number;
    }>
  >();
  const [expendableCnt, setExpendableCnt] = useState(0); // 소모품
  const [expendableCntB, setExpendableCntB] = useState(0); // 비품
  const [currentStat, setCurrentStat] = useState<CurrentInventoryStat[]>([]); // 소모품
  const [currentStatB, setCurrentStatB] = useState<CurrentInventoryStat[]>([]); // 비품
  const {currentInventroy, invenStatRelease} = useSelector(
    (state: RootState) => state[INVENTORY],
  );
  // 센터 id 가져오기
  const userId = useSelector((state: RootState) => state[PROFILE].id);
  const centerId = useSelector(
    (state: RootState) => state[DEPARTMENT].centerInfo?.centerId,
  );
  const TableHeader = [
    '입고수량',
    '입고금액',
    '사용수량',
    '사용금액',
    '현재재고',
  ];

  const DataFormater = (number: number) => {
    return number.toLocaleString();
  };

  useEffect(() => {
    if (userId) {
      dispatch(loadDepartment(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (centerId) {
      const request = {
        scale: statScale,
        datetime: moment(date),
        centerId: centerId,
      };
      //dispatch(requestGetItems(request));
      dispatch(loadCurrentStoredItems(request));
      dispatch(loadStatRelease(request));
    }
  }, [dispatch, centerId, date, statScale]);

  // 테이블에 들어갈 값
  useEffect(() => {
    // 소모품
    const somopum = _.filter(currentInventroy, (i) => i.type === '소모품');
    // 비품
    const bipum = _.filter(currentInventroy, (i) => i.type === '비품');
    setCurrentStat(somopum);
    setExpendableCnt(somopum.length);
    setCurrentStatB(bipum);
    setExpendableCntB(bipum.length);
  }, [currentInventroy]);

  // 그래프
  useEffect(() => {
    if (invenStatRelease) {
      if (statScale === STAT_SCALE.WEEK) {
        setData(
          _.map(invenStatRelease, (i, idx) => {
            const datetime = moment(idx);
            return {
              name: `${datetime.date()}일`,
              비품: _.has(i, '비품') ? _.get(i, '비품') : 0,
              소모품: _.has(i, '소모품') ? _.get(i, '소모품') : 0,
            };
          }),
        );
      } else {
        let idx = -1;
        setData(
          _.map(invenStatRelease, (i) => {
            idx += 1;
            return {
              name: `${months[idx]}`,
              비품: _.has(i, '비품') ? _.get(i, '비품') : 0,
              소모품: _.has(i, '소모품') ? _.get(i, '소모품') : 0,
            };
          }),
        );
      }
    }
  }, [date, statScale, invenStatRelease]);

  const CustomTooltip = ({payload, label}: any) => {
    if (payload && payload.length) {
      return (
        <div className={classes.tooltip}>
          {payload.map((item: any, idx: number) => {
            return (
              <div key={idx} className={classes.tooltip__label}>
                <svg
                  width="24"
                  height="17"
                  viewBox="0, 0, 32 32"
                  style={{
                    display: 'inline-block',
                    marginRight: theme.spacing(5) - 2,
                    verticalAlign: 'middle',
                    marginLeft: theme.spacing(0.5),
                  }}>
                  <path
                    stroke="none"
                    fill={item.fill}
                    d="M0, 4h 64v 24h-64z"></path>
                </svg>
                <p
                  style={{
                    width: 81,
                    display: 'flex',
                    justifyContent: 'left',
                    marginRight: theme.spacing(1) + 2,
                  }}>{`${item.name}`}</p>
                <p
                  style={{
                    width: 115,
                    display: 'flex',
                    justifyContent: 'end',
                  }}>{`${item.value.toLocaleString()}원`}</p>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  const renderSomopum = useCallback(() => {
    return (
      <>
        {currentStat[0] ? (
          <TableRow>
            <TableCell
              rowSpan={expendableCnt}
              align="center"
              className={classes.backColorGray}>
              소모품
            </TableCell>
            <TableCell className={classes.backColorBlue} align="center">
              {`${currentStat[0].name ? currentStat[0].name : '-'}`}
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              {`${currentStat[0].storeCount}개`}
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              {`${currentStat[0].storePrice}원`}
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              {`${currentStat[0].useCount.toLocaleString()}개`}
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              {`${currentStat[0].usePrice.toLocaleString()}원`}
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              {`${currentStat[0].currentCount.toLocaleString()}개`}
            </TableCell>
          </TableRow>
        ) : (
          <TableRow>
            <TableCell
              rowSpan={expendableCnt}
              align="center"
              colSpan={2}
              className={classes.backColorGray}>
              소모품
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              -
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              -
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              -
            </TableCell>
          </TableRow>
        )}
        {_.map(currentStat.slice(1, currentStat.length), (c, idx) => (
          <TableRow key={idx}>
            <TableCell className={classes.backColorBlue} align="center">
              {c.name}
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              {`${c.storeCount.toLocaleString()}개`}
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              {`${c.storePrice.toLocaleString()}원`}
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              {`${c.useCount.toLocaleString()}개`}
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              {`${c.usePrice.toLocaleString()}원`}
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              {`${c.currentCount.toLocaleString()}개`}
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  }, [
    currentStat,
    classes.backColorGray,
    classes.backColorBlue,
    expendableCnt,
  ]);

  const renderBipum = useCallback(() => {
    return (
      <>
        {currentStatB[0] ? (
          <TableRow>
            <TableCell
              rowSpan={expendableCntB}
              align="center"
              className={classes.backColorGray}>
              비품
            </TableCell>
            <TableCell className={classes.backColorBlue} align="center">
              {`${currentStatB[0].name ? currentStatB[0].name : '-'}`}
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              {`${currentStatB[0].storeCount}개`}
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              {`${currentStatB[0].storePrice}원`}
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              {`${currentStatB[0].useCount.toLocaleString()}개`}
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              {`${currentStatB[0].usePrice.toLocaleString()}원`}
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              {`${currentStatB[0].currentCount.toLocaleString()}개`}
            </TableCell>
          </TableRow>
        ) : (
          <TableRow>
            <TableCell
              rowSpan={expendableCntB}
              align="center"
              colSpan={2}
              className={classes.backColorGray}>
              비품
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              -
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              -
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              -
            </TableCell>
          </TableRow>
        )}
        {_.map(currentStatB.slice(1, currentStatB.length), (c, idx) => (
          <TableRow key={idx}>
            <TableCell className={classes.backColorBlue} align="center">
              {c.name}
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              {`${c.storeCount.toLocaleString()}개`}
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              {`${c.storePrice.toLocaleString()}원`}
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              {`${c.useCount.toLocaleString()}개`}
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              {`${c.usePrice.toLocaleString()}원`}
            </TableCell>
            <TableCell
              style={{
                fontSize: '0.625em',
              }}
              align="center">
              {`${c.currentCount.toLocaleString()}개`}
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  }, [
    currentStatB,
    classes.backColorGray,
    classes.backColorBlue,
    expendableCntB,
  ]);

  return (
    <>
      <div className={classes.chartContainer}>
        <ResponsiveContainer className={classes.chartLegend}>
          <BarChart
            data={data}
            margin={{
              top: 20,
              right: 20,
              left: 40,
              bottom: 5,
            }}>
            <CartesianGrid strokeDasharray="5 5" />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={DataFormater} />
            <Tooltip content={<CustomTooltip />} />
            <Legend verticalAlign="top" />
            <Bar dataKey="비품" stackId="a" fill="#FF6384" />
            <Bar dataKey="소모품" stackId="a" fill="#36A2EB" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <TableContainer className={classes.table}>
        <Table aria-label="simple table">
          <TableHead className={classes.backColorGray}>
            <TableRow>
              <TableCell align="center" colSpan={2}>
                <Typography
                  style={{
                    fontSize: '0.75em',
                  }}
                  variant="Small">
                  구분
                </Typography>
              </TableCell>
              {_.map(TableHeader, (t, idx) => (
                <TableCell key={idx} align="center">
                  <Typography
                    style={{
                      fontSize: '0.625em',
                    }}
                    variant="Small">
                    {t}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {renderBipum()}
            {renderSomopum()}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withStatTemplate(MaterialCostScreen, {title: '재료비 현황'});
