import {takeLatest, call, put, select} from 'redux-saga/effects';
import {
  loadAccounts,
  saveAccount,
  successAccounts,
  removeAccount,
  successRemove,
  ACCOUNTS,
} from './slice';
import * as api from '~/api';

function* handleLoad() {
  try {
    const resp = yield call(api.getAccounts);
    yield put(successAccounts(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleSave({type, payload}: ReturnType<typeof saveAccount>) {
  try {
    yield call(api.saveAccount, payload);
    yield put(loadAccounts());
  } catch (err) {
    console.log('error', err);
  }
}

function* handleRemove() {
  try {
    const state = yield select((state) => state[ACCOUNTS]);
    yield call(api.removeAccount, state.request);
    yield put(successRemove());
  } catch (err) {
    console.log('error', err);
  }
}

export function* watchAccounts() {
  yield takeLatest(loadAccounts, handleLoad);
  yield takeLatest(saveAccount, handleSave);
  yield takeLatest(removeAccount, handleRemove);
}
