import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {makeStyles, Theme, createStyles} from '@material-ui/core';
import MProfileForm from '~/forms/MProfileForm';
import {SimpleHeader} from '~/components/SimpleHeader';
import {RootState} from '~/app/rootReducer';
import {genSalt, hash} from 'bcryptjs';
import {PROFILE, updateRequest} from '~/features/Profile/slice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      flexGrow: 1,
    },
    body: {
      justifyContent: 'flex-start',
      flex: 1,
    },
    footer: {
      display: 'flex',
      backgroundColor: theme.palette.primary.main,
      height: 64,
      color: theme.palette.primary.contrastText,
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
  }),
);

const MProfileScreen = () => {
  const dispatch = useDispatch();
  const {token, email, caregiver, phone} = useSelector(
    (state: RootState) => state[PROFILE],
  );
  const classes = useStyles();
  const submit = (values: any) => {
    //(values.password, values.phone);
    handleChangeProf(values.password, values.phone);
  };

  const handleChangeProf = async (passoword: any, new_phone: any) => {
    if (passoword) {
      const new_password = await hash(passoword, await genSalt());
      dispatch(updateRequest({token, new_password, new_phone}));
    } else {
      dispatch(updateRequest({token, new_password: undefined, new_phone}));
    }
  };

  return (
    <div className={classes.root}>
      {/* 헤더 부분을 수정해야 하는데 나중에 처리할 예정 */}
      <SimpleHeader mode="profile" />
      <div className={classes.body}>
        <MProfileForm
          initialValues={{
            email: email,
            caregiver: caregiver,
            password: undefined,
            phone: phone,
          }}
          onSubmit={submit}
        />
      </div>
    </div>
  );
};

export default MProfileScreen;
