import React, {useEffect, useMemo, useRef, useState} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';

import {
  AppBar,
  ClickAwayListener,
  Grow,
  Hidden,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  useMediaQuery,
} from '@material-ui/core';
import {useTheme} from '@material-ui/styles';
import MenuIcon from '@material-ui/icons/Menu';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {Typography} from './Typography';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {Breakpoint} from '~/contants/Breakpoints';
import {drawerWidth} from '~/contants/DrawerWidth';
import clsx from 'clsx';
import {useDispatch, useSelector} from 'react-redux';
import {LOGIN, logOut} from '~/features/Login/slice';
import {RootState} from '~/app/rootReducer';
import {profileRequest, initProfile} from '~/features/Profile/slice';
import {categories} from '~/contants/Sitemap';

const useStyles = makeStyles((theme: Theme) => ({
  appbar: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: theme.spacing(7.5),
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.up(Breakpoint.mobile)]: {
      minHeight: theme.spacing(10),
    },
  },
  openMenuAppbar: {
    zIndex: theme.zIndex.drawer,
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    position: 'absolute',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: theme.spacing(8) - 4,
    padding: theme.spacing(0, 1.75, 0, 2.25),
    marginLeft: (props: MainHeaderProps) => (props.open ? 48 : 0),
    [theme.breakpoints.up(Breakpoint.mobile)]: {
      padding: theme.spacing(0, 2.875, 0, 2.625),
    },
  },
  leftItem: {
    display: 'flex',
    alignItems: 'center',
  },
  logoWrap: {
    textDecoration: 'none',
    [theme.breakpoints.up(Breakpoint.mobile)]: {
      marginTop: theme.spacing(1.25),
    },
  },
  logo: {
    marginRight: theme.spacing(8.125),
  },
  marginR13: {
    marginRight: theme.spacing(2) - 3,
  },

  menuDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  profileName: {
    marginRight: theme.spacing(1.125),
    [theme.breakpoints.up(Breakpoint.mobile)]: {
      marginRight: theme.spacing(1.375),
    },
  },
  menuWrap: {
    borderRadius: '0px 0px 15px 15px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  profileMenuList: {
    minWidth: 120,
    paddingTop: 0,
    paddingBottom: theme.spacing(2),
  },
  profileMenuItem: {
    display: 'flex',
    minHeight: 30,
    justifyContent: 'center',
    padding: theme.spacing(0.625, 2),
    '&:hover': {
      backgroundColor: theme.palette.info.main,
    },
  },
  [theme.breakpoints.up(Breakpoint.mobile)]: {
    marginRight: theme.spacing(1.375),
    padding: theme.spacing(0.75, 2),
  },
}));

export interface MainHeaderProps {
  open: boolean;
  title: string;
  onClickOpen: () => void;
}

export const MainHeader = React.forwardRef((props: MainHeaderProps, ref) => {
  const classes = useStyles(props);
  const theme = useTheme() as Theme;
  const {open, onClickOpen} = props;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const mobile = useMediaQuery(theme.breakpoints.down(Breakpoint.mobile));
  const {payload, token} = useSelector((state: RootState) => state[LOGIN]);
  const [username, setUsername] = useState('');
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const profileAnchorRef = useRef<HTMLDivElement>(null);
  const title = useMemo(() => {
    for (let c of categories) {
      for (let ch of c.children) {
        if (ch.linkto === location.pathname) {
          return ch.id;
        }
      }
    }
    return undefined;
  }, [location.pathname]);

  useEffect(() => {
    if (payload) {
      setUsername(payload.name);
    }
  }, [payload]);

  useEffect(() => {
    if (token) {
      profileRequest();
    }
  }, [token]);

  return (
    <AppBar
      className={clsx(classes.appbar, {
        [classes.openMenuAppbar]: open,
      })}
      position="fixed">
      <Toolbar className={classes.toolbar}>
        <div className={classes.leftItem}>
          <Hidden smDown>
            <IconButton
              size="medium"
              edge="start"
              color="inherit"
              className={classes.marginR13}
              onClick={onClickOpen}
              style={{display: open ? 'none' : ''}}>
              <MenuIcon />
            </IconButton>
          </Hidden>

          <Link to="/" className={classes.logoWrap}>
            <Typography
              variant={mobile ? 'H3' : 'H2'}
              htmlColor={theme.palette.primary.contrastText}
              className={classes.logo}>
              3S-BIS
            </Typography>
          </Link>
          {/* 일단 임의로 텍스트 넣어둠 */}
          <Hidden smDown>
            <Typography
              variant="Small"
              htmlColor={theme.palette.primary.contrastText}>
              센터운영정보
            </Typography>
            <KeyboardArrowRightIcon />
            <Typography
              style={{fontWeight: 'bold'}}
              variant="Small"
              htmlColor={theme.palette.primary.contrastText}>
              {title}
            </Typography>
          </Hidden>
        </div>

        <div
          ref={profileAnchorRef}
          className={classes.menuDiv}
          onClick={() => {
            setOpenProfileMenu(true);
          }}>
          <Typography
            variant={mobile ? 'Small' : 'H5'}
            className={classes.profileName}>
            {`${username}`}
          </Typography>
          <KeyboardArrowDownIcon />
        </div>

        <Popper
          open={openProfileMenu}
          anchorEl={profileAnchorRef.current}
          role={undefined}
          style={{zIndex: 101}}
          transition
          popperOptions={{
            modifiers: {
              offset: {
                enabled: true,
                offset: mobile ? '-23px, 18px' : '-17px, 26px',
              },
              flip: {
                enabled: false,
              },
            },
          }}>
          {({TransitionProps}) => (
            <Grow {...TransitionProps}>
              <Paper className={classes.menuWrap}>
                <ClickAwayListener
                  onClickAway={() => setOpenProfileMenu(false)}>
                  <MenuList
                    id="menu-list-grow"
                    className={classes.profileMenuList}>
                    <MenuItem
                      className={classes.profileMenuItem}
                      onClick={() => {
                        setOpenProfileMenu(false);
                        history.push('/profile');
                      }}
                      disableRipple>
                      <Typography variant="Caption">내 정보</Typography>
                    </MenuItem>
                    {/* 로그아웃기능 붙일예정 */}
                    <MenuItem
                      className={classes.profileMenuItem}
                      onClick={() => {
                        dispatch(initProfile());
                        history.push('/');
                        dispatch(logOut());
                      }}
                      disableRipple>
                      <Typography variant="Caption">로그아웃</Typography>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Toolbar>
    </AppBar>
  );
});
