export const Fonts = {
  H1: {
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: '3.5625em',
    lineHeight: '82.54px',
    letterSpacing: undefined,
  },
  H2: {
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: '2.8125em',
    lineHeight: '65.16px',
    letterSpacing: undefined,
  },
  H3: {
    fontWeight: 900,
    fontStyle: 'normal',
    fontSize: '2.1875em',
    lineHeight: '50.68px',
    letterSpacing: undefined,
  },
  H4: {
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: '1.5em',
    lineHeight: '34.75px',
    letterSpacing: undefined,
  },
  H5: {
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '1.25em',
    lineHeight: '28.96px',
    letterSpacing: undefined,
  },
  Body: {
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: '1.125em',
    lineHeight: '25.97px',
    letterSpacing: undefined,
  },
  Small: {
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '1em',
    lineHeight: '23.17px',
    letterSpacing: undefined,
  },
  Caption: {
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '0.875em',
    lineHeight: '20.27px',
    letterSpacing: undefined,
  },
  CaptionSmall: {
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '0.75em',
    lineHeight: '17.38px',
    letterSpacing: undefined,
  },
};
