import React, {CSSProperties, MouseEventHandler} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Divider} from '@material-ui/core';
import {Typography} from './Typography';
import {useHistory} from 'react-router';
import Patient from '~/models/patient';
import {useDispatch} from 'react-redux';
import {saveCurrentSchedules} from '~/features/Employee/slice';
import {requestSaveCurrentId} from '~/features/Schedule/slice';

interface IScheduleBtnProps {
  scheduleId: number;
  centerName: string;
  name: string;
  startTime: string;
  date: string;
  endTime?: string;
  className?: string;
  patient?: Patient;
  style?: CSSProperties;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 36,
    padding: theme.spacing(1.125, 0.875),
    boxShadow: 'none',
  },
  text: {
    whiteSpace: 'nowrap',
    padding: theme.spacing(0, 1.25),
    // '&:first-child': {
    //   textAlign: 'right',
    //   minWidth: 130,
    // },
  },
  scheduleBtn: {
    width: '100%',
    height: 42,
    marginBottom: 10,
    fontSize: '0.88em',
    fontWeight: 400,
  },
  scheduleMargin: {
    margin: '0px 10px',
  },
  divider: {
    height: 14,
    backgroundColor: `${theme.palette.info.contrastText}4d`,
  },
}));

export const ScheduleBtn = (props: IScheduleBtnProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const history = useHistory();
  const {
    scheduleId,
    centerName,
    name,
    startTime,
    endTime,
    patient,
    date,
  } = props;

  return (
    <Button
      onClick={() => {
        if (patient) {
          dispatch(saveCurrentSchedules(patient));
          dispatch(requestSaveCurrentId(scheduleId));
          history.push({
            pathname: '/history',
            state: {patient: patient, date: date},
          });
        }
      }}
      variant="contained"
      color="primary"
      fullWidth
      className={classes.scheduleBtn}>
      <Typography
        variant="Small"
        style={{fontSize: '0.7em'}}
        className={classes.text}>{`${centerName}센터`}</Typography>
      <Divider orientation="vertical" className={classes.divider} />
      <Typography
        variant="Small"
        style={{fontSize: '0.7em'}}
        className={classes.text}>
        {name}
      </Typography>
      <Divider orientation="vertical" className={classes.divider} />
      <Typography
        variant="Small"
        style={{fontSize: '0.7em'}}
        className={classes.text}>{`${startTime} ~ ${endTime}`}</Typography>
    </Button>
  );
};
