import React from 'react';
import {WrappedFieldProps} from 'redux-form';
import {InputHTMLAttributes} from 'react';
import {
  TextField,
  Select,
  MenuItem,
  StandardTextFieldProps,
  withStyles,
  Theme,
} from '@material-ui/core';
import _ from 'lodash';
import DatePicker from 'react-date-picker';
import {Detail} from 'react-calendar';
import MuiTableCell from '@material-ui/core/TableCell';
import {
  months,
  StatResult,
  STAT_SCALE,
  TotalStat,
  TotalType,
} from '~/types/types';
import moment from 'moment';

const TableCell = withStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.info.dark}`,
  },
}))(MuiTableCell);

const renderTextField = (field: WrappedFieldProps & StandardTextFieldProps) => {
  const {input, type, autoFocus, ...restField} = field;
  return (
    <TextField
      fullWidth
      autoFocus={autoFocus}
      type={type}
      {...input}
      {...restField}
    />
  );
};

const renderSelect = (
  field: WrappedFieldProps &
    InputHTMLAttributes<HTMLInputElement> & {
      items: {id: number; name: string}[];
    },
) => {
  const {input, type, autoFocus, items} = field;
  return (
    <Select
      required
      fullWidth
      autoFocus={autoFocus}
      label={input.name}
      type={type}
      {...input}>
      {_.map(items, (act) => (
        <MenuItem key={`account-${act.id}`} value={act.id}>
          {act.name}
        </MenuItem>
      ))}
    </Select>
  );
};
const renderDatePicker = (
  field: WrappedFieldProps &
    InputHTMLAttributes<HTMLInputElement> & {
      view: Detail;
    },
) => {
  const {input, view} = field;
  return (
    <DatePicker
      name={input.name}
      value={input.value}
      onClickMonth={input.onChange}
      onClickDay={input.onChange}
      view={view}
    />
  );
};

const renderDateTableHeader = (
  viewType: Detail,
  stat: {[key: string]: any},
) => {
  if (viewType === 'year') {
    return _.range(0, 12).map((m) => (
      <TableCell style={{fontSize: '0.75em'}} align="center">
        {m + 1}월
      </TableCell>
    ));
  } else if (viewType === 'decade') {
    //return _.keys(stat).map((m) => <TableCell align="center">{m}년</TableCell>);
    return _.range(0, 12).map((m) => (
      <TableCell style={{fontSize: '0.75em'}} align="center">
        {m + 1}월
      </TableCell>
    ));
  }
};

const renderDateTableHeader_2 = (
  viewType: Detail,
  stat: {[key: string]: any},
  length: number,
) => {
  if (viewType === 'year') {
    return _.range(0, length).map((m, idx) => (
      <TableCell key={idx} style={{fontSize: '0.75em'}} align="center">
        {m + 1}일
      </TableCell>
    ));
  } else if (viewType === 'decade') {
    return _.range(0, 12).map((m, idx) => (
      <TableCell key={idx} style={{fontSize: '0.75em'}} align="center">
        {m + 1}월
      </TableCell>
    ));
  }
};

// 날짜 별 서비스별 price
const handleParseEachGradeAndService = (
  data: TotalStat[],
  dateRange: string[],
) => {
  const raw = _.map(dateRange, (day) => {
    let ret = {};
    const nurse = _.sum(
      _.map(
        _.filter(data, (s) => s.date === day && s.service === '방문간호'),
        (f) => f.price,
      ),
    );
    const care = _.sum(
      _.map(
        _.filter(data, (s) => s.date === day && s.service === '방문요양'),
        (f) => f.price,
      ),
    );
    // const bath = _.sum(
    //   _.map(
    //     _.filter(data, (s) => s.date === day && s.service === '방문목욕'),
    //     (f) => f.price,
    //   ),
    // );

    ret = {
      nurse,
      care,
      //bath,
    };

    return {[day]: ret};
  });

  const result: StatResult = _.chain(raw)
    .reduce((acc, r) => ({...acc, ...r}), {})
    .value();

  return result;
};

const handleTotalGraph = (
  date: Date,
  statScale: STAT_SCALE,
  dData: TotalStat[],
  grade: number,
) => {
  // 해당 등급 데이터 추출
  const dutyD = _.filter(dData, (d) => d.grade === grade);

  if (statScale === STAT_SCALE.WEEK) {
    const duration = moment(date).endOf('M').daysInMonth();
    const startDate = moment(date).format('YYYY-MM-01');
    const dateRange = _.range(0, duration).map((m) =>
      moment(moment.tz(startDate, 'Asia/Seoul'))
        .add(m, 'd')
        .format('YYYY-MM-DD'),
    );

    const result = handleParseEachGradeAndService(dutyD, dateRange);

    const ret: TotalType[] = _.keys(result).map((d) => {
      return {
        name: `${parseInt(d.slice(8, 10))} 일`,
        방문간호실적: result[d].nurse,
        방문요양실적: result[d].care,
        //방문목욕실적: result[d].bath,
      };
    });

    return ret;
  } else {
    const duration = 12;
    const startDate = moment(date).format('YYYY-01');
    const dateRange = _.range(0, duration).map((m) =>
      moment(moment.tz(startDate, 'Asia/Seoul'))
        .add(m, 'M')
        .format('YYYY-MM-01'),
    );

    const result = handleParseEachGradeAndService(dutyD, dateRange);

    const ret: TotalType[] = _.keys(result).map((d, idx) => {
      const tmpDate = `${d.slice(0, 4)}-${d.slice(5, 7)}-01`;
      return {
        name: months[idx],
        방문간호실적: result[tmpDate].nurse,
        방문요양실적: result[tmpDate].care,
        //방문목욕실적: result[tmpDate].bath,
      };
    });

    return ret;
  }
};

export {
  renderTextField,
  renderDatePicker,
  renderSelect,
  renderDateTableHeader,
  renderDateTableHeader_2,
  handleTotalGraph,
};
