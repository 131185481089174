import React from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';

import MLoginScreen from '~/screens/MLoginScreen';
import MPasswordResetFinishScreen from '~/screens/MPasswordResetFinishScreen';
import MResetPasswordScreen from '~/screens/MResetPasswordScreen';
import MSignUpScreen from '~/screens/MSignUpScreen';

const MLoginRouter = () => {
  return (
    <Switch>
      <Route path={`/signup`} component={MSignUpScreen} />
      <Route path={`/restore`} component={MResetPasswordScreen} />
      <Route path={`/reset-password`} component={MPasswordResetFinishScreen} />
      <Route path={`/`} component={MLoginScreen} />
    </Switch>
  );
};

export default withRouter(MLoginRouter);
