import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Theme,
  useTheme,
} from '@material-ui/core';
import {useHistory, useLocation} from 'react-router-dom';
import _ from 'lodash';
import {Typography} from './Typography';
import {drawerWidth} from '~/contants/DrawerWidth';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';
import {Breakpoint} from '~/contants/Breakpoints';
import {categories} from '~/contants/Sitemap';

interface IWebSideBarProps {
  open: boolean;
  onClickClose: () => void;
  onChangeTitle: (title: string) => void;
}

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    width: drawerWidth,
    overflow: 'visible',
    borderRight: `1px solid ${theme.palette.info.dark}`,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    minHeight: theme.spacing(10),
  },
  listItemTitle: {
    padding: theme.spacing(1.125, 7.5),
    marginTop: 15,
    '&:first-child': {
      marginTop: 0,
    },
  },
  listItem: {
    padding: theme.spacing(0.375, 7.5),
  },
  divider: {
    backgroundColor: theme.palette.info.dark,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    paddingBottom: theme.spacing(6.25),
    '& > ul': {
      overflow: 'auto',
    },
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up(Breakpoint.mobile)]: {
      width: theme.spacing(8),
    },
  },
}));
export const WebSideBar = ({
  open,
  onClickClose,
  onChangeTitle,
}: IWebSideBarProps) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme() as Theme;

  const [activates, setActivates] = useState<{[key: string]: boolean}>({});

  useEffect(() => {
    const cat = _.chain(categories)
      .reduce((r, c) => {
        const sub = _.reduce(
          c.children,
          (ret, ch) => {
            return {...ret, [ch.id]: ch.linkto === location.pathname};
          },
          {},
        );
        return {...r, ...sub};
      }, {})
      .value();
    setActivates(cat);
  }, [location]);

  const handleListItemClick = (childId: string, linkto: string) => {
    onChangeTitle(childId);
    history.push(linkto);
    setActivates(_.mapValues(activates, (_val, key) => key === childId));
  };

  return (
    <Drawer
      open={open}
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}>
      <div className={classes.toolbar}>
        <IconButton onClick={onClickClose}>
          <ChevronLeftIcon style={{marginRight: 7, marginTop: 3}} />
        </IconButton>
      </div>

      <Divider className={classes.divider} />
      {open && (
        <List>
          {categories.map(({id, children}) => (
            <React.Fragment key={id}>
              <ListItem className={classes.listItemTitle}>
                <ListItemText
                  primary={<Typography variant="H5">{id} </Typography>}
                />
              </ListItem>
              {children.map(({id: childId, linkto}) => (
                <ListItem
                  key={childId}
                  button
                  selected={activates[childId]}
                  className={classes.listItem}
                  onClick={() => handleListItemClick(childId, linkto)}>
                  <ListItemText
                    primary={
                      <Typography
                        variant="Small"
                        htmlColor={`${theme.palette.info.contrastText}8a`}>
                        {childId}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </React.Fragment>
          ))}
        </List>
      )}
    </Drawer>
  );
};
