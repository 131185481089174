import {takeLatest, call, put, select} from 'redux-saga/effects';
import {
  requsetSaveHistory,
  successSaveHistory,
  loadHistoryStat,
  successLoadHistoryStat,
  loadHistories,
  successLoadHistories,
  loadSummary,
  successLoadSummary,
  HISTORY,
  loadHistoryFileDetail,
  successLoadHistoryFileDetail,
  requestHistoryFileStat,
  successLoadHistoryFileStat,
} from './slice';
import {PayloadAction} from '@reduxjs/toolkit';
import {
  saveHistory,
  getHistoryStat,
  getHistories,
  getHistorySummary,
  getHistoryFileDetail,
  getHistoryFileStat,
} from '~/api';
import HistoryDetail from '~/models/hisotry';

function* handleSaveHistory({payload}: PayloadAction<HistoryDetail>) {
  try {
    yield call(saveHistory, payload);
    yield put(successSaveHistory());
  } catch (err) {
    console.log('error', err);
  }
}

function* handleLoadHistoryStat() {
  try {
    const state = yield select((state) => state[HISTORY]);
    const resp = yield call(getHistoryStat, state.request);
    yield put(successLoadHistoryStat(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleLoadHistories() {
  try {
    const resp = yield call(getHistories);
    yield put(successLoadHistories(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleLoadSummary() {
  try {
    const state = yield select((state) => state[HISTORY]);
    const resp = yield call(getHistorySummary, state.request);
    yield put(successLoadSummary(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleLoadHistoryFileDetail() {
  try {
    const state = yield select((state) => state[HISTORY]);
    const resp = yield call(getHistoryFileDetail, state.request);
    yield put(successLoadHistoryFileDetail(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleLoadHistoryFileStat() {
  try {
    const state = yield select((state) => state[HISTORY]);
    const resp = yield call(getHistoryFileStat, state.request);
    yield put(successLoadHistoryFileStat(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

export default function* watchHistory() {
  yield takeLatest(requsetSaveHistory, handleSaveHistory);
  yield takeLatest(loadHistoryStat, handleLoadHistoryStat);
  yield takeLatest(loadHistories, handleLoadHistories);
  yield takeLatest(loadSummary, handleLoadSummary);
  yield takeLatest(loadHistoryFileDetail, handleLoadHistoryFileDetail);
  yield takeLatest(requestHistoryFileStat, handleLoadHistoryFileStat);
}
