import React, {useState, useCallback, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Theme,
  useTheme,
  Paper,
} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
  Legend,
  ComposedChart,
} from 'recharts';
import {loadDutyStat, loadAccStat, BEP} from '~/features/BEP/slice';
import {RootState} from '~/app/rootReducer';
import {loadAccountType, ACCOUNT_TYPE} from '~/features/AccountType/slice';
import {STAT_SCALE} from '~/types/types';
import withStatTemplate, {StatProps} from '~/hoc/withStatTemplate';
import {renderDateTableHeader_2} from '~/utils';
import {withStyles} from '@material-ui/styles';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import {Typography} from '~/components/Typography';
import {PROFILE} from '~/features/Profile/slice';
import {DEPARTMENT, loadDepartment} from '~/features/Department/slice';
import {INVENTORY, loadMaterialCost} from '~/features/Inventory/slice';

// const BEP_DAY: {[key: string]: number} = {
//   '2021-01': 8670930,
//   '2021-02': 7261960,
//   '2021-03': 8080450,
//   '2021-04': 8684990,
//   '2021-05': 9185570,
//   '2021-06': 7892230,
//   '2021-07': 9075870,
//   '2021-08': 9478910,
//   '2021-09': 8958820,
//   '2021-10': 10002270,
//   '2021-11': 9379475,
//   '2021-01-01': 94360,
//   '2021-01-02': 214420,
//   '2021-01-03': 199330,
//   '2021-01-04': 491330,
//   '2021-01-05': 613450,
//   '2021-01-06': 275030,
//   '2021-01-07': 348060,
//   '2021-01-08': 561610,
//   '2021-01-09': 70810,
//   '2021-01-10': 22640,
//   '2021-01-11': 372680,
//   '2021-01-12': 363340,
//   '2021-01-13': 265720,
//   '2021-01-14': 302220,
//   '2021-01-15': 595140,
//   '2021-01-16': 118980,
//   '2021-01-17': 22640,
//   '2021-01-18': 357030,
//   '2021-01-19': 445650,
//   '2021-01-20': 226860,
//   '2021-01-21': 96340,
//   '2021-01-22': 502550,
//   '2021-01-23': 139820,
//   '2021-01-25': 426010,
//   '2021-01-26': 282580,
//   '2021-01-27': 252390,
//   '2021-01-28': 233800,
//   '2021-01-29': 535940,
//   '2021-01-30': 240200,

//   '2021-02-01': 454680,
//   '2021-02-02': 491460,
//   '2021-02-03': 406480,
//   '2021-02-04': 268720,
//   '2021-02-05': 498130,
//   '2021-02-06': 70810,
//   '2021-02-07': 22640,
//   '2021-02-08': 308860,
//   '2021-02-09': 434010,
//   '2021-02-10': 452890,
//   '2021-02-11': 180580,
//   '2021-02-12': 22640,
//   '2021-02-13': 22640,
//   '2021-02-14': 22640,
//   '2021-02-15': 318170,
//   '2021-02-16': 544220,
//   '2021-02-17': 281980,
//   '2021-02-18': 318480,
//   '2021-02-19': 354390,
//   '2021-02-20': 70810,
//   '2021-02-22': 283220,
//   '2021-02-23': 420680,
//   '2021-02-24': 166560,
//   '2021-02-25': 212370,
//   '2021-02-26': 380870,
//   '2021-02-27': 253030,

//   '2021-03-01': 228840,
//   '2021-03-02': 550250,
//   '2021-03-03': 226860,
//   '2021-03-04': 226860,
//   '2021-03-05': 455320,
//   '2021-03-06': 209060,
//   '2021-03-07': 22640,
//   '2021-03-08': 409820,
//   '2021-03-09': 498410,
//   '2021-03-10': 226860,
//   '2021-03-11': 226860,
//   '2021-03-12': 373320,
//   '2021-03-13': 267180,
//   '2021-03-14': 22640,
//   '2021-03-15': 324480,
//   '2021-03-16': 461910,
//   '2021-03-17': 226860,
//   '2021-03-18': 171740,
//   '2021-03-19': 415820,
//   '2021-03-20': 111130,
//   '2021-03-22': 179320,
//   '2021-03-23': 365530,
//   '2021-03-24': 243080,
//   '2021-03-25': 243080,
//   '2021-03-26': 398820,
//   '2021-03-27': 137300,
//   '2021-03-29': 370920,
//   '2021-03-30': 193960,
//   '2021-03-31': 291580,
//   '2021-04-01': 271720,
//   '2021-04-02': 619700,
//   '2021-04-03': 257870,
//   '2021-04-04': 22640,
//   '2021-04-05': 488180,
//   '2021-04-06': 259410,
//   '2021-04-07': 424460,
//   '2021-04-08': 457960,
//   '2021-04-09': 662510,
//   '2021-04-10': 111130,
//   '2021-04-11': 22640,
//   '2021-04-12': 390560,
//   '2021-04-13': 295390,
//   '2021-04-14': 272360,
//   '2021-04-15': 214240,
//   '2021-04-16': 549940,
//   '2021-04-17': 53010,
//   '2021-04-18': 22640,
//   '2021-04-19': 354700,
//   '2021-04-20': 211240,
//   '2021-04-21': 298530,
//   '2021-04-22': 390150,
//   '2021-04-23': 383870,
//   '2021-04-24': 142270,
//   '2021-04-26': 368560,
//   '2021-04-27': 283220,
//   '2021-04-28': 460580,
//   '2021-04-29': 152100,
//   '2021-04-30': 243410,

//   '2021-05-01': 91360,
//   '2021-05-02': 22640,
//   '2021-05-03': 491820,
//   '2021-05-04': 461600,
//   '2021-05-05': 263260,
//   '2021-05-06': 464910,
//   '2021-05-07': 559250,
//   '2021-05-08': 125930,
//   '2021-05-09': 22640,
//   '2021-05-10': 315200,
//   '2021-05-11': 373290,
//   '2021-05-12': 236170,
//   '2021-05-13': 467910,
//   '2021-05-14': 364010,
//   '2021-05-15': 272670,
//   '2021-05-16': 22640,
//   '2021-05-17': 416100,
//   '2021-05-18': 373290,
//   '2021-05-19': 188000,
//   '2021-05-20': 376600,
//   '2021-05-21': 494110,
//   '2021-05-22': 152100,
//   '2021-05-24': 399460,
//   '2021-05-25': 396460,
//   '2021-05-26': 356320,
//   '2021-05-27': 288580,
//   '2021-05-28': 554560,
//   '2021-05-29': 149100,
//   '2021-05-30': 59550,
//   '2021-05-31': 426040,

//   '2021-06-01': 259410,
//   '2021-06-02': 330150,
//   '2021-06-03': 118980,
//   '2021-06-04': 415150,
//   '2021-06-05': 226340,
//   '2021-06-06': 91360,
//   '2021-06-07': 445370,
//   '2021-06-08': 305220,
//   '2021-06-09': 391270,
//   '2021-06-10': 357030,
//   '2021-06-11': 567890,
//   '2021-06-12': 199750,
//   '2021-06-13': 22640,
//   '2021-06-14': 403480,
//   '2021-06-15': 211240,
//   '2021-06-16': 287980,
//   '2021-06-17': 281670,
//   '2021-06-18': 495130,
//   '2021-06-19': 53010,
//   '2021-06-20': 22640,
//   '2021-06-21': 279940,
//   '2021-06-22': 243720,
//   '2021-06-23': 256030,
//   '2021-06-24': 295530,
//   '2021-06-25': 383840,
//   '2021-06-26': 167800,
//   '2021-06-28': 335030,
//   '2021-06-29': 96980,
//   '2021-06-30': 347650,

//   '2021-07-01': 366805,
//   '2021-07-02': 543145,
//   '2021-07-03': 70810,
//   '2021-07-04': 22640,
//   '2021-07-05': 393715,
//   '2021-07-06': 247255,
//   '2021-07-07': 418490,
//   '2021-07-08': 366340,
//   '2021-07-09': 500490,
//   '2021-07-10': 162430,
//   '2021-07-11': 22640,
//   '2021-07-12': 490540,
//   '2021-07-13': 258770,
//   '2021-07-14': 362700,
//   '2021-07-15': 365700,
//   '2021-07-16': 502850,
//   '2021-07-17': 53010,
//   '2021-07-18': 97300,
//   '2021-07-19': 460350,
//   '2021-07-20': 304580,
//   '2021-07-21': 246080,
//   '2021-07-22': 444630,
//   '2021-07-23': 480210,
//   '2021-07-24': 178690,
//   '2021-07-26': 486490,
//   '2021-07-27': 236130,
//   '2021-07-28': 331420,
//   '2021-07-29': 346370,
//   '2021-07-30': 292650,
//   '2021-07-31': 22640,

//   '2021-08-01': 22640,
//   '2021-08-02': 594470,
//   '2021-08-03': 402200,
//   '2021-08-04': 403020,
//   '2021-08-05': 167150,
//   '2021-08-06': 603780,
//   '2021-08-07': 162430,
//   '2021-08-08': 22640,
//   '2021-08-09': 518440,
//   '2021-08-10': 356390,
//   '2021-08-11': 363340,
//   '2021-08-12': 275030,
//   '2021-08-13': 408870,
//   '2021-08-14': 70810,
//   '2021-08-15': 22640,
//   '2021-08-16': 166620,
//   '2021-08-17': 461270,
//   '2021-08-18': 274720,
//   '2021-08-19': 430770,
//   '2021-08-20': 452320,
//   '2021-08-21': 231410,
//   '2021-08-22': 59550,
//   '2021-08-23': 350010,
//   '2021-08-24': 443990,
//   '2021-08-25': 391870,
//   '2021-08-26': 544610,
//   '2021-08-27': 398180,
//   '2021-08-28': 106290,
//   '2021-08-30': 346370,
//   '2021-08-31': 427080,
//   '2021-12-30': 9478910,
//   '2021-09-01': 454320,
//   '2021-09-02': 424130,
//   '2021-09-03': 613090,
//   '2021-09-04': 164790,
//   '2021-09-05': 85190,
//   '2021-09-06': 369010,
//   '2021-09-07': 304580,
//   '2021-09-08': 348060,
//   '2021-09-09': 369010,
//   '2021-09-10': 521740,
//   '2021-09-11': 210600,
//   '2021-09-12': 22640,
//   '2021-09-13': 359700,
//   '2021-09-14': 341110,
//   '2021-09-15': 359700,
//   '2021-09-16': 372010,
//   '2021-09-17': 603780,
//   '2021-09-18': 116620,
//   '2021-09-19': 22640,
//   '2021-09-20': 108320,
//   '2021-09-21': 85680,
//   '2021-09-22': 68720,
//   '2021-09-23': 493110,
//   '2021-09-24': 556350,
//   '2021-09-25': 226480,
//   '2021-09-27': 342850,
//   '2021-09-28': 414320,
//   '2021-09-29': 350600,
//   '2021-09-30': 249670,

//   '2021-10-01': 316250,
//   '2021-10-02': 167790,
//   '2021-10-03': 22640,
//   '2021-10-04': 318560,
//   '2021-10-05': 490180,
//   '2021-10-06': 362060,
//   '2021-10-07': 371370,
//   '2021-10-08': 505210,
//   '2021-10-09': 22640,
//   '2021-10-10': 22640,
//   '2021-10-11': 246300,
//   '2021-10-12': 535990,
//   '2021-10-13': 417180,
//   '2021-10-14': 222270,
//   '2021-10-15': 407590,
//   '2021-10-16': 254170,
//   '2021-10-17': 22640,
//   '2021-10-18': 417180,
//   '2021-10-19': 362060,
//   '2021-10-20': 426490,
//   '2021-10-21': 355680,
//   '2021-10-22': 528380,
//   '2021-10-23': 476830,
//   '2021-10-24': 213000,
//   '2021-10-25': 394540,
//   '2021-10-26': 559160,
//   '2021-10-27': 449660,
//   '2021-10-28': 254750,
//   '2021-10-29': 541000,
//   '2021-10-30': 318060,

//   '2021-11-01': 275030,
//   '2021-11-02': 375960,
//   '2021-11-03': 229220,
//   '2021-11-04': 320840,
//   '2021-11-05': 463960,
//   '2021-11-06': 127130,
//   '2021-11-07': 22640,
//   '2021-11-08': 308250,
//   '2021-11-09': 497770,
//   '2021-11-10': 431080,
//   '2021-11-11': 265720,
//   '2021-11-12': 369650,
//   '2021-11-13': 355680,
//   '2021-11-14': 239820,
//   '2021-11-15': 366370,
//   '2021-11-16': 360340,
//   '2021-11-17': 385270,
//   '2021-11-18': 421770,
//   '2021-11-19': 258249,
//   '2021-11-20': 100659,
//   '2021-11-21': 73220,
//   '2021-11-22': 291890,
//   '2021-11-23': 382349,
//   '2021-11-24': 435630,
//   '2021-11-25': 452200,
//   '2021-11-26': 431189,
//   '2021-11-27': 157889,
//   '2021-11-28': 61041,
//   '2021-11-29': 397689,
//   '2021-11-30': 520970,
// };

const TableCell = withStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.info.dark}`,
    '&.MuiTableCell-head': {
      fontSize: '0.75em',
    },
    minWidth: 79.72,
    height: 28,
    //padding: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
}))(MuiTableCell);

const TableRow = withStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.info.dark}`,
  },
}))(MuiTableRow);

const useStyles = makeStyles((theme) => ({
  date: {
    width: 120,
    padding: theme.spacing(1),
  },
  dateItem: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    textAlign: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
  },
  chartContainer: {
    width: '100%',
    flexGrow: 1,
    height: 436,
    marginTop: theme.spacing(4) + 3,
  },
  pieContainer: {
    width: 200,
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
    flex: 1,
  },
  backColorBlue: {
    fontSize: '0.75em',
    backgroundColor: `${theme.palette.primary.light}14`,
  },
  backColorGray: {
    fontSize: '0.625em',
    backgroundColor: `${theme.palette.info.light}`,
  },
  chartLegend: {
    '& > div > svg > path': {
      fill: 'none',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper': {
      top: '0px !important',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend > .recharts-legend-item': {
      display: 'flex !important',
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '0.75em',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend > li > svg': {
      width: 50,
      height: 11,
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend > li > svg > path': {
      d: 'path("M-50,0h146v32h-146z")',
    },
  },
  tooltip: {
    //height: 203,
    padding: theme.spacing(2.5, 1.875, 1.125),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    //alignItems: 'center',
    backgroundColor: '#FFFFFF',
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: theme.spacing(0.5),
  },
  tooltip__label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 23,
    marginBottom: theme.spacing(1.125),
    fontSize: '1em',
  },
  unit: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    fontSize: '0.625em',
    marginBottom: theme.spacing(1) - 1,
  },
  table: {
    boxShadow: 'none',
    //height: 342,
    '& > table > thead > tr > th': {
      paddingTop: 0,
      paddingBottom: 0,
      fontWeight: 400,
    },
  },
  tableText: {
    fontSize: '0.625em',
  },
}));
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const BEPScreen = ({statScale, viewType, date}: StatProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme() as Theme;
  const duty = useSelector((state: RootState) => state[BEP].duty);
  const account = useSelector((state: RootState) => state[BEP].account);
  const accountTypes = useSelector(
    (state: RootState) => state[ACCOUNT_TYPE].accountTypes,
  );
  const [sales, setSales] = useState([{}]);
  // 센터 id 가져오기
  const userId = useSelector((state: RootState) => state[PROFILE].id);
  const centerId = useSelector(
    (state: RootState) => state[DEPARTMENT].centerInfo?.centerId,
  );

  const material = useSelector(
    (state: RootState) => state[INVENTORY].materialCost,
  );

  useEffect(() => {
    if (userId) {
      dispatch(loadDepartment(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (centerId) {
      const datetime = moment(date);
      const request = {
        scale: statScale,
        datetime: datetime,
        centerId: centerId,
      };
      dispatch(loadDutyStat(request));
      dispatch(loadAccStat(request));
      dispatch(loadAccountType());
      dispatch(loadMaterialCost(request));
    }
  }, [dispatch, date, statScale, centerId]);

  const renderTableHeader = useCallback(() => {
    return renderDateTableHeader_2(viewType, duty, _.values(duty).length);
  }, [viewType, duty]);

  const DataFormater = (number: number) => {
    return number.toLocaleString();
  };

  const CustomTooltip = ({payload, label}: any) => {
    if (payload && payload.length) {
      return (
        <div className={classes.tooltip}>
          {payload.map((item: any, idx: number) => {
            return (
              <div key={idx} className={classes.tooltip__label}>
                <svg
                  width="24"
                  height="17"
                  viewBox="0, 0, 32 32"
                  style={{
                    display: 'inline-block',
                    marginRight: theme.spacing(5) - 2,
                    verticalAlign: 'middle',
                    marginLeft: theme.spacing(0.5),
                  }}>
                  <path
                    stroke="none"
                    fill={item.fill}
                    d="M0, 4h 64v 24h-64z"></path>
                </svg>
                <p
                  style={{
                    width: 81,
                    display: 'flex',
                    justifyContent: 'left',
                    marginRight: theme.spacing(1) + 2,
                  }}>{`${item.name}`}</p>
                <p
                  style={{
                    width: 115,
                    display: 'flex',
                    justifyContent: 'end',
                  }}>{`${item.value.toLocaleString()}원`}</p>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  // 매출 데이터
  useEffect(() => {
    if (statScale === STAT_SCALE.WEEK) {
      if (duty && account) {
        setSales(
          _.keys(duty).map((d) => {
            return {
              name: `${parseInt(d.slice(8, 10))} 일`,
              매출:
                _.sum(_.values(duty[d])) +
                (_.has(account[d], '기타') ? _.get(account[d], '기타') : 0),
              // 인건비: _.has(account[d], '인건비')
              //   ? _.get(account[d], '인건비')
              //   : 0,
            };
          }),
        );
      }
    } else {
      if (duty && account) {
        setSales(
          _.keys(duty).map((d, idx) => {
            return {
              name: months[idx],
              매출:
                _.sum(_.values(duty[d])) +
                (_.has(account[d], '기타') ? _.get(account[d], '기타') : 0),
              인건비: _.has(account[d], '인건비')
                ? _.get(account[d], '인건비')
                : 0,
            };
          }),
        );
      }
    }
  }, [statScale, duty, account]);

  return (
    <>
      <div className={classes.main}>
        <div className={classes.chartContainer}>
          <ResponsiveContainer className={classes.chartLegend}>
            <ComposedChart
              //width={500}
              height={300}
              data={sales}
              margin={{
                top: 20,
                right: 20,
                left: 40,
                bottom: 5,
              }}>
              <CartesianGrid strokeDasharray="5 5" />
              <XAxis dataKey="name" />
              <YAxis tickFormatter={DataFormater} />
              <Tooltip content={<CustomTooltip />} />
              <Legend verticalAlign="top" />
              <Bar dataKey="매출" stackId="a" fill="#FFB1C1" />
              {statScale !== STAT_SCALE.WEEK ? (
                <Bar dataKey="인건비" stackId="b" fill="#64B6F7" />
              ) : (
                <></>
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className={classes.unit}>단위:원</div>
      <Paper
        style={{
          overflow: 'auto',
          boxShadow: 'none',
          borderRadius: 0,
          backgroundColor: '#FFFFFF',
        }}>
        <TableContainer className={classes.table}>
          <Table aria-label="simple table">
            <TableHead className={classes.backColorBlue}>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <Typography
                    style={{
                      fontSize: '0.75em',
                    }}
                    variant="Small">
                    구분
                  </Typography>
                </TableCell>
                {renderTableHeader()}
                <TableCell colSpan={2} align="center">
                  <Typography
                    style={{
                      fontSize: '0.75em',
                    }}
                    variant="Small">
                    총합
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  rowSpan={4}
                  align="center"
                  className={classes.backColorBlue}>
                  매출
                </TableCell>
                <TableCell align="center" className={classes.backColorGray}>
                  방문간호
                </TableCell>
                {_.values(duty).map((d, idx) => (
                  <TableCell
                    key={idx}
                    className={classes.tableText}
                    align="center">
                    {(_.has(d, '방문간호')
                      ? _.get(d, '방문간호')
                      : 0
                    ).toLocaleString()}
                  </TableCell>
                ))}
                <TableCell className={classes.tableText} align="center">
                  {_.sum(
                    _.values(duty).map((d) => {
                      return _.has(d, '방문간호') ? _.get(d, '방문간호') : 0;
                    }),
                  ).toLocaleString()}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.backColorGray}>
                  방문요양
                </TableCell>
                {_.values(duty).map((d, idx) => (
                  <TableCell
                    key={idx}
                    className={classes.tableText}
                    align="center">
                    {(_.has(d, '방문요양')
                      ? _.get(d, '방문요양')
                      : 0
                    ).toLocaleString()}
                  </TableCell>
                ))}

                <TableCell className={classes.tableText} align="center">
                  {_.sum(
                    _.values(duty).map((d) => {
                      return _.has(d, '방문요양') ? _.get(d, '방문요양') : 0;
                    }),
                  ).toLocaleString()}
                </TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell align="center" className={classes.backColorGray}>
                  방문목욕
                </TableCell>
                {_.values(duty).map((d, idx) => (
                  <TableCell
                    key={idx}
                    className={classes.tableText}
                    align="center">
                    {(_.has(d, '방문목욕')
                      ? _.get(d, '방문목욕')
                      : 0
                    ).toLocaleString()}
                  </TableCell>
                ))}
                <TableCell className={classes.tableText} align="center">
                  {_.sum(
                    _.values(duty).map((d) => {
                      return _.has(d, '방문목욕') ? _.get(d, '방문목욕') : 0;
                    }),
                  ).toLocaleString()}
                </TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell align="center" className={classes.backColorGray}>
                  기타
                </TableCell>
                {_.values(account).map((d, idx) => (
                  <TableCell
                    key={idx}
                    className={classes.tableText}
                    align="center">
                    {(_.has(d, '기타') ? _.get(d, '기타') : 0).toLocaleString()}
                  </TableCell>
                ))}
                <TableCell className={classes.tableText} align="center">
                  {_.sum(
                    _.values(account).map((d) => {
                      return _.has(d, '기타') ? _.get(d, '기타') : 0;
                    }),
                  ).toLocaleString()}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.backColorGray}>
                  소계
                </TableCell>
                {_.zip(_.values(duty), _.values(account)).map((d, key) => {
                  const dutyTotal = _.sum(_.values(d[0]));
                  // 비급여 대체로 account에 기타 항목으로 넣어서 합산
                  const etc = _.has(d[1], '기타') ? _.get(d[1], '기타') : 0;
                  const total = etc ? dutyTotal + etc : dutyTotal;
                  // const correct = BEP_DAY[key] === total;
                  return (
                    <TableCell
                      key={key}
                      className={classes.tableText}
                      // style={{backgroundColor: correct ? 'inherit' : 'red'}}
                      align="center">
                      {total.toLocaleString()}
                      {/* {!correct && `(${BEP_DAY[key]?.toLocaleString()})`} */}
                    </TableCell>
                  );
                })}
                <TableCell className={classes.tableText} align="center">
                  {_.sum(
                    _.zip(_.values(duty), _.values(account)).map((d) => {
                      const etc = _.has(d[1], '기타') ? _.get(d[1], '기타') : 0;
                      return etc
                        ? _.sum(_.values(d[0])) + etc
                        : _.sum(_.values(d[0]));
                    }),
                  ).toLocaleString()}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className={classes.backColorBlue}
                  rowSpan={accountTypes.length + 1}
                  align="center">
                  지출
                </TableCell>
              </TableRow>
              {_.map(accountTypes, (acc, idx) => {
                if (acc.name !== '기타') {
                  return (
                    <TableRow key={idx}>
                      <TableCell
                        align="center"
                        className={classes.backColorGray}>
                        {acc.name}
                      </TableCell>
                      {acc.name !== '재료비'
                        ? _.values(account).map((d, idx) => (
                            <TableCell
                              key={idx}
                              className={classes.tableText}
                              align="center">
                              {(_.has(d, acc.name)
                                ? _.get(d, acc.name)
                                : 0
                              ).toLocaleString()}
                            </TableCell>
                          ))
                        : _.values(material).map((d, idx) => (
                            <TableCell
                              key={idx}
                              className={classes.tableText}
                              align="center">
                              {(_.has(d, acc.name)
                                ? _.get(d, acc.name)
                                : 0
                              ).toLocaleString()}
                            </TableCell>
                          ))}
                      <TableCell className={classes.tableText} align="center">
                        {acc.name !== '재료비'
                          ? _.sum(
                              _.values(account).map((d) => {
                                return _.has(d, acc.name)
                                  ? _.get(d, acc.name)
                                  : 0;
                              }),
                            ).toLocaleString()
                          : _.sum(
                              _.values(material).map((d) => {
                                return _.has(d, acc.name)
                                  ? _.get(d, acc.name)
                                  : 0;
                              }),
                            ).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
              <TableRow>
                <TableCell align="center" className={classes.backColorGray}>
                  소계
                </TableCell>
                {_.zip(_.values(account), _.values(material)).map((d, idx) => (
                  <TableCell
                    key={idx}
                    className={classes.tableText}
                    align="center">
                    {(
                      _.sum(
                        _.keys(d[0]).map((q) => {
                          if (d[0] && q !== '기타' && q !== '재료비') {
                            return d[0][q];
                          }
                          return 0;
                        }),
                      ) + _.sum(_.values(d[1]))
                    ).toLocaleString()}
                    {/* {(
                      _.sum(_.values(d[0]).slice(0, 6)) + _.sum(_.values(d[1]))
                    ).toLocaleString()} */}
                  </TableCell>
                ))}
                <TableCell className={classes.tableText} align="center">
                  {_.sum(
                    _.zip(_.values(account), _.values(material)).map(
                      (d) =>
                        _.sum(
                          _.keys(d[0]).map((q) => {
                            if (d[0] && q !== '기타' && q !== '재료비') {
                              return d[0][q];
                            }
                            return 0;
                          }),
                        ) + _.sum(_.values(d[1])),
                    ),
                  ).toLocaleString()}
                </TableCell>
              </TableRow>
              {/* 줄 띄움 위해서 추가 */}
              <TableRow style={{border: 'none'}}>
                <TableCell style={{border: 'none'}}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={2}
                  align="center"
                  className={classes.backColorGray}>
                  손익값
                </TableCell>
                {_.zip(
                  _.values(duty),
                  _.values(account),
                  _.values(material),
                ).map((d, idx) => (
                  <TableCell
                    key={idx}
                    style={{
                      fontSize: '0.625em',
                    }}
                    align="center"
                    className={classes.backColorBlue}>
                    {(
                      _.sum(_.values(d[0])) +
                      _.sum(
                        _.keys(d[1]).map((q) => {
                          if (d[1] && q === '기타') {
                            return d[1][q];
                          }
                          return 0;
                        }),
                      ) -
                      _.sum(
                        _.keys(d[1]).map((q) => {
                          if (d[1] && q !== '기타' && q !== '재료비') {
                            return d[1][q];
                          }
                          return 0;
                        }),
                      ) -
                      _.sum(_.values(d[2]))
                    ).toLocaleString()}
                  </TableCell>
                ))}
                <TableCell align="center" className={classes.backColorBlue}>
                  {_.sum(
                    _.zip(
                      _.values(duty),
                      _.values(account),
                      _.values(material),
                    ).map(
                      (d) =>
                        _.sum(_.values(d[0])) +
                        _.sum(
                          _.keys(d[1]).map((q) => {
                            if (d[1] && q === '기타') {
                              return d[1][q];
                            }
                            return 0;
                          }),
                        ) -
                        _.sum(
                          _.keys(d[1]).map((q) => {
                            if (d[1] && q !== '기타' && q !== '재료비') {
                              return d[1][q];
                            }
                            return 0;
                          }),
                        ) -
                        _.sum(_.values(d[2])),
                    ),
                  ).toLocaleString()}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default withStatTemplate(BEPScreen, {
  title: '총괄운영현황',
});
