import React from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import HumanResourceScreen from '~/screens/HumanResourceScreen';
import StockScreen from '~/screens/StockScreen';
import OperationScreen from '~/screens/OperationScreen';
import InsuranceScreen from '~/screens/InsuranceScreen';
import NonInsuranceScreen from '~/screens/NonInsuranceScreen';
import PerformanceScreen from '~/screens/PerformanceScreen';
import CostScreen from '~/screens/CostScreen';
import GoalScreen from '~/screens/GoalScreen';
import SettingsScreen from '~/screens/SettingsScreen';
import PatientStatScreen from '~/screens/PatientStatScreen';
import PatientTimeScreen from '~/screens/PatientTimeScreen';
import PerfAmdScreen from '~/screens/PerfAmdScreen';
import BEPScreen from '~/screens/BEPScreen';
import ETCInputScreen from '~/screens/ETCInputScreen';
import WageScreen from '~/screens/WageScreen';
import AchievementScreen from '~/screens/AchievementScreen';
import ScheduleScreen from '~/screens/ScheduleScreen';
import StockAlarmScreen from '~/screens/StockAlarmScreen';
import DistributionScreen from '~/screens/DistributionScreen';
import HistoryScreen from '~/screens/HistoryScreen';
import ProfileScreen from '~/screens/ProfileScreen';
import MaterialCostScreen from '~/screens/MaterialCostScreen';
import HistoryFileScreen from '~/screens/HistoryFileScreen';

const HomeRouter = () => {
  return (
    <Switch>
      {/* <Route path={`/employee`} component={EmployeeScreen} /> */}
      <Route path={`/profile`} component={ProfileScreen} />
      <Route path={`/material-cost`} component={MaterialCostScreen} />
      <Route path={`/stock`} component={StockScreen} />
      <Route path={`/operation`} component={OperationScreen} />
      <Route path={`/insurance`} component={InsuranceScreen} />
      <Route path={`/non-insurance`} component={NonInsuranceScreen} />
      <Route path={`/performance`} component={PerformanceScreen} />
      <Route path={`/perf-amd`} component={PerfAmdScreen} />
      <Route path={`/cost`} component={CostScreen} />
      <Route path={`/goal`} component={GoalScreen} />
      <Route path={`/settings`} component={SettingsScreen} />
      <Route path={`/pstat`} component={PatientStatScreen} />
      <Route path={`/ptime`} component={PatientTimeScreen} />
      <Route path={`/hr`} component={HumanResourceScreen} />
      <Route path={`/wage`} component={WageScreen} />
      <Route path={`/etc-input`} component={ETCInputScreen} />
      <Route path={`/achievement`} component={AchievementScreen} />
      <Route path={`/schedule`} component={ScheduleScreen} />
      <Route path={`/stock-alarm`} component={StockAlarmScreen} />
      <Route path={`/distribution`} component={DistributionScreen} />
      <Route path={`/history`} component={HistoryScreen} />
      <Route path={`/history-file`} component={HistoryFileScreen} />
      <Route path={`/`} component={BEPScreen} />
    </Switch>
  );
};

export default withRouter(HomeRouter);
