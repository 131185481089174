import React, {useEffect, useState} from 'react';
import MLoginForm from '~/forms/MLoginForm';
import {useDispatch} from 'react-redux';
import {loginRequest} from '~/features/Login/slice';
import {makeStyles, Theme, createStyles} from '@material-ui/core';
import {SimpleHeader} from '~/components/SimpleHeader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //width: 360,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      flexGrow: 1,
      fontSize: '3.5625em',
      fontWeight: 700,
      marginTop: 10,
      lineHeight: '83px',
    },
    body: {
      justifyContent: 'flex-start',
      flex: 1,
      paddingTop: 160,
    },
    footer: {
      display: 'flex',
      backgroundColor: theme.palette.primary.main,
      height: 50,
      color: theme.palette.primary.contrastText,
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
  }),
);

const LOACL_EMAIL = 'email';

const MLoginScreen = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [initEmail, setEmail] = useState('');

  const submit = (values: any) => {
    const {email, password} = values;
    dispatch(loginRequest({email, password}));
  };

  const loadEmail = () => {
    const loadedEamil = localStorage.getItem(LOACL_EMAIL);

    if (loadedEamil !== null) {
      const parsedEmail = JSON.parse(loadedEamil);
      setEmail(parsedEmail.email);
    }
  };

  useEffect(() => {
    loadEmail();
  }, [initEmail]);

  return (
    <div className={classes.root}>
      <SimpleHeader mode={'login'} />
      <div className={classes.body}>
        {/* 로그인 폼 라우터로 변경 */}
        <MLoginForm
          initialValues={{
            email: initEmail,
          }}
          onSubmit={submit}
        />
      </div>
    </div>
  );
};

export default MLoginScreen;
