import React, {
  CSSProperties,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';
import {
  Field,
  InjectedFormProps,
  reduxForm,
  WrappedFieldProps,
} from 'redux-form';
import {
  makeStyles,
  TextField,
  Button,
  Link,
  FormHelperTextProps,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import {useSelector} from 'react-redux';
import {LOGIN} from '~/features/Login/slice';
import {ChangeEvent} from 'react';
import {RootState} from '~/app/rootReducer';

interface IInputProps {
  label?: string;
  error?: boolean;
  helperText?: string | React.ReactNode;
  FormHelperTextProps?: Partial<FormHelperTextProps<'p', {}>> | undefined;
  disabled?: boolean;
  value?: string;
  className?: string;
  style?: CSSProperties;
}

const useStyles = makeStyles((theme) => ({
  frame: {
    width: 496,
    //height: 670,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    boxSizing: 'border-box',
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    fontSize: '1.438em',
    fontWeight: 500,
    lineHeight: '35px',
    width: 67,
    display: 'flex',
    textAlign: 'center',
    marginTop: theme.spacing(8) + 3,
  },
  form: {
    width: 325,
    marginTop: theme.spacing(3) + 6,
    position: 'relative',
  },
  divBtn: {
    width: 325,
    height: 42,
    display: 'flex',
    marginBottom: theme.spacing(19.75),
  },
  loginBtn: {
    fontSize: '0.938em',
  },
  storeInfo: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 29,
  },
  mail: {
    display: 'flex',
    height: 42,
    alignItems: 'center',
    justifyContent: 'center',
  },
  options: {
    display: 'flex',
    height: 23,
    marginBottom: theme.spacing(3) - 1,
    alignItems: 'center',
  },
}));

const renderTextField = (
  field: WrappedFieldProps &
    InputHTMLAttributes<HTMLInputElement> &
    IInputProps,
) => {
  const {
    input,
    type,
    autoFocus,
    label,
    meta: {touched, error},
    disabled,
    style,
    FormHelperTextProps,
  } = field;
  return (
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      label={label}
      autoFocus={autoFocus}
      FormHelperTextProps={FormHelperTextProps}
      //label={input.name}
      error={error}
      disabled={disabled}
      helperText={touched && error}
      type={type}
      style={style}
      {...input}
    />
  );
};

const helperTextEmail = makeStyles((theme) => ({
  contained: {
    '&.MuiFormHelperText-contained': {
      display: 'none',
    },
  },
}));

const helperTextPassword = makeStyles((theme) => ({
  contained: {
    '&.MuiFormHelperText-contained': {
      marginLeft: 0,
      marginRight: 0,
      fontSize: '0.688em',
    },
  },
}));

const LoginForm = (props: InjectedFormProps) => {
  const classes = useStyles();
  const helperEmail = helperTextEmail();
  const helperPassword = helperTextPassword();
  const {handleSubmit} = props;
  const [email, setEmail] = useState('');
  const [checked, setChecked] = useState(false);
  const {loading, logout, error} = useSelector(
    (state: RootState) => state[LOGIN],
  );

  const LOACL_EMAIL = 'email';

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };

  const loadEmail = () => {
    const loadedEamil = localStorage.getItem(LOACL_EMAIL);
    if (loadedEamil !== null) {
      const parsedInfo = JSON.parse(loadedEamil);
      setEmail(parsedInfo.email);
      setChecked(parsedInfo.checked);
    }
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    // 로그아웃 했을 경우 localSotrage에 저장된 값 불러와서 저장
    loadEmail();
  }, []);

  useEffect(() => {
    // 일단 무조건 하나만 저장하도록 기존 값 삭제후 다시 입력
    if (checked) {
      localStorage.setItem(LOACL_EMAIL, JSON.stringify({checked, email}));
    } else if (!checked && !logout) {
      localStorage.setItem(LOACL_EMAIL, JSON.stringify({checked, email: ''}));
    }
  }, [loading, checked, logout, email]);

  return (
    <div className={classes.frame}>
      <div className={classes.text}>
        <span>{'로그인'}</span>
      </div>
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <Field
          variant="outlined"
          fullWidth
          label={'이메일'}
          name="email"
          onChange={handleChangeEmail}
          component={renderTextField}
          FormHelperTextProps={{classes: helperEmail}}
          type="text"
          style={{
            marginTop: 16,
            marginBottom: 14,
          }}
        />
        <Field
          variant="outlined"
          label="비밀번호"
          name="password"
          component={renderTextField}
          FormHelperTextProps={{classes: helperPassword}}
          type="password"
          style={{marginTop: 16, marginBottom: 0}}
        />
        <div className={classes.divBtn} style={{marginTop: error ? 26 : 49}}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.loginBtn}>
            로그인
          </Button>
        </div>
        <div className={classes.storeInfo}>
          <div className={classes.mail}>
            <Checkbox
              color="primary"
              value={false}
              checked={checked}
              onChange={handleChange}
            />
            <div
              style={{
                fontSize: '1em',
              }}>
              이메일 저장
            </div>
          </div>
        </div>

        <Divider
          style={{
            borderTop: '1px solid rgba(0, 0, 0, 0.23)',
            marginBottom: 15,
          }}
        />

        <div className={classes.options}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Link
              style={{
                fontSize: '1em',
                marginLeft: 79.76,
                color: '#000000',
              }}
              href="/signup">
              회원가입
            </Link>
            <Divider
              orientation="vertical"
              style={{
                width: 12,
                height: 0.5,
                transform: 'rotate(90deg)',
                //border: '0.5px solid #757575',
              }}
            />
            <Link style={{fontSize: '1em', color: '#000000'}} href="/restore">
              비밀번호 찾기
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default reduxForm({
  // a unique name for the form
  form: 'login',
  enableReinitialize: true,
})(LoginForm);
