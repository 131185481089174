import React, {useCallback, useEffect} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {RootState} from './rootReducer';
import {checkLogin, LOGIN} from '~/features/Login/slice';
import HomeScreen from '~/screens/HomeScreen';
import {CssBaseline, Theme, useMediaQuery, useTheme} from '@material-ui/core';
import LoginRouter from '~/routers/LoginRouter';

import MHomeScreen from '~/screens/MHomeScreen';
import MLoginRouter from '~/routers/MLoginRouter';
import {PROFILE, profileRequest} from '~/features/Profile/slice';
import {Breakpoint} from '~/contants/Breakpoints';

function App() {
  const dispatch = useDispatch();
  const theme = useTheme() as Theme;
  const isAuthenticated = useSelector(
    (state: RootState) => state[LOGIN].authenticated,
  );
  const token = useSelector((state: RootState) => state[LOGIN].token);
  const mobile = useMediaQuery(theme.breakpoints.down(Breakpoint.mobile));
  const roles = useSelector((state: RootState) => state[PROFILE].roles);

  useEffect(() => {
    if (token) {
      //console.log("token1", token)
      // Add a request interceptor
      const interceptor = axios.interceptors.request.use(
        function (config) {
          // Do something before request is sent
          //console.log("token2", token);
          config.headers.Authorization = `Bearer ${token}`;
          config.headers.Accept = 'application/json';
          return config;
        },
        function (error) {
          // Do something with request error
          return Promise.reject(error);
        },
      );
      dispatch(profileRequest());
      return () => {
        axios.interceptors.request.eject(interceptor);
      };
    }
  }, [dispatch, token]);

  useEffect(() => {
    dispatch(checkLogin());
  }, [dispatch]);

  const renderCenter = useCallback(() => {
    return isAuthenticated ? (
      <Route path="/" component={HomeScreen} />
    ) : (
      <LoginRouter />
    );
  }, [isAuthenticated]);

  const renderUser = useCallback(() => {
    return isAuthenticated ? (
      <Route path="/" component={MHomeScreen} />
    ) : (
      <MLoginRouter />
    );
  }, [isAuthenticated]);

  return (
    <div>
      <CssBaseline />
      <BrowserRouter>
        <Switch>
          {/* <Route exact path="/" component={HomeScreen} /> */}
          {mobile
            ? isAuthenticated
              ? roles === 'Admin'
                ? renderCenter()
                : renderUser()
              : renderUser()
            : isAuthenticated
            ? roles === 'Admin'
              ? renderCenter()
              : renderUser()
            : renderCenter()}
          {/* {mobile && roles !== 'Admin'
            ? renderUser()
            : !mobile && roles !== 'Admin'
              ? renderUser()
              :renderCenter()} */}
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
