import {call, put, takeLatest} from 'redux-saga/effects';
import {
  loadSch,
  successSch,
  RequestSchdules,
  successLoadSchedules,
  loadSchedules,
  loadSchedulesServiceType,
  successLoadSchedulesServiceType,
  loadSchedulesGrade,
  successLoadSchedulesGrade,
  RequestJobType,
  RequestGrade,
} from './slice';
import {
  getSchedule,
  getSchedules,
  getSchedulesGrade,
  getSchedulesServiceType,
} from '~/api';
import {PayloadAction} from '@reduxjs/toolkit';

function* handleLoadSchedule({payload}: PayloadAction<number>) {
  try {
    const resp = yield call(getSchedule, payload);
    yield put(successSch(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}
function* handleLoadSchedules({payload}: PayloadAction<RequestSchdules>) {
  try {
    const resp = yield call(getSchedules, payload);
    yield put(successLoadSchedules(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleLoadSchedulesServiceType({
  payload,
}: PayloadAction<RequestJobType>) {
  try {
    const resp = yield call(getSchedulesServiceType, payload);
    yield put(successLoadSchedulesServiceType(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleLoadSchedulesGrade({payload}: PayloadAction<RequestGrade>) {
  try {
    const resp = yield call(getSchedulesGrade, payload);
    yield put(successLoadSchedulesGrade(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

export default function* watchSchedule() {
  yield takeLatest(loadSch, handleLoadSchedule);
  yield takeLatest(loadSchedules, handleLoadSchedules);
  yield takeLatest(loadSchedulesServiceType, handleLoadSchedulesServiceType);
  yield takeLatest(loadSchedulesGrade, handleLoadSchedulesGrade);
}
