import React from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import MHistoryDetailScreen from '~/screens/MHistoryDetailScreen';
import MHistoryScreen from '~/screens/MHistoryScreen';
import MInventoryScreen from '~/screens/MInventoryScreen';
import MItemScreen from '~/screens/MItemScreen';
import MProfileScreen from '~/screens/MProfileScreen';
import MScheduleScreen from '~/screens/MScheduleScreen';

const MHomeRouter = () => {
  return (
    <Switch>
      <Route path={`/profile`} component={MProfileScreen} />
      <Route path={`/inventory`} component={MInventoryScreen} />
      <Route path={`/item`} component={MItemScreen} />
      <Route path={`/history`} component={MHistoryScreen} />
      <Route path={`/history-detail`} component={MHistoryDetailScreen} />
      <Route path={`/`} component={MScheduleScreen} />
    </Switch>
  );
};

export default withRouter(MHomeRouter);
