import {takeLatest, call, put, select} from 'redux-saga/effects';
import {DEPARTMENT, loadDepartment, successLoadDepartment} from './slice';
import {getUserDepartment} from '~/api';

function* handleLoadDepartment() {
  try {
    const userId = yield select((state) => state[DEPARTMENT].userId);
    const resp = yield call(getUserDepartment, userId);
    yield put(successLoadDepartment(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

export default function* watchDepartment() {
  yield takeLatest(loadDepartment, handleLoadDepartment);
}
