import {
  AccountType,
  NewAccountType,
  NewAccountSubType,
  AccountStatAll,
  StateCenterId,
} from '~/types/types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type State = {
  loading: boolean;
  accountTypes: AccountType[];
  accountStatAll: AccountStatAll[];
  request: StateCenterId | undefined;
};

let initialState: State = {
  loading: false,
  accountTypes: [],
  accountStatAll: [],
  request: undefined,
};

const slice = createSlice({
  name: 'account_type',
  initialState,
  reducers: {
    loadAccountType: (state) => {
      state.loading = true;
    },
    successAccountType: (state, action: PayloadAction<AccountType[]>) => {
      state.loading = false;
      state.accountTypes = action.payload;
    },
    saveAccountType: (state, _action: PayloadAction<NewAccountType>) => {
      state.loading = true;
    },
    saveSubAccountType: (state, _action: PayloadAction<NewAccountSubType>) => {
      state.loading = true;
    },
    loadStatAll: (state, action: PayloadAction<StateCenterId>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successAccountStatAll: (state, action: PayloadAction<AccountStatAll[]>) => {
      state.loading = false;
      state.accountStatAll = action.payload;
    },
  },
});

export const {
  loadAccountType,
  successAccountType,
  saveAccountType,
  saveSubAccountType,
  loadStatAll,
  successAccountStatAll,
} = slice.actions;
export const ACCOUNT_TYPE = slice.name;
export default slice.reducer;
