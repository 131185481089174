import React from 'react';
import {SERVICE_HISTORY_TYPE} from '~/types/types';
import {RadioGroup, FormControlLabel, Radio} from '@material-ui/core';

interface Props {
  value: SERVICE_HISTORY_TYPE;
  onChange: (mode: SERVICE_HISTORY_TYPE) => void;
}

const ServiceHistorySelector = ({value, onChange}: Props) => {
  return (
    <div>
      <RadioGroup
        row
        aria-label="position"
        name="position"
        onChange={(e) =>
          onChange(parseInt(e.target.value) as SERVICE_HISTORY_TYPE)
        }
        defaultValue={0}
        value={value}>
        <FormControlLabel
          value={SERVICE_HISTORY_TYPE.NURSE}
          control={<Radio color="primary" />}
          label="방문간호"
          labelPlacement="end"
        />
        <FormControlLabel
          value={SERVICE_HISTORY_TYPE.CARE}
          control={<Radio color="primary" />}
          label="방문요양"
          labelPlacement="end"
        />
      </RadioGroup>
    </div>
  );
};

export default ServiceHistorySelector;
