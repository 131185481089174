import React, {useEffect, useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  ACCOUNT_TYPE,
  loadStatAll,
  loadAccountType,
} from '~/features/AccountType/slice';
import {RootState} from '~/app/rootReducer';
import _ from 'lodash';
import EtcInputForm from '~/forms/EtcInputForm';
import {AccountStatAll, AccountType, NewAccount} from '~/types/types';
import {ACCOUNTS, removeAccount, saveAccount} from '~/features/Account/slice';
import moment from 'moment';
import {Pagination} from '@material-ui/lab';
import usePagination from '~/components/Pagination';
import {
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {DEPARTMENT, loadDepartment} from '~/features/Department/slice';
import {PROFILE} from '~/features/Profile/slice';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
  backColorBlue: {
    fontSize: '0.75em',
    height: theme.spacing(5),
    backgroundColor: '#7F91F7',
  },
  backColorGray: {
    fontSize: '0.625em',
    backgroundColor: `${theme.palette.info.light}`,
  },
  table: {
    boxShadow: 'none',
    marginBottom: theme.spacing(2),
    '& > table > thead > tr > th': {
      paddingTop: 0,
      paddingBottom: 0,
      fontWeight: 400,
    },
  },
  accDiv: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(4, 0),
  },
}));

type Props = {
  accounts?: AccountType[];
};

const ETCInputScreen = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  // const accountTypes = useSelector((state: RootState) =>
  //   _.filter(state[ACCOUNT_TYPE].accountTypes, (act) => act.name !== '인건비'),
  // );
  const {accountTypes, accountStatAll} = useSelector(
    (state: RootState) => state[ACCOUNT_TYPE],
  );

  const {refresh} = useSelector((state: RootState) => state[ACCOUNTS]);

  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState(1);

  const count = Math.ceil(data.length / 10);
  const DATA = usePagination(data, 10);

  // 센터 id 가져오기
  const userId = useSelector((state: RootState) => state[PROFILE].id);
  const centerId = useSelector(
    (state: RootState) => state[DEPARTMENT].centerInfo?.centerId,
  );

  useEffect(() => {
    if (userId) {
      dispatch(loadDepartment(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (centerId) {
      dispatch(loadAccountType());
      const request = {
        centerId: centerId,
      };
      dispatch(loadStatAll(request));
    }
  }, [dispatch, centerId, refresh]);

  const handleSubmit = useCallback(
    (value: NewAccount) => {
      if (centerId) {
        if (!value.accountTypeId || !value.accountSubTypeId || !value.date) {
          alert('계정, 세부계정, 날짜를 선택해주세요.');
          //window.location.reload();
        } else {
          dispatch(
            saveAccount({
              ...value,
              amount: (value.amount as number) ? (value.amount as number) : 0,
              date: moment.tz(new Date(value.date), 'Asia/Seoul').format(), //moment(value.date).format(),
              centerId: centerId,
              memo: value.memo ? value.memo : '-',
            }),
          );
          alert('입력이 완료되었습니다.');
          window.location.reload();
        }
      }
    },
    [dispatch, centerId],
  );

  const handleChange = (event: any, value: number) => {
    setPage(value);
    DATA.jump(value);
  };

  const renderAccountTypeTable = useCallback(() => {
    const handleRemove = (id: number) => {
      dispatch(removeAccount({id}));
    };

    return (
      <TableContainer className={classes.table} style={{minHeight: 309}}>
        <Table aria-label="simple table">
          <TableHead className={classes.backColorBlue}>
            <TableRow>
              <TableCell align="center">추가일</TableCell>
              <TableCell colSpan={2} align="center">
                구분
              </TableCell>
              <TableCell align="center">금액</TableCell>
              <TableCell align="center">메모</TableCell>
              <TableCell align="center">삭제</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(DATA.currentData(), (acc: AccountStatAll, idx) => {
              return (
                <TableRow key={idx}>
                  <TableCell style={{fontSize: '0.75em'}} align="center">
                    {`${acc.date}`}
                  </TableCell>
                  <TableCell style={{fontSize: '0.75em'}} align="center">
                    {`${acc.accountName}`}
                  </TableCell>
                  <TableCell style={{fontSize: '0.75em'}} align="center">
                    {`${acc.accountSubName}`}
                  </TableCell>
                  <TableCell style={{fontSize: '0.75em'}} align="center">
                    {`${acc.amount ? acc.amount : 0}`}
                  </TableCell>
                  <TableCell style={{fontSize: '0.75em'}} align="center">
                    {`${acc.memo ? acc.memo : '-'}`}
                  </TableCell>
                  <TableCell style={{fontSize: '0.75em'}} align="center">
                    <CloseIcon
                      fontSize="small"
                      onClick={() => handleRemove(acc.id)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [DATA, classes.backColorBlue, classes.table, dispatch]);

  return (
    <div>
      <div>
        <EtcInputForm accounts={accountTypes} onSubmit={handleSubmit} />
      </div>
      <div className={classes.accDiv}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            계정:
            <Select
              fullWidth
              required
              autoFocus={true}
              onChange={(e: any) => {
                setData(
                  _.sortBy(accountStatAll, 'date')
                    .reverse()
                    .filter((acc) => acc.accountTypeId === e.target.value),
                );
              }}>
              {_.map(accountTypes, (act) => (
                <MenuItem key={`account-${act.id}`} value={act.id}>
                  {act.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </div>
      {renderAccountTypeTable()}
      <div className={classes.pagination}>
        <Pagination count={count} page={page} onChange={handleChange} />
      </div>
    </div>
  );
};

export default ETCInputScreen;
