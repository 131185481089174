import React, {useState, useEffect} from 'react';
import {makeStyles, TextField, Button} from '@material-ui/core';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {months, StatService, STAT_SCALE, TotalStat} from '~/types/types';
import {RootState} from '~/app/rootReducer';
import _ from 'lodash';
import withStatTemplate, {StatProps} from '~/hoc/withStatTemplate';
import {
  loadSchStatGrade,
  requestSchSearch,
  requestSchTotals,
  SCHEDULES_STAT,
} from '~/features/ScheduleStat/slice';
import {
  DUTIES_STAT,
  loadDutyStatGrade,
  requestGradeCount,
  requestDutySearch,
  loadDutyTotals,
} from '~/features/DutyStat/slice';
import {PROFILE} from '~/features/Profile/slice';
import {DEPARTMENT, loadDepartment} from '~/features/Department/slice';
import PatientStatByGrade from '~/components/PatientStat';
import {handleTotalGraph} from '~/utils';
const useStyles = makeStyles((theme) => ({
  flex1: {
    flex: 1,
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  helpMark: {
    display: 'flex',
    marginTop: theme.spacing(7.75),
    alignItems: 'center',
    fontSize: '1em',
  },
}));

// const PAT_N: {[key: string]: {[key: string]: number}} = {
//   '2021-01': {1: 2201570, 2: 1621280, 3: 1103180, 4: 601130, 5: 419640},

//   '2021-02': {1: 1679710, 2: 1675720, 3: 1158300, 4: 592960, 5: 339040},

//   '2021-03': {1: 1485760, 2: 1849420, 3: 828320, 4: 1532690, 5: 461220},

//   '2021-04': {1: 1597400, 2: 2498870, 3: 1136180, 4: 971440, 5: 415410},

//   '2021-05': {1: 1276610, 2: 3675170, 3: 1666000, 4: 302170, 5: 439970},

//   '2021-06': {1: 983440, 2: 2493910, 3: 1741020, 4: 302170, 5: 296170},

//   '2021-07': {1: 1222140, 2: 2127070, 3: 1768950, 4: 436600, 5: 240020},

//   '2021-08': {1: 1441780, 2: 1940980, 3: 2344410, 4: 286860, 5: 168360},

//   '2021-09': {1: 1035680, 2: 1359540, 3: 1680890, 4: 1091260, 5: 327410},

//   '2021-10': {1: 1173110, 2: 934200, 3: 2211160, 4: 391100, 5: 156050},

//   '2021-11': {1: 2393610, 2: 897700, 3: 2467415, 4: 280860, 5: 110240},
// };

// const PAT_C: {[key: string]: {[key: string]: number}} = {
//   '2021-01': {1: 121480, 2: 452800, 3: 0, 4: 2149850, 5: 0},

//   '2021-02': {1: 0, 2: 452800, 3: 0, 4: 1363430, 5: 0},

//   '2021-03': {1: 0, 2: 452800, 3: 0, 4: 1470240, 5: 0},

//   '2021-04': {1: 0, 2: 452800, 3: 0, 4: 1612890, 5: 0},

//   '2021-05': {1: 0, 2: 645480, 3: 0, 4: 1180170, 5: 0},

//   '2021-06': {1: 0, 2: 886330, 3: 0, 4: 1189190, 5: 0},

//   '2021-07': {1: 922180, 2: 452800, 3: 722550, 4: 1183560, 5: 0},

//   '2021-08': {1: 982890, 2: 452800, 3: 674380, 4: 1186450, 5: 0},

//   '2021-09': {1: 936380, 2: 452800, 3: 963400, 4: 1111460, 5: 0},

//   '2021-10': {1: 1649130, 2: 452800, 3: 963400, 4: 2071320, 5: 0},

//   '2021-11': {1: 530660, 2: 452800, 3: 1059740, 4: 1186450, 5: 0},
// };

const PatientStatScreen = ({statScale, grade, date}: StatProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [search, setSearch] = useState(false);
  const [patientName, setPatientName] = useState('');
  const [gradeName, setGradeName] = useState('1등급');
  const [data, setData] = useState<
    ReadonlyArray<{
      [key: string]: string | number;
    }>
  >();
  const [total, setTotal] = useState<
    ReadonlyArray<{
      [key: string]: string | number;
    }>[]
  >([]);
  const [statics, setStatics] = useState<StatService>({
    nurse_plan: 0,
    nurse_real: 0,
    care_plan: 0,
    care_real: 0,
    //bath_plan: 0,
    //bath_real: 0,
    total_plan: 0,
    total_real: 0,
    cnt: 0,
  });
  const [totalStat, setTotalStat] = useState<StatService[]>([]);

  const grade_sch = useSelector(
    (state: RootState) => state[SCHEDULES_STAT].priceByGrades,
  );
  const {schTotals, schSearch} = useSelector(
    (state: RootState) => state[SCHEDULES_STAT],
  );
  const grade_duty = useSelector(
    (state: RootState) => state[DUTIES_STAT].priceByGrades,
  );
  const patientCnt = useSelector(
    (state: RootState) => state[DUTIES_STAT].count,
  );
  const dutySearch = useSelector(
    (state: RootState) => state[DUTIES_STAT].search,
  );
  const dutyTotals = useSelector(
    (state: RootState) => state[DUTIES_STAT].dutyTotals,
  );
  // 센터 정보 가져오기
  const userId = useSelector((state: RootState) => state[PROFILE].id);
  const centerId = useSelector(
    (state: RootState) => state[DEPARTMENT].centerInfo?.centerId,
  );

  const totalGrade = [
    '1등급',
    '2등급',
    '3등급',
    '4등급',
    '5등급',
    '인지지원등급',
  ];

  // 해당 일/월에 대하여 각 서버스의 수가 리턴
  const handleService = (arr: any, serviceType: string) => {
    let value = 0;
    const tmp: any = _.keys(arr).map((a) => {
      const tmp2: any = arr[a];
      return tmp2.service === serviceType ? tmp2.price : 0;
    });
    _.map(tmp, (t) => (t !== 0 ? (value = t) : undefined));
    return value;
  };

  useEffect(() => {
    if (userId) {
      dispatch(loadDepartment(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (centerId && grade) {
      const request = {
        scale: statScale,
        datetime: moment(date),
        grade: grade,
        centerId: centerId,
      };
      if (grade === 8) {
        dispatch(requestSchTotals(request));
        dispatch(loadDutyTotals(request));
      } else if (grade > 0 && grade < 7) {
        dispatch(loadSchStatGrade(request));
        dispatch(loadDutyStatGrade(request));
        dispatch(requestGradeCount(request));
        setSearch(false);
      }
    }
  }, [dispatch, date, statScale, grade, centerId]);

  // 전체 등급 조회에서 그래프용 데이터 처리하는 함수 (매출실적만 그림)

  // 전체 등급 조회에서 표 그리기용 데이터 처리하는 함수
  const handleTotalGrade = (
    sData: TotalStat[],
    dData: TotalStat[],
    grade: number,
  ) => {
    // 해당 등급 데이터 추출
    const schData = _.filter(sData, (s) => s.grade === grade);
    const dutyData = _.filter(dData, (d) => d.grade === grade);
    // 해당 등급에 환자 수 (매출 건에 대해서만)
    const dutyCnt = _.uniqBy(dutyData, 'name').length;
    // 해당 등급에 대한 서비스별 금액 계획 (방문간호, 방문요양, 방문목욕)
    const np = _.sum(
      _.map(
        _.filter(schData, (s) => s.service === '방문간호'),
        (f) => f.price,
      ),
    );
    const cp = _.sum(
      _.map(
        _.filter(schData, (s) => s.service === '방문요양'),
        (f) => f.price,
      ),
    );
    // const bp = _.sum(
    //   _.map(
    //     _.filter(schData, (s) => s.service === '방문목욕'),
    //     (f) => f.price,
    //   ),
    // );

    // 해당 등급에 대한 서비스별 금액 매출 (방문간호, 방문요양, 방문목욕)
    const nr = _.sum(
      _.map(
        _.filter(dutyData, (d) => d.service === '방문간호'),
        (f) => f.price,
      ),
    );
    const cr = _.sum(
      _.map(
        _.filter(dutyData, (d) => d.service === '방문요양'),
        (f) => f.price,
      ),
    );
    // const br = _.sum(
    //   _.map(
    //     _.filter(dutyData, (d) => d.service === '방문목욕'),
    //     (f) => f.price,
    //   ),
    // );

    return {
      nurse_plan: np,
      nurse_real: nr,
      care_plan: cp,
      care_real: cr,
      //bath_plan: bp,
      //bath_real: br,
      total_plan: np + cp,
      total_real: nr + cr,
      cnt: dutyCnt,
    };
  };

  // 전체 등급 조회
  // grade 구분 없이 가져와서 grade 별로 정리(sch, duty에 대해서)
  useEffect(() => {
    if (schTotals && dutyTotals) {
      // 그래프 그리이용 등급 별 데이터 추출
      const g1 = handleTotalGraph(date, statScale, dutyTotals, 1);
      const g2 = handleTotalGraph(date, statScale, dutyTotals, 2);
      const g3 = handleTotalGraph(date, statScale, dutyTotals, 3);
      const g4 = handleTotalGraph(date, statScale, dutyTotals, 4);
      const g5 = handleTotalGraph(date, statScale, dutyTotals, 5);
      const g6 = handleTotalGraph(date, statScale, dutyTotals, 6);

      setTotal([g1, g2, g3, g4, g5, g6]);

      // 표 그리기용 등급 별 데이터 추출
      const chart1: StatService = handleTotalGrade(schTotals, dutyTotals, 1);
      const chart2: StatService = handleTotalGrade(schTotals, dutyTotals, 2);
      const chart3: StatService = handleTotalGrade(schTotals, dutyTotals, 3);
      const chart4: StatService = handleTotalGrade(schTotals, dutyTotals, 4);
      const chart5: StatService = handleTotalGrade(schTotals, dutyTotals, 5);
      const chart6: StatService = handleTotalGrade(schTotals, dutyTotals, 6);

      setTotalStat([chart1, chart2, chart3, chart4, chart5, chart6]);
    }
  }, [schTotals, dutyTotals, date, statScale]);

  useEffect(() => {
    if (grade === 1) {
      setGradeName('1등급');
    } else if (grade === 2) {
      setGradeName('2등급');
    } else if (grade === 3) {
      setGradeName('3등급');
    } else if (grade === 4) {
      setGradeName('4등급');
    } else if (grade === 5) {
      setGradeName('5등급');
    } else if (grade === 6) {
      setGradeName('인지지원등급');
    } else {
      setGradeName('전체');
    }
  }, [dispatch, grade]);

  // const [nMatched, nIncome, cMatched, cIncome] = useMemo(() => {
  //   let day = moment(date).format('yyyy-MM');

  //   let ninc = grade !== undefined ? PAT_N[day]?.[grade + 1] : undefined;
  //   let nmat = grade !== undefined ? ninc === statics.nurse_real : false;

  //   let cinc = grade !== undefined ? PAT_C[day]?.[grade + 1] : undefined;
  //   let cmat = grade !== undefined ? cinc === statics.care_real : false;

  //   return [nmat, ninc, cmat, cinc];
  // }, [date, grade, statics]);

  // 등급별 데이터
  useEffect(() => {
    // 하단의 표에서 월별 연별 매출 계산
    if (grade_sch && grade_duty) {
      if (statScale === STAT_SCALE.WEEK) {
        const allData = {
          np: _.keys(grade_sch).map((d) => {
            return handleService(grade_sch[d], '방문간호');
          }),
          cp: _.keys(grade_sch).map((d) => {
            return handleService(grade_sch[d], '방문요양');
          }),
          // bp: _.keys(grade_sch).map((d) => {
          //   return handleService(grade_sch[d], '방문목욕');
          // }),
          nr: _.keys(grade_duty).map((d) => {
            return handleService(grade_duty[d], '방문간호');
          }),
          cr: _.keys(grade_duty).map((d) => {
            return handleService(grade_duty[d], '방문요양');
          }),
          // br: _.keys(grade_duty).map((d) => {
          //   return handleService(grade_duty[d], '방문목욕');
          // }),
        };
        setStatics({
          nurse_plan: _.sum(_.values(allData.np)),
          care_plan: _.sum(_.values(allData.cp)),
          //bath_plan: _.sum(_.values(allData.bp)),
          nurse_real: _.sum(_.values(allData.nr)),
          care_real: _.sum(_.values(allData.cr)),
          //bath_real: _.sum(_.values(allData.br)),
          total_plan: _.sum([
            _.sum(_.values(allData.np)),
            _.sum(_.values(allData.cp)),
            // _.sum(_.values(allData.bp)),
          ]),
          total_real: _.sum([
            _.sum(_.values(allData.nr)),
            _.sum(_.values(allData.cr)),
            // _.sum(_.values(allData.br)),
          ]),
          cnt: 0,
        });
      } else {
        const allData = {
          name: months.map((m) => m),
          np: _.keys(grade_sch).map((d) => {
            return handleService(grade_sch[d], '방문간호');
          }),
          cp: _.keys(grade_sch).map((d) => {
            return handleService(grade_sch[d], '방문요양');
          }),
          // bp: _.keys(grade_sch).map((d) => {
          //   return handleService(grade_sch[d], '방문목욕');
          // }),
          nr: _.keys(grade_duty).map((d) => {
            return handleService(grade_duty[d], '방문간호');
          }),
          cr: _.keys(grade_duty).map((d) => {
            return handleService(grade_duty[d], '방문요양');
          }),
          // br: _.keys(grade_duty).map((d) => {
          //   return handleService(grade_duty[d], '방문목욕');
          // }),
        };
        setStatics({
          nurse_plan: _.sum(_.values(allData.np)),
          care_plan: _.sum(_.values(allData.cp)),
          //bath_plan: _.sum(_.values(allData.bp)),
          nurse_real: _.sum(_.values(allData.nr)),
          care_real: _.sum(_.values(allData.cr)),
          //bath_real: _.sum(_.values(allData.br)),
          total_plan: _.sum([
            _.sum(_.values(allData.np)),
            _.sum(_.values(allData.cp)),
            //_.sum(_.values(allData.bp)),
          ]),
          total_real: _.sum([
            _.sum(_.values(allData.nr)),
            _.sum(_.values(allData.cr)),
            //_.sum(_.values(allData.br)),
          ]),
          cnt: 0,
        });
      }
    }

    // 차트 그리기용
    let ret: any = {};
    if (grade_sch && grade_duty) {
      if (statScale === STAT_SCALE.WEEK) {
        setData(
          _.keys(grade_sch).map((d) => {
            ret = {
              name: `${parseInt(d.slice(8, 10))} 일`,
              방문간호실적: handleService(grade_duty[d], '방문간호'),
              방문요양실적: handleService(grade_duty[d], '방문요양'),
              //방문목욕실적: handleService(grade_duty[d], '방문목욕'),
            };
            return ret;
          }),
        );
      } else {
        setData(
          _.keys(grade_sch).map((d, idx) => {
            const tmpDate = `${d.slice(0, 4)}-${d.slice(5, 7)}`;
            ret = {
              name: months[idx],
              방문간호실적: handleService(grade_duty[tmpDate], '방문간호'),
              방문요양실적: handleService(grade_duty[tmpDate], '방문요양'),
              //방문목욕실적: handleService(grade_duty[tmpDate], '방문목욕'),
            };
            return ret;
          }),
        );
      }
    }
  }, [statScale, grade_sch, grade_duty]);

  // 검색했을 경우
  useEffect(() => {
    // 하단의 표에서 월별 연별 매출 계산
    if (schSearch && dutySearch) {
      if (statScale === STAT_SCALE.WEEK) {
        const allData = {
          np: _.keys(schSearch).map((d) => {
            return handleService(schSearch[d], '방문간호');
          }),
          cp: _.keys(schSearch).map((d) => {
            return handleService(schSearch[d], '방문요양');
          }),
          // bp: _.keys(schSearch).map((d) => {
          //   return handleService(schSearch[d], '방문목욕');
          // }),
          nr: _.keys(dutySearch).map((d) => {
            return handleService(dutySearch[d], '방문간호');
          }),
          cr: _.keys(dutySearch).map((d) => {
            return handleService(dutySearch[d], '방문요양');
          }),
          // br: _.keys(dutySearch).map((d) => {
          //   return handleService(dutySearch[d], '방문목욕');
          // }),
        };
        setStatics({
          nurse_plan: _.sum(_.values(allData.np)),
          care_plan: _.sum(_.values(allData.cp)),
          // bath_plan: _.sum(_.values(allData.bp)),
          nurse_real: _.sum(_.values(allData.nr)),
          care_real: _.sum(_.values(allData.cr)),
          // bath_real: _.sum(_.values(allData.br)),
          total_plan: _.sum([
            _.sum(_.values(allData.np)),
            _.sum(_.values(allData.cp)),
            // _.sum(_.values(allData.bp)),
          ]),
          total_real: _.sum([
            _.sum(_.values(allData.nr)),
            _.sum(_.values(allData.cr)),
            // _.sum(_.values(allData.br)),
          ]),
          cnt: 0,
        });
      } else {
        const allData = {
          name: months.map((m) => m),
          np: _.keys(schSearch).map((d) => {
            return handleService(schSearch[d], '방문간호');
          }),
          cp: _.keys(schSearch).map((d) => {
            return handleService(schSearch[d], '방문요양');
          }),
          // bp: _.keys(schSearch).map((d) => {
          //   return handleService(schSearch[d], '방문목욕');
          // }),
          nr: _.keys(dutySearch).map((d) => {
            return handleService(dutySearch[d], '방문간호');
          }),
          cr: _.keys(dutySearch).map((d) => {
            return handleService(dutySearch[d], '방문요양');
          }),
          // br: _.keys(dutySearch).map((d) => {
          //   return handleService(dutySearch[d], '방문목욕');
          // }),
        };
        setStatics({
          nurse_plan: _.sum(_.values(allData.np)),
          care_plan: _.sum(_.values(allData.cp)),
          //bath_plan: _.sum(_.values(allData.bp)),
          nurse_real: _.sum(_.values(allData.nr)),
          care_real: _.sum(_.values(allData.cr)),
          //bath_real: _.sum(_.values(allData.br)),
          total_plan: _.sum([
            _.sum(_.values(allData.np)),
            _.sum(_.values(allData.cp)),
            //_.sum(_.values(allData.bp)),
          ]),
          total_real: _.sum([
            _.sum(_.values(allData.nr)),
            _.sum(_.values(allData.cr)),
            //_.sum(_.values(allData.br)),
          ]),
          cnt: 0,
        });
      }
    }

    // 차트 그리기용
    let ret: any = {};
    if (schSearch && dutySearch) {
      if (statScale === STAT_SCALE.WEEK) {
        setData(
          _.keys(schSearch).map((d) => {
            ret = {
              name: `${parseInt(d.slice(8, 10))} 일`,
              방문간호실적: handleService(dutySearch[d], '방문간호'),
              방문요양실적: handleService(dutySearch[d], '방문요양'),
              //방문목욕실적: handleService(dutySearch[d], '방문목욕'),
            };
            return ret;
          }),
        );
      } else {
        setData(
          _.keys(schSearch).map((d, idx) => {
            const tmpDate = `${d.slice(0, 4)}-${d.slice(5, 7)}`;
            ret = {
              name: months[idx],
              방문간호실적: handleService(dutySearch[tmpDate], '방문간호'),
              방문요양실적: handleService(dutySearch[tmpDate], '방문요양'),
              //방문목욕실적: handleService(dutySearch[tmpDate], '방문목욕'),
            };
            return ret;
          }),
        );
      }
    }
  }, [schSearch, dutySearch, statScale]);

  const handleSearch = () => {
    if (centerId && grade === 7) {
      const request = {
        scale: statScale,
        datetime: moment(date),
        centerId: centerId,
        name: patientName,
      };

      dispatch(requestSchSearch(request));
      dispatch(requestDutySearch(request));
    }
    setSearch(true);
  };

  return (
    <div>
      {grade === 7 ? (
        <>
          <div className={classes.flex1}>
            <TextField
              style={{
                marginRight: 20,
                maxWidth: 400,
              }}
              label="수급자이름 또는 인정번호"
              fullWidth
              value={patientName}
              onKeyPress={handleSearch}
              variant="outlined"
              onChange={(e) => setPatientName(e.target.value)}
            />
            <Button
              style={{
                width: 100,
              }}
              variant="contained"
              color="primary"
              onClick={handleSearch}>
              검색
            </Button>
          </div>
          <br />
        </>
      ) : (
        <></>
      )}

      {grade === 8 ? (
        <>
          {_.zip(_.map(total), _.map(totalStat)).map((item, idx) => {
            return (
              <React.Fragment key={idx}>
                <div className={classes.helpMark}>
                  <span style={{fontWeight: 500}}>{`${totalGrade[idx]}`}</span>
                </div>
                <PatientStatByGrade
                  data={item[0]}
                  search={search}
                  patientName={''}
                  gradeName={totalGrade[idx]}
                  patientCnt={item[1] ? item[1].cnt : patientCnt}
                  statics={item[1] ? item[1] : statics}
                />
              </React.Fragment>
            );
          })}
        </>
      ) : (
        // 각 등급 별 그래프 및 표
        <PatientStatByGrade
          data={data}
          search={search}
          patientName={patientName}
          gradeName={gradeName}
          patientCnt={patientCnt}
          statics={statics}
        />
      )}
    </div>
  );
};

export default withStatTemplate(PatientStatScreen, {
  title: '등급별 현황',
  grade: true,
  search: true,
  pstat: true,
});
