import React, {
  CSSProperties,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  makeStyles,
  Theme,
  createStyles,
  TextField,
  Divider,
  Button,
  Modal,
} from '@material-ui/core';
import {
  Field,
  InjectedFormProps,
  reduxForm,
  WrappedFieldProps,
} from 'redux-form';
import {useHistory, useLocation} from 'react-router';
import {Typography} from '~/components/Typography';
import {
  HISTORY,
  initHistories,
  onClickHistories,
} from '~/features/History/slice';
import {RootState} from '~/app/rootReducer';
import _ from 'lodash';
import submit from '~/components/Submit';
import RemoteSubmitButton from '~/components/RemoteSubmitButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    body: {
      justifyContent: 'flex-start',
      flex: 1,
    },
    title: {
      display: 'flex',
      marginTop: theme.spacing(1) + 1,
      justifyContent: 'center',
      marginBottom: 32,
    },
    form: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      minWidth: 320,
      padding: '0px 15px',
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      marginBottom: 5,
      justifyContent: 'space-between',
    },
    contentText: {
      width: '100%',
      fontSize: '1em',
    },
    fieldDiv: {
      display: 'flex',
      alignItems: 'center',
    },
    field: {
      height: 42,
      minWidth: 107,
      margin: '0px 10px 0px 40px',
      '& > .MuiInputBase-root': {
        height: 42,
      },
      '& > div > input': {
        textAlign: 'right',
      },
    },
    divider: {
      width: '100%',
      borderTop: '1px solid rgba(0, 0, 0, 0.23)',
      marginBottom: 5,
    },
    minute: {
      fontSize: '1em',
    },
    load: {
      width: '100%',
      height: 42,
      marginBottom: theme.spacing(1) - 1,
      fontSize: '0.75em',
    },
    buttons: {
      width: '100%',
      height: 42,
      display: 'flex',
    },
    cancel: {
      display: 'inline',
      width: '100%',
      fontSize: '0.9375em',
    },
    submit: {
      display: 'inline',
      width: '100%',
      marginLeft: theme.spacing(1) + 6,
      fontSize: '0.938em',
    },
    modalBack: {
      width: 276,
      height: 140,
      background: '#F6F6F6',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      boxSizing: 'border-box',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    modalBtn: {
      display: 'inline',
      width: 100,
      height: 42,
      fontSize: '0.938em',
    },
  }),
);

interface IInputProps {
  style?: CSSProperties;
}

const renderTextField = (
  field: WrappedFieldProps &
    InputHTMLAttributes<HTMLInputElement> &
    IInputProps,
) => {
  const {input, defaultValue, type, placeholder, className, style} = field;
  return (
    <TextField
      className={className}
      variant="outlined"
      margin="normal"
      fullWidth
      defaultValue={defaultValue}
      placeholder={placeholder}
      type={type}
      style={style}
      {...input}
    />
  );
};

export type HistoryList = {
  name: string | undefined;
  time: string | undefined;
};

export type LocationType = {
  mode: string;
  key: number;
  date: string;
  id: number;
};

const MHistoryForm = (props: InjectedFormProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<LocationType>().state;
  // history는 환자의 하위 테이블이기 때문에 어차피 여기에 다 불려와짐. 히스토리 버튼 누르면 이걸로 hisotry 처리하면 될 듯
  // const {currentPatient} = useSelector((state: RootState) => state[EMPLOYEES]);
  const {handleSubmit} = props;
  const [open, setOpen] = React.useState(false);
  const [children, setChildren] = useState<HistoryList[]>([]);
  const histories = useSelector((state: RootState) => state[HISTORY].histories);

  useEffect(() => {
    if (location) {
      try {
        const detail = _.filter(
          histories,
          (his) => his.key === location.key,
        )[0];
        // 오름차순 정렬 재정리
        // order 값 추가
        const newChildren = _.map(detail.children, (child) => {
          return {...child, order: parseInt(child.time.slice(7, 9)) + 100};
        });

        setChildren(newChildren);
      } catch (error) {
        history.push('/');
      }
    }
  }, [location, histories, history]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div className={classes.modalBack}>
        <div
          style={{
            fontSize: '0.875em',
            marginTop: 26,
            marginBottom: 30,
          }}>
          {'저장 하시겠습니까?'}
        </div>
        <div style={{display: 'flex'}}>
          <Button
            onClick={handleClose}
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.modalBtn}>
            취소
          </Button>
          <RemoteSubmitButton mode="mhistory" />
        </div>
      </div>
    </div>
  );

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit}>
      {_.sortBy(children, 'order').map((list, idx) => {
        return (
          <div key={idx} style={{width: '100%'}}>
            <div className={classes.content}>
              <Typography style={{width: '100%'}} variant="Small">
                {list.name}
              </Typography>
              <div className={classes.fieldDiv}>
                <Field
                  name={list.time}
                  className={classes.field}
                  variant="outlined"
                  component={renderTextField}
                  type="text"
                />
                <span className={classes.minute}>분</span>
              </div>
            </div>
            <Divider className={classes.divider} />
          </div>
        );
      })}
      <Divider
        style={{
          width: '100%',
          borderTop: '1px solid rgba(0, 0, 0, 0.23)',
          margin: '69px 0px 5px',
        }}
      />
      <Button
        onClick={() => {
          // history slice의 loading state를 변경하여 screen에서 값 불러오도록 함
          dispatch(onClickHistories());
        }}
        variant="contained"
        color="primary"
        className={classes.load}>
        수급자 히스토리 불러오기
      </Button>
      <div className={classes.buttons}>
        <Button
          onClick={() => {
            dispatch(initHistories());
            history.push({pathname: '/history', state: {date: location.date}});
          }}
          variant="contained"
          color="secondary"
          className={classes.cancel}>
          취소
        </Button>
        <Button
          //type="submit"
          onClick={handleOpen}
          value={location.key}
          variant="contained"
          color="primary"
          className={classes.submit}>
          완료
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          {body}
        </Modal>
      </div>
    </form>
  );
};

export default reduxForm({
  // a unique name for the form
  form: 'mhistory',
  onSubmit: submit,
  enableReinitialize: true,
})(MHistoryForm);
