import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CheckCenterName, NewUser, Payload} from '~/types/types';

type SignUpState = {
  loading: boolean;
  payload: Payload | undefined;
  error: boolean | undefined;
  checked: string[];
} & NewUser;

let initialState: SignUpState = {
  loading: false,
  email: undefined,
  name: undefined,
  password: undefined,
  payload: undefined,
  error: undefined,
  caregiver: undefined,
  phone: undefined,
  checked: [],
};

const signUpSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    signUpRequest: (state, action: PayloadAction<NewUser>) => {
      const {email, name, caregiver, phone, password} = action.payload;
      state.loading = true;
      state.email = email;
      state.name = name;
      state.password = password;
      state.caregiver = caregiver;
      state.phone = phone;
      state.error = false;
    },
    signUpSuccess: (state) => {
      state.loading = false;
      state.email = undefined;
      state.name = undefined;
      state.password = undefined;
      state.caregiver = undefined;
      state.phone = undefined;
      state.error = false;
      state.checked = [];
    },
    signUpFailed: (state) => {
      state.loading = false;
      state.error = true;
    },
    saveCheckCenterName: (state, action: PayloadAction<CheckCenterName>) => {
      state.checked = state.checked.concat(action.payload.name);
    },
    removeCheckCenterName: (state, action: PayloadAction<CheckCenterName>) => {
      state.checked = state.checked.filter(
        (elem) => elem !== action.payload.name,
      );
    },
  },
});

export const {
  signUpRequest,
  signUpSuccess,
  signUpFailed,
  saveCheckCenterName,
  removeCheckCenterName,
} = signUpSlice.actions;
export const SIGNUP = signUpSlice.name;
export default signUpSlice.reducer;
