import React, {useState, useCallback, useEffect} from 'react';
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Theme,
} from '@material-ui/core';
import {useTheme, withStyles} from '@material-ui/styles';
import {useDispatch, useSelector} from 'react-redux';
import {STAT_SCALE, SERVICE_TYPE, WAGE_MINIMUM_RATIO} from '~/types/types';
import {RootState} from '~/app/rootReducer';
import {
  BEP,
  loadDutyStat,
  loadEtcWageStat,
  loadWageStat,
} from '~/features/BEP/slice';
import moment from 'moment';
import _ from 'lodash';
import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar,
  Tooltip,
  Line,
} from 'recharts';
import withStatTemplate, {StatProps} from '~/hoc/withStatTemplate';
import {renderDateTableHeader_2} from '~/utils';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import {Typography} from '~/components/Typography';
import {PROFILE} from '~/features/Profile/slice';
import {DEPARTMENT, loadDepartment} from '~/features/Department/slice';
import {Detail} from 'react-calendar';

const TableCell = withStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.info.dark}`,
    '&.MuiTableCell-head': {
      fontSize: '0.75em',
    },
    minWidth: 99.64,
    height: 28,
    paddingTop: 0,
    paddingBottom: 0,
  },
}))(MuiTableCell);

const TableRow = withStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.info.dark}`,
  },
}))(MuiTableRow);

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
  },
  chartContainer: {
    width: '100%',
    flexGrow: 1,
    height: 436,
    margin: theme.spacing(4.375, 0, 2),
  },
  chartLegend: {
    '& > div > svg > path': {
      fill: 'none',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper': {
      top: '0px !important',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend > .recharts-legend-item': {
      display: 'flex !important',
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '0.75em',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend > li > svg': {
      width: 50,
      height: 11,
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend > li:nth-child(-n+2) > svg > path': {
      d: 'path("M-50,0h146v32h-146z")',
    },
  },
  tooltip: {
    padding: theme.spacing(2.5, 1.875, 1.125),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: theme.spacing(0.5),
  },
  tooltip__label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 23,
    marginBottom: theme.spacing(1.125),
    fontSize: '1em',
  },
  unit: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    fontSize: '0.625em',
    marginBottom: theme.spacing(1) - 1,
  },
  backColorBlue: {
    fontSize: '0.75em',
    backgroundColor: `${theme.palette.primary.light}14`,
  },
  backColorGray: {
    fontSize: '0.625em',
    backgroundColor: `${theme.palette.info.light}`,
  },
  table: {
    boxShadow: 'none',
    '& > table > thead > tr > th': {
      paddingTop: 0,
      paddingBottom: 0,
      fontWeight: 400,
    },
  },
  tableText: {
    fontSize: '0.625em',
  },
}));

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// const CORRECT: {[key: string]: {[key: string]: number[]}} = {
//   '2021-01': {
//     방문간호: [5946800, 3289420, 3496718, -207298, 55.314],
//     방문요양: [2724130, 1816440, 2353648, -537208, 66.68],
//     사회복지사: [0, 2389080, 0, 0, 0],
//   },
//   '2021-02': {
//     방문간호: [5445730, 3084630, 3202089, -117459, 56.643],
//     방문요양: [1816230, 1311850, 1569223, -257373, 72.229],
//     사회복지사: [0, 2253140, 0, 0, 0],
//   },
//   '2021-03': {
//     방문간호: [6157410, 2907440, 3620557, -713117, 47.219],
//     방문요양: [1923040, 1482490, 1661507, -179017, 77.091],
//     사회복지사: [0, 2380430, 0, 0, 0],
//   },
//   '2021-04': {
//     방문간호: [6619300, 4338230, 3892148, 446082, 65.539],
//     방문요양: [2065690, 1441800, 1784756, -342956, 69.798],
//     사회복지사: [0, 2278600, 0, 0, 0],
//   },
//   '2021-05': {
//     방문간호: [7359920, 4182120, 4327633, -145513, 56.823],
//     방문요양: [1825650, 1302720, 1577362, -274642, 71.357],
//     사회복지사: [0, 2227690, 0, 0, 0],
//   },
//   '2021-06': {
//     방문간호: [5816710, 3997189, 3420225, 576964, 68.719],
//     방문요양: [2075520, 1512820, 1793249, -280429, 72.889],
//     사회복지사: [0, 2180360, 0, 0, 0],
//   },
//   '2021-07': {
//     방문간호: [5794780, 3502760, 3407331, 95429, 60.447],
//     방문요양: [3281090, 2332690, 2834862, -502172, 71.095],
//     사회복지사: [0, 2180360, 0, 0, 0],
//   },
//   '2021-08': {
//     방문간호: [6182390, 2300908, 3635245, -1334337, 37.217],
//     방문요양: [3296520, 2449450, 2848193, -398743, 74.304],
//     사회복지사: [0, 2287300, 0, 0, 0],
//   },
//   '2021-09': {
//     방문간호: [5494780, 2192939, 3230931, -1037992, 39.909],
//     방문요양: [3464040, 2481120, 2992931, -511811, 71.625],
//     사회복지사: [0, 2180420, 0, 0, 0],
//   },
//   '2021-10': {
//     방문간호: [4865620, 2061130, 2860985, -799855, 42.361],
//     방문요양: [5136650, 3798070, 4438066, -639996, 73.941],
//     사회복지사: [0, 2180360, 0, 0, 0],
//   },
//   '2021-11': {
//     방문간호: [6149825, 2366252, 3616097, -1249845, 38.477],
//     방문요양: [3229650, 2454990, 2790418, -335428, 76.014],
//     사회복지사: [0, 2180360, 0, 0, 0],
//   },
//   '2021-12': {
//     방문간호: [7896970, 2552100, 4643418, -2091318, 32.317],
//     방문요양: [4214315, 3302790, 3641168, -338378, 78.371],
//     사회복지사: [0, 2180840, 0, 0, 0],
//   },
// };

const WageScreen = ({date, service}: StatProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme() as Theme;

  const duty = useSelector((state: RootState) => state[BEP].duty);
  const [statScale /*setStateScale*/] = useState<STAT_SCALE>(STAT_SCALE.MONTH);
  const [viewType /*setViewType*/] = useState<Detail>('decade');
  const wages = useSelector((state: RootState) => state[BEP].wage);
  const etc = useSelector((state: RootState) => state[BEP].etc);
  const [data, setData] = useState<
    ReadonlyArray<{[key: string]: string | number}>
  >();
  const [total, setTotal] = useState<number[]>([]);

  // 센터 id 가져오기
  const userId = useSelector((state: RootState) => state[PROFILE].id);
  const centerId = useSelector(
    (state: RootState) => state[DEPARTMENT].centerInfo?.centerId,
  );

  const DataFormater = (number: number) => {
    return number.toLocaleString();
  };

  useEffect(() => {
    if (userId) {
      dispatch(loadDepartment(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (centerId) {
      const datetime = moment(date);
      const request = {
        scale: statScale,
        datetime: datetime,
        centerId: centerId,
      };

      // 직업별로 나누지 않고 전체 데이터 불러오는 api
      dispatch(loadDutyStat(request));
      dispatch(loadWageStat(request));
      dispatch(loadEtcWageStat(request));
    }
  }, [dispatch, date, statScale, centerId]);

  useEffect(() => {
    // 연간으로만 보여주기로 수정(2022.06.08)
    if (statScale === STAT_SCALE.WEEK) {
      // 월별로 보여주는 거에는 매출만 보여주는 걸로 정리됨
      // setData(
      //   _.keys(duty).map((k) => {
      //     let type = '방문간호';
      //     if (service === SERVICE_TYPE.CARE) {
      //       type = '방문요양';
      //     } else if (service === SERVICE_TYPE.BATH) {
      //       type = '방문목욕';
      //     } else if (service === SERVICE_TYPE.WELFARE) {
      //       type = '사회복지사';
      //     } else if (service === SERVICE_TYPE.ETC) {
      //       type = '기타';
      //     }
      //     const datetime = moment(k);
      //     let ret: {[key: string]: string | number} = {
      //       name: `${datetime.date()}일`,
      //     };
      //     if (duty[k]) {
      //       ret = {...ret, 매출: duty[k][type] ?? 0};
      //     }
      //     return ret;
      //   }),
      // );
    } else {
      let idx = 0;
      setData(
        _.keys(duty).map((k) => {
          let type = '방문간호';
          if (service === SERVICE_TYPE.CARE) {
            type = '방문요양';
          }
          // else if (service === SERVICE_TYPE.BATH) {
          //   type = '방문목욕';
          // }
          else if (service === SERVICE_TYPE.WELFARE) {
            type = '사회복지사';
          } else if (service === SERVICE_TYPE.ETC) {
            type = '기타';
          }
          let ret: {[key: string]: string | number} = {
            name: `${months[idx]}`,
          };
          idx += 1;
          if (duty[k]) {
            ret = {...ret, 매출: duty[k][type] ?? 0};
          }
          if (wages[k]) {
            ret = {...ret, 지급총액: wages[k][type] ?? 0};
          }
          if (duty[k] && wages[k]) {
            const minimumCost = duty[k][type] ?? 0 * WAGE_MINIMUM_RATIO[type];
            const wageCost = wages[k][type] ?? 0;
            if (minimumCost !== 0) {
              ret = {
                ...ret,
                지급비율: _.round((wageCost / minimumCost) * 100, 2),
                권고비율: WAGE_MINIMUM_RATIO[type] * 100,
              };
            }
          }
          return ret;
        }),
      );
    }
  }, [service, wages, duty, statScale]);

  const renderTableHeader = useCallback(() => {
    return renderDateTableHeader_2(viewType, wages, _.values(wages).length);
  }, [viewType, wages]);

  useEffect(() => {
    let realWage: number[] = [];
    let etcWage: number[] = [];
    let totalWage: number[] = [];
    _.values(wages).map((w) => {
      const nurse = _.has(w, '방문간호') ? _.get(w, '방문간호') : 0;
      const care = _.has(w, '방문요양') ? _.get(w, '방문요양') : 0;
      //const bath = _.has(w, '방문목욕') ? _.get(w, '방문목욕') : 0;
      const welfare = _.has(w, '사회복지사') ? _.get(w, '사회복지사') : 0;
      //realWage.push(_.sum([nurse, care, bath, welfare]));
      realWage.push(_.sum([nurse, care, welfare]));
      return null;
    });
    _.values(etc).map((e) =>
      etcWage.push(_.has(e, '기타') ? _.get(e, '기타') : 0),
    );
    _.map(realWage, (_r, idx) => totalWage.push(realWage[idx] + etcWage[idx]));
    setTotal(totalWage);
  }, [etc, wages]);

  const renderPaymentRatio = useCallback(
    (type: string) => {
      return (
        <>
          <TableRow>
            <TableCell
              align="center"
              rowSpan={5}
              className={classes.backColorBlue}>
              {type}
              <br />
              {`${(WAGE_MINIMUM_RATIO[type] ?? 0) * 100}%`}
            </TableCell>
            <TableCell align="center" className={classes.backColorGray}>
              매출
            </TableCell>
            {_.values(duty).map((d, idx) => {
              const value = _.get(d, type);
              // const day = `${date.getFullYear()}-${(idx + 1)
              //   .toString()
              //   .padStart(2, '0')}`;
              // const correct = CORRECT[day]?.[type];

              // let matched = true;
              // if (value && correct) {
              //   matched = value === correct[0];
              // }

              return (
                <TableCell
                  key={idx}
                  className={classes.tableText}
                  style={
                    {
                      // backgroundColor: matched ? 'inherit' : 'red',
                    }
                  }
                  align="center">
                  {value ? `${value.toLocaleString()}원` : '-'}
                  {/* {!matched && `(${correct[0].toLocaleString()})`} */}
                </TableCell>
              );
            })}
            <TableCell className={classes.tableText} align="center">
              {_.sum(
                _.values(duty).map((d) => {
                  return _.has(d, type) ? _.get(d, type) : 0;
                }),
              ) !== 0
                ? `${_.sum(
                    _.values(duty).map((d) => {
                      return _.has(d, type) ? _.get(d, type) : 0;
                    }),
                  ).toLocaleString()}원`
                : '-'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" className={classes.backColorGray}>
              지급총액
            </TableCell>
            {_.values(wages).map((d, idx) => {
              const value = _.get(d, type);
              // const day = `${date.getFullYear()}-${(idx + 1)
              //   .toString()
              //   .padStart(2, '0')}`;
              // const correct = CORRECT[day]?.[type];

              // let matched = true;
              // if (value && correct) {
              //   matched = value === correct[1];
              // }

              return (
                <TableCell
                  key={idx}
                  className={classes.tableText}
                  style={
                    {
                      // backgroundColor: matched ? 'inherit' : 'red',
                    }
                  }
                  align="center">
                  {value ? `${value.toLocaleString()}원` : '-'}
                  {/* {!matched && `(${correct[1].toLocaleString()})`} */}
                </TableCell>
              );
            })}
            <TableCell className={classes.tableText} align="center">
              {_.sum(
                _.values(wages).map((d) => {
                  return _.has(d, type) ? _.get(d, type) : 0;
                }),
              ) !== 0
                ? `${_.sum(
                    _.values(wages).map((d) => {
                      return _.has(d, type) ? _.get(d, type) : 0;
                    }),
                  ).toLocaleString()}원`
                : '-'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" className={classes.backColorGray}>
              권고총액
            </TableCell>
            {_.zip(_.values(duty), _.values(wages)).map((d, idx) => {
              const minimumCost = _.round(
                ((d[0] || {[type]: 0})[type] ?? 0) * WAGE_MINIMUM_RATIO[type],
              );

              // const day = `${date.getFullYear()}-${(idx + 1)
              //   .toString()
              //   .padStart(2, '0')}`;
              // const correct = CORRECT[day]?.[type];

              // let matched = true;
              // if (minimumCost && correct) {
              //   matched = minimumCost === correct[2];
              // }

              return (
                <TableCell
                  key={idx}
                  className={classes.tableText}
                  style={
                    {
                      // backgroundColor: matched ? 'inherit' : 'red',
                    }
                  }
                  align="center">
                  {minimumCost === 0
                    ? '-'
                    : `${minimumCost.toLocaleString()}원`}
                  {/* {!matched && `(${correct[2].toLocaleString()})`} */}
                </TableCell>
              );
            })}
            <TableCell className={classes.tableText} align="center">
              {_.sum(
                _.zip(_.values(duty), _.values(wages)).map((d) => {
                  return _.round(
                    ((d[0] || {[type]: 0})[type] ?? 0) *
                      WAGE_MINIMUM_RATIO[type],
                  );
                }),
              ) !== 0
                ? `${_.sum(
                    _.zip(_.values(duty), _.values(wages)).map((d) => {
                      return _.round(
                        ((d[0] || {[type]: 0})[type] ?? 0) *
                          WAGE_MINIMUM_RATIO[type],
                      );
                    }),
                  ).toLocaleString()}원`
                : '-'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" className={classes.backColorGray}>
              과부족
            </TableCell>
            {_.zip(_.values(duty), _.values(wages)).map((d, idx) => {
              const minimumCost = _.round(
                ((d[0] || {[type]: 0})[type] ?? 0) * WAGE_MINIMUM_RATIO[type],
              );
              const wageCost = (d[1] || {[type]: 0})[type] ?? 0;

              // const day = `${date.getFullYear()}-${(idx + 1)
              //   .toString()
              //   .padStart(2, '0')}`;
              // const correct = CORRECT[day]?.[type];

              // let matched = true;
              // if (minimumCost && correct) {
              //   matched = wageCost - minimumCost === correct[3];
              // }
              return (
                <TableCell
                  key={idx}
                  className={classes.tableText}
                  style={
                    {
                      // backgroundColor: matched ? 'inherit' : 'red',
                    }
                  }
                  align="center">
                  {minimumCost === 0
                    ? '-'
                    : `${(wageCost - minimumCost).toLocaleString()}원`}
                  {/* {!matched && `(${correct[3].toLocaleString()})`} */}
                </TableCell>
              );
            })}
            <TableCell className={classes.tableText} align="center">
              {_.sum(
                _.zip(_.values(duty), _.values(wages)).map((d) => {
                  return _.round(
                    ((d[0] || {[type]: 0})[type] ?? 0) *
                      WAGE_MINIMUM_RATIO[type],
                  );
                }),
              ) !== 0
                ? `${_.sum(
                    _.zip(_.values(duty), _.values(wages)).map((d) => {
                      const minimumCost = _.round(
                        ((d[0] || {[type]: 0})[type] ?? 0) *
                          WAGE_MINIMUM_RATIO[type],
                      );
                      const wageCost = (d[1] || {[type]: 0})[type] ?? 0;
                      return wageCost - minimumCost;
                    }),
                  ).toLocaleString()}원`
                : '-'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" className={classes.backColorGray}>
              지급비율
            </TableCell>
            {_.zip(_.values(duty), _.values(wages)).map((d, idx) => {
              const dutyCost = (d[0] || {[type]: 0})[type] ?? 0;
              const wageCost = (d[1] || {[type]: 0})[type] ?? 0;

              // const day = `${date.getFullYear()}-${(idx + 1)
              //   .toString()
              //   .padStart(2, '0')}`;
              // const correct = CORRECT[day]?.[type];

              // let matched = true;
              // if (dutyCost && correct) {
              //   matched =
              //     _.round((wageCost / dutyCost) * 100, 3) === correct[4];
              // }
              return (
                <TableCell
                  key={idx}
                  className={classes.tableText}
                  style={
                    {
                      // backgroundColor: matched ? 'inherit' : 'red',
                    }
                  }
                  align="center">
                  {dutyCost === 0
                    ? '-'
                    : `${((wageCost / dutyCost) * 100).toLocaleString()}%`}
                  {/* {!matched && `(${correct[4].toLocaleString()})`} */}
                </TableCell>
              );
            })}
            <TableCell className={classes.tableText} align="center">
              {_.sum(
                _.values(duty).map((d) => {
                  return _.has(d, type) ? _.get(d, type) : 0;
                }),
              ) !== 0
                ? `${(
                    (_.sum(
                      _.values(wages).map((d) => {
                        return _.has(d, type) ? _.get(d, type) : 0;
                      }),
                    ) /
                      _.sum(
                        _.values(duty).map((d) => {
                          return _.has(d, type) ? _.get(d, type) : 0;
                        }),
                      )) *
                    100
                  ).toLocaleString()}%`
                : '-'}
            </TableCell>
          </TableRow>
        </>
      );
    },
    [
      duty,
      wages,
      classes.backColorBlue,
      classes.backColorGray,
      classes.tableText,
    ],
  );

  const renderEtcRow = useCallback(
    (type: string) => {
      return (
        <>
          <TableRow>
            <TableCell
              align="center"
              colSpan={2}
              className={classes.backColorBlue}>
              {type}
            </TableCell>
            {_.values(etc).map((d, idx) => (
              <TableCell key={idx} className={classes.tableText} align="center">
                {_.has(d, type) ? `${_.get(d, type).toLocaleString()}원` : '-'}
              </TableCell>
            ))}
            <TableCell className={classes.tableText} align="center">
              {_.sum(
                _.values(etc).map((d) => {
                  return _.has(d, type) ? _.get(d, type) : 0;
                }),
              ) !== 0
                ? `${_.sum(
                    _.values(etc).map((d) => {
                      return _.has(d, type) ? _.get(d, type) : 0;
                    }),
                  ).toLocaleString()}`
                : '-'}
            </TableCell>
          </TableRow>
        </>
      );
    },
    [etc, classes.backColorBlue, classes.tableText],
  );

  const CustomTooltip = ({payload, label}: any) => {
    if (payload && payload.length) {
      return (
        <div className={classes.tooltip}>
          {payload.map((item: any, idx: number) => {
            return (
              <div key={idx} className={classes.tooltip__label}>
                <svg
                  width="24"
                  height="17"
                  viewBox="0, 0, 32 32"
                  style={{
                    display: 'inline-block',
                    marginRight: theme.spacing(5) - 2,
                    verticalAlign: 'middle',
                    marginLeft: theme.spacing(0.5),
                  }}>
                  <path
                    //stroke={'none'}
                    fill={
                      item.name === '지급비율'
                        ? '#fad44f'
                        : item.name === '권고비율'
                        ? '#4CAF50'
                        : item.fill
                    }
                    d="M0, 4h 64v 24h-64z"></path>
                </svg>
                <p
                  style={{
                    width: 81,
                    display: 'flex',
                    justifyContent: 'left',
                    marginRight: theme.spacing(1) + 2,
                  }}>{`${item.name}`}</p>
                <p
                  style={{
                    width: 115,
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {item.name === '지급비율'
                    ? `${item.value}%`
                    : item.name === '권고비율'
                    ? `${item.value}%`
                    : `${item.value.toLocaleString()}원`}
                </p>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  const renderGraph = useCallback(() => {
    // 연간으로만 보여주기로 수정(2022.06.08)
    if (statScale === STAT_SCALE.WEEK) {
      // return (
      //   <ComposedChart
      //     height={300}
      //     data={data}
      //     margin={{top: 20, right: 30, left: 20, bottom: 5}}>
      //     <CartesianGrid strokeDasharray="5 5" />
      //     <XAxis dataKey="name" />
      //     <YAxis tickFormatter={DataFormater} />
      //     <YAxis
      //       yAxisId="right"
      //       orientation="right"
      //       tickFormatter={DataFormater}
      //     />
      //     <Bar dataKey="매출" fill="#FF6384" stackId="a" />;
      //     <Tooltip content={<CustomTooltip />} />
      //     <Legend verticalAlign="top" />
      //   </ComposedChart>
      // );
    } else {
      return (
        <ComposedChart
          height={300}
          data={data}
          margin={{top: 20, right: 20, left: 40, bottom: 5}}>
          <CartesianGrid strokeDasharray="5 5" />
          <XAxis dataKey="name" />
          <YAxis tickFormatter={DataFormater} />
          <YAxis
            yAxisId="right"
            orientation="right"
            tickFormatter={DataFormater}
          />
          <Bar dataKey="매출" fill="#FF6384" stackId="a" />
          <Bar dataKey="지급총액" fill="#36A2EB" stackId="b" />
          <Line
            yAxisId="right"
            dataKey="지급비율"
            stroke="#fad44f"
            strokeWidth={2}
            activeDot={{r: 6}}
          />
          <Line
            yAxisId="right"
            dataKey="권고비율"
            stroke="#4CAF50"
            strokeWidth={3}
            activeDot={{r: 6}}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign="top" />
        </ComposedChart>
      );
    }
  }, [data, statScale]);

  return (
    <>
      <div className={classes.chartContainer}>
        <ResponsiveContainer className={classes.chartLegend}>
          {renderGraph()}
        </ResponsiveContainer>
      </div>
      <br />
      <TableContainer className={classes.table}>
        <Table aria-label="simple table">
          <TableHead className={classes.backColorBlue}>
            <TableRow>
              <TableCell align="center" colSpan={2}>
                <Typography style={{fontSize: '0.75em'}} variant="Small">
                  구분
                </Typography>
              </TableCell>
              {renderTableHeader()}
              <TableCell align="center" colSpan={2}>
                <Typography style={{fontSize: '0.75em'}} variant="Small">
                  총합
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderPaymentRatio('방문간호')}
            {renderPaymentRatio('방문요양')}
            {/* {renderPaymentRatio('방문목욕')} */}
            {renderPaymentRatio('사회복지사')}
            {renderEtcRow('기타')}
            <br />
            <TableRow>
              <TableCell align="center" colSpan={2}>
                <Typography style={{fontSize: '0.75em'}} variant="Small">
                  소계
                </Typography>
              </TableCell>
              {_.map(total, (t, idx) => (
                <TableCell
                  key={idx}
                  className={classes.tableText}
                  align="center">
                  {t !== 0 ? `${t.toLocaleString()}원` : '-'}
                </TableCell>
              ))}
              <TableCell className={classes.tableText} align="center">
                {_.sum(_.map(total, (t) => t)) !== 0
                  ? `${_.sum(_.map(total, (t) => t)).toLocaleString()}원`
                  : '-'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withStatTemplate(WageScreen, {
  search: true,
  service: true,
  wage: true,
  title: '인건비',
});
