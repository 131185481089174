import {Employee} from '~/types/types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import EmployeeSchedules from '~/models/employee';
import Patient from '~/models/patient';

type State = {
  loading: boolean;
  centerId: number;
  employees: Employee[];
  currentPatient: Patient | undefined;
} & EmployeeSchedules;

let initialState: State = {
  loading: false,
  employees: [],
  id: 0,
  name: '',
  birth: '',
  sex: '',
  code: '',
  centerId: 0,
  schedules: undefined,
  currentPatient: undefined,
};

const slice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    loadEmployee: (state) => {
      state.loading = true;
    },
    successLoadEmployee: (state, action: PayloadAction<Employee[]>) => {
      state.employees = action.payload;
    },
    loadEmployeeSchedules: (state) => {
      state.loading = true;
    },
    successLoadEmployeeSchedules: (
      state,
      action: PayloadAction<EmployeeSchedules>,
    ) => {
      const {id, name, schedules} = action.payload;
      state.loading = false;
      state.id = id;
      state.name = name;
      state.schedules = schedules;
    },
    saveCurrentSchedules: (state, action: PayloadAction<Patient>) => {
      state.currentPatient = action.payload;
    },
  },
});

export const {
  loadEmployee,
  loadEmployeeSchedules,
  successLoadEmployee,
  successLoadEmployeeSchedules,
  saveCurrentSchedules,
} = slice.actions;
export const EMPLOYEES = slice.name;
export default slice.reducer;
