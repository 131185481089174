import React from 'react';
import {STAT_JOB_TYPE} from '~/types/types';
import {RadioGroup, FormControlLabel, Radio} from '@material-ui/core';

interface Props {
  value: STAT_JOB_TYPE;
  search: boolean | undefined;
  onChange: (mode: STAT_JOB_TYPE) => void;
}

const JobSelector = ({value, search, onChange}: Props) => {
  return (
    <div>
      <RadioGroup
        row
        aria-label="position"
        name="position"
        onChange={(e) => onChange(parseInt(e.target.value) as STAT_JOB_TYPE)}
        defaultValue={0}
        value={value}>
        {/* <FormControlLabel
          value={STAT_JOB_TYPE.TOTAL}
          control={<Radio color="primary" />}
          label="전체"
          labelPlacement="end"
        /> */}
        <FormControlLabel
          value={STAT_JOB_TYPE.NURSE}
          control={<Radio color="primary" />}
          label="방문간호"
          labelPlacement="end"
        />
        <FormControlLabel
          value={STAT_JOB_TYPE.CARE}
          control={<Radio color="primary" />}
          label="방문요양"
          labelPlacement="end"
        />
        {/* <FormControlLabel
          value={STAT_JOB_TYPE.BATH}
          control={<Radio color="primary" />}
          label="방문목욕"
          labelPlacement="end"
        /> */}
        {search ? (
          <FormControlLabel
            value={STAT_JOB_TYPE.SEARCH}
            control={<Radio color="primary" />}
            label="직원검색"
            labelPlacement="end"
          />
        ) : (
          <></>
        )}
      </RadioGroup>
    </div>
  );
};

export default JobSelector;
