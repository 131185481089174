import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: 50,
    zIndex: theme.zIndex.drawer + 1,
    position: 'fixed',
    left: 0,
    bottom: 0,
    display: 'flex',
    flexGrow: 1,
    backgroundColor: `${theme.palette.primary.main}`,
  },
}));

export const WebFooter = () => {
  const classes = useStyles();
  return <Box className={classes.root}></Box>;
};
