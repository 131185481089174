export const categories = [
  {
    id: '센터운영정보',
    children: [
      {
        id: '총괄운영현황',
        linkto: '/',
      },
      {id: '인건비', linkto: '/wage'},
      {id: '운영관리비', linkto: '/operation'},
      {id: '재료비', linkto: '/material-cost'},
    ],
  },
  {
    id: '직원별 운영현황',
    children: [
      {id: '종합업무 퍼포먼스', linkto: '/performance'},
      {id: '업무스케쥴(직원용)', linkto: '/achievement'},
      // {id: '직원 관리', linkto: '/employee'},
    ],
  },
  {
    id: '수급자 관리현황',
    children: [
      {id: '등급별 현황', linkto: '/pstat'},
      {id: '업무 스케쥴(수급자용)', linkto: '/ptime'},
      {id: '처치히스토리(모바일)', linkto: '/history'},
      {id: '처치히스토리(파일)', linkto: '/history-file'},
    ],
  },
  {
    id: '재고현황',
    children: [{id: '현재 재고 현황', linkto: '/stock'}],
  },
  {
    id: '운영성과',
    children: [
      {id: 'SUMMARY', linkto: '/perf-amd'},
      {id: '목표관리', linkto: '/cost'},
    ],
  },
  {
    id: '기타',
    children: [
      {id: '파일 업로드', linkto: '/insurance'},
      {id: '기타 입력', linkto: '/etc-input'},
    ],
  },
];
