import React, {useCallback} from 'react';
import {
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Theme,
  useTheme,
  withStyles,
} from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import {PatientStatTotalProps} from '~/types/types';
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
} from 'recharts';
const TableCell = withStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.info.dark}`,
    '&.MuiTableCell-head': {
      fontSize: '0.75em',
    },
    height: 28,
    paddingTop: 0,
    paddingBottom: 0,
  },
}))(MuiTableCell);

const TableRow = withStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.info.dark}`,
  },
}))(MuiTableRow);

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    width: '100%',
    height: 522,
    marginTop: theme.spacing(4),
  },
  tooltip: {
    padding: theme.spacing(2.5, 1.875, 1.125),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: theme.spacing(0.5),
  },
  tooltip__label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 23,
    marginBottom: theme.spacing(1.125),
    fontSize: '1em',
  },
  backColorBlue: {
    fontSize: '0.75em',
    backgroundColor: `#E8EAF6`,
  },
  backColorGray: {
    fontSize: '0.625em',
    backgroundColor: `${theme.palette.info.light}`,
  },
  table: {
    boxShadow: 'none',
    '& > table > thead > tr > th': {
      padding: theme.spacing(1, 3.75),
      fontWeight: 400,
    },
  },
  tableText: {
    fontSize: '0.625em',
  },
}));

const PatientStatByGrade = (props: PatientStatTotalProps) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const {data, search, patientName, gradeName, patientCnt, statics} = props;

  const CustomTooltip = ({payload, label}: any) => {
    if (payload && payload.length) {
      return (
        <div className={classes.tooltip}>
          {payload.map((item: any, idx: number) => {
            return (
              <div key={idx} className={classes.tooltip__label}>
                <svg
                  width="24"
                  height="17"
                  viewBox="0, 0, 32 32"
                  style={{
                    display: 'inline-block',
                    marginRight: theme.spacing(5) - 2,
                    verticalAlign: 'middle',
                    marginLeft: theme.spacing(0.5),
                  }}>
                  <path
                    stroke="none"
                    fill={item.fill}
                    d="M0, 4h 64v 24h-64z"></path>
                </svg>
                <p
                  style={{
                    width: 'fit-content',
                    display: 'flex',
                    justifyContent: 'left',
                    marginRight: theme.spacing(1) + 2,
                  }}>{`${item.name}`}</p>
                <p
                  style={{
                    width: 115,
                    display: 'flex',
                    justifyContent: 'end',
                  }}>{`${item.value.toLocaleString()}원`}</p>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  const DataFormater = (number: number) => {
    return number.toLocaleString();
  };

  const renderGradeCell = useCallback(() => {
    return (
      <TableCell
        align="center"
        style={{
          fontSize: '0.75em',
        }}
        rowSpan={2}>
        {search ? `${patientName}` : `${gradeName}(${patientCnt})명`}
      </TableCell>
    );
  }, [search, patientName, gradeName, patientCnt]);

  return (
    <>
      <div className={classes.chartContainer}>
        <ResponsiveContainer>
          <BarChart
            data={data}
            margin={{
              top: 20,
              right: 20,
              left: 50,
              bottom: 5,
            }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={DataFormater} />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="방문간호실적" stackId="b" fill="#FF6384" />
            <Bar dataKey="방문요양실적" stackId="b" fill="#36A2EB" />
            {/* <Bar dataKey="방문목욕실적" stackId="b" fill="#4BC0C0" /> */}
          </BarChart>
        </ResponsiveContainer>
      </div>
      <br />
      <TableContainer className={classes.table}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.backColorBlue} align="center">
                구분
              </TableCell>
              {/* {renderTableHeader()} */}
              <TableCell className={classes.backColorBlue} align="center">
                등급(명) / 이름
              </TableCell>
              <TableCell className={classes.backColorBlue} align="center">
                매출계획
              </TableCell>
              <TableCell className={classes.backColorBlue} align="center">
                달성매출
              </TableCell>
              <TableCell className={classes.backColorBlue} align="center">
                달성률
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.backColorGray} align="center">
                방문간호
              </TableCell>
              {renderGradeCell()}
              <TableCell
                style={{
                  fontSize: '0.625em',
                }}
                align="center">
                {statics.nurse_plan !== 0
                  ? `${statics.nurse_plan.toLocaleString()}원`
                  : '-'}
              </TableCell>
              <TableCell
                style={{
                  fontSize: '0.625em',
                  // backgroundColor: nMatched ? 'inherit' : 'red',
                }}
                align="center">
                {statics.nurse_real !== 0
                  ? `${statics.nurse_real.toLocaleString()}원`
                  : '-'}

                {/* {!nMatched && `(${nIncome?.toLocaleString()})`} */}
              </TableCell>
              <TableCell
                style={{
                  fontSize: '0.625em',
                }}
                align="center">
                {statics.nurse_plan === 0
                  ? '-'
                  : `${(
                      (statics.nurse_real / statics.nurse_plan) *
                      100
                    ).toFixed(2)}%`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.backColorGray} align="center">
                방문요양
              </TableCell>
              <TableCell
                style={{
                  fontSize: '0.625em',
                }}
                align="center">
                {statics.care_plan !== 0
                  ? `${statics.care_plan.toLocaleString()}원`
                  : '-'}
              </TableCell>
              <TableCell
                style={{
                  fontSize: '0.625em',
                  // backgroundColor: cMatched ? 'inherit' : 'red',
                }}
                align="center">
                {statics.care_real !== 0
                  ? `${statics.care_real.toLocaleString()}원`
                  : '-'}
                {/* {!cMatched && `(${cIncome?.toLocaleString()})`} */}
              </TableCell>
              <TableCell
                style={{
                  fontSize: '0.625em',
                }}
                align="center">
                {statics.care_plan === 0
                  ? '-'
                  : `${((statics.care_real / statics.care_plan) * 100).toFixed(
                      2,
                    )}%`}
              </TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell className={classes.backColorGray} align="center">
                방문목욕
              </TableCell>
              <TableCell
                style={{
                  fontSize: '0.625em',
                }}
                align="center">
                {statics.bath_plan !== 0
                  ? `${statics.bath_plan.toLocaleString()}원`
                  : '-'}
              </TableCell>
              <TableCell
                style={{
                  fontSize: '0.625em',
                }}
                align="center">
                {statics.bath_real !== 0
                  ? `${statics.bath_real.toLocaleString()}원`
                  : '-'}
              </TableCell>
              <TableCell
                style={{
                  fontSize: '0.625em',
                }}
                align="center">
                {statics.bath_plan === 0
                  ? '-'
                  : `${((statics.bath_real / statics.bath_plan) * 100).toFixed(
                      2,
                    )}%`}
              </TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell
                className={classes.backColorGray}
                align="center"
                colSpan={2}>
                소계
              </TableCell>
              <TableCell
                style={{
                  fontSize: '0.625em',
                }}
                align="center">
                {statics.total_plan !== 0
                  ? `${statics.total_plan.toLocaleString()}원`
                  : '-'}
              </TableCell>
              <TableCell
                style={{
                  fontSize: '0.625em',
                }}
                align="center">
                {statics.total_real !== 0
                  ? `${statics.total_real.toLocaleString()}원`
                  : '-'}
              </TableCell>
              <TableCell
                style={{
                  fontSize: '0.625em',
                }}
                align="center">
                {statics.total_plan === 0
                  ? '-'
                  : `${(
                      (statics.total_real / statics.total_plan) *
                      100
                    ).toFixed(2)}%`}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PatientStatByGrade;
