import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface SimulValue {
  sale: number;
  expenditure: number;
}

interface State {
  //loading: boolean;
  simCnt: number;
  sale_1: number;
  sale_2: number;
  expenditure_1: number;
  expenditure_2: number;
  flag_1: number;
  flag_2: number;
  currentA: boolean;
  currentB: boolean;
}

const initialState: State = {
  //loading: false,
  simCnt: 0,
  sale_1: 0,
  sale_2: 0,
  expenditure_1: 0,
  expenditure_2: 0,
  flag_1: 0,
  flag_2: 0,
  currentA: false,
  currentB: false,
};

const slice = createSlice({
  name: 'simulation',
  initialState,
  reducers: {
    addSimCount: (state) => {
      //state.loading = true;
      state.simCnt += 1;
    },
    minSimCount: (state) => {
      state.simCnt -= 1;
    },
    addSimulation_1: (state) => {
      state.flag_1 = 1;
    },
    addSimulation_2: (state) => {
      state.flag_2 = 1;
    },
    updateCurrentSimulationA: (state) => {
      state.currentA = true;
      state.currentB = false;
    },
    updateCurrentSimulationB: (state) => {
      state.currentA = false;
      state.currentB = true;
    },
    updateSimulation_1: (state, body: PayloadAction<SimulValue>) => {
      const {sale, expenditure} = body.payload;
      state.sale_1 = sale;
      state.expenditure_1 = expenditure;
    },
    updateSimulation_2: (state, body: PayloadAction<SimulValue>) => {
      const {sale, expenditure} = body.payload;
      state.sale_2 = sale;
      state.expenditure_2 = expenditure;
    },
    initSimulation_1: (state) => {
      state.sale_1 = 0;
      state.expenditure_1 = 0;
      state.flag_1 = 0;
      state.currentA = false;
    },
    initSimulation_2: (state) => {
      state.sale_2 = 0;
      state.expenditure_2 = 0;
      state.flag_2 = 0;
      state.currentB = false;
    },
  },
});

export const {
  addSimCount,
  minSimCount,
  addSimulation_1,
  addSimulation_2,
  updateSimulation_1,
  updateSimulation_2,
  initSimulation_1,
  initSimulation_2,
  updateCurrentSimulationA,
  updateCurrentSimulationB,
} = slice.actions;
export const SIMULATION = slice.name;
export default slice.reducer;
