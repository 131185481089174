import React from 'react';
import ProfileForm from '~/forms/ProfileForm';
import {useDispatch, useSelector} from 'react-redux';

import {AppBar, makeStyles, Theme, createStyles} from '@material-ui/core';
import {RootState} from '~/app/rootReducer';
import {PROFILE, updateRequest} from '~/features/Profile/slice';
import {genSalt, hash} from 'bcryptjs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      flexGrow: 1,
    },
    body: {
      justifyContent: 'flex-start',
      flex: 1,
    },
    footer: {
      display: 'flex',
      backgroundColor: theme.palette.primary.main,
      height: 64,
      color: theme.palette.primary.contrastText,
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
  }),
);

const ProfileScreen = () => {
  const dispatch = useDispatch();
  const {token, email, caregiver, phone} = useSelector(
    (state: RootState) => state[PROFILE],
  );

  const classes = useStyles();
  const submit = (values: any) => {
    handleChangeProf(values.password, values.phone);
  };

  const handleChangeProf = async (passoword: any, new_phone: any) => {
    if (passoword) {
      const new_password = await hash(passoword, await genSalt());
      dispatch(updateRequest({token, new_password, new_phone}));
    } else {
      dispatch(updateRequest({token, new_password: undefined, new_phone}));
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static"></AppBar>
      <div className={classes.body}>
        <ProfileForm
          initialValues={{
            email: email,
            caregiver: caregiver,
            password: undefined,
            phone: phone,
          }}
          onSubmit={submit}
        />
      </div>
    </div>
  );
};

export default ProfileScreen;
