import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Button} from '@material-ui/core';
import {DropzoneArea} from 'material-ui-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducer';
import {PROFILE} from '~/features/Profile/slice';
import {DEPARTMENT, loadDepartment} from '~/features/Department/slice';

const InsuranceScreen = () => {
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  // 센터 id 가져오기
  const userId = useSelector((state: RootState) => state[PROFILE].id);
  const centerId = useSelector(
    (state: RootState) => state[DEPARTMENT].centerInfo?.centerId,
  );

  useEffect(() => {
    if (userId) {
      dispatch(loadDepartment(userId));
    }
  }, [dispatch, userId]);

  const handleFiles = (files: File[]) => {
    setSelectedFiles(files);
  };

  const handleUpload = async () => {
    if (centerId) {
      const formData = new FormData();
      formData.append('centerId', centerId.toString()); // 센터 id 정보 추가(인건비 필터를 위하여)
      selectedFiles.forEach((file, i) => {
        formData.append('files', file);
        formData.append(`names[${i}]`, encodeURIComponent(file.name));
      });
      await axios.post('/allowance', formData);
    }
    // alert('파일 업로드가 완료되었습니다.');
    // window.location.reload();
  };

  return (
    <div>
      <DropzoneArea
        onChange={handleFiles}
        dropzoneText="엑셀파일 업로드"
        filesLimit={30}
        acceptedFiles={[
          'text/xml',
          'application/xml',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet ',
        ]}
      />
      <Button
        variant="contained"
        color="primary"
        startIcon={<CloudUploadIcon />}
        onClick={handleUpload}>
        선택된 파일 업로드
      </Button>
    </div>
  );
};

export default InsuranceScreen;
