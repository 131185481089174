import React, {
  InputHTMLAttributes,
  CSSProperties,
  useState,
  ChangeEvent,
  useEffect,
} from 'react';
import {
  Field,
  InjectedFormProps,
  reduxForm,
  WrappedFieldProps,
} from 'redux-form';
import {
  makeStyles,
  TextField,
  Button,
  FormHelperTextProps,
  Checkbox,
} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducer';
import {CENTER, requsetCenter} from '~/features/Center/slice';
import {
  removeCheckCenterName,
  saveCheckCenterName,
  SIGNUP,
} from '~/features/SignUp/slice';
import {Center} from '~/models/center';
import _ from 'lodash';
interface IInputProps {
  label?: string;
  error?: boolean;
  helperText?: string | React.ReactNode;
  FormHelperTextProps?: Partial<FormHelperTextProps<'p', {}>> | undefined;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3) - 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    minWidth: 320,
    padding: '0px 15px',
  },
  buttons: {
    width: '100%',
    height: 42,
    display: 'flex',
    marginTop: theme.spacing(4) + 7,
    marginBottom: theme.spacing(4),
  },
  cancel: {
    display: 'inline',
    width: '100%',
    fontSize: '0.9375em',
  },
  submit: {
    display: 'inline',
    width: '100%',
    height: 42,
    marginLeft: theme.spacing(1) + 6,
    fontSize: '0.938em',
  },
  modalBack: {
    width: 253,
    height: 164,
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  modelBtn: {
    width: 156,
    height: 42,
    fontSize: '0.938em',
    marginBottom: 28.19,
  },
  centerBox: {
    display: 'flex',
    height: 100,
    width: '100%',
    overflow: 'auto',
    flexDirection: 'column',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const helperTextEmailStyles = makeStyles((theme) => ({
  contained: {
    '&.MuiFormHelperText-contained': {
      marginTop: 10,
      lineHeight: '17px',
    },
  },
}));

const helperStyles = makeStyles((theme) => ({
  contained: {
    '&.MuiFormHelperText-contained': {
      height: 0,
      visibility: 'hidden',
    },
  },
}));

const helperPasswordStyles = makeStyles((theme) => ({
  contained: {
    '&.MuiFormHelperText-contained': {
      marginLeft: 0,
      marginTop: 3,
    },
  },
}));

const renderTextField = (
  field: WrappedFieldProps &
    InputHTMLAttributes<HTMLInputElement> &
    IInputProps,
) => {
  const {
    input,
    type,
    autoFocus,
    label,
    //meta: {touched, invalid, error},
    error,
    disabled,
    style,
    helperText,
    FormHelperTextProps,
  } = field;
  return (
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      label={label}
      autoFocus={autoFocus}
      FormHelperTextProps={FormHelperTextProps}
      error={error}
      disabled={disabled}
      //helperText={(touched && error) || helperText}
      helperText={helperText}
      type={type}
      style={style}
      {...input}
    />
  );
};

const RenderCheckCenter = (props: {name: string}) => {
  const {name} = props;
  const [checked, setChecked] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (checked) {
      dispatch(saveCheckCenterName({name}));
    } else {
      dispatch(removeCheckCenterName({name}));
    }
  }, [checked, name, dispatch]);

  return (
    <>
      {name.includes('테스트') ? (
        <></>
      ) : (
        <div className={classes.center}>
          <Checkbox color="primary" checked={checked} onChange={handleChange} />
          <div
            style={{
              fontSize: '1em',
            }}>
            {name.length > 10 ? name.slice(0, 8) + '...센터' : name}
          </div>
        </div>
      )}
    </>
  );
};

const SignUpForm = (props: InjectedFormProps) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const helperEmailClass = helperTextEmailStyles();
  const helperConfirmPwdClass = helperPasswordStyles();
  const helperDefaultClass = helperStyles();
  const {handleSubmit} = props;
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [caregiver, setCaregiver] = useState('');
  const [phone, setPhone] = useState('');
  // 추후 yup 또는 객체로 state 정리
  const [eError, setEError] = useState(false);
  const [nError, setNError] = useState(false);
  const [cError, setCError] = useState(false);
  const [pError, setPError] = useState(false);
  const [pwd, setPwd] = useState('');
  const [pwdFocus, setPwdFocus] = useState(false);
  const [pwdError, setPwdError] = useState(false);
  const [confirmPwd, setconfirmPwd] = useState('');
  const [confirmPwdFocus, setConfirmFocus] = useState(false);
  const [confirmError, setConfirmError] = useState(false);
  const [open, setOpen] = React.useState(false);

  const centers = useSelector((state: RootState) => state[CENTER].centers);
  const error = useSelector((state: RootState) => state[SIGNUP].error);

  useEffect(() => {
    dispatch(requsetCenter());
  }, [dispatch]);

  useEffect(() => {
    if (!pwdFocus && pwd !== '') {
      if (pwd.length < 4) {
        setPwdError(true);
      } else {
        setPwdError(false);
      }
    }
  }, [pwd, pwdFocus]);

  useEffect(() => {
    if (!confirmPwdFocus && confirmPwd !== '') {
      if (pwd !== confirmPwd) {
        setConfirmError(true);
      } else {
        setConfirmError(false);
      }
    }
  }, [confirmPwd, confirmPwdFocus, pwd]);

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line
    const regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

    if (regExp.test(e.target.value)) {
      setEmail(e.target.value);
      setEError(false);
    } else {
      setEError(true);
    }
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleChangeCaregiver = (e: ChangeEvent<HTMLInputElement>) => {
    setCaregiver(e.target.value);
  };

  const handleChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
  };

  const handleOpen = () => {
    if (!email) {
      setEError(true);
    } else {
      setEError(false);
    }
    if (!name) {
      setNError(true);
    } else {
      setNError(false);
    }
    if (!caregiver) {
      setCError(true);
    } else {
      setCError(false);
    }
    if (!phone) {
      setPError(true);
    } else {
      setPError(false);
    }

    if (email && name && caregiver && phone) {
      if (!pwdError && !confirmError) {
        setOpen(true);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onPasswordHandler = (event: any) => {
    setPwd(event.currentTarget.value);
  };

  const onconfirmPasswordHandler = (event: any) => {
    setconfirmPwd(event.currentTarget.value);
  };

  const body = (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        marginTop: 198,
      }}>
      {error && error ? (
        <div className={classes.modalBack}>
          <div
            style={{
              fontSize: '1em',
              marginTop: 28.97,
              marginBottom: 30,
            }}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              {'회원가입이 실패하였습니다.'}
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              {'요양보호사번호를 확인해주세요.'}
            </div>
          </div>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            variant="contained"
            color="primary"
            className={classes.modelBtn}>
            닫기
          </Button>
        </div>
      ) : (
        <div className={classes.modalBack}>
          <div
            style={{
              fontSize: '1em',
              marginTop: 28.97,
              marginBottom: 30,
            }}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              {'감사합니다.'}
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              {'회원가입 신청이 완료되었습니다.'}
            </div>
          </div>
          <Button
            onClick={() => {
              history.push('/login');
            }}
            variant="contained"
            color="primary"
            className={classes.modelBtn}>
            확인
          </Button>
        </div>
      )}
    </div>
  );

  return (
    // 일단 중앙 정렬로 맞췄는 검토 후 margin으로 수정시 주석 해제(signform도 동일)
    <div className={classes.paper}>
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <Field
          name="email"
          label="이메일"
          onChange={handleChangeEmail}
          error={eError}
          helperText={
            eError
              ? '올바른 이메일 형식이 아닙니다.'
              : '사용가능한 이메일을 적어주세요.'
          }
          component={renderTextField}
          type="text"
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
          FormHelperTextProps={{classes: helperEmailClass}}
        />
        <Field
          name="name"
          label="이름"
          onChange={handleChangeName}
          component={renderTextField}
          error={nError}
          type="text"
        />
        <Field
          onChange={onPasswordHandler}
          label="비밀번호"
          name="password"
          component={renderTextField}
          error={pwdError}
          onFocus={() => setPwdFocus(true)}
          onBlur={() => setPwdFocus(false)}
          type="password"
          style={{marginTop: 22}}
          helperText={
            pwd.length < 4 ? '비밀번호는 최소 4글자 이상 입력해주세요.' : ''
          }
        />
        <Field
          label="비밀번호 확인"
          onChange={onconfirmPasswordHandler}
          name="confirmPassword"
          component={renderTextField}
          error={confirmError}
          onFocus={() => setConfirmFocus(true)}
          onBlur={() => setConfirmFocus(false)}
          FormHelperTextProps={{classes: helperConfirmPwdClass}}
          helperText={
            confirmError
              ? '비밀번호가 일치하지 않습니다. 다시 확인해주세요.'
              : ''
          }
          type="password"
          style={{marginTop: 22, display: 'flex'}}
        />
        <Field
          name="caregiver"
          label="요양보호사번호"
          onChange={handleChangeCaregiver}
          component={renderTextField}
          error={cError}
          FormHelperTextProps={{classes: helperDefaultClass}}
          type="text"
          style={{marginTop: 7, marginBottom: 0}}
        />
        <Field
          name="phone"
          label="휴대전화번호"
          onChange={handleChangePhone}
          component={renderTextField}
          error={pError}
          FormHelperTextProps={{classes: helperDefaultClass}}
          type="text"
          style={{marginTop: 16}}
        />
        <div className={classes.centerBox}>
          {_.map(centers, (center: Center) => (
            <RenderCheckCenter key={center.id} name={center.name} />
          ))}
        </div>
        <div className={classes.buttons}>
          <Button
            onClick={() => {
              history.push('/login');
            }}
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.cancel}>
            취소
          </Button>
          <Button
            type="submit"
            onClick={handleOpen}
            variant="contained"
            color="primary"
            className={classes.submit}>
            완료
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            {body}
          </Modal>
        </div>
      </form>
    </div>
  );
};

export default reduxForm({
  // a unique name for the form
  form: 'msignup',
})(SignUpForm);
