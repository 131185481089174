import {Button} from '@material-ui/core';
import React from 'react';
import {connect, useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {submit} from 'redux-form';

export interface RemoteButton {
  mode: 'mprofile' | 'profile' | 'mhistory';
}

//@ts-ignore
const RemoteSubmitButton = (props: RemoteButton) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {mode} = props;

  return (
    <Button
      type="button"
      variant="contained"
      color="primary"
      style={{
        display: 'inline',
        width: 100,
        height: 42,
        fontSize: '0.938em',
        marginLeft: 10,
      }}
      onClick={() => {
        dispatch(submit(mode));
        history.goBack();
      }}>
      확인
    </Button>
  );
};

export default connect()(RemoteSubmitButton);
