import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {makeStyles, Theme, createStyles, Button} from '@material-ui/core';

import MResetPasswordForm from '../forms/MReestPasswordForm';
import {
  loadPasswordResetRequet,
  PROFILE,
  requestResetPwdFinish,
} from '~/features/Profile/slice';
import {RootState} from '~/app/rootReducer';
import {genSalt, hash} from 'bcryptjs';
import {useHistory} from 'react-router-dom';
import {SimpleHeader} from '~/components/SimpleHeader';
import {Typography} from '~/components/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(7.5),
    },
    text: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(15) + 5,
      marginBottom: theme.spacing(2) - 1,
    },
    explain: {},
    buttons: {
      width: '100%',
      height: 42,
      display: 'flex',
      marginTop: theme.spacing(3),
    },
    submit: {
      display: 'inline',
      width: '100%',
      fontSize: '0.9em',
    },
  }),
);

const MPasswordResetFinishScreen = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const resetId = useSelector((state: RootState) => state[PROFILE].resetId);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    dispatch(loadPasswordResetRequet());
  }, [dispatch]);

  const submit = async (values: any) => {
    const {password} = values;
    const newPassword = await hash(password, await genSalt());
    dispatch(requestResetPwdFinish({resetId, newPassword}));
    setComplete(true);
  };

  return (
    <div className={classes.root}>
      <SimpleHeader mode={'findPW'} />
      {complete ? (
        <div className={classes.paper}>
          <div className={classes.text} style={{marginTop: 175}}>
            <Typography
              variant={'H4'}
              style={{fontWeight: 500, lineHeight: '36px'}}>
              비밀번호 변경 완료
            </Typography>
          </div>
          <div className={classes.explain}>
            <span>새로운 비밀번호로 로그인이 가능합니다.</span>
          </div>
          <div className={classes.buttons}>
            <Button
              onClick={() => history.replace('/')}
              variant="contained"
              color="primary"
              className={classes.submit}>
              로그인 화면으로 이동
            </Button>
          </div>
        </div>
      ) : (
        <MResetPasswordForm onSubmit={submit} />
      )}
    </div>
  );
};

export default MPasswordResetFinishScreen;
