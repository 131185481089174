import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import Statistics from '~/models/statistics';
import {
  ServiceSummary,
  SimulationStat,
  StateCenterId,
  StatReqeustPayload,
  StatRequestGrade,
  StatResult,
  StatSummary,
  SummaryStatics,
  TotalStat,
} from '~/types/types';

type State = {
  loading: boolean;
  request:
    | StatReqeustPayload
    | StatRequestGrade
    | StatSummary
    | StateCenterId
    | undefined;
  total: Statistics;
  employee: Statistics;
  priceByGrades: StatResult | undefined;
  count: number;
  summary: SummaryStatics[] | undefined;
  search?: StatResult;
  simulation: SimulationStat[];
  allGrade?: StatResult;
  nurse: ServiceSummary;
  bath: ServiceSummary;
  care: ServiceSummary;
  lastInfo: string;
  dutyTotals?: TotalStat[];
};

let initialState: State = {
  loading: false,
  request: undefined,
  total: {range: [], total: [], raw: []},
  employee: {range: [], total: [], raw: []},
  nurse: {count: 0, minutes: 0},
  bath: {count: 0, minutes: 0},
  care: {count: 0, minutes: 0},
  summary: undefined,
  priceByGrades: undefined,
  count: 0,
  search: undefined,
  simulation: [],
  allGrade: undefined,
  lastInfo: '',
  dutyTotals: undefined,
};

const slice = createSlice({
  name: 'duties_stat',
  initialState,
  reducers: {
    loadDutyLast: (state, action: PayloadAction<StateCenterId>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successLoadLastDuty: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.lastInfo = action.payload;
    },
    loadDutyStatEmployee: (
      state,
      action: PayloadAction<StatReqeustPayload>,
    ) => {
      state.loading = true;
      state.request = action.payload;
    },
    successDutyStatEmployee: (state, action: PayloadAction<Statistics>) => {
      state.loading = false;
      state.employee = action.payload;
    },
    loadDutyStatGrade: (state, action: PayloadAction<StatRequestGrade>) => {
      state.loading = true;
      state.request = action.payload;
      state.dutyTotals = undefined;
      state.search = undefined; // 검색 하지 않은 경우에 하나만 선언해주면 됨
    },
    successDutyLoadStateGrade: (state, action: PayloadAction<StatResult>) => {
      state.loading = false;
      state.priceByGrades = action.payload;
    },
    requestGradeCount: (state, action: PayloadAction<StatRequestGrade>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successLoadGradeCount: (state, action: PayloadAction<number>) => {
      state.loading = false;
      state.count = action.payload;
    },
    loadStatSummary: (state, action: PayloadAction<StatSummary>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successLoadSummary: (state, action: PayloadAction<SummaryStatics[]>) => {
      state.loading = false;
      state.summary = action.payload;
    },
    requestDutySearch: (state, action: PayloadAction<StatReqeustPayload>) => {
      state.loading = true;
      state.request = action.payload;
      state.dutyTotals = undefined;
      state.priceByGrades = undefined;
    },
    successSearch: (state, action: PayloadAction<StatResult>) => {
      state.loading = false;
      state.search = action.payload;
    },
    loadSimulationStat: (state, action: PayloadAction<StatSummary>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successLoadSimulation: (state, action: PayloadAction<SimulationStat[]>) => {
      state.loading = false;
      state.simulation = action.payload;
    },
    loadNurseSummary: (state, action: PayloadAction<StatSummary>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successLoadNurseSummary: (state, action: PayloadAction<ServiceSummary>) => {
      state.loading = false;
      state.nurse = action.payload;
    },
    loadBathSummary: (state, action: PayloadAction<StatSummary>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successLoadBathSummary: (state, action: PayloadAction<ServiceSummary>) => {
      state.loading = false;
      state.bath = action.payload;
    },
    loadCareSummary: (state, action: PayloadAction<StatSummary>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successLoadCareSummary: (state, action: PayloadAction<ServiceSummary>) => {
      state.loading = false;
      state.care = action.payload;
    },
    // 전체 grade에 대해서 불러오기(등급별 현황 페이지)
    loadDutyTotals: (state, action: PayloadAction<StatRequestGrade>) => {
      state.loading = true;
      state.request = action.payload;
      state.priceByGrades = undefined;
      state.search = undefined;
    },
    successDutyLoadTotals: (state, action: PayloadAction<TotalStat[]>) => {
      state.loading = false;
      state.dutyTotals = action.payload;
    },
  },
});

export const {
  loadDutyLast,
  successLoadLastDuty,
  loadDutyStatEmployee,
  successDutyStatEmployee,
  loadDutyStatGrade,
  successDutyLoadStateGrade,
  requestGradeCount,
  successLoadGradeCount,
  loadStatSummary,
  successLoadSummary,
  requestDutySearch,
  successSearch,
  loadSimulationStat,
  successLoadSimulation,
  loadNurseSummary,
  successLoadNurseSummary,
  loadBathSummary,
  successLoadBathSummary,
  loadCareSummary,
  successLoadCareSummary,
  loadDutyTotals,
  successDutyLoadTotals,
} = slice.actions;
export const DUTIES_STAT = slice.name;
export default slice.reducer;
