import React, {ChangeEvent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  makeStyles,
  Theme,
  createStyles,
  TextField,
  Button,
} from '@material-ui/core';

import {Typography} from '../components/Typography';
import {SimpleHeader} from '~/components/SimpleHeader';
import {PROFILE, resetPasswordRequest} from '~/features/Profile/slice';
import {RootState} from '~/app/rootReducer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      paddingTop: 60,
    },
    form: {
      minWidth: 320,
      padding: '0px 15px',
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(15) + 5,
      marginBottom: theme.spacing(2) - 1,
    },
    titleConfirm: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(28) + 4,
      marginBottom: theme.spacing(2) - 1.32,
    },
    comment: {
      display: 'flex',
      textAlign: 'center',
      marginBottom: theme.spacing(4) + 3.41, // 원래는 (1) + 3.41
    },
    field: {
      width: '100%',
    },
    confirm: {
      width: '100%',
      height: 42,
    },
  }),
);

const helperTextStyles = makeStyles((theme) => ({
  contained: {
    '&.MuiFormHelperText-contained': {
      marginLeft: 0,
      marginTop: 4,
    },
  },
}));

const MResetPasswordScreen = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const helperTextClass = helperTextStyles();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const {reset, check} = useSelector((state: RootState) => state[PROFILE]);

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    if (reset && check) {
      //history.push('/');
      setCheckEmail(true);
    } else if (!reset && !check) {
      setError(true);
    }
  }, [reset, check]);

  return (
    <div className={classes.root}>
      <SimpleHeader mode={'findPW'} />
      {checkEmail ? (
        <div className={classes.body}>
          <div className={classes.form}>
            <div className={classes.titleConfirm}>
              <Typography
                variant={'H4'}
                style={{fontWeight: 500, lineHeight: '36px'}}>
                이메일을 확인해주세요.
              </Typography>
            </div>
            <div>
              <span className={classes.comment}>
                암호 재설정을 위한 이메일을 입력하신 <br />
                {`${email}에 보냈습니다. 확인해주세요.`}{' '}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.body}>
          <div className={classes.form}>
            <div className={classes.title}>
              <Typography
                variant={'H4'}
                style={{fontWeight: 500, lineHeight: '36px'}}>
                비밀번호 재설정
              </Typography>
            </div>
            <div>
              <span className={classes.comment}>
                회원 가입시 사용한 이메일 주소를 입력하면 <br />
                암호를 재설정할 수 있는 링크를 보내드리겠습니다.
              </span>
            </div>
            <TextField
              className={classes.field}
              variant="outlined"
              error={error}
              FormHelperTextProps={{classes: helperTextClass}}
              helperText={error ? '등록되지 않은 이메일입니다.' : null}
              onChange={handleChangeEmail}
              name="email"
              label="이메일"
            />
            <Button
              onClick={() => {
                dispatch(resetPasswordRequest({email}));
              }}
              style={{marginTop: error ? '16px' : '39px'}}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.confirm}>
              확인
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MResetPasswordScreen;
