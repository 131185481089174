import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import moment from 'moment';
import Duty from '~/models/duty';
import Schedule from '~/models/schedule';
import HistoryDetail from '~/models/hisotry';

export interface RequestSchdules {
  //scale: STAT_SCALE;
  centerId: number;
}

export interface RequestJobType {
  service: string;
  datetime: moment.Moment;
  centerId: number;
}

export interface RequestGrade {
  grade: number;
  datetime: moment.Moment;
  centerId: number;
}

interface TypeSchedules {
  employeeName: string;
  //centerName?: string;
  patientGrade?: number;
  patientName: string;
  patientCode: string;
  startDate: string;
  endDate: string;
  service: string;
  histories?: HistoryDetail;
  licenseId: number;
  duty?: Duty;
}

type State = {
  loading: boolean;
  jobSchedules: TypeSchedules[];
  gradeSchedules: TypeSchedules[];
  schedule: Schedule | null;
  schedules: Schedule[];
  currentId: number;
};

let initialState: State = {
  loading: false,
  schedule: null,
  schedules: [],
  jobSchedules: [],
  gradeSchedules: [],
  currentId: 0,
};

const slice = createSlice({
  name: 'schedules',
  initialState,
  reducers: {
    loadSch: (state, _action: PayloadAction<number>) => {
      state.loading = true;
    },
    loadSchedules: (state, _action: PayloadAction<RequestSchdules>) => {
      state.loading = true;
    },
    successSch: (state, action: PayloadAction<Schedule>) => {
      state.loading = false;
      state.schedule = action.payload;
    },
    successLoadSchedules: (state, action: PayloadAction<Schedule[]>) => {
      state.loading = false;
      state.schedules = action.payload;
      state.jobSchedules = [];
    },
    loadSchedulesServiceType: (
      state,
      action: PayloadAction<RequestJobType>,
    ) => {
      state.loading = true;
      state.jobSchedules = [];
      state.gradeSchedules = [];
    },
    successLoadSchedulesServiceType: (
      state,
      action: PayloadAction<TypeSchedules[]>,
    ) => {
      state.loading = false;
      state.schedules = [];
      state.jobSchedules = action.payload;
    },
    loadSchedulesGrade: (state, _action: PayloadAction<RequestGrade>) => {
      state.loading = true;
      state.gradeSchedules = [];
    },
    successLoadSchedulesGrade: (
      state,
      action: PayloadAction<TypeSchedules[]>,
    ) => {
      state.loading = false;
      state.schedules = [];
      state.gradeSchedules = action.payload;
    },
    requestSaveCurrentId: (state, action: PayloadAction<number>) => {
      state.loading = true;
      state.currentId = action.payload;
    },
  },
});

export const {
  loadSch,
  loadSchedules,
  successSch,
  successLoadSchedules,
  loadSchedulesServiceType,
  successLoadSchedulesServiceType,
  loadSchedulesGrade,
  successLoadSchedulesGrade,
  requestSaveCurrentId,
} = slice.actions;
export const SCHEDULES = slice.name;
export default slice.reducer;
