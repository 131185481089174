import {call, put, select, takeLatest} from 'redux-saga/effects';
import {
  successLoadEmployee,
  loadEmployee,
  loadEmployeeSchedules,
  successLoadEmployeeSchedules,
} from './slice';
import * as api from '~/api';
import {RootState} from '~/app/rootReducer';
import {DEPARTMENT} from '../Department/slice';

function* handleLoad() {
  try {
    const resp = yield call(api.getEmployees);
    yield put(successLoadEmployee(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleLoadSchedules() {
  try {
    // id가 아니라 요양보호사번호로 수정해야 함
    const id = yield select(
      (state: RootState) => state[DEPARTMENT].centerInfo?.employeeId,
    );
    const res_schedules = yield call(api.getEmployeeSchedules, id);
    yield put(successLoadEmployeeSchedules(res_schedules.data));
  } catch (err) {
    console.log('error', err);
  }
}

export function* watchEmployees() {
  yield takeLatest(loadEmployee, handleLoad);
  yield takeLatest(loadEmployeeSchedules, handleLoadSchedules);
}
