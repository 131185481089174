import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {
  Payload,
  ResetPassword,
  Token,
  UpdateUserProfile,
  UserProfile,
} from '~/types/types';

interface ResetRequest {
  resetId: number;
  newPassword: string;
}

type ProfileState = {
  loading: boolean;
  payload: Payload | undefined;
  token: string | undefined;
  reset: boolean;
  check: boolean;
  resetId: number;
  request: ResetRequest | undefined;
} & UserProfile;

let initialState: ProfileState = {
  loading: false,
  email: undefined,
  username: undefined,
  password: undefined,
  payload: undefined,
  caregiver: undefined,
  phone: undefined,
  token: undefined,
  id: 0,
  reset: false,
  check: true,
  roles: '',
  resetId: 0,
  request: undefined,
};

const profileSlice = createSlice({
  name: 'proflie',
  initialState,
  reducers: {
    profileRequest: (state) => {
      state.loading = true;
    },
    getProfile: (state, action: PayloadAction<UserProfile>) => {
      const {
        email,
        username,
        caregiver,
        phone,
        password,
        roles,
      } = action.payload;
      state.loading = false;
      state.email = email;
      state.username = username;
      state.password = password;
      state.caregiver = caregiver;
      state.phone = phone;
      state.id = action.payload.id;
      state.roles = roles;
    },
    initProfile: (state) => {
      state.loading = false;
      state.email = undefined;
      state.username = undefined;
      state.password = undefined;
      state.caregiver = undefined;
      state.phone = undefined;
      state.token = undefined;
      state.roles = '';
    },
    updateRequest: (
      state,
      action: PayloadAction<UpdateUserProfile> & PayloadAction<Token>,
    ) => {
      state.loading = true;
      const {new_password, new_phone, token} = action.payload;
      state.token = token;
      state.password = new_password;
      state.phone = new_phone;
    },
    completeUpdate: (state) => {
      state.loading = false;
    },
    resetPasswordRequest: (state, action: PayloadAction<ResetPassword>) => {
      const {email} = action.payload;
      state.loading = true;
      state.email = email;
      state.check = true;
      state.reset = false;
    },
    successResetPassword: (state, action: PayloadAction<boolean>) => {
      state.loading = false;
      state.reset = action.payload;
      state.check = action.payload;
    },
    loadPasswordResetRequet: (state) => {
      state.loading = true;
    },
    successLoadPasswordResetUser: (state, action: PayloadAction<number>) => {
      state.loading = false;
      state.resetId = action.payload;
    },
    requestResetPwdFinish: (state, action: PayloadAction<ResetRequest>) => {
      state.loading = true;
      state.request = action.payload;
    },
  },
});

export const {
  profileRequest,
  getProfile,
  initProfile,
  updateRequest,
  completeUpdate,
  resetPasswordRequest,
  successResetPassword,
  loadPasswordResetRequet,
  successLoadPasswordResetUser,
  requestResetPwdFinish,
} = profileSlice.actions;
export const PROFILE = profileSlice.name;
export default profileSlice.reducer;
