import React from 'react';
import {STAT_MODE} from '~/types/types';
import {makeStyles} from '@material-ui/core';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
const useStyles = makeStyles((theme) => ({
  chartContainer: {
    width: '100%',
    flexGrow: 1,
    height: 436,
    margin: theme.spacing(2.3125, 0, 1.5),
  },
  chartLegend: {
    '& > div > svg > path': {
      fill: 'none',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper': {
      top: '0px !important',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend > .recharts-legend-item': {
      display: 'flex !important',
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '0.75em',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend > li > svg': {
      width: 50,
      height: 11,
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend > li > svg > path': {
      d: 'path("M-50,0h146v32h-146z")',
    },
  },
  tooltip: {
    padding: theme.spacing(2.5, 1.875, 1.125),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    //alignItems: 'center',
    backgroundColor: '#FFFFFF',
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: theme.spacing(0.5),
  },
  tooltip__label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 23,
    marginBottom: theme.spacing(1.125),
    fontSize: '1em',
  },
  helpMark: {
    display: 'flex',
    marginTop: theme.spacing(7.75),
    alignItems: 'center',
    fontSize: '1em',
  },
  backColorBlue: {
    fontSize: '0.625em',
    backgroundColor: '#E8EAF6',
  },
  backColorGray: {
    fontSize: '0.625em',
    backgroundColor: `${theme.palette.info.light}`,
  },
  table: {
    boxShadow: 'none',
    '& > table > thead > tr > th, & > table > tbody > tr > td': {
      padding: theme.spacing(0.74375, 4.66625),
      fontWeight: 400,
    },
  },
  tableText: {
    fontSize: '0.625em',
  },
  nameTable: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    display: 'flex',
    minWidth: theme.spacing(7),
    textAlign: 'end',
    flexDirection: 'column',
    marginRight: theme.spacing(1.625),
  },
}));

export enum GRAPH_TYPE {
  ALL,
  PRICE,
  HOURS,
  PPH,
}

interface Props {
  type: GRAPH_TYPE;
  mode: STAT_MODE;
  onClick?: (date: string) => void;
}

// const titles = {
//   [GRAPH_TYPE.ALL]: '',
//   [GRAPH_TYPE.PRICE]: '수가',
//   [GRAPH_TYPE.HOURS]: '근무시간',
//   [GRAPH_TYPE.PPH]: '시간당 수가',
// };
// TODO: change to apex chart
export default ({type, mode, onClick}: Props) => {
  const classes = useStyles();
  // const getTotal = (sch_total: Total[], duty_total: Total[]) => {
  //   if (type === GRAPH_TYPE.PRICE) {
  //     return {
  //       sch: _.map(sch_total, (t) => t.price),
  //       duties: _.map(duty_total, (t) => t.price),
  //     };
  //   } else if (type === GRAPH_TYPE.HOURS) {
  //     return {
  //       sch: _.map(sch_total, (t) => t.hours),
  //       duties: _.map(duty_total, (t) => t.hours),
  //     };
  //   } else if (type === GRAPH_TYPE.PPH) {
  //     return {
  //       sch: _.map(sch_total, (t) => _.round(t.price / t.hours, 0)),
  //       duties: _.map(duty_total, (t) => _.round(t.price / t.hours, 0)),
  //     };
  //   }
  //   return {sch: [], duties: []};
  // };
  // const ranges = useSelector((state: RootState) => {
  //   const {total, employee, nurse, care} = state[SCHEDULES_STAT];
  //   if (mode === STAT_MODE.EMPLOYEE) {
  //     return employee.range;
  //   } else if (mode === STAT_MODE.NURSE) {
  //     return nurse.range;
  //   } else if (mode === STAT_MODE.CARE) {
  //     return care.range;
  //   }
  //   return total.range;
  // });
  // const total = useSelector((state: RootState) => {
  //   const {
  //     total: sch_total,
  //     employee: sch_employee,
  //     nurse: sch_nurse,
  //     care: sch_care,
  //   } = state[SCHEDULES_STAT];
  //   const {
  //     total: duty_total,
  //     employee: duty_employee,
  //     nurse: duty_nurse,
  //     care: duty_care,
  //   } = state[DUTIES_STAT];

  //   if (mode === STAT_MODE.EMPLOYEE) {
  //     return getTotal(sch_employee.total, duty_employee.total);
  //   } else if (mode === STAT_MODE.NURSE) {
  //     return getTotal(sch_nurse.total, duty_nurse.total);
  //   } else if (mode === STAT_MODE.CARE) {
  //     return getTotal(sch_care.total, duty_care.total);
  //   }
  //   return getTotal(sch_total.total, duty_total.total);
  // });

  // const loading = useSelector(
  //   (state: RootState) => state[SCHEDULES_STAT].loading,
  // );

  return (
    <>
      {/* <ReactEcharts
        showLoading={loading}
        onEvents={{
          click: (params) => {
            if (onClick) {
              onClick(params.name);
            }
          },
        }}
        option={{
          grid: {left: 70},
          title: {
            text: titles[type],
          },
          tooltip: {},
          xAxis: {type: 'category', data: ranges},
          yAxis: {},
          series: [
            {
              type: 'bar',
              data: total.sch,
              encode: {
                x: 'month',
                y: 'price',
              },
            },
            {
              type: 'bar',
              data: total.duties,
              encode: {
                x: 'month',
                y: 'price',
              },
            },
          ],
        }}
      /> */}
      <div className={classes.chartContainer}>
        <ResponsiveContainer className={classes.chartLegend}>
          <ComposedChart
            //data={data}
            margin={{top: 20, right: 30, left: 20, bottom: 5}}>
            <CartesianGrid strokeDasharray="5 5" />
            <XAxis dataKey="name" />
            <YAxis />
            <YAxis yAxisId="right" orientation="right" />
            {/* <Tooltip content={<CustomTooltip />} /> */}
            <Bar dataKey="매출" fill="#FF6384" stackId="a" />
            <Bar dataKey="지급총액" fill="#36A2EB" stackId="b" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};
