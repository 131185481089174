import React from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';

import LoginScreen from '~/screens/LoginScreen';
import PasswordResetFinishScreen from '~/screens/PasswordResetFinishScreen';
import PasswordResetScreen from '~/screens/PasswordResetScreen';
import SignUpScreen from '~/screens/SignUpScreen';

const LoginRouter = () => {
  return (
    <Switch>
      <Route path={`/signup`} component={SignUpScreen} />
      <Route path={`/restore`} component={PasswordResetScreen} />
      <Route path={`/reset-password`} component={PasswordResetFinishScreen} />
      <Route path={`/`} component={LoginScreen} />
    </Switch>
  );
};

export default withRouter(LoginRouter);
