import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducer';

import {Detail} from 'react-calendar';
import {
  makeStyles,
  TextField,
  Button,
  Typography,
  Divider,
  Popover,
  useTheme,
  Theme,
  FormControlLabel,
  FormGroup,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import {
  Calendar as BigCalendar,
  momentLocalizer,
  Event,
  EventProps,
} from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/ko';
import 'react-big-calendar/lib/sass/styles.scss';
import {
  SCHEDULES,
  loadSchedules,
  loadSchedulesGrade,
} from '~/features/Schedule/slice';
import _ from 'lodash';
import withStatTemplate, {StatProps} from '~/hoc/withStatTemplate';
import StyledCalendar from '~/components/StyledCalendar';
import HistoryDetail from '~/models/hisotry';
import CloseIcon from '@material-ui/icons/Close';
import StarRateIcon from '@material-ui/icons/StarRate';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {PopBodyHistory, PopBodyTopContent} from '~/components/PopOverContent';
import {DEPARTMENT, loadDepartment} from '~/features/Department/slice';
import {PROFILE} from '~/features/Profile/slice';
import Schedule from '~/models/schedule';
import {HISTORY, loadHistories} from '~/features/History/slice';
// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
moment.locale('ko');
const localizer = momentLocalizer(moment); // or globalizeLocalizer

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
  },
  calendarPaper: {
    display: 'flex',
    flexDirection: 'column',
    //padding: theme.spacing(1),
    minHeight: 700,
    alignItems: 'center',
    boxShadow: 'none',
  },
  calendar: {
    display: 'flex',
    border: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    '& > .react-calendar__navigation': {
      '& > .react-calendar__navigation__label': {
        fontSize: '1.25em',
        fontWeight: 500,
      },
    },
    '& > .react-calendar__viewContainer': {
      display: 'none',
    },
  },
  bigCalendar: {
    width: '100%',
    flex: 1,
    '& .rbc-month-row': {
      marginBottom: theme.spacing(1.25),
    },
    '& .rbc-month-view, .rbc-header': {
      border: 'none',
    },
    '& .rbc-header': {
      height: theme.spacing(4.5),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(1.25),
    },
    '& .rbc-header:first-child, .rbc-date-cell:first-child': {
      color: '#E91E63',
    },
    '& .rbc-date-cell': {
      textAlign: 'start',
      margin: theme.spacing(1, 0, 0, 2),
    },
    '& .rbc-header:last-child, .rbc-date-cell:last-child': {
      color: '#2196F3',
    },
    '& .rbc-month-row + .rbc-month-row': {
      border: 'none',
    },
    '& .rbc-day-bg, rbc-off-range-bg': {
      border: '1px solid #BDBDBD',
      borderRadius: theme.spacing(1.875),
      margin: theme.spacing(0, 0.625),
    },
    '& .rbc-off-range-bg': {
      backgroundColor: '#fff',
    },
    '& .rbc-off-range': {
      visibility: 'hidden',
    },
    '& .rbc-row-segment': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .rbc-event': {
      color: '#000',
      backgroundColor: '#fff',
      width: 'fit-content',
    },
  },
  chartContainer: {
    width: '100%',
    height: '30vh',
  },
  flex1: {
    flex: 1,
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  total: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: theme.spacing(3.125),
    background: '#F6F6F6',
    borderRadius: theme.spacing(1.875),
    padding: theme.spacing(2.25, 0),
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    width: '25%',
    height: 63.56,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textSpan: {
    marginTop: theme.spacing(1.625),
  },
  divider: {
    height: 51,
    borderLeft: '1px solid #C2C2C2',
  },
  modalBack: {
    padding: theme.spacing(1.25, 2.5),
    background: '#FBFBFB',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px 10px 0px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modalBtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
    marginTop: theme.spacing(4),
  },
  modalInnerBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  exitModal: {
    display: 'flex',
    justifyContent: 'end',
    marginBottom: theme.spacing(1.25),
  },
  modalText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modalInput: {
    background: '#fff',
    margin: theme.spacing(0, 1, 0, 1.625),
    '& > div': {
      width: 218.78,
      height: 35,
    },
  },
  state: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    width: '100%',
  },
  checkBoxes: {
    display: 'flex',
    flexDirection: 'row',
  },
  infoHistory: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(4.66875),
  },
}));

type MyEvent = Event & {
  employee?: string;
  patient?: string;
  centerName?: string;
  patientGrade?: number;
  employeeName?: string;
  patientName?: string;
  startDate?: string;
  endDate?: string;
  service?: string;
  history?: HistoryDetail;
};

const PatientTimeScreen = ({statScale, grade}: StatProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme() as Theme;

  const schedules = useSelector(
    (state: RootState) => state[SCHEDULES].schedules,
  );
  const gradeSchedules = useSelector(
    (state: RootState) => state[SCHEDULES].gradeSchedules,
  );
  const [sEvents, setSEvents] = useState<Event[]>([]);
  const [dEvents, setDEvents] = useState<Event[]>([]);

  const [date, setDate] = useState(new Date());
  const [search, setSearch] = useState<string>('');
  const [viewType /*, setViewType*/] = useState<Detail>('month');

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [data, setData] = useState<any>({});
  const [state, setState] = useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
  });

  const [planCnt, setPlanCnt] = useState(0);
  const [completeCnt, setCompleteCnt] = useState(0);
  const [jobName, setJobName] = useState('');
  const [searchBtn, setSearchBtn] = useState(false);

  // 센터 정보 가져오기
  const userId = useSelector((state: RootState) => state[PROFILE].id);
  const centerName = useSelector(
    (state: RootState) => state[DEPARTMENT].centerInfo?.centerName,
  );
  const centerId = useSelector(
    (state: RootState) => state[DEPARTMENT].centerInfo?.centerId,
  );
  const [searchData, setSearchData] = useState<Schedule[]>([]);
  const histories = useSelector((state: RootState) => state[HISTORY].histories);

  useEffect(() => {
    if (userId) {
      dispatch(loadDepartment(userId));
      dispatch(loadHistories());
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (grade === 7) {
      const patient = schedules.filter((f) => f.patient?.name === search);
      const monthly = patient.filter(
        (f) =>
          moment(f.startDate).format('YYYY-MM') ===
          moment(date).format('YYYY-MM'),
      );
      setPlanCnt(monthly.length);
      setCompleteCnt(monthly.filter((f) => f.duty).length);
    } else {
      setPlanCnt(gradeSchedules.length);
      setCompleteCnt(gradeSchedules.filter((f) => f.duty).length);
    }
  }, [schedules, date, search, grade, gradeSchedules]);

  useEffect(() => {
    if (centerId) {
      const datetime = moment(date);
      if (grade !== undefined) {
        switch (grade) {
          case 1:
            setJobName('1급');
            break;
          case 2:
            setJobName('2급');
            break;
          case 3:
            setJobName('3급');
            break;
          case 4:
            setJobName('4급');
            break;
          case 5:
            setJobName('5급');
            break;
          case 6:
            setJobName('인지지원등급');
            break;
          case 7:
            setJobName(search);
            break;
        }
        if (grade !== 7) {
          dispatch(
            loadSchedulesGrade({
              grade: grade,
              datetime: datetime,
              centerId: centerId,
            }),
          );
        }
      }
    }
  }, [dispatch, grade, date, search, centerId]);

  useEffect(() => {
    if (centerId && grade === 7 && searchBtn) {
      const startDate = moment(date).startOf('M').format('YYYY-MM-DDT00:00:00');
      const endDate = moment(date).endOf('M').format('YYYY-MM-DDT23:59:59');
      setSearchData(
        schedules.filter(
          (sch: Schedule) =>
            sch.patient &&
            sch.patient.name === search &&
            sch.startDate >= startDate &&
            sch.endDate <= endDate,
        ),
      );
    }
  }, [schedules, date, centerId, grade, search, searchBtn]);

  useEffect(() => {
    if (centerId && grade === 7 && searchBtn) {
      dispatch(
        loadSchedules({
          centerId: centerId,
        }),
      );
    }
  }, [dispatch, grade, searchBtn, search, centerId]);

  useEffect(() => {
    setSEvents(
      gradeSchedules.map<MyEvent>((s, i) => {
        const start = moment(s.startDate);
        const end = moment(s.endDate);
        return {
          id: i,
          patientName: s.patientName,
          start: start.toDate(),
          end: end.toDate(),
          title: `${start.format('HH:mm')}~${end.format('HH:mm')}`,
        };
      }),
    );
    if (centerName) {
      setDEvents(
        _.chain(gradeSchedules)
          .filter((s) => {
            if (_.startsWith(search, 'L')) {
              const patient = s.patientName;
              if (patient === undefined) {
                return false;
              }
              return s.patientCode.indexOf(search) >= 0;
            } else {
              const patient = s.patientName;
              if (patient === undefined) {
                return false;
              }
              return s.patientName.indexOf(search) >= 0;
            }
          })
          .map<MyEvent | undefined>((s, i) => {
            if (s.duty) {
              const start = moment(s.duty.startDate);
              const end = moment(s.duty.endDate);
              return {
                id: i,
                centerName: centerName,
                patientGrade: s.patientGrade,
                employeeName: s.employeeName,
                patientName: s.patientName,
                start: start.toDate(),
                end: end.toDate(),
                startDate: `${start.format('HH:mm')}`,
                endDate: `${end.format('HH:mm')}`,
                service: s.service,
                history: s.histories,
                title: `${start.format('HH:mm')}~${end.format('HH:mm')}`,
              };
            }
            return undefined;
          })
          .compact()
          .value(),
      );
    }
  }, [gradeSchedules, search, centerName]);

  useEffect(() => {
    setSEvents(
      _.chain(searchData)
        .filter((s) => {
          if (_.startsWith(search, 'L')) {
            // 수급자 코드로 검색하는 부분
            const patient = s.patient;
            if (patient === undefined) {
              return false;
            }
            return patient.code.indexOf(search) >= 0;
          } else {
            const patient = s.patient;
            if (patient === undefined) {
              return false;
            }
            return patient.name.indexOf(search) >= 0;
          }
        })
        .map<MyEvent>((s, i) => {
          const start = moment(s.startDate);
          const end = moment(s.endDate);
          return {
            id: i,
            title: `${start.format('HH:mm')}~${end.format('HH:mm')}`,
            patientGrade: s.patient?.grade,
            patientName: s.patient?.name,
            start: start.toDate(),
            end: end.toDate(),
          };
        })
        .value(),
    );
    if (centerName) {
      setDEvents(
        _.chain(searchData)
          .filter((s) => {
            if (_.startsWith(search, 'L')) {
              const patient = s.patient;
              if (patient === undefined) {
                return false;
              }
              return patient.code.indexOf(search) >= 0;
            } else {
              const patient = s.patient;
              if (patient === undefined) {
                return false;
              }
              return patient.name.indexOf(search) >= 0;
            }
          })
          .map<MyEvent | undefined>((s, i) => {
            if (s.duty) {
              const start = moment(s.duty.startDate);
              const end = moment(s.duty.endDate);
              return {
                id: i,
                title: `${start.format('HH:mm')}~${end.format('HH:mm')}`,
                start: start.toDate(),
                end: end.toDate(),
                centerName: centerName,
                patientGrade: s.patient?.grade,
                patientName: s.patient?.name,
                history: s.histories,
                startDate: `${start.format('HH:mm')}`,
                endDate: `${end.format('HH:mm')}`,
                service: s.insurance?.service,
              };
            }
            return undefined;
          })
          .compact()
          .value(),
      );
    }
  }, [searchData, centerName, search]);

  const handleClickEmployee = (e: any, info: any) => {
    setData(info);
    if (info.service === '방문요양') {
      setState({
        ...state,
        checkedA: true,
        checkedB: false,
        checkedC: false,
      });
    } else if (info.service === '방문간호') {
      setState({
        ...state,
        checkedA: false,
        checkedB: true,
        checkedC: false,
      });
    } else {
      setState({
        ...state,
        checkedA: false,
        checkedB: false,
        checkedC: true,
      });
    }
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const PopBody = (props: {data: any}) => {
    const classes = useStyles();
    const {data} = props;
    const [historyOpen, setHistoryOpen] = useState(false);
    let totalTime = 0;

    return (
      <div className={classes.modalBack}>
        <div className={classes.modalInnerBox}>
          <div className={classes.exitModal}>
            <CloseIcon
              onClick={handleClose}
              style={{
                background: 'rgba(0, 0, 0, 0.54)',
                color: '#fff',
              }}
            />
          </div>
          <div className={classes.modalText}>
            <div className={classes.state}>
              <StarRateIcon
                style={{
                  color: '#E91E63',
                }}
              />
              <span
                style={{
                  fontSize: '1em',
                }}>
                상태 : 완료
              </span>
            </div>
            <PopBodyTopContent
              name={'센터명'}
              value={
                data.centerName && data.centerName.length > 5
                  ? data.centerName.slice(0, 5) + '...'
                  : data.centerName
              }
            />
            <PopBodyTopContent name={'등급'} value={data.patientGrade} />
            <PopBodyTopContent name={'수급자'} value={data.patientName} />
            <PopBodyTopContent name={'시작시간'} value={data.startDate} />
            <PopBodyTopContent name={'종료시간'} value={data.endDate} />
          </div>
          <div className={classes.checkBoxes}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox checked={state.checkedA} name="a" color="primary" />
                }
                label="방문요양"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={state.checkedB} name="b" color="primary" />
                }
                label="방문간호"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={state.checkedC} name="c" color="primary" />
                }
                label="방문목욕"
              />
            </FormGroup>
          </div>
          <Divider
            style={{
              width: 338,
              border: '1px solid rgba(0, 0, 0, 0.23)',
              flex: 'none',
              order: 1,
              flexGrow: 0,
              margin: '10px 0px',
            }}
          />
        </div>
        <div
          style={{
            width: '100%',
          }}>
          <IconButton
            style={{
              marginRight: theme.spacing(1),
            }}
            aria-label="expand row"
            size="small"
            onClick={() => setHistoryOpen(!historyOpen)}>
            {historyOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <span
            style={{
              fontSize: '1em',
            }}>
            처치히스토리
          </span>
        </div>

        <div className={classes.infoHistory}>
          {historyOpen ? (
            <>
              {_.sortBy(
                histories.filter((his) => his.name !== '기타'),
                'order',
              ).map((history, _idx) => {
                let hisData: any = {};
                _.keys(data).forEach((key) => {
                  if (key === 'history') {
                    hisData = data[key];
                  }
                });
                let ret: any = [];
                let total = 0;
                let detail: HistoryDetail | undefined = undefined;
                _.forEach(hisData, (d: HistoryDetail) => {
                  if (d.historyTypeId === history.key) {
                    detail = d;
                  }
                });

                _.forEach(history.children, (child) => {
                  let tmp = _.has(detail, child.time)
                    ? _.get(detail, child.time)
                    : 0;
                  total += tmp;
                  ret.push({
                    name: `${child.name}`,
                    time: _.has(detail, child.time)
                      ? _.get(detail, child.time)
                      : 0,
                  });
                });

                totalTime += total;

                return (
                  <PopBodyHistory
                    key={history.name}
                    history={history}
                    time={total}
                    data={ret}
                  />
                );
              })}
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: theme.spacing(3),
                  fontSize: '1.25em',
                  fontWeight: 500,
                }}>{`총 ${totalTime}분`}</span>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };
  const MouseOverPopever = () => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          <PopBody data={data} />
        </Popover>
      </div>
    );
  };

  const sEventComp: React.ComponentType<EventProps<MyEvent>> = ({event}) => {
    if (grade !== undefined) {
      return (
        <div>
          <div>
            {event.patientName} / {event.title}
          </div>
        </div>
      );
    }
    return null;
  };

  const dEventComp: React.ComponentType<EventProps<MyEvent>> = ({event}) => {
    if (grade !== undefined) {
      return (
        <div>
          <span onClick={(e) => handleClickEmployee(e, event)}>
            {event.patientName} / {event.title}
          </span>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {grade === 7 ? (
        <>
          <div className={classes.flex1}>
            <TextField
              style={{
                marginRight: 20,
                maxWidth: 400,
              }}
              label="수급자이름 또는 인정번호"
              fullWidth
              value={search}
              onKeyPress={() => setSearchBtn(true)}
              variant="outlined"
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              style={{
                width: 100,
              }}
              variant="contained"
              color="primary"
              onClick={() => setSearchBtn(true)}>
              검색
            </Button>
          </div>
          <br />
        </>
      ) : (
        <></>
      )}

      <StyledCalendar
        className={classes.calendar}
        value={date}
        navigationLabel={({date}) =>
          `${date.getFullYear()}년 ${date.getMonth() + 1}월`
        }
        view={viewType}
        onActiveStartDateChange={({activeStartDate}) => {
          setDate(activeStartDate);
        }}
        next2Label={null}
        prev2Label={null}
      />

      <br />
      <div className={classes.calendarPaper}>
        <Typography
          style={{
            marginBottom: 20,
          }}
          variant="h5"
          gutterBottom>
          {/* {date.getMonth() + 1}월  */}
          계획 스케쥴
        </Typography>
        <BigCalendar
          popup
          className={classes.bigCalendar}
          localizer={localizer}
          date={date}
          events={sEvents}
          views={{
            month: true,
          }}
          defaultView={'month'}
          startAccessor="start"
          endAccessor="end"
          toolbar={false}
          components={{
            event: sEventComp,
          }}
        />
      </div>

      <div
        style={{
          marginTop: 108,
        }}
        className={classes.calendarPaper}>
        <Typography
          style={{
            marginBottom: 20,
          }}
          variant="h5"
          gutterBottom>
          {/* {date.getMonth() + 1}월 */}
          달성 스케쥴
        </Typography>
        <BigCalendar
          className={classes.bigCalendar}
          localizer={localizer}
          date={date}
          events={dEvents}
          views={{
            month: true,
          }}
          defaultView={'month'}
          startAccessor="start"
          endAccessor="end"
          popup
          popupOffset={{
            x: 300,
            y: 30,
          }}
          toolbar={false}
          components={{
            event: dEventComp,
          }}
        />
        <MouseOverPopever />
      </div>
      <div className={classes.total}>
        <div className={classes.info}>
          <span>등급/수급자</span>
          <span className={classes.textSpan}>{`${jobName}`}</span>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.info}>
          <span>계획횟수</span>
          <span className={classes.textSpan}>{`${planCnt}회`}</span>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.info}>
          <span>미진행건수</span>
          <span className={classes.textSpan}>{`${
            planCnt - completeCnt
          }건`}</span>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.info}>
          <span>계획완료건수</span>
          <span className={classes.textSpan}>{`${completeCnt}건`}</span>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.info}>
          <span>진행률</span>
          <span className={classes.textSpan}>
            {planCnt !== 0
              ? `${((completeCnt / planCnt) * 100).toFixed(2)}%`
              : '0%'}
          </span>
        </div>
      </div>
    </>
  );
};

export default withStatTemplate(PatientTimeScreen, {
  grade: true,
  search: true,
  employee: true,
  title: '업무 스케쥴(수급자용)',
});
