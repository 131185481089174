import {call, put, takeLatest, select} from 'redux-saga/effects';
import {
  DUTIES_STAT,
  loadDutyLast,
  successLoadLastDuty,
  loadDutyStatEmployee,
  successDutyStatEmployee,
  loadDutyStatGrade,
  successDutyLoadStateGrade,
  requestGradeCount,
  successLoadGradeCount,
  loadStatSummary,
  successLoadSummary,
  requestDutySearch,
  successSearch,
  loadSimulationStat,
  successLoadSimulation,
  loadNurseSummary,
  successLoadNurseSummary,
  loadBathSummary,
  successLoadBathSummary,
  loadCareSummary,
  successLoadCareSummary,
  loadDutyTotals,
  successDutyLoadTotals,
} from './slice';
import {
  getDutyStat,
  getDutyGrade,
  getGradeCount,
  getDutySummary,
  getDutySearch,
  getSimulation,
  getLastDuty,
  geServiceSummary,
  getDutyTotals,
} from '~/api';

function* handleLoadLastDuty() {
  try {
    const state = yield select((state) => state[DUTIES_STAT]);
    const resp = yield call(getLastDuty, state.request);
    yield put(successLoadLastDuty(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleStatEmployee() {
  try {
    const state = yield select((state) => state[DUTIES_STAT]);
    const resp = yield call(getDutyStat, state.request);
    yield put(successDutyStatEmployee(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleGradeCount() {
  try {
    const state = yield select((state) => state[DUTIES_STAT]);
    const resp = yield call(getGradeCount, state.request);
    yield put(successLoadGradeCount(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleDutyStatGrade() {
  try {
    const state = yield select((state) => state[DUTIES_STAT]);
    const resp = yield call(getDutyGrade, state.request);
    yield put(successDutyLoadStateGrade(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleStatSummary() {
  try {
    const state = yield select((state) => state[DUTIES_STAT]);
    const resp = yield call(getDutySummary, state.request);
    yield put(successLoadSummary(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleSearch() {
  try {
    const state = yield select((state) => state[DUTIES_STAT]);
    const resp = yield call(getDutySearch, state.request);
    yield put(successSearch(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleSimulationStat() {
  try {
    const state = yield select((state) => state[DUTIES_STAT]);
    const resp = yield call(getSimulation, state.request);
    yield put(successLoadSimulation(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleNurseSummary() {
  try {
    const state = yield select((state) => state[DUTIES_STAT]);
    const resp = yield call(geServiceSummary, state.request);
    yield put(successLoadNurseSummary(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handlePnurseSummary() {
  try {
    const state = yield select((state) => state[DUTIES_STAT]);
    const resp = yield call(geServiceSummary, state.request);
    yield put(successLoadBathSummary(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}
function* handleCareSummary() {
  try {
    const state = yield select((state) => state[DUTIES_STAT]);
    const resp = yield call(geServiceSummary, state.request);
    yield put(successLoadCareSummary(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleDutyGradeTotals() {
  try {
    const state = yield select((state) => state[DUTIES_STAT]);
    const resp = yield call(getDutyTotals, state.request);
    yield put(successDutyLoadTotals(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

export default function* watchDutyStat() {
  yield takeLatest(loadDutyLast, handleLoadLastDuty);
  yield takeLatest(loadDutyStatEmployee, handleStatEmployee);
  yield takeLatest(loadDutyStatGrade, handleDutyStatGrade);
  yield takeLatest(requestGradeCount, handleGradeCount);
  yield takeLatest(loadStatSummary, handleStatSummary);
  yield takeLatest(requestDutySearch, handleSearch);
  yield takeLatest(loadSimulationStat, handleSimulationStat);
  yield takeLatest(loadNurseSummary, handleNurseSummary);
  yield takeLatest(loadBathSummary, handlePnurseSummary);
  yield takeLatest(loadCareSummary, handleCareSummary);
  yield takeLatest(loadDutyTotals, handleDutyGradeTotals);
}
