import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import Statistics from '~/models/statistics';
import {
  StatReqeustPayload,
  StatRequestGrade,
  StatResult,
  TotalStat,
} from '~/types/types';

type State = {
  loading: boolean;
  request: StatReqeustPayload | StatRequestGrade | undefined;
  total: Statistics; // 오리지널 total
  schTotals?: TotalStat[]; // 수정한 total
  employee: Statistics;
  nurse: Statistics;
  pnurse: Statistics;
  care: Statistics;
  priceByGrades: StatResult | undefined;
  schSearch?: StatResult;
};

let initialState: State = {
  loading: false,
  request: undefined,
  total: {range: [], total: [], raw: []},
  schTotals: undefined,
  employee: {range: [], total: [], raw: []},
  nurse: {range: [], total: [], raw: []},
  pnurse: {range: [], total: [], raw: []},
  care: {range: [], total: [], raw: []},
  priceByGrades: undefined,
  schSearch: undefined,
};

const slice = createSlice({
  name: 'schedules_stat',
  initialState,
  reducers: {
    loadSchStatTotal: (state, action: PayloadAction<StatReqeustPayload>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successSchStatTotal: (state, action: PayloadAction<Statistics>) => {
      state.loading = false;
      state.total = action.payload;
    },
    loadSchStatEmployee: (state, action: PayloadAction<StatReqeustPayload>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successSchStatEmployee: (state, action: PayloadAction<Statistics>) => {
      state.loading = false;
      state.employee = action.payload;
    },
    loadSchStatNurse: (state, action: PayloadAction<StatReqeustPayload>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successSchStatNurse: (state, action: PayloadAction<Statistics>) => {
      state.loading = false;
      state.nurse = action.payload;
    },
    loadSchStatCare: (state, action: PayloadAction<StatReqeustPayload>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successSchStatCare: (state, action: PayloadAction<Statistics>) => {
      state.loading = false;
      state.care = action.payload;
    },
    loadSchStatGrade: (state, action: PayloadAction<StatRequestGrade>) => {
      state.loading = true;
      state.request = action.payload;
      state.schTotals = undefined;
      state.schSearch = undefined;
    },
    successLoadSchStateGrade: (state, action: PayloadAction<StatResult>) => {
      state.loading = false;
      state.priceByGrades = action.payload;
    },
    requestSchSearch: (state, action: PayloadAction<StatReqeustPayload>) => {
      state.loading = true;
      state.request = action.payload;
      state.priceByGrades = undefined;
    },
    successSearch: (state, action: PayloadAction<StatResult>) => {
      state.loading = false;
      state.priceByGrades = undefined;
      state.schTotals = undefined;
      state.schSearch = action.payload;
    },
    requestSchTotals: (state, action: PayloadAction<StatReqeustPayload>) => {
      state.loading = true;
      state.priceByGrades = undefined;
      state.schSearch = undefined;
      state.request = action.payload;
    },
    successLoadSchTotals: (state, action: PayloadAction<TotalStat[]>) => {
      state.loading = false;
      state.schTotals = action.payload;
    },
  },
});

export const {
  loadSchStatTotal,
  successSchStatTotal,
  loadSchStatEmployee,
  successSchStatEmployee,
  loadSchStatNurse,
  successSchStatNurse,
  loadSchStatCare,
  successSchStatCare,
  loadSchStatGrade,
  successLoadSchStateGrade,
  requestSchSearch,
  successSearch,
  requestSchTotals,
  successLoadSchTotals,
} = slice.actions;
export const SCHEDULES_STAT = slice.name;
export default slice.reducer;
