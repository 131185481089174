import {combineReducers} from '@reduxjs/toolkit';
import {reducer as formReducer} from 'redux-form';
import scheduleStatReducer, {
  SCHEDULES_STAT,
} from '~/features/ScheduleStat/slice';
import dutyStatReducer, {DUTIES_STAT} from '~/features/DutyStat/slice';
import scheduleReducer, {SCHEDULES} from '~/features/Schedule/slice';
import loginReducer, {LOGIN} from '~/features/Login/slice';
import accountTypeReducer, {ACCOUNT_TYPE} from '~/features/AccountType/slice';
import employeeReducer, {EMPLOYEES} from '~/features/Employee/slice';
import accountsReducer, {ACCOUNTS} from '~/features/Account/slice';
import patientsReducer, {PATIENT} from '~/features/Patient/slice';
import bepReducer, {BEP} from '~/features/BEP/slice';
import signUpReducer, {SIGNUP} from '~/features/SignUp/slice';
import profileReducer, {PROFILE} from '~/features/Profile/slice';
import historyReducer, {HISTORY} from '~/features/History/slice';
import centerReducer, {CENTER} from '~/features/Center/slice';
import inventoryReducer, {INVENTORY} from '~/features/Inventory/slice';
import departmentReducer, {DEPARTMENT} from '~/features/Department/slice';
import simulationReducer, {SIMULATION} from '~/features/Simulation/slice';

const rootReducer = combineReducers({
  [SCHEDULES_STAT]: scheduleStatReducer,
  [DUTIES_STAT]: dutyStatReducer,
  [SCHEDULES]: scheduleReducer,
  [LOGIN]: loginReducer,
  [SIGNUP]: signUpReducer,
  [PROFILE]: profileReducer,
  [ACCOUNT_TYPE]: accountTypeReducer,
  [EMPLOYEES]: employeeReducer,
  [ACCOUNTS]: accountsReducer,
  [PATIENT]: patientsReducer,
  [HISTORY]: historyReducer,
  [INVENTORY]: inventoryReducer,
  [CENTER]: centerReducer,
  [BEP]: bepReducer,
  [DEPARTMENT]: departmentReducer,
  [SIMULATION]: simulationReducer,
  form: formReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
