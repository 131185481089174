import React from 'react';
import Calendar, {CalendarProps} from 'react-calendar';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  calendar: {
    display: 'flex',
    border: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    '& > .react-calendar__navigation': {
      '& > .react-calendar__navigation__arrow': {
        //color: '#A4A4A4', // 반영하면 더 안보여서 적용 안함
      },
      '& > .react-calendar__navigation__label': {
        fontSize: '1.25em',
        fontWeight: 500,
      },
    },
    '& > .react-calendar__viewContainer': {
      width: '100%',
      border: '1px solid #C2C2C2',
      boxSizing: 'border-box',
      borderRadius: theme.spacing(0.5),
    },
    '& > .react-calendar__viewContainer > .react-calendar__year-view > .react-calendar__year-view__months > button': {
      padding: '1.3em 0.5em',
      alignItems: 'center',
      height: 50,
    },
    '& > .react-calendar__viewContainer > .react-calendar__decade-view > .react-calendar__decade-view__years > button': {
      padding: '1.3em 0.5em',
      alignItems: 'center',
      height: 50,
    },
  },
}));

const StyledCalendar = (props: CalendarProps) => {
  const classes = useStyles();

  return <Calendar className={classes.calendar} {...props} />;
};

export default StyledCalendar;
