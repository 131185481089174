import React, {useState, useCallback, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import {
  Button,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  useTheme,
} from '@material-ui/core';
import moment from 'moment';
import {SimulationStat, STAT_SCALE} from '~/types/types';
import {BEP, loadAccStat, loadWageStat} from '~/features/BEP/slice';
import {RootState} from '~/app/rootReducer';
import _ from 'lodash';

import {Typography} from '~/components/Typography';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import AddIcon from '@material-ui/icons/Add';
import {
  DUTIES_STAT,
  loadDutyLast,
  loadSimulationStat,
  loadStatSummary,
} from '~/features/DutyStat/slice';
import {PROFILE} from '~/features/Profile/slice';
import {DEPARTMENT, loadDepartment} from '~/features/Department/slice';
import {INVENTORY, loadMaterialCost} from '~/features/Inventory/slice';
import html2canvas from 'html2canvas';
import CloseIcon from '@material-ui/icons/Close';
import {
  addSimCount,
  addSimulation_1,
  addSimulation_2,
  initSimulation_1,
  initSimulation_2,
  minSimCount,
  SIMULATION,
  updateCurrentSimulationA,
  updateCurrentSimulationB,
  updateSimulation_1,
  updateSimulation_2,
} from '~/features/Simulation/slice';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
  ComposedChart,
} from 'recharts';

const useStyles = makeStyles((theme) => ({
  simul: {
    marginTop: theme.spacing(3) + 3,
  },
  table: {
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 'none',
    border: '1px solid #7F91F7',
    boxSizing: 'border-box',
    borderRadius: theme.spacing(1.25),
    '& > table > thead > tr > th': {
      padding: theme.spacing(1, 3.75),
      fontWeight: 400,
      border: 'none',
    },
  },
  backColorBlue: {
    fontSize: '0.86em',
    backgroundColor: `#7F91F7`,
    color: '#fff',
  },
  addBtn: {
    margin: theme.spacing(2, 1.5, 2, 0),
    display: 'flex',
    justifyContent: 'end',
  },
  add: {
    width: 'fit-content',
    height: 40,
  },
  tableMid: {
    display: 'flex',
    maxWidth: 430,
    justifyContent: 'center',
    boxShadow: 'none',
    border: '1px solid #7F91F7',
    boxSizing: 'border-box',
    borderRadius: theme.spacing(1.25),
    '& > table > thead > tr > th': {
      padding: theme.spacing(1, 3.75),
      fontWeight: 400,
      backgroundColor: `#7F91F7`,
      border: 'none',
    },
  },
  addHeader: {
    fontSize: '0.86em',
    color: '#fff',
  },
  addTable: {
    width: '100%',
    //justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    '&:last-child': {
      '& > div': {
        marginRight: `${theme.spacing(0)} !important`,
      },
    },
  },
  service: {
    display: 'flex',
    marginTop: theme.spacing(3.75),
  },
  chartContainer: {
    width: '100%',
    flexGrow: 1,
    height: 436,
    marginTop: theme.spacing(4) + 3,
  },
  chartLegend: {
    '& > div > svg > path': {
      fill: 'none',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper': {
      top: '0px !important',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend > .recharts-legend-item': {
      display: 'flex !important',
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '0.75em',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend > li > svg': {
      width: 50,
      height: 11,
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend > li > svg > path': {
      d: 'path("M-50,0h146v32h-146z")',
    },
  },
  tooltip: {
    //height: 203,
    padding: theme.spacing(2.5, 1.875, 1.125),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    //alignItems: 'center',
    backgroundColor: '#FFFFFF',
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: theme.spacing(0.5),
  },
  tooltip__label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 23,
    marginBottom: theme.spacing(1.125),
    fontSize: '1em',
  },
  downDiv: {
    marginTop: theme.spacing(1.125),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',
  },
  downBox: {
    display: 'flex',
    width: 'fit-content',
    height: 35,
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid rgba(0, 0, 0, 0.54)',
    boxSizing: 'border-box',
    borderRadius: theme.spacing(0.5),
    color: 'rgba(0, 0, 0, 0.54)',
  },
  input: {
    '& > input': {textAlign: 'center'},
    fontSize: '0.75em',
    fontWeight: 400,
  },
}));

const RenderSimulTotal = (props: {
  current: boolean;
  change: boolean;
  title: string;
  sales: number;
  expenditure: number;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {current, change, title, sales, expenditure} = props;
  const theme = useTheme() as Theme;
  const HEADER = ['매출', '지출', '이익', '이익률'];
  const {currentA, currentB} = useSelector(
    (state: RootState) => state[SIMULATION],
  );

  return (
    <div className={classes.addTable}>
      <TableContainer
        className={classes.tableMid}
        style={{marginRight: theme.spacing(2.5)}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.addHeader}
                style={{
                  background: current ? '#7F91F7' : '#757575',
                }}
                align="center"
                colSpan={change ? 3 : 4}>
                {title}
              </TableCell>
              {change ? (
                <TableCell
                  style={{
                    background: current ? '#7F91F7' : '#757575',
                  }}
                  align="right">
                  <CloseIcon
                    onClick={() => {
                      if (currentA) {
                        dispatch(minSimCount());
                        dispatch(initSimulation_1());
                      } else if (currentB) {
                        dispatch(minSimCount());
                        dispatch(initSimulation_2());
                      }
                    }}
                    fontSize="small"
                  />
                </TableCell>
              ) : (
                <></>
              )}
            </TableRow>
            <TableRow>
              {HEADER.map((h, idx) => (
                <TableCell
                  key={idx}
                  style={{
                    fontSize: '0.86em',
                    backgroundColor: '#F6F6F6',
                  }}
                  align="center">{`${h}`}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={{fontSize: '0.86em'}} align="center">
                {`${sales.toLocaleString()}원`}
              </TableCell>
              <TableCell style={{fontSize: '0.86em'}} align="center">
                {`${expenditure.toLocaleString()}원`}
              </TableCell>
              <TableCell style={{fontSize: '0.86em'}} align="center">
                {`${(sales - expenditure).toLocaleString()}원`}
              </TableCell>
              <TableCell style={{fontSize: '0.86em'}} align="center">
                {expenditure !== 0 && sales - expenditure > 0
                  ? `${(((sales - expenditure) / sales) * 100).toFixed(2)}%`
                  : '-'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const RenderComponent = (props: {
  current: boolean;
  change: boolean;
  simulation: SimulationStat[];
  serviceWage: any;
  materialCost: number;
  operationCost: number;
  sales: number;
  expenditure: number;
  NURSESUGA: number[];
  CARESUGA: number[];
  //BATHSUGA: number[];
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    current,
    change,
    simulation,
    serviceWage,
    materialCost,
    operationCost,
    sales,
    expenditure,
    NURSESUGA,
    CARESUGA,
    //BATHSUGA,
  } = props;
  const TOTALHEADER = [
    '횟수',
    '매출',
    '인건비',
    '재료비',
    '운영관리비',
    '수익',
    '수익률',
  ];
  const {currentA, currentB} = useSelector(
    (state: RootState) => state[SIMULATION],
  );

  const [counts, setCounts] = useState<number[]>(
    _.map(simulation, (s) => s.count),
  );
  const [sale, setSale] = useState<number[]>(_.map(simulation, (s) => s.price)); // 매출
  const [expend, setExpend] = useState(expenditure); // 지출
  const [wages, setWages] = useState<number[]>([
    // 인건비
    serviceWage.nurse,
    serviceWage.care,
    //serviceWage.bath,
    serviceWage.welfare,
  ]);
  const [mater, setMater] = useState(materialCost); // 재료비
  const [oper, setOper] = useState(operationCost); // 운영관리비

  useEffect(() => {
    setExpend(_.sum(wages) + mater + oper);
  }, [wages, mater, oper]);

  useEffect(() => {
    if (change && currentA) {
      dispatch(updateSimulation_1({sale: _.sum(sale), expenditure: expend}));
    } else if (change && currentB) {
      dispatch(updateSimulation_2({sale: _.sum(sale), expenditure: expend}));
    }
  }, [dispatch, sale, expend, currentA, currentB, change]);

  return current ? (
    <div className={classes.service}>
      <TableContainer className={classes.table} style={{border: 'none'}}>
        <Table style={{border: '1px solid #7F91F7'}}>
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.backColorBlue}
                align="center"
                colSpan={2}>
                구분
              </TableCell>
              {TOTALHEADER.map((i, idx) => (
                <TableCell
                  key={idx}
                  className={classes.backColorBlue}
                  align="center">{`${i}`}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                style={{fontSize: '0.75em'}}
                align="center"
                rowSpan={4}>
                방문간호
              </TableCell>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                {simulation[0].name}
              </TableCell>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                {/* {`${simulation[0].count.toLocaleString()}회`} */}
                {change ? (
                  <InputBase
                    className={classes.input}
                    defaultValue={`${counts[0].toLocaleString()}회`}
                    onChange={(e) => {
                      let newArr = _.map(counts, (c, idx) => {
                        if (idx === 0) {
                          return parseInt(e.target.value);
                        }
                        return c;
                      });
                      let newSale = _.map(sale, (s, idx) => {
                        if (idx === 0) {
                          return parseInt(e.target.value) * NURSESUGA[0];
                        }
                        return s;
                      });
                      setCounts(newArr);
                      setSale(newSale);
                    }}
                  />
                ) : (
                  `${simulation[0].count.toLocaleString()}회`
                )}
              </TableCell>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                {change
                  ? `${(counts[0] * NURSESUGA[0]).toLocaleString()}원`
                  : `${simulation[0].price // simulation[0].count * NURSESUGA[0]
                      .toLocaleString()}원`}
              </TableCell>
              <TableCell
                rowSpan={4}
                style={{fontSize: '0.75em'}}
                align="center">
                {change ? (
                  <InputBase
                    className={classes.input}
                    defaultValue={`${wages[0].toLocaleString()}원`}
                    onChange={(e) => {
                      let newArr = _.map(wages, (w, idx) => {
                        if (idx === 0) {
                          return parseInt(
                            e.target.value
                              .toString()
                              .slice(0, e.target.value.toString().length - 1)
                              .replace(/,/g, ''),
                          );
                        }
                        return w;
                      });
                      setWages(newArr);
                    }}
                  />
                ) : (
                  `${serviceWage.nurse.toLocaleString()}원`
                )}
              </TableCell>
              <TableCell
                rowSpan={15}
                style={{fontSize: '0.75em'}}
                align="center">
                {change ? (
                  <InputBase
                    className={classes.input}
                    defaultValue={`${mater.toLocaleString()}원`}
                    onChange={(e) => {
                      const value = e.target.value
                        .toString()
                        .slice(0, e.target.value.toString().length - 1)
                        .replace(/,/g, '');
                      setMater(parseInt(value));
                    }}
                  />
                ) : (
                  `${materialCost.toLocaleString()}원`
                )}
              </TableCell>
              <TableCell
                style={{fontSize: '0.75em'}}
                align="center"
                rowSpan={15}>
                {change ? (
                  <InputBase
                    className={classes.input}
                    defaultValue={`${oper.toLocaleString()}원`}
                    onChange={(e) =>
                      setOper(
                        parseInt(
                          e.target.value
                            .toString()
                            .slice(0, e.target.value.toString().length - 1)
                            .replace(/,/g, ''),
                        ),
                      )
                    }
                  />
                ) : (
                  `${operationCost.toLocaleString()}원`
                )}
              </TableCell>
              <TableCell
                style={{fontSize: '0.75em'}}
                align="center"
                rowSpan={15}>
                {change
                  ? `${(_.sum(sale) - expend).toLocaleString()}원`
                  : `${(sales - expenditure).toLocaleString()}원`}
              </TableCell>
              <TableCell
                style={{fontSize: '0.75em'}}
                align="center"
                rowSpan={15}>
                {change
                  ? expend !== 0 && _.sum(sale) - expend > 0
                    ? `${(((_.sum(sale) - expend) / _.sum(sale)) * 100).toFixed(
                        2,
                      )}%`
                    : '-'
                  : expenditure !== 0 && sales - expenditure > 0
                  ? `${(((sales - expenditure) / sales) * 100).toFixed(2)}%`
                  : '-'}
              </TableCell>
            </TableRow>
            {_.map(simulation.slice(1, 4), (s, idx) => (
              <TableRow key={idx}>
                <TableCell style={{fontSize: '0.75em'}} align="center">
                  {s.name}
                </TableCell>
                <TableCell style={{fontSize: '0.75em'}} align="center">
                  {change ? (
                    <InputBase
                      className={classes.input}
                      defaultValue={`${counts[idx + 1].toLocaleString()}회`}
                      onChange={(e) => {
                        let newArr = _.map(counts, (c, i) => {
                          if (i === idx + 1) {
                            return parseInt(e.target.value);
                          }
                          return c;
                        });
                        let newSale = _.map(sale, (s_, i) => {
                          if (i === idx + 1) {
                            return (
                              parseInt(e.target.value) * NURSESUGA[idx + 1]
                            );
                          }
                          return s_;
                        });
                        setCounts(newArr);
                        setSale(newSale);
                      }}
                    />
                  ) : (
                    `${s.count.toLocaleString()}회`
                  )}
                </TableCell>
                <TableCell style={{fontSize: '0.75em'}} align="center">
                  {change
                    ? `${(
                        counts[idx + 1] * NURSESUGA[idx + 1]
                      ).toLocaleString()}원`
                    : `${s.price.toLocaleString()}원`}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell
                style={{fontSize: '0.75em'}}
                align="center"
                rowSpan={10}>
                방문요양
              </TableCell>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                {simulation[4].name}
              </TableCell>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                {/* {`${simulation[3].count.toLocaleString()}회`} */}
                {change ? (
                  <InputBase
                    className={classes.input}
                    defaultValue={`${counts[4].toLocaleString()}회`}
                    onChange={(e) => {
                      let newArr = _.map(counts, (c, idx) => {
                        if (idx === 4) {
                          return parseInt(e.target.value);
                        }
                        return c;
                      });
                      let newSale = _.map(sale, (s, idx) => {
                        if (idx === 4) {
                          return parseInt(e.target.value) * CARESUGA[0];
                        }
                        return s;
                      });
                      setCounts(newArr);
                      setSale(newSale);
                    }}
                  />
                ) : (
                  `${simulation[4].count.toLocaleString()}회`
                )}
              </TableCell>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                {/* {`${(simulation[3].count * CARESUGA[0]).toLocaleString()}원`} */}
                {change
                  ? `${(counts[4] * CARESUGA[0]).toLocaleString()}원`
                  : `${simulation[4].price.toLocaleString()}원`}
              </TableCell>
              <TableCell
                rowSpan={10}
                style={{fontSize: '0.75em'}}
                align="center">
                {change ? (
                  <InputBase
                    className={classes.input}
                    defaultValue={`${wages[1].toLocaleString()}원`}
                    onChange={(e) => {
                      let newArr = _.map(wages, (w, idx) => {
                        if (idx === 1) {
                          return parseInt(
                            e.target.value
                              .toString()
                              .slice(0, e.target.value.toString().length - 1)
                              .replace(/,/g, ''),
                          );
                        }
                        return w;
                      });
                      setWages(newArr);
                    }}
                  />
                ) : (
                  `${serviceWage.care.toLocaleString()}원`
                )}
              </TableCell>
            </TableRow>
            {_.map(simulation.slice(5, 14), (s, idx) => (
              <TableRow key={idx}>
                <TableCell style={{fontSize: '0.75em'}} align="center">
                  {s.name}
                </TableCell>
                <TableCell style={{fontSize: '0.75em'}} align="center">
                  {change ? (
                    <InputBase
                      className={classes.input}
                      defaultValue={`${counts[idx + 5].toLocaleString()}회`}
                      onChange={(e) => {
                        let newArr = _.map(counts, (c, i) => {
                          if (i === idx + 5) {
                            return parseInt(e.target.value);
                          }
                          return c;
                        });
                        let newSale = _.map(sale, (s_, i) => {
                          if (i === idx + 5) {
                            return parseInt(e.target.value) * CARESUGA[idx + 1];
                          }
                          return s_;
                        });
                        setCounts(newArr);
                        setSale(newSale);
                      }}
                    />
                  ) : (
                    `${s.count.toLocaleString()}회`
                  )}
                </TableCell>
                <TableCell style={{fontSize: '0.75em'}} align="center">
                  {change
                    ? `${(
                        counts[idx + 5] * CARESUGA[idx + 1]
                      ).toLocaleString()}원`
                    : `${s.price.toLocaleString()}원`}
                </TableCell>
              </TableRow>
            ))}
            {/*             
            <TableRow>
              <TableCell
                style={{fontSize: '0.75em'}}
                align="center"
                rowSpan={3}>
                방문목욕
              </TableCell>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                {simulation[14].name}
              </TableCell>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                {change ? (
                  <InputBase
                    className={classes.input}
                    defaultValue={`${counts[14].toLocaleString()}회`}
                    onChange={(e) => {
                      let newArr = _.map(counts, (c, idx) => {
                        if (idx === 14) {
                          return parseInt(e.target.value);
                        }
                        return c;
                      });
                      let newSale = _.map(sale, (s, idx) => {
                        if (idx === 14) {
                          return parseInt(e.target.value) * BATHSUGA[0];
                        }
                        return s;
                      });
                      setCounts(newArr);
                      setSale(newSale);
                    }}
                  />
                ) : (
                  `${simulation[14].count.toLocaleString()}회`
                )}
              </TableCell>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                {change
                  ? `${(counts[14] * BATHSUGA[0]).toLocaleString()}원`
                  : `${simulation[14].price.toLocaleString()}원`}
              </TableCell>
              <TableCell
                rowSpan={3}
                style={{fontSize: '0.75em'}}
                align="center">
                {change ? (
                  <InputBase
                    className={classes.input}
                    defaultValue={`${wages[2].toLocaleString()}원`}
                    onChange={(e) => {
                      let newArr = _.map(wages, (w, idx) => {
                        if (idx === 2) {
                          return parseInt(
                            e.target.value
                              .toString()
                              .slice(0, e.target.value.toString().length - 1)
                              .replace(/,/g, ''),
                          );
                        }
                        return w;
                      });
                      setWages(newArr);
                    }}
                  />
                ) : (
                  `${serviceWage.bath.toLocaleString()}원`
                )}
              </TableCell>
            </TableRow>
            {_.map(simulation.slice(15, 17), (s, idx) => (
              <TableRow key={idx}>
                <TableCell style={{fontSize: '0.75em'}} align="center">
                  {s.name}
                </TableCell>
                <TableCell style={{fontSize: '0.75em'}} align="center">
                  {change ? (
                    <InputBase
                      className={classes.input}
                      defaultValue={`${counts[idx + 14].toLocaleString()}회`}
                      onChange={(e) => {
                        let newArr = _.map(counts, (c, i) => {
                          if (i === idx + 14) {
                            return parseInt(e.target.value);
                          }
                          return c;
                        });
                        let newSale = _.map(sale, (s_, i) => {
                          if (i === idx + 14) {
                            return parseInt(e.target.value) * CARESUGA[idx + 1];
                          }
                          return s_;
                        });
                        setCounts(newArr);
                        setSale(newSale);
                      }}
                    />
                  ) : (
                    `${s.count.toLocaleString()}회`
                  )}
                </TableCell>
                <TableCell style={{fontSize: '0.75em'}} align="center">
                  {change
                    ? `${(
                        counts[idx + 14] * BATHSUGA[idx + 1]
                      ).toLocaleString()}원`
                    : `${s.price.toLocaleString()}원`}
                </TableCell>
              </TableRow>
            ))}
 */}
            <TableRow>
              <TableCell
                style={{fontSize: '0.75em', paddingLeft: 34}}
                align="left"
                colSpan={4}>
                사회복지사
              </TableCell>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                {change ? (
                  <InputBase
                    className={classes.input}
                    defaultValue={`${wages[2].toLocaleString()}원`}
                    onChange={(e) => {
                      let newArr = _.map(wages, (w, idx) => {
                        if (idx === 2) {
                          return parseInt(
                            e.target.value
                              .toString()
                              .slice(0, e.target.value.toString().length - 1)
                              .replace(/,/g, ''),
                          );
                        }
                        return w;
                      });
                      setWages(newArr);
                    }}
                  />
                ) : (
                  `${serviceWage.welfare.toLocaleString()}원`
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                className={classes.backColorBlue}
                align="center"
                colSpan={2}>
                소계
              </TableCell>
              <TableCell className={classes.backColorBlue} align="center">
                {change
                  ? `${_.sum(counts).toLocaleString()}회`
                  : `${_.sum(
                      _.map(simulation, (s) => s.count),
                    ).toLocaleString()}회`}
              </TableCell>
              <TableCell className={classes.backColorBlue} align="center">
                {change
                  ? `${_.sum(sale).toLocaleString()}원`
                  : `${sales.toLocaleString()}원`}
              </TableCell>
              <TableCell className={classes.backColorBlue} align="center">
                {change
                  ? `${_.sum(wages).toLocaleString()}원`
                  : `${(
                      serviceWage.nurse +
                      serviceWage.care +
                      //serviceWage.bath +
                      serviceWage.welfare
                    ).toLocaleString()}원`}
              </TableCell>
              <TableCell className={classes.backColorBlue} align="center">
                {change
                  ? `${mater.toLocaleString()}원`
                  : `${materialCost.toLocaleString()}원`}
              </TableCell>
              <TableCell className={classes.backColorBlue} align="center">
                {change
                  ? `${oper.toLocaleString()}원`
                  : `${operationCost.toLocaleString()}원`}
              </TableCell>
              <TableCell className={classes.backColorBlue} align="center">
                {change
                  ? `${(_.sum(sale) - expend).toLocaleString()}원`
                  : `${(sales - expenditure).toLocaleString()}원`}
              </TableCell>
              <TableCell className={classes.backColorBlue} align="center">
                {change
                  ? expend !== 0 && _.sum(sale) - expend > 0
                    ? `${(((_.sum(sale) - expend) / _.sum(sale)) * 100).toFixed(
                        2,
                      )}%`
                    : '-'
                  : expenditure !== 0 && sales - expenditure > 0
                  ? `${(((sales - expenditure) / sales) * 100).toFixed(2)}%`
                  : '-'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  ) : (
    <></>
  );
};

const CostScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme() as Theme;

  const wages = useSelector((state: RootState) => state[BEP].wage);
  const account = useSelector((state: RootState) => state[BEP].account);
  const {summary, simulation, lastInfo} = useSelector(
    (state: RootState) => state[DUTIES_STAT],
  );
  const {
    simCnt,
    sale_1,
    sale_2,
    expenditure_1,
    expenditure_2,
    flag_1,
    flag_2,
  } = useSelector((state: RootState) => state[SIMULATION]);
  // 센터 id 가져오기
  const userId = useSelector((state: RootState) => state[PROFILE].id);
  const centerId = useSelector(
    (state: RootState) => state[DEPARTMENT].centerInfo?.centerId,
  );
  const materialCost = useSelector(
    (state: RootState) => state[INVENTORY].materialCost,
  );
  const NURSESUGA = [36530, 45810, 55120, 3000];
  const CARESUGA = [
    14750,
    22640,
    30370,
    38340,
    43570,
    48170,
    52400,
    56320,
    95975,
    108590,
  ];
  //const BATHSUGA = [35530, 45810, 55120];
  //const [addCnt, setAddCnt] = useState(0);
  const [sales, setSales] = useState(0);
  const [expenditure, setExpenditure] = useState(0);
  const [current, setCurrent] = useState({
    currentA: true,
    currentB: false,
    currentC: false,
  });
  const [serviceWage, setServiceWage] = useState({
    nurse: 0,
    care: 0,
    //bath: 0,
    welfare: 0,
  });
  const [operation, setOperation] = useState(0);
  const [material, setMaterial] = useState(0);

  const [sumPatient, setSumPatient] = useState<
    ReadonlyArray<{[key: string]: string | number}>
  >();

  const GRADEHEADER = [
    '전체',
    '1등급',
    '2등급',
    '3등급',
    '4등급',
    '5등급',
    '인지지원등급',
    '소계',
  ];
  const GRADEMAX = [1520700, 1351700, 1295400, 1189800, 1021300, 573900];
  const [totalGraph, setTotalGraph] = useState([{}]);
  const [lastDutyDate, setLastDutyDate] = useState('');
  const [standard, setStandard] = useState('기준값');
  useEffect(() => {
    if (userId) {
      dispatch(loadDepartment(userId));
    }
  }, [dispatch, userId]);

  // duty에서 가장 최근 값 검색해서 해당 달의 정보를 가져오도록 해야 함
  useEffect(() => {
    if (centerId) {
      const request = {
        centerId,
      };
      dispatch(loadDutyLast(request));
    }
  }, [dispatch, centerId]);

  useEffect(() => {
    if (lastInfo !== '') {
      setLastDutyDate(lastInfo.slice(0, 10));
      setStandard(`기준값 (${lastInfo.slice(0, 10)})`);
    }
  }, [lastInfo]);

  useEffect(() => {
    // 이전 달 정보를 불러와야 함
    // const nowDate = new Date();
    // const prevDate = `${nowDate.getFullYear()}-${nowDate
    //   .getMonth()
    //   .toString()
    //   .padStart(2, '0')}-${nowDate.getDay()}`;
    // 마지막 duty가 있는 달의 정보를 불러오는 것으로 변경(2022.05.27)
    if (centerId && lastDutyDate !== '') {
      const request = {
        scale: STAT_SCALE.WEEK,
        datetime: moment(new Date(lastDutyDate)),
        centerId: centerId,
      };
      dispatch(loadAccStat(request));
      //dispatch(loadStatStore(request));
      dispatch(loadWageStat(request));
      dispatch(loadMaterialCost(request));
    }
  }, [dispatch, centerId, lastDutyDate]);

  useEffect(() => {
    if (centerId && lastDutyDate !== '') {
      // 이전 달 정보를 불러와야 함
      // const nowDate = new Date();
      // const prevDate = `${nowDate.getFullYear()}-${nowDate
      //   .getMonth()
      //   .toString()
      //   .padStart(2, '0')}-${nowDate.getDay()}`;
      // 마지막 duty가 있는 달의 정보를 불러오는 것으로 변경(2022.05.27)
      const request = {
        datetime: moment(new Date(lastDutyDate)),
        centerId: centerId,
      };
      // 수급자 현황
      dispatch(loadStatSummary(request));
      dispatch(loadSimulationStat(request));
    }
  }, [dispatch, centerId, lastDutyDate]);

  // 등급 별 환자 수 카운트
  useEffect(() => {
    if (summary) {
      // 수급자현황
      const first = _.filter(summary, (s) => s.grade === 1);
      const second = _.filter(summary, (s) => s.grade === 2);
      const third = _.filter(summary, (s) => s.grade === 3);
      const fourth = _.filter(summary, (s) => s.grade === 4);
      const fifth = _.filter(summary, (s) => s.grade === 5);
      const cog = _.filter(summary, (s) => s.grade === 6);

      const ret2 = [
        {
          name: '1등급',
          count: _.uniqBy(first, (f) => f.patientName).length,
        },
        {
          name: '2등급',
          count: _.uniqBy(second, (f) => f.patientName).length,
        },
        {
          name: '3등급',
          count: _.uniqBy(third, (f) => f.patientName).length,
        },
        {
          name: '4등급',
          count: _.uniqBy(fourth, (f) => f.patientName).length,
        },
        {
          name: '5등급',
          count: _.uniqBy(fifth, (f) => f.patientName).length,
        },
        {
          name: '인지지원등급',
          count: _.uniqBy(cog, (f) => f.patientName).length,
        },
      ];

      setSumPatient(
        _.map(ret2, (r) => {
          return {
            name: r.name,
            count: r.count,
          };
        }),
      );
    }
  }, [summary]);

  // 인건비
  // 총괄운영현황의 인건비로 다시 수정
  useEffect(() => {
    const nurse = _.sum(
      _.values(wages).map((w) =>
        _.has(w, '방문간호') ? _.get(w, '방문간호') : 0,
      ),
    );
    const care = _.sum(
      _.values(wages).map((w) =>
        _.has(w, '방문요양') ? _.get(w, '방문요양') : 0,
      ),
    );
    // const bath = _.sum(
    //   _.values(wages).map((w) =>
    //     _.has(w, '방문목욕') ? _.get(w, '방문목욕') : 0,
    //   ),
    // );
    const welfare = _.sum(
      _.values(wages).map((w) =>
        _.has(w, '사회복지사') ? _.get(w, '사회복지사') : 0,
      ),
    );
    setServiceWage({
      nurse: nurse,
      care: care,
      //bath: bath,
      welfare: welfare,
    });
  }, [wages]);

  // 재료비
  useEffect(() => {
    setMaterial(_.sum(_.values(materialCost).map((d) => _.sum(_.values(d)))));
  }, [materialCost]);

  // 운영관리비
  useEffect(() => {
    setOperation(
      _.sum(
        _.values(account).map((d) =>
          _.sum(
            _.keys(d).map((q) => {
              if (d && q !== '기타' && q !== '재료비' && q !== '인건비') {
                return d[q];
              }
              return 0;
            }),
          ),
        ),
      ),
    );
  }, [account]);

  // 매출 계산
  useEffect(() => {
    setSales(_.sum(_.map(simulation, (s) => s.price)));
  }, [simulation]);
  // useEffect(() => {
  //   const nurse = _.sum(
  //     _.map(simulation.slice(0, 4), (s, idx) => s.count * NURSESUGA[idx]),
  //   );
  //   const care = _.sum(
  //     _.map(simulation.slice(4, 14), (s, idx) => s.count * CARESUGA[idx]),
  //   );
  //   const bath = _.sum(
  //     _.map(simulation.slice(14, 17), (s, idx) => s.count * BATHSUGA[idx]),
  //   );
  //   setSales(nurse + care + bath);
  // }, [simulation, NURSESUGA, CARESUGA, BATHSUGA]);

  // 지출 계산
  useEffect(() => {
    const nurse = serviceWage.nurse;
    const care = serviceWage.care;
    //const bath = serviceWage.bath;
    const welfare = serviceWage.welfare;

    setExpenditure(nurse + care + welfare + material + operation);
  }, [serviceWage, material, operation]);

  useEffect(() => {
    if (simCnt === 0) {
      setCurrent({currentA: true, currentB: false, currentC: false});
      //dispatch(initSimulation_1());
      //dispatch(initSimulation_2());
    } else if (simCnt === 1) {
      setCurrent({currentA: false, currentB: true, currentC: false});
      dispatch(updateCurrentSimulationA());
    } else if (simCnt === 2) {
      setCurrent({currentA: false, currentB: false, currentC: true});
      dispatch(updateCurrentSimulationB());
    }
  }, [dispatch, simCnt]);

  const handleSelectSimulation = (title: string) => {
    if (title === standard) {
      setCurrent({currentA: true, currentB: false, currentC: false});
    } else if (title === '변경값 1') {
      setCurrent({currentA: false, currentB: true, currentC: false});
      dispatch(updateCurrentSimulationA());
    } else {
      setCurrent({currentA: false, currentB: false, currentC: true});
      dispatch(updateCurrentSimulationB());
    }
  };

  // 등급 별 환자 수 및 금액 테이블
  const renderSimulationTable = useCallback(() => {
    return (
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              {GRADEHEADER.map((s, idx) => (
                <TableCell
                  key={idx}
                  className={classes.backColorBlue}
                  align="center">{`${s}`}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                인원
              </TableCell>
              {_.map(sumPatient, (p, idx) => (
                <TableCell
                  key={idx}
                  style={{fontSize: '0.75em'}}
                  align="center">
                  {`${p.count.toLocaleString()}명`}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                이용한도액(개인)
              </TableCell>
              {_.map(GRADEMAX, (g, idx) => (
                <TableCell
                  key={idx}
                  style={{fontSize: '0.75em'}}
                  align="center">
                  {`${g.toLocaleString()}원`}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                이용한도액(월)
              </TableCell>
              {_.map(sumPatient, (p, idx) => (
                <TableCell
                  key={idx}
                  style={{fontSize: '0.75em'}}
                  align="center">
                  {`${(
                    parseInt(`${p.count}`) * GRADEMAX[idx]
                  ).toLocaleString()}원`}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [classes.backColorBlue, classes.table, sumPatient, GRADEMAX, GRADEHEADER]);

  // TODO
  // 클릭하면 해당 테이블이 보여야 함
  const handleRenderSimul = () => {
    if (simCnt < 2) {
      if (simCnt === 0) {
        dispatch(updateSimulation_1({sale: sales, expenditure: expenditure}));
        dispatch(addSimulation_1());
      } else if (simCnt === 1 && flag_2 === 1) {
        dispatch(updateSimulation_1({sale: sales, expenditure: expenditure}));
        dispatch(addSimulation_1());
      } else if (simCnt === 1 && flag_1 === 1) {
        dispatch(updateSimulation_2({sale: sales, expenditure: expenditure}));
        dispatch(addSimulation_2());
      }
      dispatch(addSimCount());
    }
  };

  const handleDownload = () => {
    // TODO
    // 이미지로 다운로드
    html2canvas(document.getElementById('current-screen') as HTMLElement).then(
      (canvas) => {
        let link = document.createElement('a');
        document.body.appendChild(link);
        link.href = canvas.toDataURL('image/png');
        link.download = 'simulation-image.png';
        link.click();
        document.body.removeChild(link);
      },
    );
  };

  useEffect(() => {
    setTotalGraph([
      {
        name: '매출',
        기준값: sales,
        변경값_1: sale_1,
        변경값_2: sale_2,
      },
      {
        name: '지출',
        기준값: expenditure,
        변경값_1: expenditure_1,
        변경값_2: expenditure_2,
      },
      {
        name: '수익',
        기준값: sales - expenditure < 0 ? 0 : sales - expenditure,
        변경값_1: sale_1 - expenditure_1 < 0 ? 0 : sale_1 - expenditure_1,
        변경값_2: sale_2 - expenditure_2 < 0 ? 0 : sale_2 - expenditure_2,
      },
    ]);
  }, [sales, sale_1, sale_2, expenditure, expenditure_1, expenditure_2]);

  const CustomTooltip = ({payload, label}: any) => {
    if (payload && payload.length) {
      return (
        <div className={classes.tooltip}>
          {payload.map((item: any, idx: number) => {
            return (
              <div key={idx} className={classes.tooltip__label}>
                <svg
                  width="24"
                  height="17"
                  viewBox="0, 0, 32 32"
                  style={{
                    display: 'inline-block',
                    marginRight: theme.spacing(5) - 2,
                    verticalAlign: 'middle',
                    marginLeft: theme.spacing(0.5),
                  }}>
                  <path
                    stroke="none"
                    fill={item.fill}
                    d="M0, 4h 64v 24h-64z"></path>
                </svg>
                <p
                  style={{
                    width: 81,
                    display: 'flex',
                    justifyContent: 'left',
                    marginRight: theme.spacing(1) + 2,
                  }}>{`${item.name}`}</p>
                <p
                  style={{
                    width: 115,
                    display: 'flex',
                    justifyContent: 'end',
                  }}>{`${item.value.toLocaleString()}원`}</p>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  const DataFormater = (number: number) => {
    return number.toLocaleString();
  };

  return (
    <>
      <div style={{marginTop: theme.spacing(2.25)}}>
        <Typography style={{fontWeight: 400}} variant="H4">
          목표관리
        </Typography>
      </div>
      <div className={classes.simul}>{renderSimulationTable()}</div>
      <div className={classes.addBtn}>
        <Button
          className={classes.add}
          variant="contained"
          onClick={handleRenderSimul}
          color="primary">
          <AddIcon style={{marginRight: theme.spacing(1)}} />
          추가하기
        </Button>
      </div>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        {
          <div
            style={{display: 'flex', flex: 1}}
            onClick={() => handleSelectSimulation(standard)}>
            <RenderSimulTotal
              current={current.currentA}
              change={false}
              title={standard}
              sales={sales}
              expenditure={expenditure}
            />
          </div>
        }
        {flag_1 === 1 ? (
          <div
            style={{display: 'flex', flex: 1}}
            onClick={() => handleSelectSimulation('변경값 1')}>
            <RenderSimulTotal
              current={current.currentB}
              change={current.currentB}
              title={'변경값 1'}
              sales={sale_1}
              expenditure={expenditure_1}
            />
          </div>
        ) : (
          <></>
        )}
        {flag_2 === 1 ? (
          <div
            style={{display: 'flex', flex: 1}}
            onClick={() => handleSelectSimulation('변경값 2')}>
            <RenderSimulTotal
              current={current.currentC}
              change={current.currentC}
              title={'변경값 2'}
              sales={sale_2}
              expenditure={expenditure_2}
            />
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className={classes.chartContainer}>
        <ResponsiveContainer className={classes.chartLegend}>
          <ComposedChart
            data={totalGraph}
            margin={{
              top: 20,
              right: 50,
              left: 50,
              bottom: 5,
            }}>
            <CartesianGrid strokeDasharray="5 5" />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={DataFormater} />
            <Tooltip content={<CustomTooltip />} />
            {/* <Legend verticalAlign="top" /> */}
            <Bar dataKey="기준값" stackId="a" fill="#FFE57F" />
            <Bar dataKey="변경값_1" stackId="b" fill="#FF8A80" />
            <Bar dataKey="변경값_2" stackId="c" fill="#B71C1C" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>

      {simulation.length !== 0 ? (
        <RenderComponent
          current={current.currentA}
          change={false}
          simulation={simulation}
          serviceWage={serviceWage}
          materialCost={material}
          operationCost={operation}
          sales={sales}
          expenditure={expenditure}
          NURSESUGA={NURSESUGA}
          CARESUGA={CARESUGA}
          //BATHSUGA={BATHSUGA}
        />
      ) : (
        <></>
      )}
      {flag_1 === 1 ? (
        <RenderComponent
          current={current.currentB}
          change={current.currentB}
          simulation={simulation}
          serviceWage={serviceWage}
          materialCost={material}
          operationCost={operation}
          sales={sale_1}
          expenditure={expenditure}
          NURSESUGA={NURSESUGA}
          CARESUGA={CARESUGA}
          //BATHSUGA={BATHSUGA}
        />
      ) : (
        <></>
      )}
      {flag_2 === 1 ? (
        <RenderComponent
          current={current.currentC}
          change={current.currentC}
          simulation={simulation}
          serviceWage={serviceWage}
          materialCost={material}
          operationCost={operation}
          sales={sale_2}
          expenditure={expenditure}
          NURSESUGA={NURSESUGA}
          CARESUGA={CARESUGA}
          //BATHSUGA={BATHSUGA}
        />
      ) : (
        <></>
      )}

      <div className={classes.downDiv}>
        <div className={classes.downBox} onClick={handleDownload}>
          <Typography style={{marginRight: theme.spacing(1)}} variant="Small">
            DOWNDLOAD
          </Typography>
          <SystemUpdateAltIcon fontSize="small" />
        </div>
      </div>
    </>
  );
};

export default CostScreen;
