import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Inventory} from '~/models/inventory';
import {InventoryRelease} from '~/models/inventoryRelease';
import {InventoryStore} from '~/models/inventoryStore';
import {
  CurrentInventoryStat,
  InsertReleaseItem,
  InsertStoreItem,
  InventorySummary,
  ItemSummary,
  StatResult,
  UpdateInventory,
  UpdateMinmumCount,
} from '~/types/types';

type State = {
  loading: boolean;
  request:
    | ItemSummary
    | InsertStoreItem
    | InsertReleaseItem
    | UpdateInventory
    | UpdateMinmumCount
    | Inventory
    | undefined;
  inventories?: InventorySummary[];
  currentItems: InventorySummary[];
  invenStore: InventoryStore[];
  invenRelease: InventoryRelease[];
  newInventory?: Inventory;
  invenStat?: StatResult;
  invenStatRelease?: StatResult;
  currentInventroy?: CurrentInventoryStat[];
  updateItemStore?: InventoryStore;
  updateItem?: Inventory;
  updateRelease?: InventoryRelease;
  materialCost: StatResult;
};

const initialState: State = {
  loading: false,
  request: undefined,
  inventories: undefined,
  currentItems: [],
  invenStore: [],
  invenRelease: [],
  newInventory: undefined,
  invenStat: undefined,
  invenStatRelease: undefined,
  currentInventroy: undefined,
  updateItemStore: undefined,
  updateItem: undefined,
  updateRelease: undefined,
  materialCost: {},
};

const slice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    requestUpdate: (state, action: PayloadAction<UpdateInventory>) => {
      state.loading = true;
      state.request = action.payload;
    },
    updateSuccess: (state) => {
      state.loading = false;
    },
    requestGetItems: (state, action: PayloadAction<ItemSummary>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successSaveItems: (state, action: PayloadAction<InventorySummary[]>) => {
      state.loading = false;
      state.inventories = action.payload;
    },
    requestMinimumCount: (state, action: PayloadAction<UpdateMinmumCount>) => {
      state.loading = true;
      state.request = action.payload;
      state.updateItem = undefined;
    },
    SuccessMinimumCount: (state, action: PayloadAction<Inventory>) => {
      state.loading = false;
      state.updateItem = action.payload;
    },
    requestInsertItem: (state, action: PayloadAction<Inventory>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successNewItem: (state, action: PayloadAction<Inventory>) => {
      state.loading = false;
      state.newInventory = action.payload;
    },
    requestInsertStoreItems: (
      state,
      action: PayloadAction<InsertStoreItem>,
    ) => {
      state.loading = true;
      state.request = action.payload;
    },
    successSaveStoreItems: (state, action: PayloadAction<InventoryStore>) => {
      state.loading = false;
      state.request = undefined;
      state.updateItemStore = action.payload;
    },
    requestInsertReleaseItems: (
      state,
      action: PayloadAction<InsertReleaseItem>,
    ) => {
      state.loading = true;
      state.request = action.payload;
    },
    successSaveReleaseItems: (
      state,
      action: PayloadAction<InventoryRelease>,
    ) => {
      state.loading = false;
      state.updateRelease = action.payload;
    },
    loadStoredItems: (state) => {
      state.loading = true;
    },
    successLoadStoredItems: (
      state,
      action: PayloadAction<InventoryStore[]>,
    ) => {
      state.loading = false;
      state.invenStore = action.payload;
    },
    // requestDeleteItem: (state, body: any) => {
    //   const {id} = body.payload;
    //   state.loading = true;
    //   state.id = id;
    // },
    // successDeleteItem: (state) => {
    //   state.loading = false;
    //   state.id = 0;
    // },
    loadStatStore: (state, action: PayloadAction<ItemSummary>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successLoadStateStore: (state, action: PayloadAction<StatResult>) => {
      state.loading = false;
      state.invenStat = action.payload;
    },
    loadStatRelease: (state, action: PayloadAction<ItemSummary>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successLoadStateRelease: (state, action: PayloadAction<StatResult>) => {
      state.loading = false;
      state.invenStatRelease = action.payload;
    },
    loadCurrentStoredItems: (state, action: PayloadAction<ItemSummary>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successLoadCurrentStoredItems: (
      state,
      action: PayloadAction<CurrentInventoryStat[]>,
    ) => {
      state.loading = false;
      state.currentInventroy = action.payload;
    },
    requestCurrentItems: (state, action: PayloadAction<ItemSummary>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successLoadCurrentItems: (
      state,
      action: PayloadAction<InventorySummary[]>,
    ) => {
      state.loading = false;
      state.currentItems = action.payload;
    },
    loadMaterialCost: (state, action: PayloadAction<ItemSummary>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successLoadMaterialCost: (state, action: PayloadAction<StatResult>) => {
      state.loading = false;
      state.materialCost = action.payload;
    },
  },
});

export const {
  requestUpdate,
  updateSuccess,
  requestGetItems,
  successSaveItems,
  requestMinimumCount,
  SuccessMinimumCount,
  requestInsertItem,
  successNewItem,
  //requestDeleteItem,
  //successDeleteItem,
  requestInsertStoreItems,
  successSaveStoreItems,
  requestInsertReleaseItems,
  successSaveReleaseItems,
  loadStoredItems,
  successLoadStoredItems,
  loadStatStore,
  successLoadStateStore,
  loadCurrentStoredItems,
  successLoadCurrentStoredItems,
  requestCurrentItems,
  successLoadCurrentItems,
  loadStatRelease,
  successLoadStateRelease,
  loadMaterialCost,
  successLoadMaterialCost,
} = slice.actions;
export const INVENTORY = slice.name;
export default slice.reducer;
