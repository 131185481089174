import React from 'react';
import {FormControlLabel, RadioGroup, Radio} from '@material-ui/core';

interface Props {
  value: number;
  total?: boolean;
  search: boolean | undefined;
  onChange: (scale: number) => void;
}

const GradeSelector = ({value, search, total, onChange}: Props) => {
  return (
    <div>
      <RadioGroup
        row
        aria-label="position"
        name="position"
        onChange={(e) => onChange(parseInt(e.target.value))}
        defaultValue={1}
        value={value}>
        {total ? (
          <FormControlLabel
            value={8}
            control={<Radio color="primary" />}
            label="전체"
            labelPlacement="end"
          />
        ) : (
          <></>
        )}
        <FormControlLabel
          value={1}
          control={<Radio color="primary" />}
          label="1등급"
          labelPlacement="end"
        />
        <FormControlLabel
          value={2}
          control={<Radio color="primary" />}
          label="2등급"
          labelPlacement="end"
        />
        <FormControlLabel
          value={3}
          control={<Radio color="primary" />}
          label="3등급"
          labelPlacement="end"
        />
        <FormControlLabel
          value={4}
          control={<Radio color="primary" />}
          label="4등급"
          labelPlacement="end"
        />
        <FormControlLabel
          value={5}
          control={<Radio color="primary" />}
          label="5등급"
          labelPlacement="end"
        />
        <FormControlLabel
          value={6}
          control={<Radio color="primary" />}
          label="인지지원등급"
          labelPlacement="end"
        />
        {search ? (
          <FormControlLabel
            value={7}
            control={<Radio color="primary" />}
            label="수급자검색"
            labelPlacement="end"
          />
        ) : (
          <></>
        )}
      </RadioGroup>
    </div>
  );
};

export default GradeSelector;
