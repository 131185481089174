import {call, select, takeLatest, put} from 'redux-saga/effects';
import {SIGNUP, signUpRequest, signUpSuccess, signUpFailed} from './slice';
import {getSignUp} from '~/api';
import {RootState} from '~/app/rootReducer';
import {CENTER} from '../Center/slice';
import {Center} from '~/models/center';

function* handleSignUp() {
  try {
    const {email, name, caregiver, phone, password} = yield select(
      (state: RootState) => state[SIGNUP],
    );
    const centers = yield select((state: RootState) => state[CENTER].centers);
    const checkedCenters = yield select(
      (state: RootState) => state[SIGNUP].checked,
    );

    const centerIds = checkedCenters.map((c: string) => {
      const elem = centers.filter((center: Center) => center.name === c)[0];
      return elem.id;
    });
    yield call(getSignUp, email, name, caregiver, phone, password, centerIds);
    yield put(signUpSuccess());
  } catch (err) {
    yield put(signUpFailed());
    console.log('error', err);
  }
}

export function* watchSignUp() {
  yield takeLatest(signUpRequest, handleSignUp);
}
