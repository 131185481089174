import moment from 'moment-timezone';
import AccountType from '~/components/AccountType';
import {Detail} from 'react-calendar';

export interface Token {
  token: string | undefined;
}

export interface User {
  email: string | undefined;
  password: string | undefined;
}

export interface NewUser {
  email: string | undefined;
  name: string | undefined;
  password: string | undefined;
  caregiver: string | undefined;
  phone: string | undefined;
}

export interface CheckCenterName {
  name: string;
}

export interface UserProfile {
  id: number;
  email: string | undefined;
  username: string | undefined;
  password: string | undefined;
  caregiver: string | undefined;
  phone: string | undefined;
  roles: string;
}

export interface ResetPassword {
  email: string | undefined;
}

export interface UpdateUserProfile {
  new_password: string | undefined;
  new_phone: string | undefined;
}

export interface LoginCheck {
  fail: boolean;
}

export interface Payload {
  id: number;
  is_activated: boolean;
  role: string;
  name: string;
}

export interface UpdateInventory {
  id: number;
  currentCount: number;
}

export interface UpdateMinmumCount {
  id: number;
  minimumCount: number;
}

export enum STAT_SCALE {
  YEAR,
  MONTH,
  WEEK,
  DAY,
}

export enum STAT_MODE {
  TOTAL,
  EMPLOYEE,
  NURSE,
  CARE,
  PNURSE,
  WELFARE,
}

export enum STAT_JOB_TYPE {
  TOTAL,
  NURSE,
  CARE,
  BATH,
  SEARCH,
}

export enum SERVICE_TYPE {
  TOTAL,
  NURSING,
  CARE,
  BATH,
  WELFARE,
  ETC,
}

export enum ITEM_TYPE {
  STOCK,
  SUPPLY,
}

export enum SERVICE_HISTORY_TYPE {
  NURSE,
  CARE,
  BATH,
}

export const SCALE_TO_VIEW = {
  [STAT_SCALE.YEAR]: 'decade' as Detail,
  [STAT_SCALE.MONTH]: 'year' as Detail,
  [STAT_SCALE.WEEK]: 'month' as Detail,
  [STAT_SCALE.DAY]: 'month' as Detail,
};

export const SCALE_TO_VIEW_2 = {
  [STAT_SCALE.YEAR]: 'century' as Detail,
  [STAT_SCALE.MONTH]: 'decade' as Detail,
  [STAT_SCALE.WEEK]: 'year' as Detail,
  [STAT_SCALE.DAY]: 'year' as Detail,
};

export interface StatReqeustPayload {
  mode?: STAT_MODE;
  jobType?: STAT_JOB_TYPE;
  scale: STAT_SCALE;
  datetime: moment.Moment;
  serviceType?: SERVICE_TYPE;
  itemType?: ITEM_TYPE;
  centerId?: number;
  name?: string;
  grade?: number;
}

export interface StateCenterId {
  centerId: number;
}
export interface StatRequestGrade {
  scale: STAT_SCALE;
  datetime: moment.Moment;
  grade?: number;
  centerId: number;
}

export interface StatSummary {
  datetime: moment.Moment;
  serviceName?: string;
  centerId: number;
}

export interface AccountRemove {
  id: number;
}

export interface SimulationStat {
  name: string;
  count: number;
  price: number;
}

export interface ServiceSummary {
  count: number;
  minutes: number;
}

export interface ItemSummary {
  scale: STAT_SCALE;
  datetime: moment.Moment;
  centerId?: number;
}
export interface InventorySummary {
  id: number;
  type: string;
  name: string;
  store: number;
  release: number;
  minimum: number;
  current: number;
}
export interface InsertStoreItem {
  price: number;
  storeCount: number;
  currentCount: number;
  startDate: string;
  endDate: string;
  inventoryId: number;
  userId: number;
  centerId?: number;
}

export interface InsertReleaseItem {
  releaseCount: number;
  price: number;
  startDate: string;
  endDate: string;
  inventoryId: number;
  userId: number;
  inventoryStoreId: number;
  centerId?: number;
}

export interface CurrentInventoryStat {
  name: string;
  type: string;
  storeCount: number;
  storePrice: number;
  useCount: number;
  usePrice: number;
  currentCount: number;
}
export interface AccountType {
  id: number;
  name: string;
  type: 'income' | 'outcome';
  code?: string;
  accountSubTypes: AccountSubType[];
}

export type NewAccountType = Omit<AccountType, 'id' | 'accountSubTypes'>;

export interface AccountStatAll {
  id: number;
  date: string;
  amount: number;
  memo?: string;
  accountTypeId: number;
  accountName: string;
  accountSubName: string;
}

export interface AccountSubType {
  id: number;
  name: string;
  code?: string;
  comment?: string;
  type?: string;
}

export type NewAccountSubType = Omit<AccountSubType, 'id'> & {
  accountTypeId: number;
};

export interface AccountInput {
  accountId: string;
  accountSubId: number;
  amount: number;
}

export type EmployeeAccountInput = {
  employeeId: string;
} & AccountInput;

export interface Employee {
  id: number;
  name: string;
  birth: string;
  sex?: string;
}

export interface Account {
  id: number;
  date: string;
  memo?: string;
  amount: number;
  accountType?: AccountType;
  accountSubType?: AccountSubType;
  employee?: Employee;
}

export type NewAccount = Omit<Account, 'id'> & {
  accountSubTypeId: number;
  accountTypeId: number;
  centerId: number;
};

// 직업별 서비스별 통계 받을 수 있도록
export interface SummaryStatics {
  service?: string;
  grade?: number;
  patientName?: string;
  price: number;
  //hours: number;
}

export interface StatService {
  nurse_plan: number;
  nurse_real: number;
  care_plan: number;
  care_real: number;
  //bath_plan: number;
  //bath_real: number;
  total_plan: number;
  total_real: number;
  cnt: number;
}

export type TotalStat = {
  price: number;
  service: string;
  grade: number;
  name: string;
  date: string;
};

export type TotalType = {
  name: string;
  방문간호실적: number;
  방문요양실적: number;
  //방문목욕실적: number;
};

// 등급별 현황 전체 등급 가져올 때
export type PatientStatTotalProps = {
  data?: ReadonlyArray<{
    [key: string]: string | number;
  }>;
  search: boolean;
  patientName: string;
  gradeName: string;
  patientCnt: number;
  statics: StatService;
};

export const SERVICE_COLORS: {[key: string]: string} = {
  방문간호: '#f7c1bf',
  방문요양: '#cd4142',
  방문목욕: '#ef5927',
  인건비: '#f4efdb',
  사무운영비: '#fad44f',
  업무추진비: '#007845',
  사업운영비: '#c7ddea',
  시설비: '#1a4770',
  사업비: '#714459',
  매출: '#ef5927',
  지출: '#007845',
};

export const WAGE_MINIMUM_RATIO: {[key: string]: number} = {
  방문요양: 0.864,
  방문간호: 0.588,
  방문목욕: 0.491,
  사회복지사: 0,
};

export interface StatResult {
  [key: string]: {[key: string]: number};
}

export interface GradeCount {
  [key: string]: number;
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
