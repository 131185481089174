import {CSSProperties} from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import {Fonts} from '../contants/Fonts';

export type Variant =
  | 'H1'
  | 'H2'
  | 'H3'
  | 'H4'
  | 'H5'
  | 'Body'
  | 'Small'
  | 'Caption'
  | 'CaptionSmall';

export interface TypographyProps {
  /**
   * The content of the typography.
   */
  children?: React.ReactNode;
  color?: 'primary' | string;
  className?: string;
  style?: CSSProperties;
  variant: Variant;
  htmlColor?: string;
  dangerouslySetInnerHTML?: {__html: string};
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const Typography = (props: TypographyProps) => {
  const {
    variant,
    children,
    className,
    style,
    htmlColor,
    dangerouslySetInnerHTML,
    onClick,
  } = props;

  return (
    <span
      onClick={onClick}
      className={clsx(className)}
      style={{
        ...Fonts[variant],
        fontFamily: 'Noto Sans KR',
        color: htmlColor,
        ...style,
      }}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
      {children}
    </span>
  );
};
