import React from 'react';
import {useDispatch} from 'react-redux';

import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';

import SignUpForm from '~/forms/SignUpForm';
import {signUpRequest} from '~/features/SignUp/slice';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      flexGrow: 1,
      fontSize: 45,
      fontWeight: 700,
      lineHeight: '65px',
      marginLeft: 38,
      marginTop: 10,
    },
    body: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
    },
    footer: {
      display: 'flex',
      backgroundColor: theme.palette.primary.main,
      height: 50,
      color: theme.palette.primary.contrastText,
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
  }),
);

const SignUpScreen = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const submit = (values: any) => {
    const {email, name, caregiver, phone, password} = values;
    dispatch(
      signUpRequest({
        email,
        name,
        caregiver,
        phone,
        password,
      }),
    );
  };

  return (
    <div className={classes.root}>
      <AppBar style={{height: 80}} position="static">
        <Toolbar>
          <Typography variant="h3" className={classes.title}>
            {'3S-BIS'}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.body}>
        <SignUpForm onSubmit={submit} />
      </div>
      <div className={classes.footer}></div>
    </div>
  );
};

export default SignUpScreen;
