import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Center} from '~/models/center';
import {StateCenterId} from '~/types/types';

interface State {
  loading: boolean;
  centers?: Center[];
  center?: Center;
  request?: StateCenterId;
}

const initialState: State = {
  loading: false,
  centers: undefined,
  center: undefined,
  request: undefined,
};

const slice = createSlice({
  name: 'center',
  initialState,
  reducers: {
    requsetCenter: (state) => {
      state.loading = true;
      state.centers = undefined;
    },
    successLoadCenter: (state, action: PayloadAction<Center[]>) => {
      state.loading = false;
      state.centers = action.payload;
    },
    requestFilteredCenter: (state, action: PayloadAction<StateCenterId>) => {
      state.loading = true;
      state.centers = undefined;
      state.request = action.payload;
    },
    successLoadFilteredCenter: (state, action: PayloadAction<Center>) => {
      state.loading = false;
      state.center = action.payload;
    },
  },
});

export const {
  requsetCenter,
  successLoadCenter,
  requestFilteredCenter,
  successLoadFilteredCenter,
} = slice.actions;
export const CENTER = slice.name;
export default slice.reducer;
