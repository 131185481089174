import React, {useState, useEffect, useCallback} from 'react';
import {
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Theme,
  useTheme,
} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment-timezone';
import {Detail} from 'react-calendar';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import {
  DUTIES_STAT,
  loadCareSummary,
  loadNurseSummary,
  loadStatSummary,
} from '~/features/DutyStat/slice';
import StyledCalendar from '~/components/StyledCalendar';
import {Typography} from '~/components/Typography';
import {Cell, Legend, Pie, PieChart, Tooltip} from 'recharts';
import _ from 'lodash';
import {RootState} from '~/app/rootReducer';
import {HISTORY, loadSummary} from '~/features/History/slice';
import {PROFILE} from '~/features/Profile/slice';
import {DEPARTMENT, loadDepartment} from '~/features/Department/slice';
import html2canvas from 'html2canvas';
//import xlsx from 'xlsx';

const useStyles = makeStyles((theme) => ({
  calendar: {
    display: 'flex',
    border: '1px solid rgba(0, 0, 0, 0.38)',
    boxSizing: 'border-box',
    borderRadius: theme.spacing(0.5),
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: theme.spacing(4.625),
    width: '100%',
    minHeight: theme.spacing(8.375),
    '& > .react-calendar__navigation': {
      margin: 0,
      '& > .react-calendar__navigation__label': {
        fontSize: '1.25em',
        fontWeight: 500,
      },
    },
    '& > .react-calendar__viewContainer': {
      display: 'none',
    },
  },
  chartboxes: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  innerboxes: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '&:last-child': {
      '& > div:last-child > div > div > div > ul': {
        textAlign: 'start !important',
      },
    },
  },
  chart: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(8),
    flex: 1,
  },
  chartInner: {
    marginTop: theme.spacing(1.25),
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #C2C2C2',
    boxSizing: 'border-box',
    borderRadius: theme.spacing(1.25),
    '& > div > div > ul': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  piechart: {
    display: 'flex',
    width: 162,
    height: 162,
  },
  table: {
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 'none',
    '& > table > thead > tr > th': {
      padding: theme.spacing(1, 3.75),
      fontWeight: 400,
      border: 'none',
    },
  },
  backColorBlue: {
    fontSize: '0.75em',
    backgroundColor: `#7F91F7`,
    color: '#fff',
  },
  downDiv: {
    marginTop: theme.spacing(1.125),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',
  },
  downBox: {
    display: 'flex',
    width: 'fit-content',
    height: 35,
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid rgba(0, 0, 0, 0.54)',
    boxSizing: 'border-box',
    borderRadius: theme.spacing(0.5),
    color: 'rgba(0, 0, 0, 0.54)',
  },
  tooltip: {
    padding: theme.spacing(2.5, 1.875, 1.125),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    //alignItems: 'center',
    backgroundColor: '#FFFFFF',
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: theme.spacing(0.5),
  },
  tooltip__label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 23,
    marginBottom: theme.spacing(1.125),
    fontSize: '1em',
  },
}));

// const INCOME: {[key: string]: {[key: string]: number[]}} = {
//   방문간호: {
//     '2021-01': [170, 5946800],

//     '2021-02': [155, 5445730],

//     '2021-03': [170, 6157410],

//     '2021-04': [216, 6619300],

//     '2021-05': [211, 7359920],

//     '2021-06': [176, 5816710],

//     '2021-07': [172, 5794780],

//     '2021-08': [181, 6182390],

//     '2021-09': [134, 5494780],

//     '2021-10': [114, 4865620],

//     '2021-11': [151, 6149825],
//   },
//   방문요양: {
//     '2021-01': [69, 2724130],

//     '2021-02': [46, 1816230],

//     '2021-03': [52, 1923040],

//     '2021-04': [55, 2065690],

//     '2021-05': [48, 1825650],

//     '2021-06': [55, 2075520],

//     '2021-07': [81, 3281090],

//     '2021-08': [79, 3296520],

//     '2021-09': [82, 3464040],

//     '2021-10': [114, 5136650],

//     '2021-11': [77, 3229650],
//   },
// };

// const PATIENTS: {[key: string]: {[key: string]: number[]}} = {
//   '2021-01': {
//     '1등급': [11, 2323050],
//     '2등급': [11, 2074080],
//     '3등급': [8, 1103180],
//     '4등급': [9, 2750980],
//     '5등급': [4, 419640],
//   },

//   '2021-02': {
//     '1등급': [10, 1679710],
//     '2등급': [11, 2128520],
//     '3등급': [10, 1158300],
//     '4등급': [8, 1956390],
//     '5등급': [2, 339040],
//   },

//   '2021-03': {
//     '1등급': [10, 1485760],
//     '2등급': [12, 2302220],
//     '3등급': [7, 828320],
//     '4등급': [9, 3002930],
//     '5등급': [4, 461220],
//   },

//   '2021-04': {
//     '1등급': [11, 1597400],
//     '2등급': [13, 2951670],
//     '3등급': [10, 1136180],
//     '4등급': [7, 2584330],
//     '5등급': [3, 415410],
//   },

//   '2021-05': {
//     '1등급': [11, 1276610],
//     '2등급': [15, 4320650],
//     '3등급': [12, 1666000],
//     '4등급': [5, 1482340],
//     '5등급': [4, 439970],
//   },

//   '2021-06': {
//     '1등급': [7, 983440],
//     '2등급': [14, 3380240],
//     '3등급': [12, 1741020],
//     '4등급': [5, 1491360],
//     '5등급': [3, 296170],
//   },

//   '2021-07': {
//     '1등급': [8, 2144320],
//     '2등급': [12, 2579870],
//     '3등급': [13, 2491500],
//     '4등급': [6, 1620160],
//     '5등급': [4, 240020],
//   },

//   '2021-08': {
//     '1등급': [9, 2424670],
//     '2등급': [13, 2393780],
//     '3등급': [14, 3018790],
//     '4등급': [5, 1473310],
//     '5등급': [3, 168360],
//   },

//   '2021-09': {
//     '1등급': [6, 1972060],
//     '2등급': [12, 1812340],
//     '3등급': [13, 2644290],
//     '4등급': [5, 2202720],
//     '5등급': [3, 327410],
//   },

//   '2021-10': {
//     '1등급': [8, 2822240],
//     '2등급': [8, 1387000],
//     '3등급': [11, 3174560],
//     '4등급': [5, 2462420],
//     '5등급': [2, 156050],
//   },

//   '2021-11': {
//     '1등급': [9, 2924270],
//     '2등급': [9, 1350500],
//     '3등급': [11, 3527155],
//     '4등급': [4, 1467310],
//     '5등급': [2, 110240],
//   },
// };

// const EMPLOYEES: {[key: string]: {[key: string]: number[]}} = {
//   '2021-01': {간호사: [3, 5886], 방문요양사: [4, 10081]},

//   '2021-02': {간호사: [3, 5356], 방문요양사: [3, 7011]},

//   '2021-03': {간호사: [4, 6345], 방문요양사: [3, 7329]},

//   '2021-04': {간호사: [4, 6609], 방문요양사: [3, 7708]},

//   '2021-05': {간호사: [4, 7271], 방문요양사: [3, 6760]},

//   '2021-06': {간호사: [4, 5360], 방문요양사: [3, 7743]},

//   '2021-07': {간호사: [4, 5475], 방문요양사: [4, 12252]},

//   '2021-08': {간호사: [4, 6229], 방문요양사: [4, 12194]},

//   '2021-09': {간호사: [3, 5497], 방문요양사: [4, 13276]},

//   '2021-10': {간호사: [3, 5223], 방문요양사: [6, 17919]},

//   '2021-11': {간호사: [4, 7067], 방문요양사: [4, 10733]},
// };

const PerfAmdScreen = () => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const [date, setDate] = useState(new Date());
  const [viewType /*, setViewType*/] = useState<Detail>('month');
  const dispatch = useDispatch();
  const MONEYCOLORS = ['#C4C4C4', '#36A2EB', '#4BC0C0'];
  const JOBCOLORS = ['#C4C4C4', '#36A2EB', '#4BC0C0', '#FFC000'];
  const GRADECOLORS = [
    '#C4C4C4',
    '#36A2EB',
    '#4BC0C0',
    '#FF9020',
    '#FFCD56',
    '#D08C9C',
  ];
  const HISTORYCOLORS = [
    '#C4C4C4',
    '#36A2EB',
    '#4BC0C0',
    '#FF9020',
    '#FFCD56',
    '#D08C9C',
    '#E676E1',
    '#8E83D3',
    '#8BB0D1',
    '#87E5B8',
    '#C8D587',
    '#E25922',
    '#7AC2E2',
  ];
  const MONEY = ['구분', '횟수', '매출'];
  const EMPLOYEE = ['구분', '인원수', '근무시간'];
  const PATIENT = ['구분', '인원수', '이용한도액', '매출'];
  const HISTORYHEADER = ['구분', '횟수', '총 처치시간', '평균 처치시간'];
  const [sumPrice, setSumPrice] = useState<
    ReadonlyArray<{[key: string]: string | number}>
  >();
  const [sumJob, setSumJob] = useState<
    ReadonlyArray<{[key: string]: string | number}>
  >();
  const [sumPatient, setSumPatient] = useState<
    ReadonlyArray<{[key: string]: string | number}>
  >();
  const [sumHistory, setSumHistory] = useState<
    ReadonlyArray<{[key: string]: string | number}>
  >();

  const statSummary = useSelector(
    (state: RootState) => state[DUTIES_STAT].summary,
  );
  const {nurse, care} = useSelector((state: RootState) => state[DUTIES_STAT]);
  const hisSummary = useSelector((state: RootState) => state[HISTORY].summary);

  // 센터 id 가져오기
  const userId = useSelector((state: RootState) => state[PROFILE].id);
  const centerId = useSelector(
    (state: RootState) => state[DEPARTMENT].centerInfo?.centerId,
  );

  useEffect(() => {
    if (userId) {
      dispatch(loadDepartment(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (centerId) {
      const request = {
        datetime: moment(date),
        centerId: centerId,
      };
      // 매출현황
      dispatch(loadStatSummary(request));
      // 직원형황
      dispatch(
        loadNurseSummary({
          datetime: moment(date),
          serviceName: '방문간호',
          centerId: centerId,
        }),
      );
      dispatch(
        loadCareSummary({
          datetime: moment(date),
          serviceName: '방문요양',
          centerId: centerId,
        }),
      );
      // dispatch(
      //   loadBathSummary({
      //     datetime: moment(date),
      //     serviceName: '방문목욕',
      //     centerId: centerId,
      //   }),
      // );
      // 처치히스토리 현황
      dispatch(loadSummary(request));
    }
  }, [date, dispatch, centerId]);

  useEffect(() => {
    if (statSummary) {
      // 매출현황
      const nurse = _.filter(statSummary, (s) => s.service === '방문간호');
      const care = _.filter(statSummary, (s) => s.service === '방문요양');
      //const bath = _.filter(statSummary, (s) => s.service === '방문목욕');
      const n_p = _.sum(_.map(nurse, (n) => n.price));
      const c_p = _.sum(_.map(care, (c) => c.price));
      //const b_p = _.sum(_.map(bath, (b) => b.price));

      const ret = [
        {
          name: '방문간호',
          count: nurse.length,
          value: n_p,
        },
        {
          name: '방문요양',
          count: care.length,
          value: c_p,
        },
        // {
        //   name: '방문목욕',
        //   count: bath.length,
        //   value: b_p,
        // },
      ];

      setSumPrice(
        _.map(ret, (r) => {
          return {
            name: r.name,
            count: r.count,
            value: r.value,
          };
        }),
      );

      // 수급자현황
      const first = _.filter(statSummary, (s) => s.grade === 1);
      const second = _.filter(statSummary, (s) => s.grade === 2);
      const third = _.filter(statSummary, (s) => s.grade === 3);
      const fourth = _.filter(statSummary, (s) => s.grade === 4);
      const fifth = _.filter(statSummary, (s) => s.grade === 5);
      const cog = _.filter(statSummary, (s) => s.grade === 6);
      const p1 = _.sum(_.map(first, (p) => p.price));
      const p2 = _.sum(_.map(second, (p) => p.price));
      const p3 = _.sum(_.map(third, (p) => p.price));
      const p4 = _.sum(_.map(fourth, (p) => p.price));
      const p5 = _.sum(_.map(fifth, (p) => p.price));
      const p6 = _.sum(_.map(cog, (p) => p.price));

      const GRADEMAX = [1520700, 1351700, 1295400, 1189800, 1021300, 573900];
      const ret2 = [
        {
          name: '1등급',
          count: _.uniqBy(first, (f) => f.patientName).length,
          max: _.uniqBy(first, (f) => f.patientName).length * GRADEMAX[0],
          value: p1,
        },
        {
          name: '2등급',
          count: _.uniqBy(second, (f) => f.patientName).length,
          max: _.uniqBy(first, (f) => f.patientName).length * GRADEMAX[1],
          value: p2,
        },
        {
          name: '3등급',
          count: _.uniqBy(third, (f) => f.patientName).length,
          max: _.uniqBy(first, (f) => f.patientName).length * GRADEMAX[2],
          value: p3,
        },
        {
          name: '4등급',
          count: _.uniqBy(fourth, (f) => f.patientName).length,
          max: _.uniqBy(first, (f) => f.patientName).length * GRADEMAX[3],
          value: p4,
        },
        {
          name: '5등급',
          count: _.uniqBy(fifth, (f) => f.patientName).length,
          max: _.uniqBy(first, (f) => f.patientName).length * GRADEMAX[4],
          value: p5,
        },
        {
          name: '인지지원등급',
          count: _.uniqBy(cog, (f) => f.patientName).length,
          max: _.uniqBy(first, (f) => f.patientName).length * GRADEMAX[5],
          value: p6,
        },
      ];

      setSumPatient(
        _.map(ret2, (r) => {
          return {
            name: r.name,
            count: r.count,
            max: r.max,
            value: r.value,
          };
        }),
      );
    }
  }, [date, statSummary]);

  useEffect(() => {
    // 직원현황
    // 서비스별현황으로 수정(2022.06.17)
    const ret = [
      {
        name: '방문간호',
        cnt: nurse.count,
        time: parseFloat(
          `${Math.floor(nurse.minutes / 60)}.${(nurse.minutes % 60)
            .toString()
            .padStart(2, '0')}`,
        ),
      },
      {
        name: '방문요양',
        cnt: care.count,
        time: parseFloat(
          `${Math.floor(care.minutes / 60)}.${(care.minutes % 60)
            .toString()
            .padStart(2, '0')}`,
        ),
      },
      // {
      //   name: '방문목욕',
      //   cnt: bath.count,
      //   time: parseFloat(
      //     `${Math.floor(bath.minutes / 60)}.${(bath.minutes % 60)
      //       .toString()
      //       .padStart(2, '0')}`,
      //   ),
      // },
    ];
    setSumJob(ret);
  }, [nurse, care]);

  useEffect(() => {
    setSumHistory(
      _.map(hisSummary, (h) => {
        return {
          name: h.name,
          count: h.count,
          minutes: h.minutes,
          time: h.count !== 0 ? (h.minutes / h.count).toFixed(2) : 0,
        };
      }),
    );
  }, [hisSummary]);

  const renderMoney = useCallback(() => {
    return (
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              {MONEY.map((m, idx) => (
                <TableCell
                  key={idx}
                  className={classes.backColorBlue}
                  align="center">
                  {`${m}`}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                전체
              </TableCell>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                {`${_.sum(_.map(sumPrice, (s) => s.count))}회`}
              </TableCell>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                {`${_.sum(_.map(sumPrice, (s) => s.value)).toLocaleString()}원`}
              </TableCell>
            </TableRow>
            {_.map(sumPrice, (p, idx) => {
              // let day = moment(date).format('yyyy-MM');
              // const income = INCOME[p.name]?.[day];
              // let cMatched = true;
              // let vMatched = true;
              // if (income) {
              //   vMatched = p.value === income[1];
              //   cMatched = p.count === income[0];
              // }
              return (
                <TableRow key={idx}>
                  <TableCell style={{fontSize: '0.75em'}} align="center">
                    {p.name}
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '0.75em',
                      // backgroundColor: cMatched ? 'inherit' : 'red',
                    }}
                    align="center">
                    {`${p.count}회`}

                    {/* {!cMatched && `(${income[0]})`} */}
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '0.75em',
                      // backgroundColor: vMatched ? 'inherit' : 'red',
                    }}
                    align="center">
                    {`${p.value.toLocaleString()}원`}

                    {/* {!vMatched && `(${income[1].toLocaleString()})`} */}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [MONEY, classes.backColorBlue, classes.table, sumPrice]);

  const renderEmployee = useCallback(() => {
    return (
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              {EMPLOYEE.map((e, idx) => (
                <TableCell
                  key={idx}
                  className={classes.backColorBlue}
                  align="center">
                  {`${e}`}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                전체
              </TableCell>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                {`${_.sum(_.map(sumJob, (s) => s.cnt))}명`}
              </TableCell>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                {`${_.sum(_.map(sumJob, (s) => s.time)).toLocaleString()}시간`}
              </TableCell>
            </TableRow>
            {_.map(sumJob, (s, idx) => {
              // let day = moment(date).format('yyyy-MM');
              // const income = EMPLOYEES[day]?.[s.name];
              // let cMatched = true;
              // let vMatched = true;
              // if (income) {
              //   vMatched = s.time === income[1];
              //   cMatched = s.cnt === income[0];
              // }

              return (
                <TableRow key={idx}>
                  <TableCell style={{fontSize: '0.75em'}} align="center">
                    {s.name}
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '0.75em',
                      // backgroundColor: cMatched ? 'inherit' : 'red',
                    }}
                    align="center">
                    {`${s.cnt}명`}
                    {/* {!cMatched && `(${income[0]})`} */}
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '0.75em',
                      // backgroundColor: vMatched ? 'inherit' : 'red',
                    }}
                    align="center">
                    {`${s.time.toLocaleString()}시간`}
                    {/* {!vMatched && `(${income[1].toLocaleString()})`} */}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [EMPLOYEE, classes.backColorBlue, classes.table, sumJob]);

  const renderPatient = useCallback(() => {
    return (
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              {PATIENT.map((p, idx) => (
                <TableCell
                  key={idx}
                  className={classes.backColorBlue}
                  align="center">
                  {`${p}`}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                전체
              </TableCell>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                {`${_.sum(_.map(sumPatient, (s) => s.count))}명`}
              </TableCell>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                {`${_.sum(_.map(sumPatient, (s) => s.max)).toLocaleString()}원`}
              </TableCell>
              <TableCell style={{fontSize: '0.75em'}} align="center">
                {`${_.sum(
                  _.map(sumPatient, (s) => s.value),
                ).toLocaleString()}원`}
              </TableCell>
            </TableRow>
            {_.map(sumPatient, (p, idx) => {
              // let day = moment(date).format('yyyy-MM');
              // const income = PATIENTS[day]?.[p.name];
              // let cMatched = true;
              // let vMatched = true;
              // if (income) {
              //   vMatched = p.value === income[1];
              //   cMatched = p.count === income[0];
              // }
              return (
                <TableRow key={idx}>
                  <TableCell style={{fontSize: '0.75em'}} align="center">
                    {p.name}
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '0.75em',
                      // backgroundColor: cMatched ? 'inherit' : 'red',
                    }}
                    align="center">
                    {`${p.count}명`}
                    {/* {!cMatched && `(${income[0]})`} */}
                  </TableCell>
                  <TableCell style={{fontSize: '0.75em'}} align="center">
                    {`${p.max.toLocaleString()}원`}
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '0.75em',
                      // backgroundColor: cMatched ? 'inherit' : 'red',
                    }}
                    align="center">
                    {`${p.value.toLocaleString()}원`}
                    {/* {!vMatched && `(${income[1].toLocaleString()})`} */}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [PATIENT, classes.backColorBlue, classes.table, sumPatient]);

  const renderHistory = useCallback(() => {
    return (
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              {HISTORYHEADER.map((h, idx) => (
                <TableCell
                  key={idx}
                  style={{fontSize: '0.75em'}}
                  className={classes.backColorBlue}
                  align="center">
                  {`${h}`}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(sumHistory, (history, idx) => (
              <TableRow key={idx}>
                <TableCell style={{fontSize: '0.75em'}} align="center">
                  {history.name}
                </TableCell>
                <TableCell style={{fontSize: '0.75em'}} align="center">
                  {`${history.count.toLocaleString()}회`}
                </TableCell>
                <TableCell style={{fontSize: '0.75em'}} align="center">
                  {`${history.minutes.toLocaleString()}분`}
                </TableCell>
                <TableCell style={{fontSize: '0.75em'}} align="center">
                  {`${history.time.toLocaleString()}분`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [HISTORYHEADER, classes.backColorBlue, classes.table, sumHistory]);

  const handleDownload = () => {
    // TODO
    // 이미지로 다운로드
    html2canvas(document.getElementById('current-screen') as HTMLElement).then(
      (canvas) => {
        let link = document.createElement('a');
        document.body.appendChild(link);
        link.href = canvas.toDataURL('image/png');
        link.download = 'summary-image.png';
        link.click();
        document.body.removeChild(link);
      },
    );
  };

  const CustomTooltip = ({payload, label}: any) => {
    if (payload && payload.length) {
      return (
        <div className={classes.tooltip}>
          {payload.map((item: any, idx: number) => {
            return (
              <div key={idx} className={classes.tooltip__label}>
                <svg
                  width="24"
                  height="17"
                  viewBox="0, 0, 32 32"
                  style={{
                    display: 'inline-block',
                    marginRight: theme.spacing(5) - 2,
                    verticalAlign: 'middle',
                    marginLeft: theme.spacing(0.5),
                  }}>
                  <path
                    //stroke="none"
                    fill={item.payload.fill}
                    d="M0, 4h 64v 24h-64z"></path>
                </svg>
                <p
                  style={{
                    width: 81,
                    display: 'flex',
                    justifyContent: 'left',
                    marginRight: theme.spacing(1) + 2,
                  }}>{`${item.name}`}</p>
                <p
                  style={{
                    width: 115,
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {item.dataKey === 'value'
                    ? `${item.value.toLocaleString()} 원`
                    : `${item.value.toLocaleString()} 분`}
                </p>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div style={{marginTop: theme.spacing(2.25)}}>
        <Typography style={{fontWeight: 400}} variant="H4">
          SUMMARY
        </Typography>
      </div>
      <StyledCalendar
        className={classes.calendar}
        value={date}
        navigationLabel={({date}) =>
          `${date.getFullYear()}년 ${date.getMonth() + 1}월`
        }
        view={viewType}
        onActiveStartDateChange={({activeStartDate}) => {
          setDate(activeStartDate);
        }}
        next2Label={null}
        prev2Label={null}
      />
      <div className={classes.chartboxes}>
        <div className={classes.innerboxes}>
          <div className={classes.chart}>
            <Typography variant="Small">매출현황</Typography>
            <div
              className={classes.chartInner}
              style={{
                minHeight: 362,
                padding: theme.spacing(5, 6),
                marginRight: theme.spacing(2),
              }}>
              <PieChart
                style={{
                  marginRight: theme.spacing(6),
                }}
                width={200}
                height={200}>
                <Legend verticalAlign="bottom" height={20} />
                <Pie
                  data={sumPrice}
                  cx="50%"
                  cy="50%"
                  legendType="circle"
                  outerRadius={80}
                  paddingAngle={0.5}
                  dataKey="value">
                  {_.map(sumPrice, (_entry, index) => {
                    return (
                      <Cell
                        key={`cell-${index}`}
                        fill={MONEYCOLORS[index % MONEYCOLORS.length]}
                      />
                    );
                  })}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
              {renderMoney()}
            </div>
          </div>
          <div className={classes.chart}>
            <Typography variant="Small">서비스현황</Typography>
            <div
              className={classes.chartInner}
              style={{
                padding: theme.spacing(3.625, 1),
              }}>
              <PieChart
                style={{
                  marginRight: theme.spacing(6),
                }}
                width={200}
                height={200}>
                <Legend verticalAlign="bottom" height={20} />
                <Pie
                  data={sumJob}
                  cx="50%"
                  cy="50%"
                  legendType="circle"
                  outerRadius={80}
                  paddingAngle={0.5}
                  dataKey="time">
                  {_.map(sumJob, (_entry, index) => {
                    return (
                      <Cell
                        key={`cell-${index}`}
                        fill={JOBCOLORS[index % JOBCOLORS.length]}
                      />
                    );
                  })}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
              {renderEmployee()}
            </div>
          </div>
        </div>
        <div className={classes.innerboxes}>
          <div className={classes.chart}>
            <Typography variant="Small">수급자현황</Typography>
            <div
              className={classes.chartInner}
              style={{
                padding: theme.spacing(4, 2, 4, 0),
                marginRight: theme.spacing(2),
              }}>
              <PieChart width={200} height={200}>
                <Legend verticalAlign="bottom" height={20} />
                <Pie
                  data={sumPatient}
                  cx="50%"
                  cy="50%"
                  legendType="circle"
                  outerRadius={80}
                  paddingAngle={0.5}
                  dataKey="value">
                  {_.map(sumPatient, (_entry, index) => {
                    return (
                      <Cell
                        key={`cell-${index}`}
                        fill={GRADECOLORS[index % GRADECOLORS.length]}
                      />
                    );
                  })}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
              {renderPatient()}
            </div>
          </div>
          <div className={classes.chart}>
            <Typography variant="Small">처치히스토리</Typography>
            <div
              className={classes.chartInner}
              style={{
                padding: theme.spacing(4, 1, 4, 0),
              }}>
              <PieChart width={200} height={200}>
                <Legend verticalAlign="bottom" height={20} />
                <Pie
                  data={sumHistory}
                  cx="50%"
                  cy="50%"
                  legendType="circle"
                  outerRadius={80}
                  paddingAngle={0.5}
                  dataKey="time">
                  {_.map(sumHistory, (_entry, index) => {
                    return (
                      <Cell
                        key={`cell-${index}`}
                        fill={HISTORYCOLORS[index % HISTORYCOLORS.length]}
                      />
                    );
                  })}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
              {renderHistory()}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.downDiv}>
        <div className={classes.downBox} onClick={handleDownload}>
          <Typography style={{marginRight: theme.spacing(1)}} variant="Small">
            DOWNLOAD
          </Typography>
          <SystemUpdateAltIcon fontSize="small" />
        </div>
      </div>
    </>
  );
};

export default PerfAmdScreen;
