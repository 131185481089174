import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Fab} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/Remove';

interface ItemName {
  id: number;
  name: string;
  count: number;
  setCount: Function;
  setId: Function;
  setChange: Function;
}

const useStyles = makeStyles((theme) => ({
  itemBox: {
    background: 'rgba(196, 196, 196, 0.16)',
    width: '100%',
    minHeight: 88,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
    marginBottom: theme.spacing(3) + 1,
    flexDirection: 'column',
  },
  item: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1.25, 0, 1.875),
    marginBottom: theme.spacing(2) + 5,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  itemName: {
    fontSize: '1em',
    minWidth: 107,
    flex: 1,
    //marginRight: theme.spacing(6),
  },
  countIcon: {
    width: theme.spacing(3),
    maxHeight: theme.spacing(3),
    minHeight: theme.spacing(3),
    boxShadow: 'none',
  },
  countItem: {
    margin: '0 20px',
    fontSize: '1em',
  },
}));

export const Items = (props: ItemName) => {
  const classes = useStyles();
  //const {name, count, setCount} = props;
  const {id, name, count, setCount, setId, setChange} = props;
  const [item, setItem] = useState(0);

  useEffect(() => {
    if (item !== 0) {
      setCount(item);
      setChange(true);
    } else {
      setChange(false);
    }
  }, [item, count, setChange, setCount]);

  return (
    <div
      className={classes.item}
      style={{
        pointerEvents: count === 0 ? 'none' : 'auto',
        opacity: count === 0 ? 0.5 : 1,
      }}>
      <div className={classes.itemName}>{`${name}`}</div>
      <div>
        <Fab
          className={classes.countIcon}
          color="primary"
          onClick={() => {
            setId(id);
            if (item > 0) {
              setItem(item - 1);
            }
          }}
          aria-label="remove">
          <MinusIcon />
        </Fab>
        <span className={classes.countItem}>
          {item.toString().padStart(2, '0')}
        </span>
        <Fab
          className={classes.countIcon}
          color="secondary"
          onClick={() => {
            setId(id);
            if (item < count) {
              setItem(item + 1);
            }
          }}
          aria-label="add">
          <AddIcon />
        </Fab>
      </div>
    </div>
  );
};
