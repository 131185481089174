import React, {useEffect, useState} from 'react';
import LoginForm from '~/forms/LoginForm';
import {useDispatch} from 'react-redux';
import {loginRequest} from '~/features/Login/slice';
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      flexGrow: 1,
      fontSize: 45,
      fontWeight: 700,
      lineHeight: '65px',
      marginLeft: 38,
      marginTop: 10,
    },
    body: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
    },
    footer: {
      display: 'flex',
      backgroundColor: theme.palette.primary.main,
      height: 50,
      color: theme.palette.primary.contrastText,
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
  }),
);

const LOACL_EMAIL = 'email';

const LoginScreen = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [initEmail, setEmail] = useState('');

  const submit = (values: any) => {
    const {email, password} = values;
    dispatch(loginRequest({email, password}));
  };

  const loadEmail = () => {
    const loadedEamil = localStorage.getItem(LOACL_EMAIL);

    if (loadedEamil !== null) {
      const parsedInfo = JSON.parse(loadedEamil);
      setEmail(parsedInfo.email);
    }
  };

  useEffect(() => {
    loadEmail();
  }, [initEmail]);

  return (
    <div className={classes.root}>
      <AppBar style={{height: 80}} position="static">
        <Toolbar>
          <Typography variant="h3" className={classes.title}>
            {'3S-BIS'}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.body}>
        {/* 로그인 폼 라우터로 변경 */}
        <LoginForm
          initialValues={{
            email: initEmail,
          }}
          onSubmit={submit}
        />
      </div>

      <div className={classes.footer}>
        {/* Copyright © 2020 3S BIS. All rights reserved. */}
      </div>
    </div>
  );
};

export default LoginScreen;
