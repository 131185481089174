import axios from 'axios';
import {
  StatReqeustPayload,
  STAT_MODE,
  NewAccountType,
  NewAccountSubType,
  NewAccount,
  StatRequestGrade,
  StatSummary,
  ItemSummary,
  InsertStoreItem,
  InsertReleaseItem,
  UpdateInventory,
  StateCenterId,
  AccountRemove,
  UpdateMinmumCount,
} from '~/types/types';
import HistoryDetail from '~/models/hisotry';
import {Inventory} from '~/models/inventory';
import {
  RequestGrade,
  RequestJobType,
  RequestSchdules,
} from '~/features/Schedule/slice';

import {ServiceHistoryType, StatRequestHistory} from '~/features/History/slice';

export const getJWTAPI = (email: string, password: string) => {
  return axios.post(
    `/users/login`,
    {email, password},
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
};

export const getSignUp = (
  email: string,
  username: string,
  caregiver: string,
  phone: string,
  password: string,
  centerIds: number[],
) => {
  return axios.post(
    `/signup`,
    {email, username, caregiver, phone, password, etc: centerIds},
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
};

export const refreshTokenAPI = (token: string) => {
  return axios.get(`/users/refresh_token`);
};

export const whoAmI = (token: string) => {
  return axios.get(`/whoAmI`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updatePhoneNumber = (
  token: string,
  id: number,
  new_phone: string,
) => {
  return axios.patch(
    `/users/${id}`,
    {phone: new_phone},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const updatePassword = (id: number, new_pwd: string) => {
  return axios.patch(
    `/users/${id}/user-credentials`,
    {password: new_pwd},
    // {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // },
  );
};

export const resetPassword = (email: string) => {
  return axios.post(
    `/users/reset-pwd`,
    {email},
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
};

export const findResetUser = () => {
  return axios.get(`/users/find-reset`);
};

export const getScheduleStat = (body: StatReqeustPayload) => {
  return getStatistics('schedules', body);
};

export const getDutyStat = (body: StatReqeustPayload) => {
  return getStatistics('duties', body);
};

export const getLastDuty = (body: StateCenterId) => {
  return axios.get(`duties/last?centerId=${body.centerId}`);
};

export const getDutyAmountStat = (body: StatReqeustPayload) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('date');

  return axios.get(
    `duties/stat?year=${year}&month=${month}&day=${day}&mode=${body.scale}&centerId=${body.centerId}`,
  );
};

export const getJobDutyAmountStat = (body: StatReqeustPayload) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('date');
  const jobType = body.jobType;

  return axios.get(
    `duties/wage-stat?year=${year}&month=${month}&day=${day}&mode=${body.scale}&jobType=${jobType}`,
  );
};

export const geServiceSummary = (body: StatSummary) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const centerId = body.centerId;
  return axios.get(
    `duties/service-summary?year=${year}&month=${month}&centerId=${centerId}&serviceName=${body.serviceName}`,
  );
};

export const getAccountStat = (body: StatReqeustPayload) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('date');

  return axios.get(
    `accounts/stat?year=${year}&month=${month}&day=${day}&mode=${body.scale}&centerId=${body.centerId}`,
  );
};

export const getAccountStatAll = (body: StateCenterId) => {
  return axios.get(`accounts/stat-all?centerId=${body.centerId}`);
};

export const getWageStat = (body: StatReqeustPayload) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('date');

  return axios.get(
    `accounts/wage?year=${year}&month=${month}&day=${day}&mode=${body.scale}&centerId=${body.centerId}`,
  );
};

export const getEtcWageStat = (body: StatReqeustPayload) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('date');

  return axios.get(
    `accounts/etc-wage?centerId=${body.centerId}&year=${year}&month=${month}&day=${day}&mode=${body.scale}`,
  );
};

export const getPatientStat = (body: StatReqeustPayload) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('date');

  return axios.get(
    `patients/stat?year=${year}&month=${month}&day=${day}&mode=${body.scale}`,
  );
};

export const getPatientSchStat = (body: StatReqeustPayload) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('date');

  return axios.get(
    `patients/stat/schedule?year=${year}&month=${month}&day=${day}&mode=${body.scale}&type=${body.serviceType}`,
  );
};

export const getPatientDutyStat = (body: StatReqeustPayload) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('date');

  return axios.get(
    `patients/stat/duty?year=${year}&month=${month}&day=${day}&mode=${body.scale}&type=${body.serviceType}`,
  );
};

export const getSchGrade = (body: StatRequestGrade) => {
  return getStatGrade('schedules', body);
};

export const getDutyGrade = (body: StatRequestGrade) => {
  return getStatGrade('duties', body);
};

export const getGradeCount = (body: StatRequestGrade) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const centerId = body.centerId;

  return axios.get(
    `/duties/grade-count?year=${year}&month=${month}&mode=${body.scale}&type=${body.grade}&centerId=${centerId}`,
  );
};

export const getDutySearch = (body: StatReqeustPayload) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const centerId = body.centerId;
  const name = body.name;
  return axios.get(
    `/duties/search?year=${year}&month=${month}&mode=${body.scale}&type=${body.grade}&centerId=${centerId}&name=${name}`,
  );
};

export const getStatGrade = (path: string, body: StatRequestGrade) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('date');
  const center = body.centerId;

  return axios.get(
    `/${path}/statistics/grade?year=${year}&month=${month}&day=${day}&mode=${body.scale}&type=${body.grade}&center=${center}`,
  );
};

export const getSchTotal = (body: StatRequestGrade) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('date');
  const center = body.centerId;

  return axios.get(
    `/schedules/statistics/totals?year=${year}&month=${month}&day=${day}&mode=${body.scale}&center=${center}`,
  );
};

export const getDutyTotals = (body: StatRequestGrade) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('date');
  const center = body.centerId;

  return axios.get(
    `/duties/statistics/totals?year=${year}&month=${month}&day=${day}&mode=${body.scale}&center=${center}`,
  );
};

export const getStatistics = (path: string, body: StatReqeustPayload) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('date');
  const centerId = body.centerId;

  const resourceMap = {
    [STAT_MODE.TOTAL]: 'total',
    [STAT_MODE.EMPLOYEE]: 'employee',
    [STAT_MODE.NURSE]: 'nurse',
    [STAT_MODE.CARE]: 'care',
    [STAT_MODE.PNURSE]: 'rnurse',
    [STAT_MODE.WELFARE]: 'welfare',
  };

  return axios.get(
    `/${path}/statistics/${
      body.mode && resourceMap[body.mode]
    }?centerId=${centerId}&year=${year}&month=${month}&day=${day}&mode=${
      body.scale
    }&type=${body.serviceType}`,
  );
};

export const getDutySummary = (body: StatSummary) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('date');
  const centerId = body.centerId;

  return axios.get(
    `/duties/stat-summary?year=${year}&month=${month}&day=${day}&centerId=${centerId}`,
  );
};

export const getSimulation = (body: StatSummary) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const centerId = body.centerId;

  return axios.get(
    `/duties/stat-simulation?year=${year}&month=${month}&centerId=${centerId}`,
  );
};

export const getSchedules = (body: RequestSchdules) => {
  const filter = {
    where: {
      centerId: body.centerId,
    },
    include: [
      {relation: 'patient'},
      {relation: 'employee'},
      {relation: 'insurance'},
      {relation: 'duty'},
      {relation: 'histories'},
    ],
  };
  return axios.get(
    `/schedules?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  );
};

export const getSchedulesServiceType = (body: RequestJobType) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('date');
  const service = body.service;
  const centerId = body.centerId;
  return axios.get(
    `/schedules/service-type?service=${service}&year=${year}&month=${month}&day=${day}&centerId=${centerId}`,
  );
};

export const getSchedulesGrade = (body: RequestGrade) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('date');
  const grade = body.grade;
  const centerId = body.centerId;
  return axios.get(
    `/schedules/grade-type?grade=${grade}&centerId=${centerId}&year=${year}&month=${month}&day=${day}`,
  );
};

export const getSchSearch = (body: StatReqeustPayload) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const centerId = body.centerId;
  const name = body.name;
  return axios.get(
    `/schedules/search?year=${year}&month=${month}&mode=${body.scale}&type=${body.grade}&centerId=${centerId}&name=${name}`,
  );
};

export const getSchedule = (id: number) => {
  const filter = {
    include: [
      {relation: 'patient'},
      {relation: 'employee'},
      {relation: 'insurance'},
      {
        relation: 'duty',
      },
    ],
  };
  return axios.get(`/schedules/${id}?filter=${JSON.stringify(filter)}`);
};

export const getAccountTypes = () => {
  const filter = {
    include: [{relation: 'accountSubTypes'}],
  };
  return axios.get(`/account-types?filter=${JSON.stringify(filter)}`);
};

export const saveAccountType = (payload: NewAccountType) => {
  return axios.post(`/account-types`, payload);
};

export const saveSubAccountType = (payload: NewAccountSubType) => {
  return axios.post(`/account-sub-types`, payload);
};

export const getEmployees = () => {
  return axios.get(`/employees`);
};

// employee 스케줄에서 nested query로 환자 및 히스토리, 센터 불러오기
export const getEmployeeSchedules = (id: number) => {
  const filter = {
    include: [
      // {
      //   relation: 'departments',
      // },
      {
        relation: 'schedules',
        scope: {
          include: [
            {
              relation: 'patient',
              scope: {
                include: [
                  {
                    relation: 'histories',
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  };
  return axios.get(`/employees/${id}?filter=${JSON.stringify(filter)}`);
};

export const updateInfo = () => {
  // return axios.patch(
  //   `/user/${id}`,
  //   {userId: body.userId, caregiver: body.caregiver},
  //   {
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //     },
  //   },
  // );
};

export const getAccounts = () => {
  const filter = {
    include: [{relation: 'accountType'}, {relation: 'accountSubType'}],
  };
  return axios.get(`/accounts?filter=${JSON.stringify(filter)}`);
};

export const saveAccount = (payload: NewAccount) => {
  return axios.post(`/accounts`, payload);
};

export const removeAccount = (payload: AccountRemove) => {
  return axios.delete(`/accounts/${payload.id}`);
};

export const saveHistory = (payload: HistoryDetail) => {
  const {
    startDate,
    minutes1,
    minutes2,
    minutes3,
    minutes4,
    minutes5,
    minutes6,
    minutes7,
    minutes8,
    minutes9,
    minutes10,
    minutes11,
    minutes12,
    patientId,
    endDate,
    scheduleId,
    userId,
    historyTypeId,
  } = payload;
  return axios.post(
    `/histories`,
    {
      minutes1,
      minutes2,
      minutes3,
      minutes4,
      minutes5,
      minutes6,
      minutes7,
      minutes8,
      minutes9,
      minutes10,
      minutes11,
      minutes12,
      patientId,
      endDate,
      startDate,
      scheduleId,
      userId,
      historyTypeId,
    },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
};

export const getHistoryStat = (body: StatRequestHistory) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('day');
  const job = body.jobType;
  const grade = body.grade;
  const centerId = body.centerId;

  return axios.get(
    `/histories/stat/total?mode=${body.scale}&year=${year}&month=${month}&day=${day}&job=${job}&grade=${grade}&centerId=${centerId}`,
  );
};

export const getHistories = () => {
  return axios.get(`history-type/detail`);
};

export const getHistoryFileStat = (body: StatRequestHistory) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const grade = body.grade;
  const centerId = body.centerId;

  return axios.get(
    `/history-file/stat?serviceType=${body.serviceHistoryType}&mode=${body.scale}&year=${year}&month=${month}&grade=${grade}&centerId=${centerId}`,
  );
};

export const getHistoryFileDetail = (body: ServiceHistoryType) => {
  return axios.get(`history-file-types/detail?serviceType=${body.id}`);
};

export const getHistorySummary = (body: StatSummary) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const centerId = body.centerId;

  return axios.get(
    `/history-type/summary?centerId=${centerId}&year=${year}&month=${month}`,
  );
};

export const getCenter = () => {
  const filter = {
    include: [
      {
        relation: 'inventory',
      },
    ],
  };
  return axios.get(`/centers?filter=${JSON.stringify(filter)}`);
};

export const getFilteredCenter = (body: StateCenterId) => {
  const filter = {
    where: {id: body.centerId},
    include: [
      {
        relation: 'inventory',
      },
    ],
  };
  return axios.get(`/centers?filter=${JSON.stringify(filter)}`);
};

export const updateInventory = (body: UpdateInventory) => {
  return axios.patch(
    `/inventory-stores/${body.id}`,
    {currentCount: body.currentCount},
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
};

export const updateMinimumCount = (body: UpdateMinmumCount) => {
  return axios.patch(
    `/inventories/${body.id}`,
    {minimumCount: body.minimumCount},
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
};

export const getItems = (body: ItemSummary) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const centerId = body.centerId;

  return axios.get(
    `/inventories-summary?year=${year}&month=${month}&mode=${body.scale}&centerId=${centerId}`,
  );
  //return axios.get(`/inventories?filter=${JSON.stringify(filter)}`);
};

export const getCurrentItems = (body: ItemSummary) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const date = body.datetime.get('date');
  const centerId = body.centerId;

  return axios.get(
    `/inventories/current?year=${year}&month=${month}&date=${date}&centerId=${centerId}`,
  );
  //return axios.get(`/inventories?filter=${JSON.stringify(filter)}`);
};

export const getMaterialCost = (body: ItemSummary) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const date = body.datetime.get('date');
  const centerId = body.centerId;
  return axios.get(
    `/inventories/cost-stat?mode=${body.scale}&year=${year}&month=${month}&date=${date}&centerId=${centerId}`,
  );
};

export const newInsertItem = (newItem: Inventory) => {
  const {
    name,
    type,
    price,
    minimumCount,
    centerId,
    startDate,
    endDate,
  } = newItem;
  return axios.post(
    `/inventories`,
    {
      name,
      type,
      price,
      minimumCount,
      centerId,
      startDate,
      endDate,
    },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
};

export const newStoreItem = (body: InsertStoreItem) => {
  const {
    price,
    storeCount,
    currentCount,
    startDate,
    endDate,
    inventoryId,
    userId,
    centerId,
  } = body;
  return axios.post(
    `/inventory-stores`,
    {
      price,
      storeCount,
      currentCount,
      startDate,
      endDate,
      inventoryId,
      userId,
      centerId,
    },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
};

export const newReleaseItem = (body: InsertReleaseItem) => {
  const {
    price,
    releaseCount,
    startDate,
    endDate,
    inventoryId,
    userId,
    inventoryStoreId,
    centerId,
  } = body;
  return axios.post(
    `/inventory-releases`,
    {
      releaseCount,
      price,
      startDate,
      endDate,
      inventoryId,
      userId,
      inventoryStoreId,
      centerId,
    },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
};

export const getStoredItems = (centerId: number) => {
  return axios.get(`/inventory-stores/all?centerId=${centerId}`);
};

export const getStatStore = (body: ItemSummary) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const centerId = body.centerId;

  return axios.get(
    `/inventory-stores/stat?year=${year}&month=${month}&mode=${body.scale}&centerId=${centerId}`,
  );
};

export const getStatRelease = (body: ItemSummary) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const centerId = body.centerId;

  return axios.get(
    `/inventory-releases/stat?year=${year}&month=${month}&mode=${body.scale}&centerId=${centerId}`,
  );
};

export const getCurrentStore = (body: ItemSummary) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const centerId = body.centerId;

  return axios.get(
    `/inventories/stat-current?year=${year}&month=${month}&mode=${body.scale}&centerId=${centerId}`,
  );
};

export const deleteItem = (id: number) => {
  return axios.delete(`/inventories/${id}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export const getUserDepartment = (userId: number) => {
  return axios.get(`/departments/user?userId=${userId}`);
};
