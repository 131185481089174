import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Typography, makeStyles, Theme, createStyles} from '@material-ui/core';
import MHistoyForm, {LocationType} from '~/forms/MHistoyForm';
import {useHistory, useLocation} from 'react-router-dom';
import {RootState} from '~/app/rootReducer';
import {EMPLOYEES} from '~/features/Employee/slice';
import {
  HISTORY,
  initHistories,
  requsetSaveHistory,
} from '~/features/History/slice';
import moment from 'moment';
import _ from 'lodash';
import {PROFILE} from '~/features/Profile/slice';
import {SCHEDULES} from '~/features/Schedule/slice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    body: {
      justifyContent: 'flex-start',
      flex: 1,
    },
    title: {
      display: 'flex',
      marginTop: theme.spacing(1) + 1,
      justifyContent: 'center',
      marginBottom: 32,
    },

    form: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
    },
    contentText: {
      width: 107,
    },
  }),
);

const MHistoryDetailScreen = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const historyDetail = useLocation<LocationType>().state;
  const [title, setTitle] = useState('');
  const [info, setInfo] = useState({
    minutes1: 0,
    minutes2: 0,
    minutes3: 0,
    minutes4: 0,
    minutes5: 0,
    minutes6: 0,
    minutes7: 0,
    minutes8: 0,
    minutes9: 0,
    minutes10: 0,
    minutes11: 0,
    minutes12: 0,
  });
  const [currentDate, setCurrentDate] = useState('');
  const {currentPatient} = useSelector((state: RootState) => state[EMPLOYEES]);
  const {historyBtn} = useSelector((state: RootState) => state[HISTORY]);
  const histories = useSelector((state: RootState) => state[HISTORY].histories);
  const userId = useSelector((state: RootState) => state[PROFILE].id);

  const scheduleId = useSelector(
    (state: RootState) => state[SCHEDULES].currentId,
  );

  useEffect(() => {
    if (historyDetail) {
      try {
        setCurrentDate(historyDetail.date);
        const detail = _.filter(
          histories,
          (his) => his.key === historyDetail.key,
        )[0];
        setTitle(detail.name);
      } catch (error) {
        history.push('/');
      }
    }
  }, [historyDetail, histories, history]);

  const submit = (values: any) => {
    dispatch(initHistories());
    history.push({pathname: '/history', state: {date: currentDate}});
    if (currentPatient) {
      const currentTime = moment
        .tz(new Date(), 'Asia/Seoul')
        .format('YYYY-MM-DDTHH:mm:ss')
        .slice(10, 19);
      dispatch(
        requsetSaveHistory({
          startDate: moment
            .tz(new Date(currentDate + currentTime), 'Asia/Seoul')
            .format(),
          minutes1: values.minutes1 ? parseInt(values.minutes1) : 0,
          minutes2: values.minutes2 ? parseInt(values.minutes2) : 0,
          minutes3: values.minutes3 ? parseInt(values.minutes3) : 0,
          minutes4: values.minutes4 ? parseInt(values.minutes4) : 0,
          minutes5: values.minutes5 ? parseInt(values.minutes5) : 0,
          minutes6: values.minutes6 ? parseInt(values.minutes6) : 0,
          minutes7: values.minutes7 ? parseInt(values.minutes7) : 0,
          minutes8: values.minutes8 ? parseInt(values.minutes8) : 0,
          minutes9: values.minutes9 ? parseInt(values.minutes9) : 0,
          minutes10: values.minutes10 ? parseInt(values.minutes10) : 0,
          minutes11: values.minutes11 ? parseInt(values.minutes11) : 0,
          minutes12: values.minutes12 ? parseInt(values.minutes12) : 0,
          patientId: currentPatient.id,
          endDate: moment
            .tz(new Date(currentDate + currentTime), 'Asia/Seoul')
            .format(),
          scheduleId: scheduleId, // 수정해야 함 현재의 스케줄 id로
          userId: userId,
          historyTypeId: historyDetail.id,
        }),
      );
    }
  };

  const handleDetail = useCallback(() => {
    if (currentPatient) {
      if (currentPatient.histories) {
        // key로 알맞은 디테일 필터링 후에
        // 여기서 history의 마지막 startDate를 구해서 러오기
        const details = _.filter(
          currentPatient.histories,
          (c) =>
            c.historyTypeId === historyDetail.id &&
            moment(c.startDate).format('YYYY-MM-DD') <= currentDate,
        );

        const recentHistory = _.sortBy(details, 'startDate').reverse()[0];

        if (recentHistory) {
          setInfo({
            minutes1: recentHistory.minutes1 ? recentHistory.minutes1 : 0,
            minutes2: recentHistory.minutes2 ? recentHistory.minutes2 : 0,
            minutes3: recentHistory.minutes3 ? recentHistory.minutes3 : 0,
            minutes4: recentHistory.minutes4 ? recentHistory.minutes4 : 0,
            minutes5: recentHistory.minutes5 ? recentHistory.minutes5 : 0,
            minutes6: recentHistory.minutes6 ? recentHistory.minutes6 : 0,
            minutes7: recentHistory.minutes7 ? recentHistory.minutes7 : 0,
            minutes8: recentHistory.minutes8 ? recentHistory.minutes8 : 0,
            minutes9: recentHistory.minutes9 ? recentHistory.minutes9 : 0,
            minutes10: recentHistory.minutes10 ? recentHistory.minutes10 : 0,
            minutes11: recentHistory.minutes11 ? recentHistory.minutes11 : 0,
            minutes12: recentHistory.minutes12 ? recentHistory.minutes12 : 0,
          });
        }
      }
    }
  }, [currentPatient, historyDetail, currentDate]);

  // 히스토리 처치시간 초기화
  const handleInitDetail = useCallback(() => {
    setInfo({
      minutes1: 0,
      minutes2: 0,
      minutes3: 0,
      minutes4: 0,
      minutes5: 0,
      minutes6: 0,
      minutes7: 0,
      minutes8: 0,
      minutes9: 0,
      minutes10: 0,
      minutes11: 0,
      minutes12: 0,
    });
  }, []);

  useEffect(() => {
    if (historyBtn) {
      handleDetail();
    } else {
      handleInitDetail();
    }
  }, [historyBtn, handleDetail, handleInitDetail]);

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <div className={classes.title}>
          <Typography style={{fontWeight: 700}} variant={'h5'}>
            {title}
          </Typography>
        </div>
        <div>
          <MHistoyForm
            // key확인해서 초기값 설정해야 함
            initialValues={info}
            onSubmit={submit}
          />
        </div>
      </div>
    </div>
  );
};

export default MHistoryDetailScreen;
