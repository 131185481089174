import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import HistoryDetail from '~/models/hisotry';
import {
  SERVICE_HISTORY_TYPE,
  StatResult,
  StatSummary,
  STAT_JOB_TYPE,
  STAT_SCALE,
} from '~/types/types';

export interface StatRequestHistory {
  scale: STAT_SCALE;
  datetime: moment.Moment;
  grade?: number;
  jobType?: STAT_JOB_TYPE;
  serviceHistoryType?: SERVICE_HISTORY_TYPE;
  centerId: number;
}

export interface HistoryStat {
  id: number;
  name: string;
  order: number;
  key: number;
  children: {
    name: string;
    time: string;
  }[];
}

export interface HisSummary {
  name: string;
  count: number;
  minutes: number;
}

export interface ServiceHistoryType {
  id: number;
}

interface State {
  loading: boolean;
  request: StatRequestHistory | StatSummary | ServiceHistoryType | undefined;
  history?: HistoryDetail;
  historyBtn: boolean;
  historyStat: StatResult;
  historyFileStat: StatResult;
  histories: HistoryStat[];
  historyFiles: HistoryStat[];
  summary: HisSummary[];
}

const initialState: State = {
  loading: false,
  history: undefined,
  historyBtn: false,
  request: undefined,
  historyStat: {},
  histories: [],
  historyFileStat: {},
  historyFiles: [],
  summary: [],
};

const slice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    onClickHistories: (state) => {
      state.historyBtn = true;
    },
    initHistories: (state) => {
      state.historyBtn = false;
    },
    requsetSaveHistory: (state, action: PayloadAction<HistoryDetail>) => {
      state.loading = true;
      state.history = action.payload;
    },
    successSaveHistory: (state) => {
      state.loading = false;
    },
    loadHistoryStat: (state, action: PayloadAction<StatRequestHistory>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successLoadHistoryStat: (state, action: PayloadAction<StatResult>) => {
      state.loading = false;
      state.historyStat = action.payload;
    },
    loadHistories: (state) => {
      state.loading = true;
    },
    successLoadHistories: (state, action: PayloadAction<HistoryStat[]>) => {
      state.loading = false;
      state.histories = action.payload;
    },
    loadSummary: (state, action: PayloadAction<StatSummary>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successLoadSummary: (state, action: PayloadAction<HisSummary[]>) => {
      state.loading = false;
      state.summary = action.payload;
    },
    loadHistoryFileDetail: (
      state,
      action: PayloadAction<ServiceHistoryType>,
    ) => {
      state.loading = true;
      state.request = action.payload;
    },
    successLoadHistoryFileDetail: (
      state,
      action: PayloadAction<HistoryStat[]>,
    ) => {
      state.loading = false;
      state.historyFiles = action.payload;
    },
    requestHistoryFileStat: (
      state,
      action: PayloadAction<StatRequestHistory>,
    ) => {
      state.loading = true;
      state.request = action.payload;
    },
    successLoadHistoryFileStat: (state, action: PayloadAction<StatResult>) => {
      state.loading = false;
      state.historyFileStat = action.payload;
    },
  },
});

export const {
  onClickHistories,
  initHistories,
  requsetSaveHistory,
  successSaveHistory,
  loadHistoryStat,
  successLoadHistoryStat,
  loadHistories,
  successLoadHistories,
  loadSummary,
  successLoadSummary,
  loadHistoryFileDetail,
  successLoadHistoryFileDetail,
  requestHistoryFileStat,
  successLoadHistoryFileStat,
} = slice.actions;
export const HISTORY = slice.name;
export default slice.reducer;
