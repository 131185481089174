import {makeStyles, Paper} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import {Detail} from 'react-calendar';
import GradeSelector from '~/components/GradeSelector';
import {GRAPH_TYPE} from '~/components/Graph';
import GraphSelector from '~/components/GraphSelector';
import ItemSelector from '~/components/ItemSelector';
import JobSelector from '~/components/JobSelector';
import PeriodSelector from '~/components/PeriodSelector';
import ServiceHistorySelector from '~/components/ServiceHistorySelector';
import ServiceSelector from '~/components/ServiceSelector';
import StyledCalendar from '~/components/StyledCalendar';
import {Typography} from '~/components/Typography';
import {
  STAT_SCALE,
  SCALE_TO_VIEW_2,
  SERVICE_TYPE,
  STAT_JOB_TYPE,
  ITEM_TYPE,
  SERVICE_HISTORY_TYPE,
} from '~/types/types';

const useStyles = makeStyles((theme) => ({
  top: {
    display: 'flex',
  },
  filterStyle: {
    width: '100%',
    background: '#FBFBFB',
    border: '1px solid #C2C2C2',
    boxShadow: 'none',
  },
  filter: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
  },
  filterTitle: {
    width: '23%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  filterContent: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
}));

export interface StatProps {
  statScale: STAT_SCALE;
  viewType: Detail;
  date: Date;
  service?: SERVICE_TYPE;
  jobType: STAT_JOB_TYPE;
  graphType: GRAPH_TYPE;
  grade?: number;
  itemType: ITEM_TYPE;
  serviceHistoryType: SERVICE_HISTORY_TYPE;
}

export interface StatConfig {
  service?: boolean;
  job?: boolean;
  graph?: boolean;
  grade?: boolean;
  title?: string;
  search?: boolean;
  pstat?: boolean;
  employee?: boolean;
  item?: boolean;
  wage?: boolean;
  serviceHistory?: boolean;
}

const withStatTemplate = (
  WrappedComponent: React.FC<any>,
  config?: StatConfig,
) => (props: any) => {
  const classes = useStyles();

  const [date, setDate] = useState(new Date());
  const [statScale, setStateScale] = useState<STAT_SCALE>(STAT_SCALE.WEEK);
  const [viewType, setViewType] = useState<Detail>('year');
  const [service, setService] = useState<SERVICE_TYPE>(SERVICE_TYPE.NURSING);
  const [jobType, setJobType] = useState<STAT_JOB_TYPE>(STAT_JOB_TYPE.NURSE);
  const [itemType, setItemType] = useState<ITEM_TYPE>(ITEM_TYPE.STOCK);
  const [serviceHistoryType, setServiceHistoryType] = useState<
    SERVICE_HISTORY_TYPE
  >(SERVICE_HISTORY_TYPE.NURSE);
  const [graphType, setGraphType] = useState<GRAPH_TYPE>(GRAPH_TYPE.ALL);
  const [grade, setGrade] = useState<number>(config?.pstat ? 8 : 1);

  const handleScale = (scale: STAT_SCALE) => {
    setStateScale(scale);
    setViewType(SCALE_TO_VIEW_2[scale]);
  };

  const handleDate = useCallback((date: Date) => {
    setDate(date);
  }, []);

  return (
    <>
      <div className={classes.top}>
        <Typography
          className={classes.filterTitle}
          variant="H4"
          style={{fontWeight: 400}}>
          {`${config?.title}`}
        </Typography>
        <Paper className={classes.filterStyle}>
          <div className={classes.filter}>
            <div className={classes.filterContent}>
              {config?.search ? (
                <></>
              ) : (
                <PeriodSelector value={statScale} onChange={handleScale} />
              )}
              {config?.search && config?.pstat ? (
                <PeriodSelector value={statScale} onChange={handleScale} />
              ) : (
                <></>
              )}
              {config?.grade && (
                <GradeSelector
                  value={grade}
                  total={config?.pstat}
                  search={config?.search}
                  onChange={(v) => setGrade(v)}
                />
              )}
              {config?.service && (
                <ServiceSelector
                  wage={config.wage}
                  value={service}
                  onChange={(v) => setService(v)}
                />
              )}
              {config?.job && (
                <JobSelector
                  search={config?.search}
                  value={jobType}
                  onChange={(v) => setJobType(v)}
                />
              )}
              {config?.graph && (
                <GraphSelector
                  value={graphType}
                  onChange={(t) => setGraphType(t)}
                />
              )}
              {config?.item && (
                <ItemSelector
                  value={itemType}
                  onChange={(t) => setItemType(t)}
                />
              )}
              {config?.serviceHistory && (
                <ServiceHistorySelector
                  value={serviceHistoryType}
                  onChange={(t) => setServiceHistoryType(t)}
                />
              )}
            </div>
          </div>
        </Paper>
      </div>
      <br />
      {config?.employee ? (
        <></>
      ) : (
        <StyledCalendar
          value={date}
          onClickYear={handleDate}
          onClickMonth={handleDate}
          onClickDay={handleDate}
          onClickDecade={handleDate}
          view={config?.wage ? ('decade' as Detail) : viewType}
          next2Label={null}
          prev2Label={null}
        />
      )}
      <WrappedComponent
        {...props}
        statScale={statScale}
        viewType={viewType}
        date={date}
        service={service}
        jobType={jobType}
        graphType={graphType}
        grade={grade}
        itemType={itemType}
        serviceHistoryType={serviceHistoryType}
      />
    </>
  );
};

export default withStatTemplate;
