import {
  IconButton,
  makeStyles,
  Popover,
  TextField,
  Theme,
  useTheme,
} from '@material-ui/core';
import React, {useState} from 'react';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  infoDiv: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(1.875, 1.7775, 1.25, 0),
  },
  spanDiv: {
    display: 'flex',
    width: 100,
    height: 35,
    alignItems: 'center',
    fontSize: '0.875em',
  },
  modalInput: {
    background: '#fff',
    margin: theme.spacing(0, 1, 0, 1.625),
    '& > div': {
      width: 218.78,
      height: 35,
    },
  },
  hisElem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1.25),
  },
  hisInput: {
    background: '#fff',
    margin: theme.spacing(0, 1, 0, 1.625),
    '& > div': {
      width: 191,
      height: 35,
    },
  },
  hisDetail: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4.28, 3.25),
    background: '#FBFBFB',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    alignItems: 'center',
  },
  detailItem: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

export const PopBodyTopContent = (props: {name: string; value: string}) => {
  const classes = useStyles();
  const {name, value} = props;

  return (
    <div className={classes.infoDiv}>
      <span className={classes.spanDiv}>{`${name}`}</span>
      <TextField
        className={classes.modalInput}
        variant="outlined"
        defaultValue={value ? value : '없음'}
        InputProps={{
          readOnly: true,
        }}
      />
    </div>
  );
};

export const PopBodyHistory = (props: {
  history: any;
  time: number;
  data: any;
}) => {
  const {history, time, data} = props;
  const classes = useStyles();
  const theme = useTheme() as Theme;

  // 히스토리 정보 저장
  //const [time, setTime] = useState<number[]>([]);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const pOpen = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.hisElem}>
      <span className={classes.spanDiv}>{`${history.name}`}</span>
      <TextField
        className={classes.hisInput}
        variant="outlined"
        defaultValue={time}
        InputProps={{
          readOnly: true,
        }}
      />
      <span>분</span>
      <IconButton
        style={{marginRight: theme.spacing(1)}}
        aria-label="expand row"
        size="small"
        onClick={(event: any) => {
          setOpen(true);
          setAnchorEl(event.currentTarget);
        }}>
        <KeyboardArrowRightIcon />
      </IconButton>

      {open ? (
        <Popover
          open={pOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          <div className={classes.hisDetail}>
            <div>
              <IconButton
                style={{marginRight: theme.spacing(1)}}
                aria-label="expand row"
                size="small"
                onClick={(event: any) => {
                  setOpen(false);
                  setAnchorEl(event.currentTarget);
                }}>
                <ChevronLeftIcon />
              </IconButton>
              <span
                style={{
                  fontSize: '1em',
                  fontWeight: 500,
                }}>{`${history.name}`}</span>
            </div>
            {_.map(data, (child, idx) => {
              return (
                <div key={idx} className={classes.hisElem}>
                  <span className={classes.spanDiv}>{`${child.name}`}</span>
                  <TextField
                    className={classes.hisInput}
                    variant="outlined"
                    defaultValue={`${child.time}`}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <span>분</span>
                </div>
              );
            })}
          </div>
        </Popover>
      ) : (
        <></>
      )}
    </div>
  );
};
