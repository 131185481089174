import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import './index.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import createStore from './app/store';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import axios from 'axios';

const {store, persistor} = createStore();

const theme = createTheme({
  palette: {
    //blue
    primary: {
      light: '#2196F3',
      main: '#536DFE',
      // dark: '#536DFE',
      contrastText: '#FFFFFF',
    },
    //red
    secondary: {
      // light: '#0066ff',
      main: '#E91E63',
      dark: '#E31B0C',
      contrastText: '#FFFFFF',
    },
    //gray
    info: {
      light: '#FBFBFB',
      main: '#F6F6F6',
      dark: '#C2C2C2',
      contrastText: '#000000',
    },
  },
});

//axios.defaults.baseURL = 'http://localhost:3000';
if (process.env.REACT_APP_ENV === 'stage') {
  axios.defaults.baseURL = 'https://3s-bis-server.thingsw.com';
} else {
  axios.defaults.baseURL = 'https://3s-bis-server-dev.thingsw.com';
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
