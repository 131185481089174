import React, {useCallback, useEffect, useState} from 'react';

import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  Divider,
} from '@material-ui/core';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducer';
import {HISTORY, loadHistories} from '~/features/History/slice';
import _ from 'lodash';
import {useLocation} from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    body: {
      display: 'flex',
      justifyContent: 'center',
      flex: 1,
    },
    frame: {
      display: 'flex',
      marginTop: theme.spacing(4) + 3,
      marginBottom: theme.spacing(2) - 1,
      flexDirection: 'column',
      alignItems: 'center',
      //justifyContent: 'center',
      minWidth: 320,
      padding: '0px 15px',
    },
    btnDivs: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    firstBtn: {
      display: 'flex',
      width: 162,
      height: theme.spacing(7) + 4,
      fontSize: '0.9em',
      fontWeight: 400,
    },
    secondBtn: {
      display: 'flex',
      width: 162,
      height: theme.spacing(7) + 4,
      fontSize: '0.9em',
      fontWeight: 400,
      marginLeft: theme.spacing(2),
    },
    buttons: {
      width: '100%',
      height: 42,
      display: 'flex',
    },
    cancel: {
      display: 'inline',
      width: '100%',
      fontSize: '0.9375em',
    },
    submit: {
      display: 'inline',
      width: '100%',
      marginLeft: theme.spacing(1) + 6,
      fontSize: '0.938em',
    },
  }),
);

type props = {
  date: string;
};

const MHistoryScreen = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation<props>().state;
  const [selectedDate, setSelectedDate] = useState('');
  const [names, setNames] = useState<any[]>([]);

  const histories = useSelector((state: RootState) => state[HISTORY].histories);

  useEffect(() => {
    dispatch(loadHistories());
  }, [dispatch]);

  useEffect(() => {
    if (location) {
      try {
        setSelectedDate(location.date);
      } catch (error) {
        history.push('/');
      }
    }
  }, [location, setSelectedDate, history]);

  useEffect(() => {
    const newHistories = _.filter(
      histories,
      (history) => history.name !== '기타',
    );
    let tmp: any = [];
    let tmp2: any = [];

    _.map(_.sortBy(newHistories, 'order'), (his, idx) => {
      if (idx === 0 || idx % 2 !== 0) {
        tmp.push([his.name, his.id]);
      } else if (idx % 2 === 0) {
        tmp2.push(tmp);
        tmp = [];
        tmp.push([his.name, his.id]);
      }
      if (idx === 11) {
        tmp2.push(tmp);
      }
    });
    setNames(tmp2);
  }, [histories]);

  const renderHistoryName = useCallback(() => {
    return _.map(names, (name, idx) => (
      <div key={idx} className={classes.btnDivs}>
        <Button
          onClick={() => {
            history.push({
              pathname: '/history-detail',
              state: {key: 2 * idx + 1, id: name[0][1], date: selectedDate},
            });
          }}
          variant="contained"
          color="primary"
          style={{padding: '7px 8px'}}
          className={classes.firstBtn}>
          {name[0][0]}
        </Button>
        <Button
          onClick={() => {
            history.push({
              pathname: '/history-detail',
              state: {key: 2 * idx + 2, id: name[1][1], date: selectedDate},
            });
          }}
          variant="contained"
          color="primary"
          style={{padding: '7px 8px'}}
          className={classes.secondBtn}>
          {name[1][0]}
        </Button>
      </div>
    ));
  }, [
    names,
    classes.btnDivs,
    classes.firstBtn,
    classes.secondBtn,
    history,
    selectedDate,
  ]);

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <div className={classes.frame}>
          {renderHistoryName()}
          <Divider
            style={{
              width: '100%',
              borderTop: '1px solid rgba(0, 0, 0, 0.23)',
              marginBottom: 14,
            }}
          />
          <div className={classes.buttons}>
            <Button
              onClick={() => {
                history.push('/');
              }}
              variant="contained"
              color="secondary"
              className={classes.cancel}>
              취소
            </Button>
            <Button
              onClick={() => {
                history.push('/');
              }}
              variant="contained"
              color="primary"
              className={classes.submit}>
              완료
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MHistoryScreen;
