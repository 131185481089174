import React from 'react';
import {useDispatch} from 'react-redux';
import {makeStyles, Theme, createStyles} from '@material-ui/core';
import MSignUpForm from '~/forms/MSignUpForm';
import {SimpleHeader} from '~/components/SimpleHeader';
import {signUpRequest} from '~/features/SignUp/slice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      flexGrow: 1,
      fontSize: '1.5em',
      fontWeight: 700,
      lineHeight: '35px',
    },
    body: {
      justifyContent: 'flex-start',
      flex: 1,
      paddingTop: 60,
    },
    footer: {
      display: 'flex',
      backgroundColor: theme.palette.primary.main,
      height: 50,
      color: theme.palette.primary.contrastText,
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
  }),
);

const MSignUpScreen = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const submit = (values: any) => {
    const {email, name, caregiver, phone, password} = values;

    dispatch(
      signUpRequest({
        email,
        name,
        caregiver,
        phone,
        password,
      }),
    );
  };

  return (
    <div className={classes.root}>
      <SimpleHeader mode={'signUp'} />
      <div className={classes.body}>
        <MSignUpForm onSubmit={submit} />
      </div>
    </div>
  );
};

export default MSignUpScreen;
