import {takeLatest, call, put, select} from 'redux-saga/effects';
import {
  CENTER,
  requsetCenter,
  successLoadCenter,
  requestFilteredCenter,
  successLoadFilteredCenter,
} from './slice';
import {getCenter, getFilteredCenter} from '~/api';

function* handleLoadCenter() {
  try {
    //const {token} = yield select((state: RootState) => state[PROFILE]);
    const resp = yield call(getCenter);
    yield put(successLoadCenter(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleFilteredLoadCenter() {
  try {
    //const {token} = yield select((state: RootState) => state[PROFILE]);

    const state = yield select((state) => state[CENTER]);
    const resp = yield call(getFilteredCenter, state.request);
    yield put(successLoadFilteredCenter(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

export default function* watchCenter() {
  yield takeLatest(requsetCenter, handleLoadCenter);
  yield takeLatest(requestFilteredCenter, handleFilteredLoadCenter);
}
