import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface DepartmentInfo {
  centerId: number;
  employeeId: number;
  centerName: string;
}
interface State {
  loading: boolean;
  userId: number;
  centerInfo?: DepartmentInfo;
}

const initialState: State = {
  loading: false,
  userId: 0,
  centerInfo: undefined,
};

const slice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    loadDepartment: (state, action: PayloadAction<number>) => {
      state.loading = true;
      state.userId = action.payload;
    },
    successLoadDepartment: (state, action: PayloadAction<DepartmentInfo>) => {
      state.loading = false;
      state.centerInfo = action.payload;
    },
  },
});

export const {loadDepartment, successLoadDepartment} = slice.actions;
export const DEPARTMENT = slice.name;
export default slice.reducer;
