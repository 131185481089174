import React, {useState, useCallback, useEffect} from 'react';
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import withStatTemplate, {StatProps} from '~/hoc/withStatTemplate';
import {useDispatch, useSelector} from 'react-redux';
import {
  Button,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import _ from 'lodash';
import {RootState} from '~/app/rootReducer';
import {
  INVENTORY,
  //requestDeleteItem,
  requestGetItems,
  requestInsertStoreItems,
  requestInsertItem,
  requestMinimumCount,
} from '~/features/Inventory/slice';
import {Typography} from '~/components/Typography';
import ItemSelector from '~/components/ItemSelector';
import {InventorySummary, ITEM_TYPE} from '~/types/types';
import CreateIcon from '@material-ui/icons/Create';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import moment from 'moment';
import {DEPARTMENT, loadDepartment} from '~/features/Department/slice';
import {PROFILE} from '~/features/Profile/slice';
//import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  backColorBlue: {
    fontSize: '0.75em',
    backgroundColor: `${theme.palette.primary.light}14`,
  },
  backColorGray: {
    fontSize: '0.625em',
    backgroundColor: `#F6F6F6`,
  },
  table: {
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 'none',
    maxHeight: 800,
    '& > table > thead > tr > th': {
      padding: theme.spacing(1, 3.75),
      fontWeight: 400,
      backgroundColor: `#F6F6F6`,
      border: 'none',
    },
  },
  tableText: {
    fontSize: '0.625em',
  },
  registerDiv: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(4.875),
  },
  regBtn: {
    height: theme.spacing(5.75),
    padding: theme.spacing(1.875, 5.25),
    '& > span': {
      letterSpacing: theme.spacing(0.15625),
    },
  },
  regBox: {
    marginLeft: theme.spacing(2.125),
    display: 'flex',
    flexDirection: 'column',
    background: '#F6F6F6',
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    boxSizing: 'border-box',
    borderRadius: theme.spacing(1.25),
    padding: theme.spacing(4, 4),
  },
  classify: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(2.5),
    '& > span': {
      marginRight: theme.spacing(5),
    },
  },
  regInput: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(2.5, 0),
  },
  input: {
    background: '#fff',
    marginLeft: theme.spacing(3),
    '& > div': {
      height: theme.spacing(4.375),
    },
  },
  regBottom: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  btnDiv: {
    height: 42,
    display: 'flex',
    marginLeft: theme.spacing(5.48),
  },
  cancel: {
    display: 'inline',
    minWidth: 100,
    fontSize: '0.9375em',
  },
  submit: {
    display: 'inline',
    minWidth: 100,
    height: 42,
    marginLeft: theme.spacing(1) + 6,
    fontSize: '0.938em',
  },
  iconCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  circle: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: '50%',
  },
  modalBack: {
    padding: theme.spacing(3, 4),
    background: '#F6F6F6',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modalText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  modalInputCount: {
    background: '#fff',
    margin: theme.spacing(0, 1, 0, 1.625),
    '& > div': {
      width: theme.spacing(8.25),
      height: theme.spacing(4.375),
    },
  },
  modalInput: {
    background: '#fff',
    margin: theme.spacing(0, 1, 0, 1.625),
    '& > div': {
      width: theme.spacing(22),
      height: theme.spacing(4.375),
    },
  },
  modelBtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
    marginTop: theme.spacing(4),
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0, 0, 0, 15),
    padding: theme.spacing(1.875, 2.75),
    background: '#FFFFFF',
    border: '1px solid #C2C2C2',
    boxSizing: 'border-box',
    borderRadius: theme.spacing(0.5),
    justifyContent: 'center',
    height: 'fit-content',
  },
  labelElem: {
    marginBottom: theme.spacing(1.25),
    display: 'flex',
    justifyContent: 'start',
    minWidth: 190,
  },
  midDiv: {
    display: 'flex',
    marginTop: theme.spacing(4.75),
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const StockScreen = ({statScale, date, itemType}: StatProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme() as Theme;
  const [register, setRegister] = useState(false);
  const [itemName, setItemName] = useState('');
  const [itemPrice, setItemPrice] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [newItemType, setNewItemType] = useState<ITEM_TYPE>(ITEM_TYPE.STOCK);
  const [supplies, setSupplies] = useState<InventorySummary[]>([]);
  const [miniCnt, setMiniCnt] = useState(0);
  const [itemId, setItemId] = useState(0);
  const [newItem, setNewItem] = useState(false);
  const [newCount, setNewCount] = useState(0);
  const [newPrice, setNewPrice] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const {inventories, updateItem, newInventory, updateItemStore} = useSelector(
    (state: RootState) => state[INVENTORY],
  );

  // 센터 id 가져오기
  const userId = useSelector((state: RootState) => state[PROFILE].id);
  const centerId = useSelector(
    (state: RootState) => state[DEPARTMENT].centerInfo?.centerId,
  );

  const [selectedDay, setSelectedDay] = useState(() => {
    const newDate = new Date();
    const today = `${newDate.getFullYear()}-${(newDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${newDate.getDate()}`;
    return today;
  });

  useEffect(() => {
    if (userId) {
      dispatch(loadDepartment(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (centerId) {
      const request = {
        scale: statScale,
        datetime: moment(date),
        centerId: centerId,
      };
      dispatch(requestGetItems(request));
    }
  }, [
    dispatch,
    updateItem,
    newInventory,
    updateItemStore,
    centerId,
    date,
    statScale,
  ]);

  useEffect(() => {
    if (inventories) {
      if (itemType === 0) {
        setSupplies(inventories.filter((item) => item.type === '비품').sort());
      } else {
        setSupplies(
          inventories.filter((item) => item.type === '소모품').sort(),
        );
      }
    }
  }, [inventories, itemType]);

  const handleMinimumConut = () => {
    const request = {
      id: itemId,
      minimumCount: miniCnt,
    };
    dispatch(requestMinimumCount(request));
    setAnchorEl(null);
  };

  const handleInputItem = () => {
    setItemName('');
    setItemPrice(0);
    setItemCount(0);
    const type = newItemType === 0 ? '비품' : '소모품';

    dispatch(
      requestInsertItem({
        name: itemName,
        type: type,
        price: itemPrice,
        minimumCount: itemCount,
        centerId: centerId,
        startDate: moment.tz(new Date(selectedDay), 'Asia/Seoul').format(),
        endDate: moment.tz(new Date(selectedDay), 'Asia/Seoul').format(),
      }),
    );
  };

  const handleItemCost = () => {
    if (newPrice === 0 || newCount === 0) {
      alert('수량 및 구매단가를 입력해주세요.');
    } else {
      dispatch(
        requestInsertStoreItems({
          price: newPrice,
          storeCount: newCount,
          currentCount: newCount,
          startDate: moment.tz(new Date(selectedDay), 'Asia/Seoul').format(),
          endDate: moment.tz(new Date(selectedDay), 'Asia/Seoul').format(),
          inventoryId: itemId,
          userId: userId,
          centerId: centerId,
        }),
      );
    }

    setAnchorEl(null);
    setNewItem(false);
    setNewCount(0);
    setNewPrice(0);
  };

  // const removeItem = useCallback(
  //   (id: number) => {
  //     dispatch(
  //       requestDeleteItem({
  //         id: id,
  //       }),
  //     );
  //   },
  //   [dispatch],
  // );

  const handleClick = () => {
    setRegister(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColor = (c: number, m: number) => {
    if (c >= m * 2) {
      return '#4CAF50';
    } else if (c >= m && c < m * 2) {
      return '#FFB400';
    } else if (c < m) {
      return '#E31B0C';
    }
    return undefined;
  };

  const body = (
    <div className={classes.modalBack}>
      <div className={classes.modalText}>
        <Typography variant="Small">최소 재고수량 설정</Typography>
        <TextField
          className={classes.modalInputCount}
          name="itemName"
          variant="outlined"
          defaultValue={0}
          onChange={(e) => setMiniCnt(parseInt(e.target.value))}
        />
        <Typography variant="Small">개</Typography>
      </div>
      <Button
        onClick={handleMinimumConut}
        style={{width: '100%', marginTop: theme.spacing(2.25)}}
        variant="contained"
        color="primary"
        className={classes.modelBtn}>
        적용
      </Button>
    </div>
  );

  const newBody = (
    <div className={classes.modalBack}>
      <Typography variant="Small">입고 수량과 단가를 입력해주세요</Typography>
      <div
        className={classes.modalText}
        style={{marginTop: theme.spacing(3.5)}}>
        <Typography variant="Small">수량</Typography>
        <TextField
          style={{marginLeft: theme.spacing(5)}}
          className={classes.modalInput}
          name="newCount"
          variant="outlined"
          onChange={(e) => setNewCount(parseInt(e.target.value))}
        />
      </div>
      <div
        className={classes.modalText}
        style={{marginTop: theme.spacing(1.25)}}>
        <Typography variant="Small">구매단가</Typography>
        <TextField
          style={{marginLeft: theme.spacing(1.5)}}
          className={classes.modalInput}
          name="newPrice"
          variant="outlined"
          onChange={(e) => setNewPrice(parseInt(e.target.value))}
        />
      </div>
      <div
        className={classes.modalText}
        style={{marginTop: theme.spacing(1.25)}}>
        <TextField
          type="date"
          onChange={(e) => setSelectedDay(e.target.value)}
          defaultValue={selectedDay}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <div className={classes.modelBtn}>
        <Button
          style={{flex: 1, marginRight: theme.spacing(1.125)}}
          onClick={handleClose}
          variant="contained"
          color="secondary">
          취소
        </Button>
        <Button
          style={{flex: 1}}
          onClick={handleItemCost}
          variant="contained"
          color="primary">
          확인
        </Button>
      </div>
    </div>
  );

  const renderItemTable = useCallback(() => {
    return _.map(supplies, (supply, idx) => {
      return (
        <TableRow key={idx}>
          <TableCell align="center">{`${supply.type}`}</TableCell>
          <TableCell
            align="center"
            style={{width: theme.spacing(18.75), wordBreak: 'break-all'}}>
            {`${supply.name}`}
          </TableCell>
          <TableCell align="center">{`${supply.store}개`}</TableCell>
          <TableCell align="center">{`${supply.release}개`}</TableCell>
          <TableCell align="center">
            <div className={classes.iconCell}>
              <div
                style={{
                  background: handleColor(supply.current, supply.minimum),
                }}
                className={classes.circle}></div>
              {`${supply.current}개`}
              <CreateIcon
                onClick={(event) => {
                  setNewItem(false);
                  setItemId(supply.id);
                  //@ts-ignore
                  setAnchorEl(event.currentTarget);
                }}
                fontSize="small"
              />
            </div>
          </TableCell>
          <TableCell align="center">
            <div className={classes.iconCell}>
              <ExitToAppIcon
                onClick={(event) => {
                  setItemId(supply.id);
                  setNewItem(true);
                  //@ts-ignore
                  setAnchorEl(event.currentTarget);
                }}
                fontSize="small"
              />
              {/* <DeleteIcon
                onClick={() => removeItem(supply.id)}
                fontSize="small"
              /> */}
            </div>
          </TableCell>
        </TableRow>
      );
    });
  }, [
    supplies,
    classes.circle,
    classes.iconCell,
    theme,
    /*removeItem]*/
  ]);

  return (
    <>
      <div className={classes.midDiv}>
        <TableContainer className={classes.table}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className={classes.backColorGray}>
              <TableRow>
                <TableCell align="center">구분</TableCell>
                <TableCell align="center">상품명</TableCell>
                <TableCell align="center">입고량</TableCell>
                <TableCell align="center">사용량</TableCell>
                <TableCell align="center">현재 재고수량</TableCell>
                <TableCell align="center">재고수량 조정</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderItemTable()}</TableBody>
          </Table>
        </TableContainer>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          <Typography variant="Small">{newItem ? newBody : body}</Typography>
        </Popover>
        <div className={classes.label}>
          <div className={classes.labelElem}>
            <div
              style={{
                background: '#E31B0C',
                marginRight: theme.spacing(2),
              }}
              className={classes.circle}></div>
            <Typography variant="Small">최소잔여수량</Typography>
          </div>
          <div className={classes.labelElem}>
            <div
              style={{
                background: '#FFB400',
                marginRight: theme.spacing(2),
              }}
              className={classes.circle}></div>
            <Typography variant="Small">최소잔여수량 2배 이하</Typography>
          </div>
          <div className={classes.labelElem}>
            <div
              style={{
                background: '#4CAF50',
                marginRight: theme.spacing(2),
              }}
              className={classes.circle}></div>
            <Typography variant="Small">최소잔여수량 2배 이상</Typography>
          </div>
          <div className={classes.labelElem}>
            <CreateIcon
              style={{marginRight: theme.spacing(2)}}
              fontSize="small"
            />
            <Typography variant="Small">최소 재고수량 설정</Typography>
          </div>
          <div className={classes.labelElem}>
            <ExitToAppIcon
              style={{marginRight: theme.spacing(2)}}
              fontSize="small"
            />
            <Typography variant="Small">입고</Typography>
          </div>
          {/* <div className={classes.labelElem} style={{margin: 0}}>
            <DeleteIcon
              style={{marginRight: theme.spacing(2)}}
              fontSize="small"
            />
            <Typography variant="Small">삭제</Typography>
          </div> */}
        </div>
      </div>
      <div className={classes.registerDiv}>
        <Button
          variant="contained"
          onClick={handleClick}
          className={classes.regBtn}
          color="primary">
          제품등록
        </Button>
        {register ? (
          <div className={classes.regBox}>
            <Typography variant="Small">제품등록</Typography>
            <div className={classes.classify}>
              <Typography variant="Small">구분</Typography>
              <ItemSelector
                value={newItemType}
                onChange={(t) => setNewItemType(t)}
              />
            </div>
            <div className={classes.regInput}>
              <Typography variant="Small">제품명</Typography>
              <TextField
                className={classes.input}
                name="itemName"
                variant="outlined"
                onChange={(e) => setItemName(e.target.value)}
              />
            </div>
            <div className={classes.regBottom}>
              <Typography variant="Small">구매단가</Typography>
              <TextField
                className={classes.input}
                style={{margin: theme.spacing(0, 2.45, 0, 1.08)}}
                name="itemName"
                variant="outlined"
                onChange={(e) => setItemPrice(parseInt(e.target.value))}
              />
              <Typography variant="Small">최소 잔여수량 설정</Typography>
              <TextField
                className={classes.input}
                name="itemName"
                variant="outlined"
                onChange={(e) => setItemCount(parseInt(e.target.value))}
              />
              <div className={classes.btnDiv}>
                <Button
                  className={classes.cancel}
                  variant="contained"
                  onClick={() => {
                    setItemName('');
                    setRegister(false);
                  }}
                  color="secondary">
                  취소
                </Button>
                <Button
                  className={classes.submit}
                  variant="contained"
                  onClick={() => {
                    handleInputItem();
                    setRegister(false);
                  }}
                  color="primary">
                  등록
                </Button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default withStatTemplate(StockScreen, {
  title: '현재 재고 현황',
  item: true,
});
