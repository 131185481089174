import React, {InputHTMLAttributes, useState} from 'react';
import {
  Field,
  InjectedFormProps,
  reduxForm,
  WrappedFieldProps,
} from 'redux-form';
import {makeStyles, TextField, Button} from '@material-ui/core';
import submit from '~/components/Submit';
import {IInputProps} from './SignUpForm';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: theme.spacing(10, 0, 16.25),
    justifyContent: 'center',
  },
  frame: {
    width: 496,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    boxSizing: 'border-box',
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(5, 10.5, 8),
  },
  text: {
    fontSize: '1.5em',
    fontWeight: 500,
    lineHeight: '36px',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'space-between',
  },
  form: {
    minWidth: 325,
  },
  buttons: {
    width: '100%',
    height: 42,
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  submit: {
    display: 'inline',
    width: '100%',
    fontSize: '0.9em',
  },
}));

const helperPwdStyles = makeStyles((theme) => ({
  contained: {
    '&.MuiFormHelperText-contained': {
      marginLeft: 0,
    },
  },
}));

const renderTextField = (
  field: WrappedFieldProps &
    InputHTMLAttributes<HTMLInputElement> &
    IInputProps,
) => {
  const {
    input,
    type,
    autoFocus,
    label,
    error,
    disabled,
    style,
    helperText,
    FormHelperTextProps,
  } = field;
  return (
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      label={label}
      autoFocus={autoFocus}
      FormHelperTextProps={FormHelperTextProps}
      error={error}
      disabled={disabled}
      helperText={helperText}
      type={type}
      style={style}
      {...input}
    />
  );
};

const ResetPasswordForm = (props: InjectedFormProps) => {
  const classes = useStyles();
  const helperPwdClass = helperPwdStyles();
  const {handleSubmit} = props;
  const [pwd, setPwd] = useState('');
  const [confirmPwd, setconfirmPwd] = useState('');

  const onPasswordHandler = (event: any) => {
    setPwd(event.currentTarget.value);
  };

  const onconfirmPasswordHandler = (event: any) => {
    setconfirmPwd(event.currentTarget.value);
  };

  const checkPwd = () => {
    let error = false;
    if (pwd.length === 0 && pwd === '' && confirmPwd === '') {
      error = false;
    } else if (pwd.length < 4 && pwd !== confirmPwd) {
      error = true;
    } else if (pwd.length < 4 && pwd === confirmPwd) {
      error = true;
    } else if (pwd.length >= 4 && pwd !== confirmPwd) {
      error = true;
    } else if (pwd.length >= 4 && pwd === confirmPwd) {
      error = false;
    }

    return error;
  };

  const hasNotSameError = (confirmPwd: any) => {
    return pwd !== confirmPwd ? true : false;
  };

  return (
    <div className={classes.paper}>
      <div className={classes.frame}>
        <div className={classes.text}>
          <span>{'비밀번호 재설정'}</span>
        </div>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Field
            onChange={onPasswordHandler}
            label="비밀번호"
            name="password"
            component={renderTextField}
            error={hasNotSameError(confirmPwd) && checkPwd()}
            type="password"
            style={{marginTop: 15.74}}
          />
          <Field
            label="비밀번호 확인"
            onChange={onconfirmPasswordHandler}
            name="confirmPassword"
            component={renderTextField}
            error={hasNotSameError(confirmPwd) && checkPwd()}
            FormHelperTextProps={{classes: helperPwdClass}}
            helperText={
              pwd.length < 4
                ? '비밀번호는 최소 4글자 이상 입력해주세요.'
                : hasNotSameError(confirmPwd)
                ? '비밀번호가 일치하지 않습니다. 다시 확인해주세요.'
                : null
            }
            type="password"
            style={{marginTop: 15, display: 'flex'}}
          />
          <div className={classes.buttons}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}>
              비밀번호 변경 확인
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default reduxForm({
  // a unique name for the form
  form: 'resetPassword',
  onSubmit: submit,
})(ResetPasswordForm);
