import {call, select, takeLatest, put} from 'redux-saga/effects';
import {
  profileRequest,
  PROFILE,
  getProfile,
  updateRequest,
  completeUpdate,
  successResetPassword,
  resetPasswordRequest,
  loadPasswordResetRequet,
  successLoadPasswordResetUser,
  requestResetPwdFinish,
} from './slice';
import {
  findResetUser,
  resetPassword,
  updatePassword,
  updatePhoneNumber,
  whoAmI,
} from '~/api';

import {RootState} from '~/app/rootReducer';

function* handleWhoAmI() {
  try {
    const {token} = yield select((state: RootState) => state[PROFILE]);

    const resp = yield call(whoAmI, token);
    //console.log('saga', resp.data);
    yield put(getProfile(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleUpdateProfile() {
  try {
    const {token, password, phone, id} = yield select(
      (state: RootState) => state[PROFILE],
    );
    yield call(updatePhoneNumber, token, id, phone);
    if (password) {
      yield call(updatePassword, id, password);
    }
    yield put(completeUpdate());
  } catch (err) {
    console.log('error', err);
  }
}

function* handleResetPassword() {
  try {
    const {email} = yield select((state: RootState) => state[PROFILE]);
    const resp = yield call(resetPassword, email);

    yield put(successResetPassword(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleLoadResetUser() {
  try {
    const resp = yield call(findResetUser);
    yield put(successLoadPasswordResetUser(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleResetPwdFinish() {
  try {
    const state = yield select((state) => state[PROFILE]);
    const {resetId, newPassword} = state.request;
    yield call(updatePassword, resetId, newPassword);
    yield put(completeUpdate());
  } catch (err) {
    console.log('error', err);
  }
}

export function* watchProfile() {
  yield takeLatest(profileRequest, handleWhoAmI);
  yield takeLatest(updateRequest, handleUpdateProfile);
  yield takeLatest(resetPasswordRequest, handleResetPassword);
  yield takeLatest(loadPasswordResetRequet, handleLoadResetUser);
  yield takeLatest(requestResetPwdFinish, handleResetPwdFinish);
}
