import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';

import {AppBar, Toolbar, useMediaQuery} from '@material-ui/core';
import {useTheme} from '@material-ui/styles';
import {Breakpoint} from '~/contants/Breakpoints';
import {Typography} from './Typography';

const useStyles = makeStyles((theme: Theme) => ({
  appbar: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: theme.spacing(7.5),
    boxShadow: 'none',
    [theme.breakpoints.up(Breakpoint.mobile)]: {
      minHeight: theme.spacing(10),
    },
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1.25, 3.75),
    minHeight: 'auto',
    [theme.breakpoints.up(Breakpoint.mobile)]: {
      padding: theme.spacing(0, 7.75),
      flexDirection: 'row',
    },
  },
  loginInfoText: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
}));

export interface ISimpleHeader {
  mode: 'login' | 'signUp' | 'profile' | 'findPW';
}

export const SimpleHeader = (props: ISimpleHeader) => {
  const classes = useStyles(props);
  const theme = useTheme() as Theme;
  const {mode} = props;
  const mobile = useMediaQuery(theme.breakpoints.down(Breakpoint.mobile));

  const getText = () => {
    if (mode === 'login') {
      return '3S-BIS';
    } else if (mode === 'signUp') {
      return '회원가입';
    } else if (mode === 'profile') {
      return '내 정보';
    } else if (mode === 'findPW') {
      return '비밀번호 찾기';
    }
  };

  return (
    <AppBar className={classes.appbar} position="fixed">
      <Toolbar className={classes.toolbar}>
        <Typography
          variant={mode === 'login' && mobile ? 'H1' : mobile ? 'H4' : 'H2'}
          style={{marginTop: mobile ? 0 : -8}}>
          {mobile ? getText() : '3S-BIS'}
        </Typography>

        {mobile && mode === 'login' && (
          <Typography
            variant="Body"
            className={classes.loginInfoText}
            style={{fontWeight: 400, fontSize: '1.1em', textAlign: 'center'}}>
            3S-BIS는 회원 전용 서비스입니다.
            <br />
            로그인 후 사용해주세요.
          </Typography>
        )}
      </Toolbar>
    </AppBar>
  );
};
