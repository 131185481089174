import {takeLatest, call, put, select} from 'redux-saga/effects';
import {
  loadAccountType,
  successAccountType,
  saveAccountType,
  saveSubAccountType,
  loadStatAll,
  successAccountStatAll,
  ACCOUNT_TYPE,
} from './slice';
import * as api from '~/api';

function* handleLoad() {
  try {
    const resp = yield call(api.getAccountTypes);
    yield put(successAccountType(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleSave({type, payload}: ReturnType<typeof saveAccountType>) {
  try {
    yield call(api.saveAccountType, payload);
    yield put(loadAccountType());
  } catch (err) {
    console.log('error', err);
  }
}

function* handleSaveSub({
  type,
  payload,
}: ReturnType<typeof saveSubAccountType>) {
  try {
    const resp = yield call(api.saveSubAccountType, payload);
    yield put(loadAccountType());
    console.log(resp);
  } catch (err) {
    console.log('error', err);
  }
}

function* handleLoadStatAll() {
  try {
    const state = yield select((state) => state[ACCOUNT_TYPE]);
    const resp = yield call(api.getAccountStatAll, state.request);
    yield put(successAccountStatAll(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

export function* watchAccountType() {
  yield takeLatest(loadAccountType, handleLoad);
  yield takeLatest(saveAccountType, handleSave);
  yield takeLatest(saveSubAccountType, handleSaveSub);
  yield takeLatest(loadStatAll, handleLoadStatAll);
}
