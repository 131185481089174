import {call, select, takeLatest, put} from 'redux-saga/effects';
import {
  loginRequest,
  LOGIN,
  loginSuccess,
  checkLogin,
  loginFailed,
  failCheck,
} from './slice';
import {getJWTAPI} from '~/api';
import moment from 'moment-timezone';
import {RootState} from '~/app/rootReducer';
import {stopSubmit} from 'redux-form';
import {profileRequest} from '../Profile/slice';

function* handleLogin() {
  try {
    const {email, password} = yield select((state: RootState) => state[LOGIN]);
    const resp = yield call(getJWTAPI, email, password);

    yield put(loginSuccess(resp.data));
  } catch (err) {
    console.log('error', err);
    yield put(loginFailed());
    yield put(failCheck());
    yield put(
      stopSubmit('login', {
        email: ' ',
        password:
          '이메일 또는 비밀번호가 일치하지 않습니다. 다시 확인해주세요.',
      }),
    );
    yield put(
      stopSubmit('mlogin', {
        email: ' ',
        password:
          '이메일 또는 비밀번호가 일치하지 않습니다. 다시 확인해주세요.',
      }),
    );
  }
}

function* handleCheckLogin() {
  try {
    const {token, payload} = yield select((state: RootState) => state[LOGIN]);
    if (payload) {
      const expire = moment.unix(payload.exp);
      const now = moment();

      //console.log(expire, now);

      if (token && expire && moment(expire) > now) {
        //const resp = yield call(refreshTokenAPI, token);
        yield put(loginSuccess({token}));
        //yield put(loadEmployeeSchedules({token}));
        yield put(profileRequest());
      } else {
        yield put(loginFailed());
      }
    } else {
      yield put(loginFailed());
    }
  } catch (err) {
    console.log('error', err);
    yield put(loginFailed());
  }
}

export function* watchLogin() {
  yield takeLatest(loginRequest, handleLogin);
  yield takeLatest(checkLogin, handleCheckLogin);
}
