import React, {useState, useEffect, useCallback} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import withStatTemplate, {StatProps} from '~/hoc/withStatTemplate';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducer';
import {
  HISTORY,
  loadHistories,
  loadHistoryStat,
} from '~/features/History/slice';
import moment from 'moment';
import HistoryDetail from '~/models/hisotry';
import {DEPARTMENT, loadDepartment} from '~/features/Department/slice';
import {PROFILE} from '~/features/Profile/slice';
import {RenderTable} from '~/components/HistoryTable';

const useStyles = makeStyles((theme) => ({
  backColorBlue: {
    fontSize: '0.75em',
    backgroundColor: `${theme.palette.primary.light}14`,
  },
  backColorGray: {
    fontSize: '0.625em',
    backgroundColor: `#F6F6F6`,
  },
  table: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4.75),
    boxShadow: 'none',
    maxHeight: 800,
    '& > table > thead > tr > th': {
      padding: theme.spacing(1, 3.75),
      fontWeight: 400,
      backgroundColor: `#F6F6F6`,
      border: 'none',
    },
  },
  tableText: {
    fontSize: '0.625em',
  },
  divider: {
    height: theme.spacing(1.5),
    borderLeft: '1px solid #C2C2C2',
  },
}));

type HistoryTotalStat = {
  key: number;
  cnt: number[];
  time: number[];
  avg: number[];
};

const HistoryScreen = ({statScale, jobType, grade, date}: StatProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  //const theme = useTheme() as Theme;

  const [gradeName, setGradeName] = useState('1등급');
  const [jobName, setJobName] = useState('간호사');
  const [hisTotal, setHisTotal] = useState<any>([]);
  const [historyTotal, setHistoryTotal] = useState<HistoryTotalStat[]>([
    {key: 0, cnt: [], time: [], avg: []},
  ]);

  const historyStat = useSelector(
    (state: RootState) => state[HISTORY].historyStat,
  );

  const histories = useSelector((state: RootState) => state[HISTORY].histories);
  // 센터 id 가져오기
  const userId = useSelector((state: RootState) => state[PROFILE].id);
  const centerId = useSelector(
    (state: RootState) => state[DEPARTMENT].centerInfo?.centerId,
  );

  useEffect(() => {
    if (userId) {
      dispatch(loadDepartment(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (grade === 1) {
      setGradeName('1등급');
    } else if (grade === 2) {
      setGradeName('2등급');
    } else if (grade === 3) {
      setGradeName('3등급');
    } else if (grade === 4) {
      setGradeName('4등급');
    } else if (grade === 5) {
      setGradeName('5등급');
    } else if (grade === 6) {
      setGradeName('인지지원등급');
    } else {
      setGradeName('1등급');
    }
  }, [dispatch, grade]);

  useEffect(() => {
    if (jobType === 2) {
      setJobName('간호조무사');
    } else if (jobType === 3) {
      setJobName('요양보호사');
    } else if (jobType === 4) {
      setJobName('사회복지사');
    } else {
      setJobName('간호사');
    }
  }, [jobType]);

  useEffect(() => {
    if (centerId) {
      const datetime = moment(date);
      const request = {
        scale: statScale,
        datetime: datetime,
        grade: grade,
        jobType: jobType,
        centerId: centerId,
      };
      dispatch(loadHistoryStat(request));
      dispatch(loadHistories());
    }
  }, [dispatch, statScale, date, grade, jobType, centerId]);

  useEffect(() => {
    const newHistories = _.filter(histories, (his) => his.name !== '기타');
    setHistoryTotal(
      _.sortBy(newHistories, 'order').map((history) => {
        return {
          key: history.id,
          cnt: _.map(history.children, (_c) => 0),
          time: _.map(history.children, (_c) => 0),
          avg: _.map(history.children, (_c) => 0),
        };
      }),
    );
  }, [histories]);

  useEffect(() => {
    const newHistories = _.filter(
      histories,
      (history) => history.name !== '기타',
    );
    // 여기서부터 다시 작업
    // 해당 월에 대한 데이터만 가져오도록 수정
    _.map(_.sortBy(newHistories, 'order'), (history) => {
      _.keys(historyStat).map((h) => {
        _.keys(historyStat[h]).forEach((a) => {
          const hisInfo: any = historyStat[h][a];
          _.map(hisInfo, (his: HistoryDetail) => {
            // key(항목) 내부 항목에 대하여 각 시간 리턴
            if (his.historyTypeId === history.id) {
              _.map(history.children, (child, idx) => {
                // 해당 히스토리가 몇 번 발생했는지 카운트
                _.map(historyTotal, (h) => {
                  if (his.historyTypeId === h.key) {
                    h.cnt[idx] += _.get(his, child.time) > 0 ? 1 : 0;
                    h.time[idx] +=
                      _.get(his, child.time) > 0 ? _.get(his, child.time) : 0;
                    h.avg[idx] =
                      h.cnt[idx] !== 0
                        ? parseInt((h.time[idx] / h.cnt[idx]).toFixed(2))
                        : 0;
                  }
                  return null;
                });
                return null;
              });
            }
            return null;
          });
          return null;
        });
        return null;
      });

      return null;
    });

    // 표 데이터 만드는 부분
    // 각 처치마다 하위 항목 맵핑시킴
    let ret: any = [];
    _.zip(_.map(_.sortBy(newHistories, 'order')), _.map(historyTotal)).map(
      (item, _idx) => {
        let tmpArray: any = [];
        let res: any = {
          id: item[0] ? item[0].name : '',
        };

        if (item[0] && item[1]) {
          // 오름차순 정렬 재정리를 위하여 order값 추가
          const newChildren = _.map(item[0]?.children, (child) => {
            return {
              ...child,
              order: parseInt(child.time.slice(7, 9)) + 100,
            };
          });
          _.zip(
            _.sortBy(newChildren, 'order'),
            item[1].avg,
            item[1].cnt,
            item[1].time,
          ).map((k) => {
            res = {
              ...res,
              name: k[0] ? k[0].name : '',
              total: k[3] ? k[3] : 0,
              count: k[2] ? k[2] : 0,
              avg: k[1] ? k[1] : 0,
            };

            tmpArray.push(res);
            return null;
          });
        }
        ret.push(tmpArray);
        return null;
      },
    );

    setHisTotal(ret);
  }, [jobType, historyTotal, grade, statScale, historyStat, histories]);

  const renderTable = useCallback(() => {
    try {
      return _.map(hisTotal, (his, idx: number) => {
        return (
          <RenderTable
            key={idx}
            gradeName={gradeName}
            jobName={jobName}
            historyId={his[0].id}
            first={idx}
            data={hisTotal[idx]}
          />
        );
      });
    } catch (error) {
      return <></>;
    }
  }, [hisTotal, gradeName, jobName]);

  return (
    <>
      <TableContainer className={classes.table}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.backColorGray}>
            <TableRow>
              <TableCell align="center">구분</TableCell>
              <TableCell align="center">직업(직군)</TableCell>
              <TableCell align="center">등급</TableCell>
              <TableCell align="center">총시간(분)</TableCell>
              <TableCell align="center">횟수(회)</TableCell>
              <TableCell align="center">평균시간(분)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTable()}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withStatTemplate(HistoryScreen, {
  title: '처치히스토리 (수급자용)',
  job: true,
  grade: true,
});
