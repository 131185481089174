import React, {InputHTMLAttributes, useState, useEffect} from 'react';
import {
  Field,
  InjectedFormProps,
  reduxForm,
  WrappedFieldProps,
} from 'redux-form';
import {makeStyles, TextField, Button} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import RemoteSubmitButton from '~/components/RemoteSubmitButton';
import {useDispatch, useSelector} from 'react-redux';
import {PROFILE, profileRequest} from '~/features/Profile/slice';
import {RootState} from '~/app/rootReducer';
import {LOGIN} from '~/features/Login/slice';
import submit from '~/components/Submit';
import {IInputProps} from './SignUpForm';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  frame: {
    width: 496,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    boxSizing: 'border-box',
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    fontSize: '1.5em',
    fontWeight: 500,
    lineHeight: '36px',
    width: 91,
    display: 'flex',
    textAlign: 'center',
    marginTop: 76,
    justifyContent: 'space-between',
  },
  form: {
    width: 325,
    marginTop: theme.spacing(4) + 5,
  },
  buttons: {
    width: '100%',
    height: 42,
    display: 'flex',
    marginTop: 40,
    marginBottom: 28,
  },
  cancel: {
    display: 'inline',
    width: '100%',
    fontSize: '0.938em',
  },
  submit: {
    display: 'inline',
    width: '100%',
    marginLeft: 14,
    fontSize: '0.938em',
  },
  modalBack: {
    width: 276,
    height: 140,
    background: '#F6F6F6',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modelBtn: {
    display: 'inline',
    width: 100,
    height: 42,
    fontSize: '0.938em',
  },
}));

const helperEmailStyles = makeStyles((theme) => ({
  contained: {
    '&.MuiFormHelperText-contained': {
      marginTop: 5,
    },
  },
}));

const helperPwdStyles = makeStyles((theme) => ({
  contained: {
    '&.MuiFormHelperText-contained': {
      marginLeft: 0,
    },
  },
}));

const renderTextField = (
  field: WrappedFieldProps &
    InputHTMLAttributes<HTMLInputElement> &
    IInputProps,
) => {
  const {
    input,
    type,
    autoFocus,
    label,
    error,
    //meta: {touched, invalid, error},
    disabled,
    style,
    helperText,
    FormHelperTextProps,
  } = field;
  return (
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      label={label}
      autoFocus={autoFocus}
      FormHelperTextProps={FormHelperTextProps}
      //label={input.name}
      error={error}
      disabled={disabled}
      //helperText={touched && error}
      helperText={helperText}
      type={type}
      style={style}
      {...input}
    />
  );
};

const ProfileForm = (props: InjectedFormProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const helperEmailClass = helperEmailStyles();
  const helperPwdClass = helperPwdStyles();
  const {handleSubmit} = props;
  // const [pwdLen, setPwdLen] = useState(true);
  const [pwd, setPwd] = useState('');
  const [pwdFocus, setPwdFocus] = useState(false);
  const [pwdError, setPwdError] = useState(false);
  const [confirmPwd, setconfirmPwd] = useState('');
  const [confirmPwdFocus, setConfirmFocus] = useState(false);
  const [confirmError, setConfirmError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const token = useSelector((state: RootState) => state[LOGIN].token);
  const phone = useSelector((state: RootState) => state[PROFILE].phone);
  const [newPhone, setNewPhone] = useState('');

  useEffect(() => {
    if (token) {
      dispatch(profileRequest());
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (phone) {
      setNewPhone(phone);
    }
  }, [phone]);

  useEffect(() => {
    if (!pwdFocus && pwd !== '') {
      if (pwd.length < 4) {
        setPwdError(true);
      } else {
        setPwdError(false);
      }
    }
  }, [pwd, pwdFocus]);

  useEffect(() => {
    if (!confirmPwdFocus && confirmPwd !== '') {
      if (pwd !== confirmPwd) {
        setConfirmError(true);
      } else {
        setConfirmError(false);
      }
    }
  }, [confirmPwd, confirmPwdFocus, pwd]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div className={classes.modalBack}>
        <div
          style={{
            fontSize: '0.875em',
            marginTop: 26,
            marginBottom: 30,
          }}>
          {'정말 변경하시겠습니까?'}
        </div>
        <div style={{display: 'flex'}}>
          <Button
            onClick={handleClose}
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.modelBtn}>
            취소
          </Button>
          <RemoteSubmitButton mode="profile" />
        </div>
      </div>
    </div>
  );

  const onPasswordHandler = (event: any) => {
    setPwd(event.currentTarget.value);
  };

  const onconfirmPasswordHandler = (event: any) => {
    setconfirmPwd(event.currentTarget.value);
  };

  const handleNewPhone = (event: any) => {
    setNewPhone(event.currentTarget.value);
  };

  const checkChangeProfile = () => {
    if ((!pwdError && !confirmError && pwd.length >= 4) || phone !== newPhone) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div //className={classes.paper}
      style={{
        display: 'flex',
        zIndex: 10,
        position: 'fixed',
        inset: 0,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div className={classes.frame}>
        <div className={classes.text}>
          <span>{'내 정보'}</span>
        </div>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Field
            name="email"
            disabled
            label="이메일"
            helperText="사용가능한 이메일을 적어주세요."
            FormHelperTextProps={{classes: helperEmailClass}}
            component={renderTextField}
            type="text"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          />
          <Field
            onChange={onPasswordHandler}
            label="비밀번호"
            name="password"
            component={renderTextField}
            error={pwdError}
            onFocus={() => setPwdFocus(true)}
            onBlur={() => setPwdFocus(false)}
            type="password"
            style={{marginTop: 15.74}}
            helperText={
              pwd.length < 4 ? '비밀번호는 최소 4글자 이상 입력해주세요.' : ''
            }
          />
          <Field
            label="비밀번호 확인"
            onChange={onconfirmPasswordHandler}
            name="confirmPassword"
            component={renderTextField}
            error={confirmError}
            onFocus={() => setConfirmFocus(true)}
            onBlur={() => setConfirmFocus(false)}
            FormHelperTextProps={{classes: helperPwdClass}}
            helperText={
              confirmError
                ? '비밀번호가 일치하지 않습니다. 다시 확인해주세요.'
                : ''
            }
            type="password"
            style={{marginTop: 15, display: 'flex'}}
          />
          <Field
            name="caregiver"
            disabled
            label="요양보호사번호"
            component={renderTextField}
            type="text"
            style={{marginTop: 38, marginBottom: 0}}
          />
          <Field
            name="phone"
            label="휴대전화번호"
            onChange={handleNewPhone}
            component={renderTextField}
            type="text"
            style={{marginTop: 15}}
          />
          <div className={classes.buttons}>
            <Button
              onClick={() => {
                history.goBack();
              }}
              variant="contained"
              color="secondary"
              className={classes.cancel}>
              취소
            </Button>
            <Button
              disabled={checkChangeProfile()}
              onClick={handleOpen}
              variant="contained"
              color="primary"
              className={classes.submit}>
              저장
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description">
              {body}
            </Modal>
          </div>
        </form>
      </div>
    </div>
  );
};

export default reduxForm({
  // a unique name for the form
  form: 'profile',
  onSubmit: submit,
  enableReinitialize: true,
})(ProfileForm);
