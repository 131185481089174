import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  AppBar,
  Toolbar,
} from '@material-ui/core';

import ResetPasswordForm from '../forms/ReestPasswordForm';
import {
  loadPasswordResetRequet,
  PROFILE,
  requestResetPwdFinish,
} from '~/features/Profile/slice';
import {RootState} from '~/app/rootReducer';
import {genSalt, hash} from 'bcryptjs';
import {useHistory} from 'react-router-dom';
import {WebFooter} from '~/components/WebFooter';
import {Typography} from '~/components/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    header: {
      flexGrow: 1,
      fontSize: 45,
      fontWeight: 700,
      lineHeight: '65px',
      marginLeft: 38,
      marginTop: 10,
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      padding: theme.spacing(10, 0, 16.25),
      justifyContent: 'center',
      alignItems: 'center',
    },
    form: {
      width: 496,
      padding: theme.spacing(9, 10.5),
      border: '1px solid rgba(0, 0, 0, 0.23)',
      boxSizing: 'border-box',
      borderRadius: 15,
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(2) - 1,
    },
    comment: {
      display: 'flex',
      textAlign: 'center',
      marginBottom: theme.spacing(8),
      justifyContent: 'center',
    },
    buttons: {
      width: '100%',
      height: 42,
      display: 'flex',
      marginTop: 39,
      marginBottom: 32,
    },
    submit: {
      display: 'inline',
      width: '100%',
      height: 42,
      fontSize: '0.9em',
    },
  }),
);

const PasswordResetFinishScreen = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const resetId = useSelector((state: RootState) => state[PROFILE].resetId);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    dispatch(loadPasswordResetRequet());
  }, [dispatch]);

  const submit = async (values: any) => {
    const {password} = values;
    const newPassword = await hash(password, await genSalt());
    dispatch(requestResetPwdFinish({resetId, newPassword}));
    setComplete(true);
  };

  return (
    <div className={classes.root}>
      <AppBar style={{height: 80}} position="static">
        <Toolbar>
          <Typography
            variant={'H1'}
            style={{fontSize: '3.21em'}}
            className={classes.header}>
            {'3S-BIS'}
          </Typography>
        </Toolbar>
      </AppBar>
      {complete ? (
        <div className={classes.body}>
          <div className={classes.form}>
            <div className={classes.title}>
              <Typography
                variant={'H4'}
                style={{
                  fontWeight: 500,
                  lineHeight: '36px',
                  fontSize: '1.7em',
                }}>
                비밀번호 변경 완료
              </Typography>
            </div>
            <div>
              <span className={classes.comment}>
                새로운 비밀번호로 로그인이 가능합니다.
              </span>
            </div>
            <div className={classes.buttons}>
              <Button
                onClick={() => history.replace('/')}
                variant="contained"
                color="primary"
                className={classes.submit}>
                로그인 화면으로 이동
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <ResetPasswordForm onSubmit={submit} />
      )}
      <WebFooter />
    </div>
  );
};

export default PasswordResetFinishScreen;
