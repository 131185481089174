import React from 'react';
import {STAT_SCALE} from '~/types/types';
import {FormControlLabel, RadioGroup, Radio} from '@material-ui/core';

interface Props {
  value: STAT_SCALE;
  onChange: (scale: STAT_SCALE) => void;
}

const PeriodSelector = ({value, onChange}: Props) => {
  return (
    <div>
      <RadioGroup
        row
        aria-label="position"
        name="position"
        onChange={(e) => {
          onChange(parseInt(e.target.value) as STAT_SCALE);
        }}
        defaultValue={1}
        value={value}>
        <FormControlLabel
          value={STAT_SCALE.MONTH}
          control={<Radio color="primary" />}
          label="연간"
          labelPlacement="end"
        />
        <FormControlLabel
          value={STAT_SCALE.WEEK}
          control={<Radio color="primary" />}
          label="월간"
          labelPlacement="end"
        />
      </RadioGroup>
    </div>
  );
};

export default PeriodSelector;
