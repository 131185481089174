import React, {useState, useEffect, useCallback} from 'react';
import {
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Theme,
  useTheme,
  withStyles,
} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment-timezone';
import {
  loadSchStatEmployee,
  SCHEDULES_STAT,
} from '~/features/ScheduleStat/slice';
import {DUTIES_STAT, loadDutyStatEmployee} from '~/features/DutyStat/slice';
import {STAT_MODE} from '~/types/types';
import withStatTemplate, {StatProps} from '~/hoc/withStatTemplate';
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Line,
  Tooltip,
  ComposedChart,
} from 'recharts';
import {RootState} from '~/app/rootReducer';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {Typography} from '~/components/Typography';
import _ from 'lodash';
import IconTooltip, {TooltipProps} from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import {PROFILE} from '~/features/Profile/slice';
import {DEPARTMENT, loadDepartment} from '~/features/Department/slice';

// const PER_H_N: {[key: string]: {[key: string]: string}} = {
//   '2021-01': {김경아: '8.41', 김선희: '64.18', 정선미: '25.07'},

//   '2021-02': {김경아: '6.16', 김선희: '58.13', 정선미: '24.47'},

//   '2021-03': {김경아: '8.45', 김선희: '1.30', 장수민: '2.30', 정선미: '21.00'},

//   '2021-04': {
//     김경아: '7.02',
//     김선희: '47.28',
//     장수민: '32.32',
//     정선미: '23.07',
//   },

//   '2021-05': {
//     김경아: '6.23',
//     김선희: '71.20',
//     장수민: '25.18',
//     정선미: '18.10',
//   },

//   '2021-06': {
//     김경아: '4.19',
//     김선희: '50.02',
//     장수민: '20.49',
//     정선미: '14.10',
//   },

//   '2021-07': {
//     김경아: '4.22',
//     김선희: '56.36',
//     장수민: '17.35',
//     정선미: '12.42',
//   },

//   '2021-08': {
//     김경아: '6.45',
//     김선희: '64.34',
//     장수민: '22.18',
//     정선미: '10.12',
//   },

//   '2021-09': {김경아: '4.27', 김선희: '76.16', 정선미: '10.54'},

//   '2021-10': {김경아: '4.09', 김선희: '76.20', 정선미: '6.34'},

//   '2021-11': {김경아: '4.03', 김선희: '95.20', 신혜경: '10.55', 정선미: '7.29'},
// };

// const PER_N: {[key: string]: {[key: string]: number}} = {
//   '2021-01': {김경아: 755060, 김선희: 3573660, 정선미: 1618080},

//   '2021-02': {김경아: 488100, 김선희: 3319220, 정선미: 1638410},

//   '2021-03': {김경아: 732150, 김선희: 3949180, 장수민: 146430, 정선미: 1329650},

//   '2021-04': {
//     김경아: 536910,
//     김선희: 2677080,
//     장수민: 1998950,
//     정선미: 1406360,
//   },

//   '2021-05': {
//     김경아: 439290,
//     김선희: 4064170,
//     장수민: 1645710,
//     정선미: 1210750,
//   },

//   '2021-06': {김경아: 341670, 김선희: 2967020, 장수민: 1559660, 정선미: 948360},

//   '2021-07': {김경아: 341670, 김선희: 3219030, 장수민: 1394610, 정선미: 839470},

//   '2021-08': {김경아: 488100, 김선희: 3416690, 장수민: 1573260, 정선미: 704340},

//   '2021-09': {김경아: 341670, 김선희: 4423840, 정선미: 729270},

//   '2021-10': {김경아: 341670, 김선희: 4072970, 정선미: 450980},

//   '2021-11': {김경아: 341670, 김선희: 4853270, 신혜경: 445815, 정선미: 509070},
// };

// const PER_Y: {[key: string]: {[key: string]: number}} = {
//   '2021-01': {김순복: 1186450, 오성희: 452800, 이순연: 963400, 이호단: 121480},

//   '2021-02': {김순복: 1130920, 오성희: 452800, 이순연: 232510},

//   '2021-03': {김순복: 1181220, 신경이: 289020, 오성희: 452800},

//   '2021-04': {김순복: 1189190, 신경이: 423700, 오성희: 452800},

//   '2021-05': {김경순: 192680, 김순복: 1180170, 오성희: 452800},

//   '2021-06': {김경순: 433530, 김순복: 1189190, 오성희: 452800},

//   '2021-07': {김순복: 1183560, 오성희: 452800, 이규숙: 722550, 허순남: 922180},

//   '2021-08': {김순복: 1186450, 오성희: 452800, 이규숙: 674380, 허순남: 982890},

//   '2021-09': {김순복: 1111460, 오성희: 452800, 이규숙: 963400, 허순남: 936380},

//   '2021-10': {
//     김순복: 1180170,
//     김인자: 513580,
//     염금실: 1043810,
//     오성희: 452800,
//     이규숙: 963400,
//     허순남: 982890,
//   },

//   '2021-11': {김순복: 1186450, 김인자: 530660, 오성희: 452800, 이규숙: 1059740},
// };

const TableCell = withStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.info.dark}`,
    '&.MuiTableCell-head': {
      fontSize: '0.75em',
    },
    height: 28,
    paddingTop: 0,
    paddingBottom: 0,
  },
}))(MuiTableCell);

const TableRow = withStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.info.dark}`,
  },
}))(MuiTableRow);

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    width: '100%',
    flexGrow: 1,
    height: 436,
    margin: theme.spacing(2.3125, 0, 0),
  },
  chartLegend: {
    '& > div > svg > path': {
      fill: 'none',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper': {
      top: '0px !important',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend > .recharts-legend-item': {
      display: 'flex !important',
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '0.75em',
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend > li > svg': {
      width: 50,
      height: 11,
    },
    '& > .recharts-wrapper > .recharts-legend-wrapper > .recharts-default-legend > li > svg > path': {
      d: 'path("M-50,0h146v32h-146z")',
    },
  },
  tooltip: {
    padding: theme.spacing(2.5, 1.875, 1.125),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    //alignItems: 'center',
    backgroundColor: '#FFFFFF',
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: theme.spacing(0.5),
  },
  tooltip__label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 23,
    marginBottom: theme.spacing(1.125),
    fontSize: '1em',
  },
  helpMark: {
    display: 'flex',
    marginTop: theme.spacing(7.75),
    alignItems: 'center',
    fontSize: '1em',
  },
  backColorBlue: {
    fontSize: '0.625em',
    backgroundColor: '#E8EAF6',
  },
  backColorGray: {
    fontSize: '0.625em',
    backgroundColor: `${theme.palette.info.light}`,
  },
  table: {
    marginTop: theme.spacing(3.5),
    boxShadow: 'none',
    '& > table > thead > tr > th, & > table > tbody > tr > td': {
      padding: theme.spacing(0.74375, 4.66625),
      fontWeight: 400,
    },
  },
  tableText: {
    fontSize: '0.625em',
  },
  nameTable: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    display: 'flex',
    minWidth: theme.spacing(7),
    marginTop: theme.spacing(4.25),
    textAlign: 'end',
    flexDirection: 'column',
    marginRight: theme.spacing(1.625),
  },
  unit: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    fontSize: '0.625em',
    marginBottom: theme.spacing(1) - 1,
  },
}));

const useStylesBootstrap = makeStyles((theme: Theme) => ({
  arrow: {
    color: '#666666',
  },
  tooltip: {
    color: '#666666',
    border: '1px solid #666666',
    background: '#FBFBFB',
    boxSizing: 'border-box',
    borderRadius: theme.spacing(0.625),
    fontSize: '0.75em',
  },
}));

interface PerformanceInfo {
  name: string[];
  sch_price: number[];
  duty_price: number[];
  sch_hours: number[];
  duty_hours: number[];
  sch_plans: number[];
  duty_plans: number[];
}

const PerformanceScreen = ({
  statScale,
  service,
  graphType,
  date,
}: StatProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme() as Theme;
  const [statMode] = useState<STAT_MODE>(STAT_MODE.EMPLOYEE);
  const [serviceName, setServiceName] = useState('방문간호');
  const [data, setData] = useState<
    ReadonlyArray<{[key: string]: string | number}>
  >();
  const [total, setTotal] = useState<
    ReadonlyArray<{[key: string]: string | number}>
  >();

  const employee_sch = useSelector(
    (state: RootState) => state[SCHEDULES_STAT].employee,
  );
  const employee_duty = useSelector(
    (state: RootState) => state[DUTIES_STAT].employee,
  );

  const tableHeadName = [
    '매출계획 (원)',
    '매출실적 (원)',
    '근무계획 (시간)',
    '근무실적 (시간)',
    '시간당 수가 계획 (원)',
    '시간당 수가 실적 (원)',
    '달성률 (%)',
  ];

  // 센터 정보 가져오기
  const userId = useSelector((state: RootState) => state[PROFILE].id);
  const centerId = useSelector(
    (state: RootState) => state[DEPARTMENT].centerInfo?.centerId,
  );

  const DataFormater = (number: number) => {
    return number.toLocaleString();
  };

  useEffect(() => {
    if (userId) {
      dispatch(loadDepartment(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (centerId) {
      const request = {
        scale: statScale,
        mode: statMode,
        serviceType: service,
        datetime: moment(date),
        centerId: centerId,
      };
      dispatch(loadSchStatEmployee(request));
      dispatch(loadDutyStatEmployee(request));
    }
  }, [date, statScale, dispatch, service, statMode, centerId]);

  useEffect(() => {
    if (service === 2) {
      setServiceName('방문요양');
    } else if (service === 3) {
      // 발생하지 않음 (2022.01.03)
      setServiceName('방문목욕');
    } else {
      setServiceName('방문간호');
    }
  }, [service]);

  useEffect(() => {
    const result: PerformanceInfo = {
      name: _.map(employee_sch.range, (e) => e),
      sch_price: _.map(employee_sch.total, (t) => t.price),
      duty_price: _.map(employee_duty.total, (t) => t.price),
      sch_hours: _.map(employee_sch.total, (t) =>
        parseFloat(
          `${Math.floor(t.hours / 60)}.${(t.hours % 60)
            .toString()
            .padStart(2, '0')}`,
        ),
      ),
      duty_hours: _.map(employee_duty.total, (t) =>
        parseFloat(
          `${Math.floor(t.hours / 60)}.${(t.hours % 60)
            .toString()
            .padStart(2, '0')}`,
        ),
      ),
      sch_plans: _.map(employee_sch.total, (t) =>
        t.hours === 0
          ? 0
          : _.round(
              t.price /
                parseFloat(`${Math.floor(t.hours / 60)}.${t.hours % 60}`),
            ),
      ),
      duty_plans: _.map(employee_duty.total, (t) =>
        t.hours === 0
          ? 0
          : _.round(
              t.price /
                parseFloat(`${Math.floor(t.hours / 60)}.${t.hours % 60}`),
            ),
      ),
    };

    let tmp: any = [];
    let tmp2: any = {};
    for (let i = 0; i < result.name.length; i++) {
      tmp.push({
        name: result.name[i],
        매출계획: result.sch_price[i],
        매출실적: result.duty_price[i] ? result.duty_price[i] : 0,
        근무계획: result.sch_hours[i].toFixed(2),
        근무실적: result.duty_hours[i]
          ? result.duty_hours[i].toFixed(2)
          : parseInt('0').toFixed(2),
        손익값: result.duty_price[i]
          ? result.duty_price[i] - result.sch_price[i]
          : 0,
        시간당수가계획: result.sch_plans[i],
        시간당수가실적: result.duty_plans[i] ? result.duty_plans[i] : 0,
        달성률:
          result.sch_plans[i] === 0
            ? 0
            : ((result.duty_plans[i] / result.sch_plans[i]) * 100).toFixed(2),
      });
    }
    tmp2 = {
      s_p: _.sum(_.values(result.sch_price)),
      d_p: _.sum(_.values(result.duty_price)),
      s_h: _.sum(_.values(result.sch_hours)).toFixed(2),
      d_h: _.sum(_.values(result.duty_hours)).toFixed(2),
      s_pl: _.sum(_.values(result.sch_plans)),
      d_pl: _.sum(_.values(result.duty_plans)),
      avg: (
        (_.sum(_.values(result.duty_plans)) /
          _.sum(_.values(result.sch_plans))) *
        100
      ).toFixed(2),
    };

    setData(tmp);
    setTotal(tmp2);
  }, [employee_sch, employee_duty, graphType, service]);

  const CustomTooltip = ({payload, label}: any) => {
    if (payload && payload.length) {
      return (
        <div className={classes.tooltip}>
          {payload.map((item: any, idx: number) => {
            //console.log(item.stroke, item.fill);
            return (
              <div className={classes.tooltip__label} key={idx}>
                <svg
                  width="24"
                  height="17"
                  viewBox="0, 0, 32 32"
                  style={{
                    display: 'inline-block',
                    marginRight: theme.spacing(5) - 2,
                    verticalAlign: 'middle',
                    marginLeft: theme.spacing(0.5),
                  }}>
                  <path
                    //stroke={'none'}
                    fill={item.name === '손익값' ? '#fad44f' : item.fill}
                    d="M0, 4h 64v 24h-64z"></path>
                </svg>
                <p
                  style={{
                    width: 'fit-content',
                    display: 'flex',
                    justifyContent: 'left',
                    marginRight: theme.spacing(1) + 2,
                  }}>{`${item.name}`}</p>
                <p
                  style={{
                    width: 115,
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {item.name === '근무계획' || item.name === '근무실적'
                    ? `${item.value}시간`
                    : `${item.value.toLocaleString()}원`}
                </p>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  const BootstrapTooltip = (props: TooltipProps) => {
    const classes = useStylesBootstrap();

    return <IconTooltip arrow classes={classes} {...props} />;
  };

  const renderNameTable = useCallback(() => {
    return (
      <div className={classes.nameTable}>
        <div className={classes.label}>
          <Typography variant="Small">이름</Typography>
          <Typography style={{marginTop: theme.spacing(1.75)}} variant="Small">
            손익값
          </Typography>
        </div>
        <TableContainer className={classes.table}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {_.map(data).map((d, idx) => {
                  return (
                    <TableCell
                      key={idx}
                      align="center"
                      className={classes.backColorBlue}>
                      {d.name}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {_.map(data).map((d, idx) => {
                  return (
                    <TableCell key={idx} align="center">
                      {d.손익값.toLocaleString()}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }, [
    data,
    classes.backColorBlue,
    classes.label,
    classes.nameTable,
    classes.table,
    theme,
  ]);

  return (
    <>
      {graphType === 0 || graphType === 1 ? (
        <>
          <div className={classes.helpMark}>
            <span>수가</span>
            <BootstrapTooltip
              title="의료서비스를 제공하고 환자와 공단으로부터 받는 돈을 의미합니다."
              placement="right-start">
              <IconButton>
                <HelpOutlineIcon fontSize="small" />
              </IconButton>
            </BootstrapTooltip>
          </div>
          <div className={classes.chartContainer}>
            <ResponsiveContainer className={classes.chartLegend}>
              <ComposedChart
                data={data}
                margin={{top: 20, right: 50, left: 50, bottom: 5}}>
                <CartesianGrid strokeDasharray="5 5" />
                <XAxis dataKey="name" />
                <YAxis tickFormatter={DataFormater} />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  domain={[0, 'dataMax']}
                  allowDataOverflow={true}
                  tickFormatter={DataFormater}
                />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="매출계획" fill="#FF6384" stackId="a" />
                <Bar dataKey="매출실적" fill="#36A2EB" stackId="b" />
                <Line
                  yAxisId="right"
                  dataKey="손익값"
                  stroke="#fad44f"
                  strokeWidth={2}
                  activeDot={{r: 6}}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
          <div className={classes.unit}>단위:원</div>
          {renderNameTable()}
        </>
      ) : (
        <></>
      )}
      {graphType === 0 || graphType === 2 ? (
        <>
          <div className={classes.helpMark}>
            <span>근무시간</span>
            <BootstrapTooltip
              title="직원별 계획 근무시간과 달성률 확인"
              placement="right-start">
              <IconButton>
                <HelpOutlineIcon fontSize="small" />
              </IconButton>
            </BootstrapTooltip>
          </div>
          <div className={classes.chartContainer}>
            <ResponsiveContainer className={classes.chartLegend}>
              <ComposedChart
                data={data}
                margin={{top: 20, right: 50, left: 50, bottom: 5}}>
                <CartesianGrid strokeDasharray="5 5" />
                <XAxis dataKey="name" />
                <YAxis tickFormatter={DataFormater} />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="근무계획" fill="#FF6384" stackId="a" />
                <Bar dataKey="근무실적" fill="#36A2EB" stackId="b" />
              </ComposedChart>
            </ResponsiveContainer>
            <div className={classes.unit}>단위:시간</div>
          </div>
        </>
      ) : (
        <></>
      )}
      {graphType === 0 || graphType === 3 ? (
        <>
          <div className={classes.helpMark}>
            <span>시간당 수가</span>
            <BootstrapTooltip
              title="직원별로 시간대비 벌 수 있는 수가 금액을 산정합니다. "
              placement="right-start">
              <IconButton>
                <HelpOutlineIcon fontSize="small" />
              </IconButton>
            </BootstrapTooltip>
          </div>
          <div className={classes.chartContainer}>
            <ResponsiveContainer className={classes.chartLegend}>
              <ComposedChart
                data={data}
                margin={{top: 20, right: 50, left: 50, bottom: 5}}>
                <CartesianGrid strokeDasharray="5 5" />
                <XAxis dataKey="name" />
                <YAxis tickFormatter={DataFormater} />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="시간당수가계획" fill="#FF6384" stackId="a" />
                <Bar dataKey="시간당수가실적" fill="#36A2EB" stackId="b" />
              </ComposedChart>
            </ResponsiveContainer>
            <div className={classes.unit}>단위:원</div>
          </div>
        </>
      ) : (
        <></>
      )}

      <TableContainer className={classes.table}>
        <Table aria-label="simple table">
          <TableHead className={classes.backColorGray}>
            <TableRow>
              <TableCell
                className={classes.backColorBlue}
                align="center"
                colSpan={2}>
                구분
              </TableCell>
              {tableHeadName.map((head, idx) => (
                <TableCell
                  key={idx}
                  className={classes.backColorBlue}
                  align="center">
                  {`${head}`}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                rowSpan={employee_sch.range.length + 1}
                align="center"
                className={classes.backColorBlue}>
                {`${serviceName}`}
              </TableCell>
            </TableRow>
            {_.map(data, (d, idx) => {
              // let matched = true;
              // let hMatched = true;
              // let yo = 0;
              // let hour = '';
              // let day = moment(date).format('yyyy-MM');
              // if (statScale === STAT_SCALE.MONTH) {
              //   day = moment(date).format('yyyy');
              // }

              //console.log(d, idx, statScale, day);
              // if (service === SERVICE_TYPE.CARE) {
              //   yo = PER_Y[day]?.[d.name];
              //   matched = yo === d.매출실적;
              // }
              // if (service === SERVICE_TYPE.NURSING) {
              //   yo = PER_N[day]?.[d.name];
              //   matched = yo === d.매출실적;
              //   hour = PER_H_N[day]?.[d.name];
              //   hMatched = hour === d.근무실적;
              // }
              return (
                <TableRow key={idx}>
                  <TableCell className={classes.backColorGray} align="center">
                    {d.name}
                  </TableCell>
                  <TableCell className={classes.backColorGray} align="center">
                    {`${d.매출계획.toLocaleString()}`}
                  </TableCell>
                  <TableCell
                    // style={{backgroundColor: matched ? 'inherit' : 'red'}}
                    className={classes.backColorGray}
                    align="center">
                    {`${d.매출실적.toLocaleString()}`}
                    {/* {!matched && `(${yo?.toLocaleString()})`} */}
                  </TableCell>
                  <TableCell className={classes.backColorGray} align="center">
                    {`${d.근무계획}`}
                  </TableCell>
                  <TableCell
                    // style={{backgroundColor: hMatched ? 'inherit' : 'red'}}
                    className={classes.backColorGray}
                    align="center">
                    {`${d.근무실적}`}
                    {/* {!hMatched && `(${hour})`} */}
                  </TableCell>
                  <TableCell className={classes.backColorGray} align="center">
                    {`${d.시간당수가계획.toLocaleString()}`}
                  </TableCell>
                  <TableCell className={classes.backColorGray} align="center">
                    {`${d.시간당수가실적.toLocaleString()}`}
                  </TableCell>
                  <TableCell className={classes.backColorGray} align="center">
                    {`${d.달성률} %`}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell
                colSpan={2}
                className={classes.backColorBlue}
                align="center">
                소계
              </TableCell>
              {_.values(total).map((k, idx) => (
                <TableCell
                  key={idx}
                  style={{fontSize: '0.625em'}}
                  align="center">
                  {k.toLocaleString()}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withStatTemplate(PerformanceScreen, {
  service: true,
  graph: true,
  wage: false,
  title: '종합업무 퍼포먼스',
});
