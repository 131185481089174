import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {NewAccount, Account, StatSummary, AccountRemove} from '~/types/types';

type State = {
  loading: boolean;
  accounts: Account[];
  request: StatSummary | AccountRemove | undefined;
  refresh: boolean;
};

let initialState: State = {
  loading: false,
  request: undefined,
  accounts: [],
  refresh: false,
};

const slice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    loadAccounts: (state) => {
      state.loading = true;
    },
    saveAccount: (state, action: PayloadAction<NewAccount>) => {
      state.loading = true;
    },
    successAccounts: (state, action: PayloadAction<Account[]>) => {
      state.loading = false;
      state.accounts = action.payload;
    },
    removeAccount: (state, action: PayloadAction<AccountRemove>) => {
      state.loading = true;
      state.refresh = false;
      state.request = action.payload;
    },
    successRemove: (state) => {
      state.loading = false;
      state.refresh = true;
      state.request = undefined;
    },
  },
});

export const {
  loadAccounts,
  saveAccount,
  successAccounts,
  removeAccount,
  successRemove,
} = slice.actions;
export const ACCOUNTS = slice.name;
export default slice.reducer;
