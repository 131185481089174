import React from 'react';
import {ITEM_TYPE} from '~/types/types';
import {RadioGroup, FormControlLabel, Radio} from '@material-ui/core';

interface Props {
  value: ITEM_TYPE;
  onChange: (mode: ITEM_TYPE) => void;
}

const ItemSelector = ({value, onChange}: Props) => {
  return (
    <div>
      <RadioGroup
        row
        aria-label="position"
        name="position"
        onChange={(e) => onChange(parseInt(e.target.value) as ITEM_TYPE)}
        defaultValue={0}
        value={value}>
        <FormControlLabel
          value={ITEM_TYPE.STOCK}
          control={<Radio color="primary" />}
          label="비품"
          labelPlacement="end"
        />
        <FormControlLabel
          value={ITEM_TYPE.SUPPLY}
          control={<Radio color="primary" />}
          label="소모품"
          labelPlacement="end"
        />
      </RadioGroup>
    </div>
  );
};

export default ItemSelector;
