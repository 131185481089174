import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles, Theme, createStyles, Button} from '@material-ui/core';

import {Typography} from '../components/Typography';
import {useHistory, useLocation} from 'react-router';
import {Items} from '~/components/Items';
import {RootState} from '~/app/rootReducer';
import {
  INVENTORY,
  loadStoredItems,
  requestCurrentItems,
  requestInsertReleaseItems,
  requestUpdate,
} from '~/features/Inventory/slice';
import {DEPARTMENT, loadDepartment} from '~/features/Department/slice';
import {PROFILE, profileRequest} from '~/features/Profile/slice';
import moment from 'moment';
import _ from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    body: {
      minWidth: 320,
      padding: '0px 15px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flex: 1,
    },
    itemKind: {
      display: 'flex',
      width: '100%',
      margin: '33px 0 5px 0',
      //justifyContent: 'center',
    },
    itemBox: {
      background: 'rgba(196, 196, 196, 0.16)',
      width: '100%',
      minHeight: 88,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: theme.spacing(0.5),
      marginBottom: theme.spacing(3) + 1,
      flexDirection: 'column',
      padding: theme.spacing(1.125, 0),
    },
    buttons: {
      width: '100%',
      height: 42,
      display: 'flex',
      marginTop: theme.spacing(1) + 3,
    },
    cancel: {
      display: 'inline',
      width: '100%',
      fontSize: '0.9375em',
    },
    submit: {
      display: 'inline',
      width: '100%',
      marginLeft: theme.spacing(1) + 6,
      fontSize: '0.9375em',
    },
  }),
);

type ChangeItem = {
  id: number;
  count: number;
  isChange: boolean;
};

type props = {
  date: string;
};

const MItemScreen = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  // 배열로 저장하게끔 수정해야 함
  //const {centers} = useSelector((state: RootState) => state[CENTER]);
  //const [inventories, setInventories] = useState<Inventory[]>([]);
  const [count, setCount] = useState(0);
  const [id, setId] = useState(0);
  const [change, setChange] = useState(false);
  const [isChange, setIsChange] = useState<ChangeItem[]>([]);

  const location = useLocation<props>().state;

  // 센터 id 가져오기
  const userId = useSelector((state: RootState) => state[PROFILE].id);
  const centerInfo = useSelector(
    (state: RootState) => state[DEPARTMENT].centerInfo,
  );

  // inventory 가져오기
  const {currentItems, invenStore} = useSelector(
    (state: RootState) => state[INVENTORY],
  );

  useEffect(() => {
    if (userId) {
      dispatch(loadDepartment(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(profileRequest());
  }, [dispatch]);

  useEffect(() => {
    if (centerInfo) {
      dispatch(loadStoredItems());
    }
  }, [dispatch, centerInfo]);

  useEffect(() => {
    if (centerInfo) {
      const request = {
        scale: 1, // 해당 년으로 가져와야 함
        datetime: moment(new Date(location.date)),
        centerId: centerInfo.centerId,
      };
      dispatch(requestCurrentItems(request));
    }
  }, [centerInfo, dispatch, location.date]);

  useEffect(() => {
    if (centerInfo) {
      let checkChangeItem: any[] = [];
      _.map(currentItems, (inventory) => {
        checkChangeItem.push({
          id: inventory.id,
          isChange: false,
        });
        return null;
      });
      setIsChange(checkChangeItem);
    }
  }, [centerInfo, currentItems]);

  useEffect(() => {
    if (id !== 0) {
      let tmp: any = [];
      tmp.push({
        id: id,
        count: count,
        isChange: change,
      });
      if (tmp.length !== 0) {
        isChange.forEach((list) => {
          if (list.id === tmp[0].id) {
            list.count = tmp[0].count;
            list.isChange = tmp[0].isChange;
          }
        });
      }
    }
  }, [change, count, id, isChange]);

  const handleUpdate = () => {
    // 모든 item에 대해서 개수 state를 만드는 것이 더 비효율적일 것 같아서 일괄 업데이트하도록 함
    isChange.forEach((list) => {
      if (list.isChange && list.count !== 0) {
        let releaseCount = list.count;
        // 수량 변화가 있는 각 아이템
        const item = _.filter(
          invenStore,
          (stored) => stored.inventoryId === list.id,
        );
        // 가장 오래된 입고날짜면서 currentCount가 0이 아닌 것
        const oldOne = item.reduce((prev, curr) => {
          return new Date(prev.endDate).getTime() <=
            new Date(curr.endDate).getTime() && prev.currentCount !== 0
            ? prev
            : curr;
        });
        // currentCount가 list.cout보다 작을 시 currentCount만큼 감소시키고 다시 뽑아서
        // 남는 개수만큼 차감시켜야 함
        if (oldOne.currentCount >= releaseCount) {
          dispatch(
            requestUpdate({
              id: oldOne.id,
              currentCount: oldOne.currentCount - releaseCount,
            }),
          );
          //release db 추가
          dispatch(
            requestInsertReleaseItems({
              releaseCount: releaseCount,
              price: oldOne.price,
              startDate: moment
                .tz(new Date(location.date), 'Asia/Seoul')
                .format(),
              endDate: moment
                .tz(new Date(location.date), 'Asia/Seoul')
                .format(),
              inventoryId: oldOne.inventoryId,
              userId: userId,
              inventoryStoreId: oldOne.id,
              centerId: oldOne.centerId,
            }),
          );
        } else {
          const ret = _.map(_.sortBy(item, 'startDate'), (i) => {
            // 아직 차감시킬 개수가 많은 경우
            if (i.currentCount <= releaseCount && releaseCount !== 0) {
              releaseCount -= i.currentCount;
              return {
                id: i.id,
                currentCount: 0,
                subCount: i.currentCount,
                price: i.price,
                centerId: i.centerId,
                inventoryId: i.inventoryId,
              };
            } else if (i.currentCount > releaseCount) {
              // 보유 개수가 더 많아진 경우
              const current = i.currentCount - releaseCount;
              const sub = releaseCount;
              releaseCount = 0;
              return {
                id: i.id,
                currentCount: current,
                subCount: sub,
                price: i.price,
                centerId: i.centerId,
                inventoryId: i.inventoryId,
              };
            }
            return undefined;
          });

          // ret: id 별 차감 count 저장
          _.map(ret, (r) => {
            if (r && r.subCount !== 0) {
              dispatch(
                requestUpdate({
                  id: r.id,
                  currentCount: r.currentCount,
                }),
              );

              //release db 추가
              dispatch(
                requestInsertReleaseItems({
                  releaseCount: r.subCount,
                  price: r.price,
                  startDate: moment
                    .tz(new Date(location.date), 'Asia/Seoul')
                    .format(),
                  endDate: moment
                    .tz(new Date(location.date), 'Asia/Seoul')
                    .format(),
                  inventoryId: r.inventoryId,
                  userId: userId,
                  inventoryStoreId: r.id,
                  centerId: r.centerId,
                }),
              );
            }
          });
        }
      }
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <div className={classes.itemKind}>
          <Typography style={{lineHeight: '35px'}} variant={'H4'}>
            비품
          </Typography>
        </div>
        <div className={classes.itemBox}>
          {currentItems.map((inventory, idx) => {
            if (inventory.type === '비품') {
              return (
                <Items
                  key={idx}
                  id={inventory.id}
                  setId={setId}
                  setChange={setChange}
                  setCount={setCount}
                  name={inventory.name}
                  count={inventory.current}
                />
              );
            }
            return undefined;
          })}
        </div>

        <div className={classes.itemKind} style={{marginTop: 0}}>
          <Typography style={{lineHeight: '35px'}} variant={'H4'}>
            소모품
          </Typography>
        </div>
        <div className={classes.itemBox}>
          {currentItems.map((inventory, idx) => {
            if (inventory.type === '소모품') {
              return (
                <Items
                  key={idx}
                  id={inventory.id}
                  setId={setId}
                  setChange={setChange}
                  setCount={setCount}
                  name={inventory.name}
                  count={inventory.current}
                />
              );
            }
            return undefined;
          })}
        </div>
        <div className={classes.buttons}>
          <Button
            onClick={() => {
              history.push({
                pathname: '/inventory',
                state: {
                  year: parseInt(location.date.slice(0, 4)),
                  month: parseInt(location.date.slice(5, 7)),
                  date: parseInt(location.date.slice(8, 10)),
                },
              });
            }}
            variant="contained"
            color="secondary"
            className={classes.cancel}>
            취소
          </Button>
          <Button
            onClick={() => {
              handleUpdate();
              history.push({
                pathname: '/inventory',
                state: {
                  year: parseInt(location.date.slice(0, 4)),
                  month: parseInt(location.date.slice(5, 7)),
                  date: parseInt(location.date.slice(8, 10)),
                },
              });
            }}
            variant="contained"
            color="primary"
            className={classes.submit}>
            완료
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MItemScreen;
