import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core';

import HomeRouter from '~/routers/HomeRouter';
import {MainHeader} from '~/components/MainHeader';
import {WebSideBar} from '~/components/WebSideBar';
import {drawerWidth} from '~/contants/DrawerWidth';
import clsx from 'clsx';
import {WebFooter} from '~/components/WebFooter';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: '#fff',
  },
  content: {
    background: '#fff',
    flexGrow: 1,
    marginLeft: drawerWidth,
  },
  closeMenuContent: {
    marginLeft: theme.spacing(9) + 1,
  },
  paper: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(6),
    padding: theme.spacing(3),
  },
}));

export interface HeaderTitle {
  title: string;
  setTitle: Function;
}

const HomeScreen = (props: HeaderTitle) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);

  const [title, setTitle] = useState('');

  return (
    <div className={classes.root}>
      <MainHeader
        title={title}
        open={menuOpen}
        onClickOpen={() => setMenuOpen(true)}
      />{' '}
      <WebSideBar
        open={menuOpen}
        onClickClose={() => setMenuOpen(false)}
        onChangeTitle={setTitle}
      />
      <main
        className={clsx(classes.content, {
          [classes.closeMenuContent]: !menuOpen,
        })}>
        <div id="current-screen" className={classes.paper}>
          <HomeRouter />
        </div>
      </main>
      <WebFooter />
    </div>
  );
};

export default HomeScreen;
